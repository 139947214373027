
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Achal somani
@Description:UI for OTP
date: 09-12-2019 
*/

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {userLogin} from "./authentication";
import { Form, Icon, Input, Button, Checkbox,Card } from 'antd';
import  './userLogin.css';
import  './forgotPasswordEmail.css';
import Swal from 'sweetalert2';
const queryString = require('query-string');
var dateTime = require("node-datetime");
var moment = require("moment");

class ForgotPwd extends React.Component  {
    constructor() {
    super();
    this.state = {
      fields: {},
      userData:[],
      errors: {},
      OTP:'',
      email:'',
      role:'',
      timeRemain:'120',
      disabled:false,
      drpdisable:'',
      drpdisable2:'',
      userID:'',
      
      
     
    }

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleResendOTP=this.handleResendOTP.bind(this);
  };


  handleResendOTP(e)
  {
    var current= dateTime.create()
    current=current.format("Y-m-d H:M:S")
    var userID=this.state.userID;

    let data={
      userID:userID,
      lastUpdated:current
    }
    
    fetch("/users/resendForgotPasswordOTP", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then(userData=> {
       window.location.reload();
      })
  }


  onSubmitHandler = (e) => {
    e.preventDefault();


    var userID=this.state.userID;

    let data={
      userID:userID
  }
    var userData=this.state.userData;
    
    
    if(true)
    {
      if(this.state.OTP==userData[0].forgotOTP)
      {
        window.location.href="/resetPassword"; //Aman-4876-Remove the passed user id from the url
        // window.location.href="/resetPassword?userID="+userID;
        
      }else{
        let errors={}
        errors['wrongOtp']="Please Enter Correct OTP."
        this.setState({errors:errors})
        // Swal.fire({
        //   text: "Please Enter Correct Otp",
        //   type: 'error',
        //   confirmButtonText: 'Ok',
        //   allowOutsideClick: false,
        // })
      }
    }else{
      fetch("/users/forgotPasswordOTP", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then(response=> { 
        
      })
    }
  }
   
  handleChange(e) {
  
    this.setState({OTP:e.target.value})

  }

  componentDidMount()
  {
    //Aman-4876-added window method to get user id and comented queryString to hide userid from url
    // var parsed = queryString.parse(this.props.location.search);
    // var userID = parsed.userID;
    var userID = window.localStorage.getItem("url");

    this.setState({userID:userID});
    let data={
      userID:userID
  }
  fetch("/users/getForgotPasswordOTP", {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    }).then(res => res.json())
    .then(userData=> { 
      this.setState({userData:userData})  
      var lastUpdated=userData[0].lastUpdated;
      var current = new Date();
      var lup = new Date(lastUpdated);
      var diff=current.getTime()-lup.getTime()
      var hours=Math.floor(diff/1000/60/60)
      diff-=hours*(1000*60*60)
      var seconds = Math.floor(diff / 1000);
  this.setState({timeRemain:this.state.timeRemain-seconds})
      //alert("email"+userData[0].userName)
       this.setState({ email: userData[0].userName }, () => {
        // Rutuja 5451 After state is updated, store email in localStorage
        window.localStorage.setItem("email", this.state.email);
      });
    
  })

    this.intervalId = setInterval(function () {
    var timmerVal = document.getElementById('timmer');
    if (timmerVal) { //Aman-4876-added condtion to check otp time value is 0 or null
    if (timmerVal.innerHTML == 0) 
      {
        //document.getElementById('textdisable').style.display = "block";
        this.setState({drpdisable2:'drpdisable'})
        fetch("/users/forgotPasswordOTP", {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then(response=> { 
          
        })
        return
      }
      var newVal=timmerVal.innerHTML-1;
      //document.getElementById('timmer').innerHTML=newVal
      timmerVal.innerHTML = newVal; //Aman-4876-added to handle newVal
     this.setState({drpdisable:'drpdisable'})
    if(newVal==0)
    {
      let errors={}
      errors['wrongOtp']=""
      this.setState({errors:errors})
      document.getElementById('msg').style.display = "none";
      document.getElementById('textdisable').style.display = "block";
     
    clearInterval(this.intervalId) //Aman-4876-replaced with MyTime it resting time to 120 se again
    this.setState({drpdisable2:'drpdisable',drpdisable:''})
    }
  }
  }.bind(this),1000)
        

  }
  //Aman-4876-added componentWillUnmount to handle otp time issue
componentWillUnmount() {
  clearInterval(this.intervalId);
}
  

render() {
  const { getFieldDecorator } = this.props.form;
  const {errors} = this.state;
  return (
    <div style={{backgroundColor:'rgb(216, 221, 230)',overflowX: "hidden"}}>
   

    <div class="container-fluid" style={{position:"absolute",width:"100%",height:"100%"}}>
    <div class="row" style={{position:"absolute",width:"100%",height:"100%"}}>
    <div class="col-xs-12  col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center" id="forgot_password_otp_background"  style={{backgroundSize:'cover', backgroundPosition: 'center center',backgroundRepeat: 'no-repeat',zIndex:'1'}}> 
    <br/><br/><div id="forgot_password_otp_form" style={{marginTop:'120px',zIndex:'5'}}>

    <Card  id="forgot_password_otp_form_card" style={{ width: 350, height:400,display:"block",border:"2px solid #ff000005",borderRadius:"10px",backgroundColor:'rgba(20,72,152,0.0)',paddingTop:'50px'}}> 
    <span id="msg" style={{color:"white"}}>&nbsp;OTP is sent to<br/> {this.state.email} </span>
    
    <Form onSubmit={this.handleSubmit} className="login-form" layout={"inline"} >
     <span style={{color:'red'}} > {errors.agency}</span>
     <br/>
     <div>
     <Form.Item >
          {getFieldDecorator('username', {
            rules: [{  message: 'Please Enter Your OTP' }],
          })(
            
            <Input 
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="OTP" onChange={this.handleChange} id="OTP" name="OTP"/>,
          
          )}
         
        </Form.Item>
       
       <br/>
        <Form.Item >
          {this.state.timeRemain>=0?
          <span><label style={{color:'white',fontWeight: "100"}}>Time Remaining&nbsp;:&nbsp;</label><label style={{color:'white',fontWeight: "100"}} id="timmer"> {this.state.timeRemain}</label> <label style={{color:'white',fontWeight: "100"}}>sec</label></span>
          :<span><label style={{color:'white',fontWeight: "100"}}>Time Remaining&nbsp;:&nbsp;</label><label style={{color:'white', fontWeight: "100"}} id="timmer">0</label> <label style={{color:'white',fontWeight: "100"}}>sec</label><label style={{color:"red", fontWeight: "100", width: "316px"}}>Your OTP Is Expired, Please Click On Resend OTP</label></span>
          }
        
          </Form.Item>
          
          <span id="textdisable"  name="text" style={{display:"none", color:"red"}}>Your OTP is expired, Please click On Resend OTP.</span><br/>
          <span class="errorMessage" style={{color:"red"}}>{this.state.errors.wrongOtp}</span>
        <Form.Item >
          
          <div class="row">
          <button type="primary"onClick={this.onSubmitHandler} className={this.state.drpdisable2+' '+"login-form-button"} onClick={this.onSubmitHandler} style={{width:'100px',margin:'20 auto',height:'40px',borderRadius:'40px',fontSize:'18px',backgroundImage: 'linear-gradient(#1f143a, #101f65, #093394)',outline:'none',color:'white'}}>
            Submit
          </button>
          <br/>

          <button type="primary" htmlType="submit" className="login-form-button" style={{width:'100px',display:'inline',float:'right',height:'40px',margin:'0 auto',borderRadius:'40px',fontSize:'18px',backgroundImage: 'linear-gradient(#1f143a, #101f65, #093394)',outline:'none',color:'white'}}>
          <a href="userLogin" style={{color:'white'}}>Cancel</a>
          </button>
          
          </div>

          <br/>
          <button type="primary" onClick={this.handleResendOTP} className={this.state.drpdisable+' '+"login-form-button"} onClick={this.handleResendOTP} style={{borderRadius:'40px',fontSize:'18px',backgroundImage: 'linear-gradient(#1f143a, #101f65, #093394)',outline:'none',color:'white',width:'186px'}} >
            Resend OTP
          </button>
      
        </Form.Item>
        </div>
      </Form>
      </Card> 
      </div>
      </div>     
    </div>
    </div>

</div>);
}


      propTypes = {
        loginUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired
      }
      
      }
      const mapStateToProps = (state) => ({
        auth: state.auth,
        errors: state.errors
      })
const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(ForgotPwd);
export default connect(mapStateToProps, { userLogin })(WrappedNormalLoginForm); 