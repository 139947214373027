/* Copyright(c) 2024 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Nilesh Zinzurao - 5055
 *@fileName :diqaUnreadAlerts.js
 *Desc:UI for DIQA/DC Unread Alerts
 *@Date of creation:02/04/2024
 */

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../login/authentication";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navigation from "../layouts/navPage";

import "datatables.net";
import "antd/dist/antd.css";
import "../agency/loader1.css";
import "../agency/agencyUnreadAlerts.css";
// import Moment from "react-moment";//kiran-4745-removing console warning-no used

import { FaArrowAltCircleLeft } from "react-icons/fa";

import Footer from "../layouts/footer";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //karan-task-3717-added default header

class diqaUnreadAlerts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agencyID: "",
            columnUnreadAlertsSearch: [],
            tableSearchText2: "",
            unreadAlerts: [],
            unreadAlertsLength: 0,
            unreadAlertsData: [],
            loading: " ",
        }; //end of state

        this.handleChangeSearch = this.handleChangeSearch.bind(this);
    } //end of constructor

    componentWillMount() {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push("/userLogin");
        } else {
            const { user } = this.props.auth;
            //  var agencyID = user.id;

            //  this.setState({ agencyID: agencyID });
            // karan-task-3715-replace query params
            var campID;
            //  if (this.props.location.state !== undefined) {
            //      campID = this.props.location.state.campID;
            //  } else {
            //      this.props.history.push("/newDashboard");
            //  }

            fetch("/notification/diqaUnreadAlerts", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
            }) //karan-task-3796-vapt remove ids
                .then((res) => res.json())
                .then((unreadAlerts) => {
                    let filterunreadalerts = unreadAlerts.filter((a) => {
                        //kiran-4745-added toString() and changed == to ===
                        return a.campID.toString() === campID;
                    });
                    var array = [];
                    var array1 = [];
                    for (var i = 0; i < unreadAlerts.length; i++) {
                        if (unreadAlerts[i].campID === campID) {
                            array.push(unreadAlerts[i]);
                        } else {
                            array1.push(unreadAlerts[i]);
                        }
                    }

                    var finalArray = array.concat(array1);
                    this.setState({
                        unreadAlertsData: finalArray,
                        unreadAlerts: filterunreadalerts,
                        unreadAlertsLength: unreadAlerts.length,
                    });

                    if (this.state.unreadAlerts && this.state.unreadAlerts.length > 0) {
                        var name = "key";
                        var value;
                        for (let i = 0; i < this.state.unreadAlerts.length; i++) {
                            value = this.state.unreadAlerts[i].alertID;
                            let unreadAlerts = [...this.state.unreadAlerts];
                            unreadAlerts[i] = { ...unreadAlerts[i], [name]: value };
                            this.setState({ unreadAlerts: unreadAlerts });
                        }
                    }
                })
                .catch(function (err) {
                    console.log(err);
                });
        }
    } // end of componentwillMount

    UnreadAlertBackButton(e) {
        e.preventDefault();
        // var agencyID = user.id;
        //Priyanka-5465-Changed page name
        this.props.history.push("/qualityLeadsReview");
    } //end of UnreadAlertBackButton

    campHandleChange(i, e) {
        e.preventDefault();
        this.setState({
            loading: "whirly-loader",
        });

        var campID = this.state.unreadAlertsData[i].campID;
        var alertID = this.state.unreadAlertsData[i].alertID;

        fetch("/notification/diqaUnreadAlerts", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => res.json())
            .then((unreadAlerts) => {
                let filterunreadalerts = unreadAlerts.filter((a) => {
                    return a.alertID === alertID;
                });

                var array = [];
                var array1 = [];
                for (var i = 0; i < unreadAlerts.length; i++) {
                    if (unreadAlerts[i].alertID === alertID) {
                        array.push(unreadAlerts[i]);
                    } else {
                        array1.push(unreadAlerts[i]);
                    }
                }

                this.setState({
                    unreadAlerts: filterunreadalerts,
                    unreadAlertsLength: filterunreadalerts.length,
                    loading: "",
                });

                if (this.state.unreadAlerts && this.state.unreadAlerts.length > 0) {
                    var name = "key";
                    var value;
                    for (let i = 0; i < this.state.unreadAlerts.length; i++) {
                        value = this.state.unreadAlerts[i].alertID;
                        let unreadAlerts = [...this.state.unreadAlerts];
                        unreadAlerts[i] = { ...unreadAlerts[i], [name]: value };
                        this.setState({ unreadAlerts: unreadAlerts });
                    }
                }

                // Check if all alerts have been read
                let allRead = unreadAlerts.every(alert => alert.status === 'Read');
                if (allRead) {
                    // Clear all alert messages
                    this.setState({ unreadAlertsData: [] });
                }
            })
            .catch(function (err) {
                console.log(err);
            });

        let data = {
            alertID: alertID,
            clickedcampaignID: campID,
        };

        fetch("/notification/AgencyAlertsToAgecncyStatusChanged", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.success === true) {
                    let unreadAlertsData = this.state.unreadAlertsData;

                    for (let i = 0; i < unreadAlertsData.length; i++) {
                        if (Number(unreadAlertsData[i].alertID) === Number(alertID)) {
                            unreadAlertsData[i].status = 'Read';
                        }
                    }

                    this.setState({
                        unreadAlertsData: unreadAlertsData
                    });
                }
            })

            .catch(function (err) {
                console.log(err);
            });
    } //end of campHandleChange

    handleChangeSearch(e) {
        e.preventDefault();
        let currentList = [];
        // Variable to hold the filtered list before putting into state
        let newList = [];
        let filterData = e.target.value;
        // If the search bar isn't empty
        if (e.target.value === "") {
            newList = this.state.unreadAlertsData;
        } else {
            // Assign the original list to currentList
            currentList = this.state.unreadAlertsData;
            newList = currentList.filter((item) => {
                let obj = {};//Somnath Task-3972, Add specific Keys in search criteria
                obj[`campID`] = item.campID;
                obj[`campaignName`] = item.campaignName;
                obj[`startDate`] = item.startDate;
                obj[`endDate`] = item.endDate;
                obj[`CPL`] = item.CPL;
                obj[`allocatedLead`] = item.allocatedLead;
                //const lc = item.toLowerCase();
                // change search term to lowercase
                const filter = e.target.value.toLowerCase();
                // check to see if the current list item includes the search term
                // If it does, it will be added to newList. Using lowercase eliminates
                // issues with capitalization in search terms and search content
                var data = Object.values(obj);
                var dataFinal = data.toString();
                dataFinal = dataFinal.replace(",", " ");
                var dataToLower = dataFinal.toLowerCase();
                return dataToLower.includes(filter);
            });
        }
        // Set the filtered state based on what our rules added to newList
        this.setState({
            columnUnreadAlertsSearch: [...newList],
            tableSearchText2: filterData,
        });
    } //end of handleChangeSearch

    render() {
        return (
            <div>
                <Navigation />
                <div class="container-fluid">
                    <div class="row" style={{ paddingTop: "100px" }}>
                        <div class="col-sm-12 col-md-6 col-lg-2">
                            {/* kiran-4745-removing console warning-added "#/" */}
                            <a
                                href="#/"
                                style={{ color: "#056eb8" }}
                                onClick={this.UnreadAlertBackButton.bind(this)}>
                                <FaArrowAltCircleLeft
                                    size={32}
                                    title="Back to Lead Review"
                                />
                            </a>
                            <h3 style={{ color: "#124E9C" }}>Unread Alerts</h3>
                        </div>

                        <div class=" float-lg-right col-sm-12 col-md-6 col-lg-3  offset-lg-7">
                            <span>
                                <input
                                    className="search"
                                    type="text"
                                    name="search"
                                    placeholder="Search by campaign ID OR any campaign keyword."
                                    style={{
                                        backgroundImage: "url(searchIcon.png)",
                                        backgroundPosition: "15px 6px",
                                        backgroundSize: "15px 15px",
                                        backgroundRepeat: "no-repeat",
                                        width: "280px",
                                        height: "30px",
                                        marginTop: "35px",
                                        fontSize: "10px",
                                        border: "1px solid lightGray",
                                        padding: "12px 20px 12px 34px",
                                        borderRadius: "5px",
                                        marginLeft: "-5rem"
                                    }}
                                    onChange={this.handleChangeSearch}
                                    onPressEnter={this.handleChangeSearch}></input>
                            </span>
                        </div>
                    </div>
                    {/* end of 1st row */}

                    <div class="row">
                        <div class=" first_div col-sm-12 col-md-6 col-lg-4">
                            <div
                                style={{
                                    background: "#FFFFFF",
                                    height: "420px",
                                    width: "340px",
                                    overflowY: "scroll",
                                }}>
                                <h4
                                    style={{
                                        color: "black",
                                        marginLeft: "20px",
                                        marginTop: "20px",
                                    }}>
                                    All
                                </h4>
                                <hr />
                                {this.state.unreadAlertsData.length > 0 ? (
                                    <>
                                        {this.state.unreadAlertsData.map((unreadAlertsData, i) => {
                                            return (
                                                <p style={{ marginLeft: "5px", color: "black" }}>
                                                    <td>
                                                        <span>
                                                            <a
                                                                href="#/"
                                                                onClick={this.campHandleChange.bind(this, i)}
                                                                id={unreadAlertsData.campID}
                                                                campID={unreadAlertsData.campID}
                                                                alertID={unreadAlertsData.alertID}
                                                                // className="clicked-link"
                                                                style={{ color: unreadAlertsData.status === "Read" ? "black" : "" }}

                                                            >
                                                                The Campaign{" "}
                                                                <b>&nbsp;{unreadAlertsData.campaignName}&nbsp;</b>
                                                                <b> CID :&nbsp;{unreadAlertsData.campID}&nbsp;</b>
                                                                has been
                                                                <b>&nbsp;{unreadAlertsData.content}&nbsp;</b>
                                                                {unreadAlertsData.author}&nbsp;on&nbsp;
                                                                {unreadAlertsData.datetime}
                                                                <hr />
                                                            </a>
                                                        </span>
                                                    </td>
                                                </p>
                                            );
                                        })}
                                    </>
                                )
                                    : (
                                        // Sandeep-task-2998 added bellIcon
                                        <span style={{ marginLeft: "60px" }}>
                                            {/* kiran-4745-removing console warning-added alt="" */}
                                            <img
                                                alt=""
                                                className="alertImg"
                                                src="bellIcon.png"
                                                width="100"
                                                height="80"
                                            ></img>
                                            <sapn
                                                className="noAlert"
                                                style={{
                                                    color: "black",
                                                    fontWeight: "600",
                                                    marginLeft: "85px",
                                                    fontSize: "15px",
                                                }}
                                            >
                                                "No Alerts Available!!"
                                            </sapn>
                                        </span>
                                    )}
                            </div>
                            <br />
                            <br />
                        </div>
                        <br />
                        <div class=" second_div col-sm-12 col-md-6 col-lg-8">
                            <div
                                style={{
                                    background: "#FFFFFF",
                                    height: "420px",
                                    width: "870px",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                    paddingTop: "15px",
                                }}>
                                <div
                                    id="pleaseWait"
                                    style={{
                                        color: "red",
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                        left: "400px",
                                        top: "200px",
                                    }}
                                    className={this.state.loading}></div>
                                {/* kiran-4745-removing console warning-changed == to === */}
                                {this.state.tableSearchText2 === "" ? (
                                    <div>
                                        {this.state.unreadAlerts.map((unreadAlerts) => {
                                            // {alert("unreadAlerts -->" + JSON.stringify(unreadAlerts))}
                                            return (
                                                <p style={{ marginLeft: "5px", color: "black" }}>
                                                    <div>
                                                        <h4 style={{ marginLeft: "15px" }}>
                                                            {unreadAlerts.content}
                                                        </h4>
                                                        <h5 style={{ marginLeft: "15px", color: "black" }}>
                                                            <b style={{ width: "2px" }}>
                                                                Campaign ID :&nbsp;{unreadAlerts.campID}
                                                            </b>
                                                        </h5>
                                                        <h5 style={{ marginLeft: "15px", color: "black" }}>
                                                            <b>
                                                                Campaign Name :&nbsp;{unreadAlerts.campaignName}
                                                            </b>
                                                        </h5>
                                                        <div class="row">
                                                            <p style={{ marginLeft: "15px" }}>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;Start Date :&nbsp;
                                                                {unreadAlerts.startDate}
                                                            </p>
                                                            <p>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;End
                                                                Date :&nbsp;{unreadAlerts.endDate}
                                                            </p>
                                                            <p>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                Time :&nbsp;{unreadAlerts.datetime}
                                                            </p>
                                                            <p style={{ marginLeft: "15px" }}>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Allocation
                                                                :&nbsp;{unreadAlerts.allocatedLead}
                                                            </p>
                                                            <p>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CPL
                                                                :&nbsp;{unreadAlerts.CPL}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </p>
                                            );
                                        })}
                                    </div>
                                ) : this.state.columnUnreadAlertsSearch.length > 0 ? (
                                    <div>
                                        {this.state.columnUnreadAlertsSearch.map(
                                            (columnUnreadAlertsSearch) => {
                                                return (
                                                    <p style={{ marginLeft: "5px", color: "black" }}>
                                                        <div>
                                                            <h4 style={{ marginLeft: "15px" }}>
                                                                {columnUnreadAlertsSearch.content}
                                                            </h4>
                                                            <h5
                                                                style={{ marginLeft: "15px", color: "black" }}>
                                                                <b style={{ width: "2px" }}>
                                                                    Campaign ID :&nbsp;
                                                                    {columnUnreadAlertsSearch.campID}
                                                                </b>
                                                            </h5>
                                                            <h5
                                                                style={{ marginLeft: "15px", color: "black" }}>
                                                                <b>
                                                                    Campaign Name :&nbsp;
                                                                    {columnUnreadAlertsSearch.campaignName}
                                                                </b>
                                                            </h5>
                                                            <div class="row">
                                                                <p style={{ marginLeft: "15px" }}>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;Start Date :&nbsp;
                                                                    {columnUnreadAlertsSearch.startDate}
                                                                </p>
                                                                <p>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;End
                                                                    Date :&nbsp;{columnUnreadAlertsSearch.endDate}
                                                                </p>
                                                                <p style={{ marginLeft: "15px" }}>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Allocation
                                                                    :&nbsp;
                                                                    {columnUnreadAlertsSearch.allocatedLead}
                                                                </p>
                                                                <p>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CPL
                                                                    :&nbsp;{columnUnreadAlertsSearch.CPL}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </p>
                                                );
                                            }
                                        )}
                                    </div>
                                ) : (
                                    <center>
                                        <label
                                            style={{
                                                color: "gray",
                                                fontSize: "medium",
                                                padding: "200px",
                                            }}>
                                            No Data Exist
                                        </label>
                                    </center>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* end of 2nd row */}
                </div>{" "}
                {/*end of container-fluid*/}
                <Footer />
            </div> //end of main div
        ); //end of return
    } //end of render
} //end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */

diqaUnreadAlerts.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
    withRouter(diqaUnreadAlerts)
);
