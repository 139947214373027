/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Chaitanya Shinde - 5426
 *@fileName :
 *Desc: DI QA TL Front End File
 */

 import React, { Component } from "react";
 import { withRouter } from "react-router-dom";
 import { logoutUser } from "../login/authentication";
 import PropTypes from "prop-types";
 import { connect } from "react-redux";
 import Navigation from "../layouts/navPage";
 // import Moment from 'react-moment'; //kiran-4752-removing console warning-no used
 import { Table, Select, Input, Tooltip, Icon, Badge, Button } from "antd"; //Aman-4947-added Tooltip, Icon
 import "datatables.net";
 import Footer from "../layouts/footer";
 import Swal from "sweetalert2";
 import { saveAs } from "file-saver";
 import { css } from "emotion";
 import "./qaloginCss.css";
 import { LeadReviewLoader } from "../loaders/LeadReviewLoader"; // Sandeep-task-3441-added loader
 import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //snehal-task-3724-vAPT URL changes
 //import { width } from 'pdfkit/js/page';
 import styled from "styled-components"; //Aman-4947-added to style tootip
 import { Tabs, Tab } from "react-bootstrap"; //Priyanka-4603
import { FaArrowAltCircleLeft } from "react-icons/fa";
 const xlsx = require("xlsx");
 const { Option } = Select;
 // const queryString = require('query-string');//kiran-4752-removing console warning-no used
 const tableCSS = css({
   backgroundColor: "white",
   fontFamily: "sans-serif", //sunita-task-3271-added css as per requirement
   borderStyle: "bold",
   // height: "40%", //sunita-task-3271-commneted the css as it's not required.
   "& thead  > tr": {
     backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
     color: "white",
     fontSize: "12px",
     textAlign: "center",
     fontWeight: "500",
   },
   "& thead > tr >th": {
     textAlign: "center",
     color: "white",
   },
 });
 
 //Aman-4947-added to hablde list in tooltip
 /* //snehal-task-2798-UI/UX-Changes-3.1 */
 const HtmlTooltip = styled(Tooltip)`
         backgroundColor: '#f5f5f9',
         color: 'rgba(0, 0, 0, 0.87)',
         maxWidth: 220,
         fontSize: '12px',
         border: '1px solid #dadde9',
         `;
 
 class dcReview extends Component {
   constructor(props) {
     super(props);
     this.state = {
       key: 1,
       agencyID: "",
       columnUnreadAlertsSearch: ["1", "2", "3"],
       tableSearchText: "", //sunita-task 3271-added this parameter for search functionality.
       unreadAlerts: [],
       unreadAlertsLength: 0,
       campaignList: [],
       loading: " ",
       uploadedFile: [],
       campDetails: [],
       campID: "",
       finalArray: [],
       disable: false,
       diLeadDetails: [],
       tableState: { size: "small" },
       getLeadCounts: "",
       reviewFlag: false,
       pageSize2: 100, //sunita-task-3271-added code for pagination
       leadDetails: [], //sunita-task-3271-added this array for storing campID when we are coming from dashboard.
       leadDetailsSearch:
         [] /* sunita-task-3271- added this array for search functionality*/,
       loading1: false, //sunita-task-3271-added code for loader
       defaultCampID: "", //sunita-task-3271-added code as per requirement
       filesData1: "", //sunita-task-3271-added code for file
       loader2: "", //sandeep--3441-used for loader.
       campList: [],
       campListSearch: [],
       leadDetailsCount: "",
       rejReasonDisplay: "none",
       errors: {},
       reasonErrorDisplayFlag: false,
       downloadFileLeadsLength: 0, //4788 - Initialize with the default value
       leadStartSelectedDate: "", //Aman-4947-added to store start input Selected date
       leadEndSelectedDate: "", //Aman-4947-added to store end input Selected date
       filterleadDetails: "", //Aman-4947-added to stored filters lead info
       filterleadDetailsCount: "", //Aman-4947-added to stored filters lead info count
       filterleadDetailsSatus: false, //Aman-4947-added to check the status of filter lead and total lead
       stectedStartDateforDisplay: "", //Aman-4947-added to display Selected start date
       stectedEndDateforDisplay: "", //Aman-4947-added to display Selected end date
       selectedDateStart: "", //Aman-4947-added to reset Selected start date from input filed
       selectedDateEnd: "", //Aman-4947-added to reset Selected end date from input filed
       unreadAlerts: ""
     };
     this.handleSelect = this.handleSelect.bind(this);
     this.handleChangeSearch = this.handleChangeSearch.bind(this);
     this.fileupload = this.fileupload.bind(this);
     this.displayCampaignSpecification =
       this.displayCampaignSpecification.bind(this);
     this.downloadFile = this.downloadFile.bind(this);
     this.displayLeads = this.displayLeads.bind(this);
     this.handleSubmit = this.handleSubmit.bind(this);
     this.validateForm = this.validateForm.bind(this);
 
     this.loadingRef = React.createRef(); // shows the Loader
     this.closeLoadingRef = React.createRef(); // hides the Loader
 
     //Aman-4947-added startDatedateHandleChange and endDatedateHandleChange to bind
     this.startDatedateHandleChange = this.startDatedateHandleChange.bind(this);
     this.endDatedateHandleChange = this.endDatedateHandleChange.bind(this);
     this.openLinkInBrowser = this.openLinkInBrowser.bind(this); //Nilesh-5051-Add binding method
   }
 
   componentWillMount() {
     if (!this.props.auth.isAuthenticated) {
       this.props.history.push("/userLogin");
     } else {
       this.getFirstTabData();
     } // end of componentwillMount
     //Nilesh-5055- add route for alerts count showing on UI
     fetch("/notification/diqaUnreadAlerts", {
       method: "POST",
       headers: { "Content-Type": "application/json" },
     })
       .then((res) => res.json())
       .then((unreadAlerts) => {
         //sunita-task-3271-added below all api code for showing by default campaign id and it's data according to that as this api are alredy present in page jst accessed below as per requirement.
         if (unreadAlerts.length > 0) {
           this.setState({
             unreadAlerts: unreadAlerts
           });
         } else {
 
         }
       })
       .catch(function (err) {
       });
   }
 
   handleSelect(key) {
     this.setState({ key });
     // const { user } = this.props.auth;
     // const { id: agencyID, role: userRole } = user;//kiran-4752-removing console warning-no used
     if (key === 1) {
       this.getFirstTabData();
     } else {
       this.getSecondTabData();
     }
   }
 
   getFirstTabData() {
     let key = 1;
     this.setState({
       loader2: "loader_review",
       key: key,
       campListSearch: "",
       tableSearchText: "",
     }); //Sandeep-task-3441-added code for loader
     this.getCampaignList(key);
     //this.getLeadTableData(key);
   }
   //kiran-4632-set campListSearch and tableSearchText to empty
   getCampaignList(key) {
     //alert("getCampaignList");
     //kiran-4752-removing console warning-changed == to ===
     let self = this;
     if (key === 1) {
       fetch("/diQARole/leadReviewDI", {
         method: "POST",
         headers: { "Content-Type": "application/json" },
       })
         .then((res) => res.json())
         .then((campList) => {
           //sunita-task-3271-added below all api code for showing by default campaign id and it's data according to that as this api are alredy present in page jst accessed below as per requirement.
           if (campList.length > 0) {
             let defaultCampID = campList[0].campID; //sunita-task-3271-added as per requirement
 
             this.setState({ campList: campList, campID: defaultCampID }); //sunita-task-3271-setstate for getting updated values
             this.getCampaignInfo(defaultCampID, key);
           } else {
             // kiran-4752-removing console warning-remove duplicate leadDetails
             this.setState({
               loader2: "",
               campList: [],
               campID: "",
               campDetails: [],
               leadDetails: [],
               getLeadCounts: "",
               leadDetailsCount: "",
             });
           }
         })
         .catch(function (err) {
           self.setState({ loading1: false, loader2: "" }); // rutuja Jagtap 4123- added for stoping loader and showing No Data if theier is no data on QA side
         });
     } else {
       fetch("/diQARole/leadReviewPV", {
         method: "POST",
         headers: { "Content-Type": "application/json" },
       })
         .then((res) => res.json())
         .then((campList) => {
           //sunita-task-3271-added below all api code for showing by default campaign id and it's data according to that as this api are alredy present in page jst accessed below as per requirement.
           if (campList.length > 0) {
             let defaultCampID = campList[0].campID; //sunita-task-3271-added as per requirement
 
             this.setState({ campList: campList, campID: defaultCampID }); //sunita-task-3271-setstate for getting updated values
             this.getCampaignInfo(defaultCampID, key);
           } else {
             // kiran-4752-removing console warning-remove duplicate leadDetails
             this.setState({
               loader2: "",
               campList: [],
               campID: "",
               campDetails: [],
               leadDetails: [],
               getLeadCounts: "",
               leadDetailsCount: "",
             });
           }
         })
         .catch(function (err) {
           self.setState({ loading1: false, loader2: "" }); // rutuja Jagtap 4123- added for stoping loader and showing No Data if theier is no data on QA side
         });
     }
   }
 
   getCampaignInfo(campID, key) {
     this.setState({ filterleadDetailsSatus: false }); //Aman-4947-added to set the filter lead status to false
     // alert("getCampaignInfo");
     let data = { campID: campID };
     //sunita-task-3271-getting right side campaign detail info
     this.setState({ loading1: true, loader2: "loader_review" });
     fetch("/diQARole/getCampDetails", {
       method: "POST",
       headers: { "Content-Type": "application/json" },
       body: JSON.stringify(data),
     })
       .then((res) => res.json())
       .then((campDetails) => {
         //sunita-task-3271-added code for voiceloglink column
         // kiran-4752-removing console warning-changed == to ===
         if (
           (campDetails[0].marketingChannel === "TeleMarketing" &&
             campDetails[0].callAudit === "Yes") ||
           (campDetails[0].marketingChannel === "Email/Telemarketing" &&
             campDetails[0].callAudit === "Yes")
         ) {
           this.setState({ marketingChannelFlag: true });
         } else {
           this.setState({ marketingChannelFlag: false });
         }
         //Chaitanya-4973-Setting clientName value
         this.setState({
           campDetails: campDetails,
           agencyID: campDetails[0].agencyID,
           reviewFlag: false,
           campID: campID,
           clientName: campDetails[0].clientName,
           clientCampID: campDetails[0].clientCampID, //Chaitanya-5019-Setting clientCampID value
         });
       });
     document.getElementById("submitButton").disabled = false;
     document.getElementById("submitButton1").disabled = false; //sunita-task-3422-added code here for disabling the submit button as only one button get disabled as per requirement done the changes.
 
     //Priyanka-4633-Enabling Submit button after loading data on PV tab
     document.getElementById("submitButtonPV").disabled = false;
     document.getElementById("submitButtonPV1").disabled = false;
     this.getLeadTableData(campID, key);
   }
 
   getLeadTableData(campID, key) {
     //alert(key);
     //kiran-4752-removing console warning-changed == to ===
     if (key === 1) {
       let data = { campID: campID };
       //sunita-task-3271-get downloaded lead count
       fetch("/diQARole/getDIQaLeadCounts", {
         method: "POST",
         headers: { "Content-Type": "application/json" },
         body: JSON.stringify(data),
       })
         .then((res) => res.json())
         .then((getDIQaLeadCounts) => {
           if (getDIQaLeadCounts.length > 0) {
             // var campID = getDIQaLeadCounts[0].campID; //kiran-4752-removing console warning-no used
           }
 
           let cnt = getDIQaLeadCounts.length;
           if (cnt < 0) {
             cnt = 0;
           }
           this.setState({ getLeadCounts: cnt });
         })
         .catch(function (err) {
           console.log(err);
         });
 
       //sunita-task-3271-display all diqa leads.
       fetch("/diQARole/DIQaleadDetails", {
         method: "POST",
         headers: { "Content-Type": "application/json" },
         body: JSON.stringify(data),
       })
         .then((res) => res.json())
         .then((leadDetails) => {
           let count = leadDetails.length;
           if (count < 0) {
             count = 0;
           }
           this.setState(
             {
               leadDetails: leadDetails,
               leadDetailsCount: count,
               loading1: false,
               loader2: "",
             },
             () => {
               document.getElementById("fileName1").innerHTML =
                 "No file Choosen";
             }
           ); //Sandeep-task-2892-added code for to show lead upload file is absence
         })
         .catch(function (err) {
           console.log(err);
         });
     } else {
       let data = { campID: campID };
       //sunita-task-3271-get downloaded lead count
       fetch("/diQARole/getPVLeadCounts", {
         method: "POST",
         headers: { "Content-Type": "application/json" },
         body: JSON.stringify(data),
       })
         .then((res) => res.json())
         .then((getPVLeadCounts) => {
           if (getPVLeadCounts.length > 0) {
             // var campID = getPVLeadCounts[0].campID;
           }
           let cnt = getPVLeadCounts.length;
           if (cnt < 0) {
             cnt = 0;
           }
           this.setState({ getLeadCounts: cnt });
         })
         .catch(function (err) {
           console.log(err);
         });
 
       //sunita-task-3271-display all diqa leads.
       fetch("/diQARole/pvLeadDetails", {
         method: "POST",
         headers: { "Content-Type": "application/json" },
         body: JSON.stringify(data),
       })
         .then((res) => res.json())
         .then((leadDetails) => {
           let count = leadDetails.length;
           if (count < 0) {
             count = 0;
           }
           this.setState(
             {
               leadDetails: leadDetails,
               leadDetailsCount: count,
               loading1: false,
               loader2: "",
             },
             () => {
               document.getElementById("fileName2").innerHTML =
                 "No file Choosen";
             }
           ); //Sandeep-task-2892-added code for to show lead upload file is absence
         })
         .catch(function (err) {
           console.log(err);
         });
     }
   }
 
   getSecondTabData() {
     //alert("calling getSecondTabData")
     let key = 2;
     //Priyanka-4633-Making all variables null to avoid page to being hang
     this.setState({
       loader2: "loader_review",
       key: key,
       campListSearch: "",
       tableSearchText: "",
       leadDetails: [],
       campList: [],
       leadDetailsCount: "",
       getLeadCounts: "",
     }); //Sandeep-task-3441-added code for loader
     this.getCampaignList(key);
     //this.getLeadTableData(key);
   }
   //kiran-4632-set campListSearch and tableSearchText to empty
   displayLeads(e) {
     e.preventDefault();
     //Aman-4947-added below this.setstate to reset all the filter values
     this.setState({
       filterleadDetailsSatus: false,
       leadStartSelectedDate: "",
       leadEndSelectedDate: "",
       selectedDateStart: "", // Reset input value
       selectedDateEnd: "", // Reset input value
     });
     let key = this.state.key;
     let campID = e.target.id;
     this.getCampaignInfo(campID, key);
   }
 
   /**
    * @author Rutuja Jagtap
    * @param  Description handle the campaignSpecification on DIQA review lead page
    * @return Description redirect to campaignSpecification page
    */
   displayCampaignSpecification(e) {
     var campID = this.state.campID;
     sessionStorage.setItem("campID", campID);
     window.open("/campaignSpecificationQADashboard");
   }
 
   handleChangePageSize2(e) {
     var pageSize2 = e.target.value;
 
     this.setState({ pageSize2: pageSize2 });
   }
 
   handleChangeSearch(e) {
     e.preventDefault();
     let currentList = [];
     // Variable to hold the filtered list before putting into state
     let newList = [];
     let filterData = e.target.value;
     //alert(filterData)
     // If the search bar isn't empty
     if (e.target.value === "") {
       //alert("if")
       newList = this.state.campList;
     } else {
       //alert("else"+JSON.stringify(this.state.campList))
       // Assign the original list to currentList
       currentList = this.state.campList;
       //alert("currentList-->"+JSON.stringify(currentList))
 
       newList = currentList.filter((item) => {
         // change current item to lowercase
         const lc = item;
         //const lc = item.toLowerCase();
         // change search term to lowercase
         const filter = e.target.value.toLowerCase();
         //alert("filter-->"+filter)
         // check to see if the current list item includes the search term
         // If it does, it will be added to newList. Using lowercase eliminates
         // issues with capitalization in search terms and search content
         var data = Object.values(lc);
         var dataFinal = data.toString();
         dataFinal = dataFinal.replace(",", " ");
         var dataToLower = dataFinal.toLowerCase();
         //alert("dataToLower-->"+dataToLower)
         return dataToLower.includes(filter);
       });
       //alert("newList-->"+JSON.stringify(newList))
     }
     // Set the filtered state based on what our rules added to newList
     this.setState({
       campListSearch: [...newList],
       tableSearchText: filterData,
     });
   }
 
   /**
    * @author Sunita Landge
    * @param  Description for voicelog link.
    * @return return the voicelog link data
    */
   //sunita-task-3271-added this handle change for voicelog link
   openLinkInBrowser(e) {
     e.preventDefault();
     var leadInfoID = e.target.id;
     fetch("lead/getVoiceLogLink?leadInfoID=" + leadInfoID + "")
       .then((res) => res.json())
       .then((voiceLogDetails) => {
         this.setState({ voiceLogDetails: voiceLogDetails });
         window.open(voiceLogDetails[0].voiceLogLink, "_blank");
       })
       .catch(function (err) {
         console.log(err);
       });
   }
 
   handleChange(i, value, type) {
     //kiran-4752-removing console warning-changed == to === and use setState
     if (type === "reason") {
       let finalArray = [...this.state.leadDetails];
       finalArray[i] = { ...finalArray[i], reason: value.target.value };
       // this.state.finalArray = [...finalArray];
       this.setState({ finalArray: [...finalArray] });
       this.setState(
         { leadDetails: finalArray, reasonErrorDisplayFlag: false },
         function () {}
       );
     }
     if (type === "status") {
       let finalArray = [...this.state.leadDetails];
       finalArray[i] = { ...finalArray[i], status: value };
       // this.state.finalArray = [...finalArray];
       this.setState({ finalArray: [...finalArray] });
       this.setState({ leadDetails: finalArray }, function () {});
       if (value === "PV REJECTED") {
         this.setState({ reasonErrorDisplayFlag: true });
       } else {
         this.setState({ reasonErrorDisplayFlag: false });
       }
     }
   }
 
   handleSubmit() {
     // const { isAuthenticated, user } = this.props.auth; //kiran-4752-removing console warning-no used
     let key = this.state.key;
     if (key === 1) {
       //kiran-4752-removing console warning-changed == to ===
       let updatedLeadDetails = []; // let updatedLeadDetails = this.state.leadDetails
       //Aman-4947-added below condtion to switch between total lead and filter lead to submit data
       if (this.state.filterleadDetailsSatus === false) {
         updatedLeadDetails = this.state.leadDetails;
       } else {
         //Aman-4947-added Extract leadInfoID values from filterleadDetails
         const filterLeadInfoIDs = this.state.filterleadDetails.map(
           (item) => item.leadInfoID
         );
 
         //Aman-4947-added Filter leadDetails based on matching leadInfoID values
         updatedLeadDetails = this.state.leadDetails.filter((item) =>
           filterLeadInfoIDs.includes(item.leadInfoID)
         );
       }
       let updatedStatus = [];
       //alert("updatedLeadDetails---->"+JSON.stringify(updatedLeadDetails))
       //Priyanka-4633-Collecting lead status of leads after reviewing
       for (let i = 0; i < updatedLeadDetails.length; i++) {
         updatedStatus.push(updatedLeadDetails[i].status);
       }
       let data = {
         campaignLeadDetails: updatedLeadDetails, //Aman-4947-added updatedLeadDetails intsted of all lead this.state.leadDetails
         campID: this.state.campID,
         //user: user, Priyanka-4580-removing user params
         agencyID: this.state.agencyID,
         clientName: this.state.clientName, //Chaitanya-4973-passing clientName at Submit button
         clientCampID: this.state.clientCampID, //Chaitanya-5019-passing clientCampID at time of file upload
       };
       //this.loadingRef.current.click();//Sandeep-task-3441-added loader
       this.setState({ loading1: true, loader2: "loader_review" });
       fetch("/diQARole/diReviewLeadSubmit", {
         method: "POST",
         headers: { "Content-Type": "application/json" },
         body: JSON.stringify(data),
       })
         .then((res) => res.json())
         .then((diLeadDetails) => {
           //this.closeLoadingRef.current.click();//Sandeep-task-3441-added loader
           this.setState({ loading1: false, loader2: "" });
           if (diLeadDetails.length > 0) {
             Swal.fire({
               text: "Lead reviewed successfully",
               type: "success",
               confirmButtonText: "Ok",
               allowOutsideClick: false,
 
               preConfirm: () => {
                 //alert("updatedStatus-->"+updatedStatus)
                 //Priyanka-4633-Navigating to second tab if lreviewd leads having PV Review status
                 if (updatedStatus.includes("PV Review")) {
                   //alert("if")
                   this.setState({ key: 2 });
                   this.getSecondTabData();
                 } else {
                   //alert("else")
                   window.location.reload();
                 }
               },
             });
           } else {
           }
         })
         .catch(function (err) {
           console.log(err);
         });
     } else {
       if (this.validateForm() === false) {
         this.setState({
           reasonErrorDisplayFlag: true,
         });
       } else {
         let data = {
           campaignLeadDetails: this.state.leadDetails,
           campID: this.state.campID,
           //user: user, Priyanka-4580-removing user params
           agencyID: this.state.agencyID,
         };
         this.loadingRef.current.click(); //Sandeep-task-3441-added loader
         fetch("/diQARole/pvReviewLeadSubmit", {
           method: "POST",
           headers: { "Content-Type": "application/json" },
           body: JSON.stringify(data),
         })
           .then((res) => res.json())
           .then((pvLeadDetails) => {
             this.closeLoadingRef.current.click(); //Sandeep-task-3441-added loader
             if (pvLeadDetails.length > 0) {
               Swal.fire({
                 text: "Lead reviewed successfully",
                 type: "success",
                 confirmButtonText: "Ok",
                 allowOutsideClick: false,
                 preConfirm: () => {
                   window.location.reload();
                 },
               });
             } else {
             }
           })
           .catch(function (err) {
             console.log(err);
           });
       }
     }
   }
 
   async fileupload(e) {
     e.preventDefault();
     let key = this.state.key;
     if (key === 1) {
       //kiran-4752-removing console warning-changed == to ===
       this.setState({ loading1: true, loader2: "loader_review" });
       let filesData1 = e.target.files[0];
       var ext;
 
       await this.fetchDownloadFileLength(); //Chaitanya-4788-fuction call for validation of wrong leads count
       var label;
       if (filesData1 == null) {
         ext = "";
         label = document.getElementById("fileName1");
         label.innerHTML = "No File Choosen";
       } else {
         ext = filesData1.name.split(".").pop();
         label = document.getElementById("fileName1");
         label.innerHTML = filesData1.name;
       }
 
       let message = "";
       // const { isAuthenticated, user } = this.props.auth;
       var data = new FormData();
       //kiran-4752-removing console warning-changed == to ===
       if (ext === "xlsx" || ext === "XLSX") {
         data.append("campID", this.state.campID);
         data.append("message", message);
         data.append("file", filesData1);
         data.append(
           "downloadFileLeadsLength",
           this.state.downloadFileLeadsLength
         );
         data.append("clientName", this.state.clientName); //Chaitanya-4973-passing clientName at time of file upload
         data.append("clientCampID", this.state.clientCampID); //Chaitanya-5019-passing clientCampID at time of file upload
         // Rutuja jagtap 4123 - User Details show in Payload Tab
         fetch("/diQARole/uploadReviewLeadDI", {
           method: "POST",
           body: data,
         })
           .then((res) => res.json())
           .then((campaignLeadDetails) => {
             //Chaitanya-4788-Alert for validation of wrong leads count upload in file
             if (true === campaignLeadDetails.success) {
               Swal.fire({
                 text: "Leads count in uploaded file is greater than total leads count",
                 type: "error",
                 allowOutsideClick: false,
                 heightAuto: false,
                 preConfirm: () => {
                   window.location.reload();
                 },
               });
               return;
             }
 
             if (campaignLeadDetails.length > 0) {
               //4612-Nilesh-File validation for wrong status upload in file
               var temp = [];
               for (let i = 0; i < campaignLeadDetails.length; i++) {
                 temp.push({
                   ...this.state.leadDetails[i],
                   leadInfoID: campaignLeadDetails[i].leadInfoID,
                   email: campaignLeadDetails[i].email,
                   jobTitle: campaignLeadDetails[i].jobTitle,
                   status: campaignLeadDetails[i].status,
                   reason: campaignLeadDetails[i].reason,
                 });
               }
               this.setState({
                 leadDetails: temp,
                 filterleadDetailsSatus: false,
               }); //Aman-4947-added  filterleadDetailsStatus=false to reset filter values
               Swal.fire({
                 text: "File uploaded and leads reviewed",
                 type: "success",
                 allowOutsideClick: false,
                 heightAuto: false,
                 onOpen: () => {
                   this.setState({ reviewFlag: true });
                   document.getElementById("submitButton").disabled = true;
                   document.getElementById("submitButton1").disabled = true; //sunita-task-3422-added code here for disabling the submit button as only one button get disabled as per requirement done the changes.
                 },
               });
             }
             //kiran-4752-removing console warning-changed == to ===
             else if (false === campaignLeadDetails.success) {
               //Nilesh-4770-validation for wrong camp ID upload in file
               Swal.fire({
                 text: campaignLeadDetails.message,
                 type: "error",
                 allowOutsideClick: false,
                 heightAuto: false,
                 preConfirm: () => {
                   window.location.reload();
                 },
               });
             } else {
               Swal.fire({
                 text: "Please enter valid status against leads as per the given instructions on the screen", //4612-Nilesh-File validation for wrong status upload in file
                 type: "error",
                 allowOutsideClick: false,
                 heightAuto: false,
                 preConfirm: () => {
                   window.location.reload();
                 },
               });
             }
             //sunita-task-3271-added below api for removing particular campaign id from left side after file upload
             fetch("/diQARole/leadReviewDI", {
               method: "POST",
               headers: { "Content-Type": "application/json" },
             })
               .then((res) => res.json())
               .then((campList) => {
                 this.setState({
                   campList: campList,
                   loading1: false,
                   loader2: "",
                 });
               });
           })
           .catch(function (err) {
             console.log(err);
             Swal.fire({
               text: "Error occured please try again",
               type: "error",
               allowOutsideClick: false,
               heightAuto: false,
               preConfirm: () => {
                 window.location.reload();
               },
             });
           });
       } else {
         Swal.fire({
           text: "File Invalid Please Upload file with .xlsx format",
           type: "error",
           allowOutsideClick: false,
           heightAuto: false,
           preConfirm: () => {
             window.location.reload();
           },
         });
       }
     } else {
       this.setState({ loading1: true, loader2: "loader_review" });
       let filesData1 = e.target.files[0];
       let ext; //kiran-4752-removing console warning-changed var to let
 
       await this.fetchDownloadFileLengthPV(); //Chaitanya-4788-fuction call for validation of wrong leads count
 
       if (filesData1 == null) {
         ext = "";
         let label = document.getElementById("fileName2");
         label.innerHTML = "No File Choosen";
       } else {
         ext = filesData1.name.split(".").pop();
         let label = document.getElementById("fileName2");
         label.innerHTML = filesData1.name;
       }
 
       let message = "";
       // const { isAuthenticated, user } = this.props.auth;
       //kiran-4752-removing console warning-changed var to let and use ===
       let data = new FormData();
       if (ext === "xlsx" || ext === "XLSX") {
         data.append("campID", this.state.campID);
         data.append("message", message);
         data.append("file", filesData1);
         data.append(
           "downloadFileLeadsLength",
           this.state.downloadFileLeadsLength
         );
         // Rutuja jagtap 4123 - User Details show in Payload Tab
         fetch("/diQARole/uploadReviewLeadPV", {
           method: "POST",
           body: data,
         })
           .then((res) => res.json())
           .then((campaignLeadDetails) => {
             //Chaitanya-4788-Alert for validation of wrong leads count upload in file
             if (true === campaignLeadDetails.success) {
               Swal.fire({
                 text: "Leads count in uploaded file is greater than total leads count",
                 type: "error",
                 allowOutsideClick: false,
                 heightAuto: false,
                 preConfirm: () => {
                   window.location.reload();
                 },
               });
               return;
             }
             //kiran-4752-removing console warning-changed == to ===
             if (campaignLeadDetails.success === false) {
               //4612-Nilesh-File validation for wrong status upload in file
               Swal.fire({
                 text: "Please enter valid status against leads as per the given instructions on the screen",
                 type: "error",
                 allowOutsideClick: false,
                 heightAuto: false,
                 preConfirm: () => {
                   window.location.reload();
                 },
               });
             } else if ("success" === campaignLeadDetails.success) {//Nilesh-5038- check else if condition for wrong camp id
               //Nilesh-4770-validation for wrong camp ID upload in file
               Swal.fire({
                 text: campaignLeadDetails.message,
                 type: "error",
                 allowOutsideClick: false,
                 heightAuto: false,
                 preConfirm: () => {
                   window.location.reload();
                 },
               });
             } else {
               if (campaignLeadDetails.length > 0) {
                 var temp = [];
                 for (let i = 0; i < campaignLeadDetails.length; i++) {
                   temp.push({
                     ...this.state.leadDetails[i],
                     leadInfoID: campaignLeadDetails[i].leadInfoID,
                     email: campaignLeadDetails[i].email,
                     jobTitle: campaignLeadDetails[i].jobTitle,
                     status: campaignLeadDetails[i].status,
                     reason: campaignLeadDetails[i].reason,
                   });
                 }
                 this.setState({ leadDetails: temp });
                 Swal.fire({
                   text: "File uploaded and leads reviewed",
                   type: "success",
                   allowOutsideClick: false,
                   heightAuto: false,
                   onOpen: () => {
                     this.setState({ reviewFlag: true });
                     //Priyanka-4633-Disabling Submit button after uploading file
                     document.getElementById("submitButtonPV").disabled = true;
                     document.getElementById("submitButtonPV1").disabled = true;
                   },
                 });
 
                 //sunita-task-3271-added below api for removing particular campaign id from left side after file upload
                 fetch("/diQARole/leadReviewPV", {
                   method: "POST",
                   headers: { "Content-Type": "application/json" },
                 })
                   .then((res) => res.json())
                   .then((campList) => {
                     this.setState({
                       campList: campList,
                       loading1: false,
                       loader2: "",
                     });
                   });
               } else {
                 Swal.fire({
                   text: "Please enter reason for Rejection",
                   type: "error",
                   allowOutsideClick: false,
                   heightAuto: false,
                   preConfirm: () => {
                     window.location.reload();
                   },
                 });
               }
             }
           })
           .catch(function (err) {
             Swal.fire({
               text: "Error occured please try again",
               type: "error",
               allowOutsideClick: false,
               heightAuto: false,
               preConfirm: () => {
                 window.location.reload();
               },
             });
           });
       } else {
         //Nilesh-4770- Add xlsx file validation
         Swal.fire({
           text: "File Invalid Please Upload file with .xlsx format",
           type: "error",
           allowOutsideClick: false,
           heightAuto: false,
           preConfirm: () => {
             window.location.reload();
           },
         });
       }
     }
   }
 
   downloadFile(e) {
     let errors = {};
     // var parsed = queryString.parse(this.props.location.search);
     // let data = {
     //   campID: this.state.campID,
     // }//kiran-4752-removing console warning-no used
     let campList = this.state.campList; //4588-Nilesh File Naming convention change
     let campDetail = campList.filter((el) => {
       return el.campID == this.state.campID; //kiran-4752-not resolved giving issue while file download
     });
     let key = this.state.key;
     //kiran-4752-removing console warning-changed == to ===
     if (key === 1) {
       //Aman-4947-added  condition to check filter lead and total lead to download lead file else will download filter lead
       if (this.state.filterleadDetailsSatus === false) {
         Swal.fire({
           onOpen: () => {
             Swal.showLoading();
             fetch(
               "/diQARole/downloadLeadDetails?campID=" + this.state.campID + ""
             )
               .then((res) => res.json())
               .then((downloadFiles) => {
                 this.setState({ downloadFiles: downloadFiles });
                 if (downloadFiles.success === true) {
                   Swal.fire({
                     type: "error",
                     text: "No Data Exists!",
                     confirmButtonText: "Ok",
                     allowOutsideClick: false,
                   });
                   this.setState({ errors: errors });
                 } else {
                   var ws = xlsx.utils.json_to_sheet(downloadFiles);
                   var wb = xlsx.utils.book_new();
                   xlsx.utils.book_append_sheet(wb, ws, "Lead");
                   var buf = xlsx.write(wb, {
                     bookType: "xlsx",
                     type: "buffer",
                   }); // generate a nodejs buffer
                   // var str = xlsx.write(wb, { bookType: 'xlsx', type: 'binary' });//kiran-4752-removing console warning-no used
                   function s2ab(s) {
                     var buf = new ArrayBuffer(s.length);
                     var view = new Uint8Array(buf);
                     for (var i = 0; i !== s.length; ++i)
                       view[i] = s.charCodeAt(i) & 0xff;
                     return buf;
                   }
                   //var fileName = this.state.campID + "_QA_Leads" + ".xlsx";
                   var fileName =
                     "DI_QA_Leads_" +
                     campDetail[0].clientCampID +
                     "_" +
                     this.state.campID +
                     ".xlsx"; //4588-Nilesh File Naming convention change
                   saveAs(
                     new Blob([s2ab(buf)], { type: "application/octet-stream" }),
                     fileName
                   );
                   Swal.fire({
                     text: "File Downloaded Successfully",
                     type: "success",
                     confirmButtonText: "Ok",
                     allowOutsideClick: false,
                   });
                 }
               });
           },
         });
       } else {
         Swal.fire({
           onOpen: () => {
             Swal.showLoading();
             let data = {
               campID: this.state.campID,
               startData: this.state.leadStartSelectedDate,
               endDate: this.state.leadEndSelectedDate,
             };
             fetch("/diQARole/downloadLeadDetailsFilter", {
               method: "POST",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify(data),
             })
               .then((res) => res.json())
               .then((downloadFiles) => {
                 this.setState({ downloadFiles: downloadFiles });
 
                 if (downloadFiles.success === true) {
                   Swal.fire({
                     type: "error",
                     text: "No Data Exists!",
                     confirmButtonText: "Ok",
                     allowOutsideClick: false,
                   });
                   this.setState({ errors: errors });
                 } else {
                   var ws = xlsx.utils.json_to_sheet(downloadFiles);
                   var wb = xlsx.utils.book_new();
                   xlsx.utils.book_append_sheet(wb, ws, "Lead");
                   var buf = xlsx.write(wb, {
                     bookType: "xlsx",
                     type: "buffer",
                   }); // generate a nodejs buffer
                   // var str = xlsx.write(wb, { bookType: 'xlsx', type: 'binary' });//kiran-4752-removing console warning-no used
                   function s2ab(s) {
                     var buf = new ArrayBuffer(s.length);
                     var view = new Uint8Array(buf);
                     for (var i = 0; i !== s.length; ++i)
                       view[i] = s.charCodeAt(i) & 0xff;
                     return buf;
                   }
                   //var fileName = this.state.campID + "_QA_Leads" + ".xlsx";
                   var fileName =
                     "DI_QA_Leads_" +
                     campDetail[0].clientCampID +
                     "_" +
                     this.state.campID +
                     ".xlsx"; //4588-Nilesh File Naming convention change
                   saveAs(
                     new Blob([s2ab(buf)], { type: "application/octet-stream" }),
                     fileName
                   );
                   Swal.fire({
                     text: "File Downloaded Successfully",
                     type: "success",
                     confirmButtonText: "Ok",
                     allowOutsideClick: false,
                   });
                 }
               });
           },
         });
       }
     } else {
       Swal.fire({
         onOpen: () => {
           Swal.showLoading();
           fetch(
             "/diQARole/downloadLeadDetailsPV?campID=" + this.state.campID + ""
           )
             .then((res) => res.json())
             .then((downloadFiles) => {
               this.setState({ downloadFiles: downloadFiles });
 
               if (downloadFiles.success === true) {
                 Swal.fire({
                   type: "error",
                   text: "No Data Exists!",
                   confirmButtonText: "Ok",
                   allowOutsideClick: false,
                 });
                 this.setState({ errors: errors });
               } else {
                 var ws = xlsx.utils.json_to_sheet(downloadFiles);
                 var wb = xlsx.utils.book_new();
                 xlsx.utils.book_append_sheet(wb, ws, "Lead");
                 var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
                 //  var str = xlsx.write(wb, { bookType: 'xlsx', type: 'binary' });
                 function s2ab(s) {
                   var buf = new ArrayBuffer(s.length);
                   var view = new Uint8Array(buf);
                   //kiran-4752-changed != to !==
                   for (var i = 0; i !== s.length; ++i)
                     view[i] = s.charCodeAt(i) & 0xff;
                   return buf;
                 }
                 //var fileName = this.state.campID + "_QA_Leads" + ".xlsx";
                 var fileName =
                   "PV_Leads_" +
                   campDetail[0].clientCampID +
                   "_" +
                   this.state.campID +
                   ".xlsx"; //4588-Nilesh File Naming convention change
                 saveAs(
                   new Blob([s2ab(buf)], { type: "application/octet-stream" }),
                   fileName
                 );
                 Swal.fire({
                   text: "File Downloaded Successfully",
                   type: "success",
                   confirmButtonText: "Ok",
                   allowOutsideClick: false,
                 });
               }
             });
         },
       });
     }
   }
 
   validateForm() {
     let formIsvalid = true;
     for (let i = 0; i < this.state.leadDetails.length; i++) {
       if (
         this.state.leadDetails[i].status === "PV REJECTED" &&
         (this.state.leadDetails[i].reason === undefined ||
           this.state.leadDetails[i].reason === "" ||
           this.state.leadDetails[i].reason === " ")
       ) {
         formIsvalid = false;
       }
     }
     return formIsvalid;
   }
 
   //Chaitanya-4788-fuction for validation of wrong leads count upload in file
   async fetchDownloadFileLength() {
     const campID = this.state.campID;
     try {
       const response = await fetch(
         "/diQARole/downloadLeadDetails?campID=" + campID
       );
       const downloadFiles1 = await response.json();
 
       var downloadFileLeadsLength = downloadFiles1.length;
       //Aman-4947-added to switch between the filter and total lead count length
       if (this.state.filterleadDetailsSatus === false) {
         this.setState({ downloadFileLeadsLength: downloadFileLeadsLength });
       } else {
         this.setState({
           downloadFileLeadsLength: this.state.filterleadDetailsCount,
         });
       }
     } catch (error) {
       console.error("Error fetching download files:", error);
     }
   }
   //Chaitanya-4788-fuction for validation of wrong leads count upload in file
   async fetchDownloadFileLengthPV() {
     const campID = this.state.campID;
     try {
       const response = await fetch(
         "/diQARole/downloadLeadDetailsPV?campID=" + campID
       );
       const downloadFiles1 = await response.json();
 
       var downloadFileLeadsLength = downloadFiles1.length;
       this.setState({ downloadFileLeadsLength: downloadFileLeadsLength });
     } catch (error) {
       console.error("Error fetching download files:", error);
     }
   }
 
   //Aman-4947-added startDatedateHandleChange to handle the lead startSelectedDate date
   startDatedateHandleChange(e) {
     e.preventDefault();
     var startSelectedDate = e.target.value;
     this.setState({ leadStartSelectedDate: startSelectedDate }, () => {
       this.filterleadByStartAndEndDate();
     });
 
     const reversedDate = this.reverseDateFormat(startSelectedDate); //Aman-4947 Output: "17-01-2024"
     this.setState({
       stectedStartDateforDisplay: reversedDate,
       selectedDateStart: startSelectedDate, //Aman-4947 Update state variable for input value
     });
   }
   //Aman-4947-added endDatedateHandleChange to handle the lead endSelectedDate date
   endDatedateHandleChange(e) {
     e.preventDefault();
     var endSelectedDate = e.target.value;
     this.setState({ leadEndSelectedDate: endSelectedDate }, () => {
       this.filterleadByStartAndEndDate();
     });
 
     const reversedDate = this.reverseDateFormat(endSelectedDate); ////Aman-4947- Output: "17-01-2024"
     this.setState({
       stectedEndDateforDisplay: reversedDate,
       selectedDateEnd: endSelectedDate, ////Aman-4947- Update state variable for input value
     });
   }
 
   //Aman-4947-added filterleadByStartAndEndDate to get filter lead from BE and return filter lead as per the date
   filterleadByStartAndEndDate(e) {
     if (
       this.state.leadStartSelectedDate !== "" &&
       this.state.leadEndSelectedDate !== ""
     ) {
       this.setState({ loading1: true, loader2: "loader_review" });
       let data = {
         campID: this.state.campID,
         startData: this.state.leadStartSelectedDate,
         endDate: this.state.leadEndSelectedDate,
       };
       //sunita-task-3271-display all diqa leads.
       fetch("/diQARole/DIQaLeadDetailsFilter", {
         method: "POST",
         headers: { "Content-Type": "application/json" },
         body: JSON.stringify(data),
       })
         .then((res) => res.json())
         .then((leadDetails) => {
           let count = leadDetails.length;
           if (count < 0) {
             count = 0;
           }
 
           this.setState({
             filterleadDetails: leadDetails,
             filterleadDetailsCount: count,
             loading1: false,
             loader2: "",
             filterleadDetailsSatus: true,
             // leadStartSelectedDate: "",
             // leadEndSelectedDate: "",
           });
         })
         .catch(function (err) {
           console.log(err);
         });
     } else {
     }
   }
 
   //Aman-4947-added reverseDateFormat to format the selected date to display correct oder
   reverseDateFormat(originalDate) {
     //Aman-4947-added Split the original date string
     const dateParts = originalDate.split("-");
 
     //Aman-4947-added Rearrange the parts in the desired order
     const reversedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
 
     return reversedDate;
   }
 
  //Nilesh-5055- This function calling for new page
   unreadAlerts(e) {
     e.preventDefault();
     this.props.history.push("/diqaUnreadAlerts");
   }
 
   render() {
     // array data
 
     //sunita-task-3271- below all qaLeadDetails cloumns ,qaLeadDetails2 columns  are used for antd table
     const qaLeadDetails = [
       {
         title: "Lead ID",
         dataIndex: "leadInfoID",
         key: "leadInfoID",
         width: 60,
         align: "left", //Sandeep-task-3441
         render: (
           text,
           record //sunita-task-3271-added below code as per requirement
         ) => (
           <div>
             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
               {record.leadInfoID}
             </span>
           </div>
         ),
       },
       {
         title: "Email",
         dataIndex: "email",
         key: "email",
         width: 105,
         align: "left", //Sandeep-task-3441
 
         render: (
           text,
           record //sunita-task-3271-added below code as per requirement
         ) => (
           <div>
             <span
               style={{
                 fontSize: "13px",
                 color: "#4F4F4F",
                 wordBreak: "break-all",
               }}
             >
               {record.email}
             </span>
           </div>
         ),
       },
 
       {
         title: "Job Title",
         dataIndex: "jobTitle",
         key: "jobTitle",
         width: 90,
         align: "left", //Sandeep-task-3441
 
         render: (
           text,
           record //sunita-task-3271-added below code as per requirement
         ) => (
           <div>
             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
               {record.jobTitle}
             </span>
           </div>
         ),
       },
       {
         title: "Status",
         dataIndex: "status",
         key: "status",
         width: 90,
         align: "left", //Sandeep-task-3441
         render: (
           text,
           record,
           i //sunita-task-3271-this code is alredy present so not done that much changes here
         ) => (
           <div>
             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
               {this.state.reviewFlag === true ? (
                 <label>{text}</label>
               ) : (
                 //kiran-4631-assign width for status dropdown
                 <Select
                   style={{ height: "60%", width: "100%" }}
                   defaultValue="DI QA ACCEPTED"
                   onChange={(value, e) => {
                     this.handleChange(i, value, "status");
                   }}
                   className="mySelect"
                 >
                   <Option value="DI QA ACCEPTED">DI QA ACCEPTED</Option>
                   <Option value="DI QA REJECTED">DI QA REJECTED</Option>
                   <Option value="PV Review">PV Review</Option>
                 </Select>
               )}
             </span>
           </div>
         ),
       },
       {
         title: "Reason For Rejection", //Sandeep-task-3441
         dataIndex: "reason",
         key: "reason",
         width: 90,
         align: "left", //Sandeep-task-3441
         render: (
           text,
           record,
           i //sunita-task-3271-this code is alredy present so not done that much changes here
         ) => (
           <div
             className="mywidth"
             style={{ fontSize: "13px", color: "#4F4F4F" }}
           >
             {this.state.reviewFlag === true ? (
               <label>{text}</label>
             ) : (
               <Input
                 onChange={(e) => {
                   this.handleChange(i, e, "reason");
                 }}
               />
             )}
           </div>
         ),
       },
     ];
 
     //sunita-task-3271- below all qaLeadDetails2 are used for antd table columns same as above only added one extra column for voicelog link
     const qaLeadDetails2 = [
       {
         title: "Lead ID",
         dataIndex: "leadInfoID",
         key: "leadInfoID",
         width: 60,
         align: "left", //Sandeep-task-3441
         render: (
           text,
           record //sunita-task-3271-added below code as per requirement
         ) => (
           <div>
             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
               {record.leadInfoID}
             </span>
           </div>
         ),
       },
       {
         title: "Email",
         dataIndex: "email",
         key: "email",
         width: 105,
         align: "left", //Sandeep-task-3441
 
         render: (
           text,
           record //sunita-task-3271-added below code as per requirement
         ) => (
           <div>
             {/* <span style={{ fontSize: '11px',wordBreak: "break-all" }}> */}
             <span
               style={{
                 fontSize: "13px",
                 color: "#4F4F4F",
                 wordBreak: "break-all",
               }}
             >
               {record.email}
             </span>
           </div>
         ),
       },
 
       {
         //sunita-task-3271-added below code as per requirement
         title: "Voice Log Link",
         dataIndex: "voiceLogLink",
         key: "voiceLogLink",
         width: 90,
         align: "left", //Sandeep-tsk-3441
 
         render: (text, record) => (
           <div>
             <span style={{ fontSize: "13px", color: "#1890FF" }}>
               {this.state.marketingChannelFlag === true ? (
                 //sunita-task-3271-added condition as per channel for showing voicelog link column
                 //kiran-4752-removing console warning-added "#/"
                 record.channel === "Telemarketing" ||
                 record.Channel === "Telemarketing" ||
                 ((record.channel === "Email/Telemarketing" ||
                   record.Channel === "Email/Telemarketing" ||
                   record.channel === "Email" ||
                   record.Channel === "Email") &&
                   record.callAudit === "Yes") ? (
                   <a
                     href="#/"
                     onClick={this.openLinkInBrowser}
                     id={record.leadInfoID}
                   >
                     Link
                   </a>
                 ) : (
                   <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                     No Link Available
                   </span>
                 )
               ) : (
                 ""
               )}
             </span>
           </div>
         ),
       },
 
       {
         title: "Job Title",
         dataIndex: "jobTitle",
         key: "jobTitle",
         width: 90,
         align: "left", //Sandeep-task-3441
 
         render: (
           text,
           record //sunita-task-3271-added below code as per requirement
         ) => (
           <div>
             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
               {record.jobTitle}
             </span>
           </div>
         ),
       },
       {
         title: "Status",
         dataIndex: "status",
         key: "status",
         width: 100,
         align: "left", //Sandeep-task-3441
         render: (text, record, i) => (
           <div>
             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
               {/*sunita-task-3271-added css  as per requirement*/}
               {this.state.reviewFlag === true ? (
                 <label>{text}</label>
               ) : (
                 //kiran-4631-assign width for status dropdown
                 <Select
                   style={{ height: "60%", width: "100%" }}
                   defaultValue="DI QA ACCEPTED"
                   onChange={(value, e) => {
                     this.handleChange(i, value, "status");
                   }}
                   className="mySelect"
                 >
                   <Option value="DI QA ACCEPTED">DI QA ACCEPTED</Option>
                   <Option value="DI QA REJECTED">DI QA REJECTED</Option>
                   <Option value="PV Review">PV Review</Option>
                 </Select>
               )}
             </span>
           </div>
         ),
       },
       {
         title: "Reason For Rejection",
         dataIndex: "reason",
         key: "reason",
         width: 90,
         align: "left", //Sandeep-task-3441
         render: (text, record, i) => (
           <div
             className="mywidth"
             style={{ fontSize: "13px", color: "#4F4F4F" }}
           >
             {" "}
             {/*sunita-task-3271-added css  as per requirement*/}
             {this.state.reviewFlag === true ? (
               <label>{text}</label>
             ) : (
               <Input
                 onChange={(e) => {
                   this.handleChange(i, e, "reason");
                 }}
               />
             )}
           </div>
         ),
       },
     ];
 
     //sunita-task-3271- below all qaLeadDetails cloumns ,qaLeadDetails2 columns  are used for antd table
     const pvLeadDetails = [
       {
         title: "Lead ID",
         dataIndex: "leadInfoID",
         key: "leadInfoID",
         width: 60,
         align: "left", //Sandeep-task-3441
         render: (
           text,
           record //sunita-task-3271-added below code as per requirement
         ) => (
           <div>
             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
               {record.leadInfoID}
             </span>
           </div>
         ),
       },
       {
         title: "Email",
         dataIndex: "email",
         key: "email",
         width: 105,
         align: "left", //Sandeep-task-3441
 
         render: (
           text,
           record //sunita-task-3271-added below code as per requirement
         ) => (
           <div>
             <span
               style={{
                 fontSize: "13px",
                 color: "#4F4F4F",
                 wordBreak: "break-all",
               }}
             >
               {record.email}
             </span>
           </div>
         ),
       },
 
       {
         title: "Job Title",
         dataIndex: "jobTitle",
         key: "jobTitle",
         width: 90,
         align: "left", //Sandeep-task-3441
 
         render: (
           text,
           record //sunita-task-3271-added below code as per requirement
         ) => (
           <div>
             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
               {record.jobTitle}
             </span>
           </div>
         ),
       },
       {
         title: "Status",
         dataIndex: "status",
         key: "status",
         width: 90,
         align: "left", //Sandeep-task-3441
         render: (
           text,
           record,
           i //sunita-task-3271-this code is alredy present so not done that much changes here
         ) => (
           <div>
             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
               {this.state.reviewFlag === true ? (
                 <label>{text}</label>
               ) : (
                 //kiran-4631-assign width for status dropdown
                 <Select
                   style={{ height: "60%", width: "100%" }}
                   defaultValue="PV ACCEPTED"
                   onChange={(value, e) => {
                     this.handleChange(i, value, "status");
                   }}
                   className="mySelect"
                 >
                   <Option value="PV ACCEPTED">PV ACCEPTED</Option>
                   <Option value="PV REJECTED">PV REJECTED</Option>
                 </Select>
               )}
             </span>
           </div>
         ),
       },
       {
         title: "Reason For Rejection", //Sandeep-task-3441
         dataIndex: "reason",
         key: "reason",
         width: 90,
         align: "left", //Sandeep-task-3441
         render: (
           text,
           record,
           i //sunita-task-3271-this code is alredy present so not done that much changes here
         ) => (
           <div
             className="mywidth"
             style={{ fontSize: "13px", color: "#4F4F4F" }}
           >
             {this.state.reviewFlag === true ? (
               <label>{text}</label>
             ) : (
               <Input
                 onChange={(e) => {
                   this.handleChange(i, e, "reason");
                 }}
               />
             )}
           </div>
         ),
       },
     ];
 
     //sunita-task-3271- below all qaLeadDetails2 are used for antd table columns same as above only added one extra column for voicelog link
     const pvLeadDetails2 = [
       {
         title: "Lead ID",
         dataIndex: "leadInfoID",
         key: "leadInfoID",
         width: 60,
         align: "left", //Sandeep-task-3441
         render: (
           text,
           record //sunita-task-3271-added below code as per requirement
         ) => (
           <div>
             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
               {record.leadInfoID}
             </span>
           </div>
         ),
       },
       {
         title: "Email",
         dataIndex: "email",
         key: "email",
         width: 105,
         align: "left", //Sandeep-task-3441
 
         render: (
           text,
           record //sunita-task-3271-added below code as per requirement
         ) => (
           <div>
             {/* <span style={{ fontSize: '11px',wordBreak: "break-all" }}> */}
             <span
               style={{
                 fontSize: "13px",
                 color: "#4F4F4F",
                 wordBreak: "break-all",
               }}
             >
               {record.email}
             </span>
           </div>
         ),
       },
 
       {
         //sunita-task-3271-added below code as per requirement
         title: "Voice Log Link",
         dataIndex: "voiceLogLink",
         key: "voiceLogLink",
         width: 90,
         align: "left", //Sandeep-tsk-3441
 
         render: (text, record) => (
           <div>
             <span style={{ fontSize: "13px", color: "#1890FF" }}>
               {this.state.marketingChannelFlag === true ? (
                 //sunita-task-3271-added condition as per channel for showing voicelog link column
                 //kiran-4752-removing console warning-added "#/"
                 record.channel === "Telemarketing" ||
                 record.Channel === "Telemarketing" ||
                 ((record.channel === "Email/Telemarketing" ||
                   record.Channel === "Email/Telemarketing" ||
                   record.channel === "Email" ||
                   record.Channel === "Email") &&
                   record.callAudit === "Yes") ? (
                   <a
                     href="#/"
                     onClick={this.openLinkInBrowser}
                     id={record.leadInfoID}
                   >
                     Link
                   </a>
                 ) : (
                   <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                     No Link Available
                   </span>
                 )
               ) : (
                 ""
               )}
             </span>
           </div>
         ),
       },
 
       {
         title: "Job Title",
         dataIndex: "jobTitle",
         key: "jobTitle",
         width: 90,
         align: "left", //Sandeep-task-3441
 
         render: (
           text,
           record //sunita-task-3271-added below code as per requirement
         ) => (
           <div>
             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
               {record.jobTitle}
             </span>
           </div>
         ),
       },
       {
         title: "Status",
         dataIndex: "status",
         key: "status",
         width: 100,
         align: "left", //Sandeep-task-3441
         render: (text, record, i) => (
           <div>
             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
               {/*sunita-task-3271-added css  as per requirement*/}
               {this.state.reviewFlag === true ? (
                 <label>{text}</label>
               ) : (
                 //kiran-4631-assign width for status dropdown
                 <Select
                   style={{ height: "60%", width: "100%" }}
                   defaultValue="PV ACCEPTED"
                   onChange={(value, e) => {
                     this.handleChange(i, value, "status");
                   }}
                   className="mySelect"
                 >
                   <Option value="PV ACCEPTED">PV ACCEPTED</Option>
                   <Option value="PV REJECTED">PV REJECTED</Option>
                 </Select>
               )}
             </span>
           </div>
         ),
       },
       {
         title: "Reason For Rejection",
         dataIndex: "reason",
         key: "reason",
         width: 90,
         align: "left", //Sandeep-task-3441
         render: (text, record, i) => (
           <div
             className="mywidth"
             style={{ fontSize: "13px", color: "#4F4F4F" }}
           >
             {" "}
             {/*sunita-task-3271-added css  as per requirement*/}
             {this.state.reviewFlag === true ? (
               <label>{text}</label>
             ) : (
               <Input
                 onChange={(e) => {
                   this.handleChange(i, e, "reason");
                 }}
               />
             )}
           </div>
         ),
       },
     ];
 
     //sunita-task-3271-added below code as per requirement
     function onChange(pagination, filters, sorter) {}
 
     // const asterisk =
     // {
     //   color: 'red',
     //   fontWeight: "500"
     // }
     // const cardBody = {
     //   height: "350px"
     // } //kiran-4752-removing console warning-no used
 
     return (
       <div>
         <LeadReviewLoader
           loadingRef={this.loadingRef}
           closeLoadingRef={this.closeLoadingRef}
           message="Please wait. Saving the data...."
         />{" "}
         {/* Sandeep-task-3341-added code for loader */}
         <Navigation />
         {/* Priyanka-4603-Added code to add PV tab*/}
         <div
           class="container-fluid"
           id="tabsContainer"
           style={{ paddingTop: "85px" }}
         >
           <Tabs activeKey={this.state.key} onSelect={this.handleSelect}>
             <Tab eventKey={1} title="DI QA Review">
               <div class="container-fluid " style={{ fontFamily: "roboto" }}>
                 <div class="row" style={{ paddingTop: "10px" }}>
                   {/*sunita-task-3271-added code as per requirement */}
                   {/* Rutuja Jagtap task-3879 added Campaign Specification button for DIQA role */}
 
                   <div class="col-xs-12 col-sm-12  col-lg-4 col-xl-4">
                   <a
                        class="TabOne"
                        href={"/dashboardDC"}
                        style={{ color: "#056eb8" }}
                        >
                        <FaArrowAltCircleLeft
                            size={50}
                            style={{ float: "left", paddingTop: "20px" }}
                            title="Back to Dashboard"
                        />
                        </a>
                   </div>
                   <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                     <center>
                       <label id="labelDI">DI Quality Analyst</label>
                     </center>
                     {/* sunita-task-3271- added  code as per new screen requirement */}
                   </div>
                   <div
                     class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"
                     style={{ textAlign: "center", marginTop: "6px" }}
                   >
                     <button
                       class="btn add-button"
                       onClick={this.displayCampaignSpecification}
                     >
                       {" "}
                       Campaign Specification
                     </button>
                     &nbsp;
                     {/* Nilesh-5055- Add alert button*/}
                     {/* <span class="badgelink"> */}
                                         <Badge
                                             count={this.state.unreadAlerts.length <= 10 ? this.state.unreadAlerts.length : "10+"}
                                             overflowCount={10}
                                             offset={[1, -5]}>
                                              <button
                       class="btn add-button"
                       onClick={this.unreadAlerts.bind(this)}
                       style={{marginLeft: "15px"}}
                     >
                       {" "}
                       Alerts
                     </button>
                                         </Badge>{" "}
                                     {/* </span> */}
                     &nbsp;
                   </div>
                 </div>
 
                 {/* <div class="row"> */}
                 {/* sunita-task-3271- added below code as per new screen requirement */}
                 <div class="card card-signin my-1">
                   <div class="card-body" style={{ padding: "5px" }}>
                     {/* <div class="col-sm-12 col-md-4 col-lg-3"> */}
                     <div
                       class="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 qacampaignDetailsDiv "
                       style={{
                         background: "#FFFFFF",
                         borderRight: "2px solid #eee",
                         padding: "0px",
                         height: "auto",
                       }}
                     >
                       {" "}
                       {/*sunita-task-3271-added code as per requirement */}
                       {/* <div><h4>Campaign List</h4> <hr style={{ marginBottom: "0px" }} /></div> */}
                       <div>
                         <center>
                           <label
                             className="campLeadHeading"
                             style={{
                               fontSize: "17px",
                               color: "#14254A",
                               marginTop: "10px",
                               fontWeight: "500",
                               fontFamily: "roboto",
                             }}
                           >
                             Campaigns for Lead Review :
                           </label>
                         </center>
                       </div>
                       {/* sunita-task-3271-added below code for  search */}
                       <div class="row">
                         <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                           <span>
                             <input
                               type="text"
                               className="inputstyle4"
                               name="search"
                               placeholder="Search Campaign"
                               style={{
                                 backgroundImage: "url(searchIcon.png)",
                                 backgroundPosition: "15px 6px",
                                 backgroundSize: "15px 15px",
                                 backgroundRepeat: "no-repeat",
                                 width: "280px",
                                 height: "28px",
                                 fontSize: "12px",
                                 // float: 'left',
                                 // marginLeft: '17px',
                                 border: "1px solid #CCCCCC",
                                 padding: "12px 20px 15px 36px",
                                 backgroundColor: "#FFFFFF",
                                 // marginBottom: '5px',
                                 // borderBottom: '1px solid #eee',
                                 fontFamily: "sans-serif",
                                 borderRadius: "2px",
                               }}
                               onChange={this.handleChangeSearch}
                               onPressEnter={this.handleChangeSearch}
                             ></input>
                           </span>
                         </div>
                       </div>
                       <br />
                       <hr />
                       {/* sunita-task-3271-added below condition as search table data */}
                       <div
                         style={{
                           overflowY: "scroll",
                           height: "auto",
                           background: "#FFFFFF",
                           padding: "0px",
                           borderRight: "2px solid #eee",
                           marginTop: "-9px",
                         }}
                       >
                         {" "}
                         {/*sunita-task-3271-added col and it's data as per requirement */}
                         {this.state.tableSearchText === "" ? (
                           <div>
                             {this.state.campList.map((campList) => (
                               <div
                                 style={{
                                   borderBottom: "1px solid #eee",
                                   padding: "5px 15px",
                                 }}
                                 className="campNames"
                               >
                                 {/* 4610-Nilesh Add agency camp ID i.e clientCampID */}
                                 {/* kiran-4752-removing console warning-added "#/" */}
                                 <a
                                   href="#/"
                                   className="reviewcampdata"
                                   id={campList.campID}
                                   onClick={this.displayLeads}
                                 >
                                   {campList.campID} - {campList.clientCampID} -{" "}
                                   {campList.campaignName}{" "}
                                 </a>
                               </div>
                             ))}
                           </div>
                         ) : this.state.campListSearch.length > 0 ? (
                           <div>
                             {this.state.campListSearch.map((campListSearch) => (
                               <div
                                 style={{
                                   borderBottom: "1px solid #eee",
                                   padding: "5px 15px",
                                 }}
                                 className="campNames"
                               >
                                 {/* 4610-Nilesh Add agency camp ID i.e clientCampID */}
                                 <a
                                   href="#/"
                                   className="reviewcampdata"
                                   id={campListSearch.campID}
                                   onClick={this.displayLeads}
                                 >
                                   {campListSearch.campID} -{" "}
                                   {campListSearch.clientCampID} -{" "}
                                   {campListSearch.campaignName}{" "}
                                 </a>
                               </div>
                             ))}
                           </div>
                         ) : (
                           <center>
                             <label
                               style={{
                                 color: "red",
                                 fontSize: "medium",
                                 marginTop: "34px",
                               }}
                             >
                               No Data Exist
                             </label>
                           </center>
                         )}
                       </div>
                     </div>
 
                     {/* <br /> */}
                     {/* <div id="class one Div" class="col-sm-12 col-md-8 col-lg-9">
               <div class="card" >
                 <div className="card-body card-body2"> 
                   {/* <div> */}
                     {/*sunita-task-3271-added below code as per requirement  */}
                     {/* Rutuja Jagtap task-3879 Responsive*/}
                     <div
                       className="col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 qacampaignDetails"
                       style={{ background: "#FFFFFF", paddingTop: "20px" }}
                     >
                       {/*sunita-task-3271-added code as per requirement */}
                       <div
                         id="pleaseWait"
                         style={{
                           color: "red",
                           fontWeight: "bold",
                           fontSize: "13px",
                           left: "400px",
                           top: "200px",
                         }}
                         className={this.state.loading}
                       ></div>
                       <div>
                         {this.state.campDetails.map((details) => {
                           return (
                             <div>
                               {/*sunita-task-3271-added below label css changes for campID,campname,start date,end date and type as per new requirement for alignment*/}
                               <div
                                 className="row"
                                 style={{ marginTop: "-9px" }}
                               >
                                 {/* <div className="col-sm-12 col-md-6 col-lg-8"> */}
                                 <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                                   <label
                                     style={{
                                       fontSize: "14px",
                                       color: "black",
                                       fontWeight: "600",
                                     }}
                                   >
                                     Campaign ID :
                                   </label>{" "}
                                   &nbsp;<span>{details.campID}</span>
                                 </div>
                                 {/* </div> */}
                                 {/* <div className="row"> */}
                                 {/* <div className="col-sm-12 col-md-8 col-lg-8"> */}
                                 <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-8">
                                   <label
                                     style={{
                                       fontSize: "14px",
                                       color: "black",
                                       fontWeight: "600",
                                     }}
                                   >
                                     Campaign Name :{" "}
                                   </label>
                                   &nbsp;
                                   <span className="reviewcampdata1">
                                     {" "}
                                     {details.campaignName}
                                   </span>
                                 </div>
                                 {/* </div> */}
 
                                 {/* <div className="row"> */}
                                 {/* <div className="col-sm-12 col-md-6 col-lg-3"> */}
                                 <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                                   <label
                                     style={{
                                       fontSize: "14px",
                                       color: "black",
                                       fontWeight: "600",
                                     }}
                                   >
                                     Campaign Type :
                                   </label>
                                   &nbsp;<span> {details.ABM}</span>
                                 </div>
 
                                 {/* <div className="col-sm-12 col-md-6 col-lg-3"> */}
                                 <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                                   <label
                                     style={{
                                       fontSize: "14px",
                                       color: "black",
                                       fontWeight: "600",
                                     }}
                                   >
                                     Start Date :
                                   </label>
                                   &nbsp;<span> {details.startDate}</span>
                                 </div>
 
                                 {/* <div className="col-sm-12 col-md-6 col-lg-3"> */}
                                 <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                                   <label
                                     style={{
                                       fontSize: "14px",
                                       color: "black",
                                       fontWeight: "600",
                                     }}
                                   >
                                     End Date :
                                   </label>
                                   &nbsp;<span>{details.endDate}</span>
                                 </div>
                               </div>
                             </div>
                           );
                         })}
                       </div>
 
                       <br />
 
                       <hr />
 
                       <div className="row" style={{ marginLeft: "0px" }}>
                         <div>
                           {/*sunita-task-3271-added below changes as per new requirement screen for instructions*/}
                           <h5>
                             <span
                               style={{ fontWeight: "bold", color: "#F28C0F" }}
                             >
                               Offline Lead Review Instructions :-{" "}
                             </span>
                             <br />
                           </h5>
                           <span>
                             1. Click on <b>"Download Leads" </b> to get Lead's
                             File.
                           </span>
                           <br />
 
                           <span>
                             2. After reviewing the lead, Please update status as{" "}
                             <b>
                               {" "}
                               "DI QA ACCEPTED" OR "DI QA REJECTED" OR "PV
                               Review"
                             </b>
                             in Status column in Lead's File.
                           </span>
                         </div>
                         <div>
                           <span>
                             3. If you rejecting the lead then please{" "}
                             <b> add reason of rejection </b> in reason column in
                             Lead's File.
                           </span>
                           <br />
                           <span>
                             4. Click on <b>"Upload Lead"</b> to upload reviewed
                             Lead's File.
                           </span>
                         </div>
                       </div>
                       <br />
 
                       {/* Aman-4947-added below all row inside code to display inpute filed to filter lead as per the dates */}
                       <div className="row">
                         <div
                           className="col-xs-5 col-sm-5 col-md-7 col-lg-7 col-xl-5"
                           id="dateForm"
                           style={{
                             display: "flex",
                             flexDirection: "row",
                             flexWrap: "wrap",
                             gap: "3",
                             alignItems: "center",
                             justifyContent: "space-between",
                           }}
                         >
                           <div
                             style={{
                               display: "flex",
                               flexDirection: "column",
                               flexWrap: "wrap",
                             }}
                           >
                             <label
                               style={{
                                 fontSize: "14px",
                                 color: "#14254A",
                                 fontFamily: "sans-serif",
                                 marginRight: "10px",
                               }}
                             >
                               Lead Start Date&nbsp;&nbsp;
                               <HtmlTooltip
                                 placement="right"
                                 title={
                                   <React.Fragment>
                                     <ol>
                                       <li>
                                         {/* 4433-validation changes */}
                                         {
                                           'Selected date range will list the lead data on  "Leads Created Date" only.'
                                         }
                                       </li>
                                       <li>
                                         {
                                           "Start Date and End Date both need to select to filter the lead data."
                                         }
                                       </li>
                                     </ol>
                                   </React.Fragment>
                                 }
                               >
                                 <Icon type="question-circle" />
                               </HtmlTooltip>
                             </label>
 
                             <input
                               type="date"
                               id="startDate"
                               name="startDate"
                               min=""
                               pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                               value={this.state.selectedDateStart}
                               onChange={this.startDatedateHandleChange}
                               className="form-control"
                               required
                             />
                           </div>
 
                           <div
                             style={{
                               display: "flex",
                               flexDirection: "column",
                               flexWrap: "wrap",
                             }}
                           >
                             <label
                               style={{
                                 fontSize: "14px",
                                 color: "#14254A",
                                 fontFamily: "sans-serif",
                                 marginRight: "10px",
                               }}
                             >
                               Lead End Date&nbsp;&nbsp;
                               <HtmlTooltip
                                 placement="right"
                                 title={
                                   <React.Fragment>
                                     <ol>
                                       <li>
                                         {/* 4433-validation changes */}
                                         {
                                           'Selected date range will list the lead data on  "Leads Created Date" only.'
                                         }
                                       </li>
                                       <li>
                                         {
                                           "Start Date and End Date both need to select to filter the lead data."
                                         }
                                       </li>
                                     </ol>
                                   </React.Fragment>
                                 }
                               >
                                 <Icon type="question-circle" />
                               </HtmlTooltip>
                             </label>
 
                             <input
                               type="date"
                               id="endDate"
                               name="endDate"
                               min=""
                               pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                               value={this.state.selectedDateEnd}
                               onChange={this.endDatedateHandleChange}
                               className="form-control"
                               required
                             />
                           </div>
 
                           {/* {this.state.selectedDateStart === "" ? ("") : (<p style={{color: "red"}}>please select End Date to also to get lead count as per the Date</p>)} */}
                         </div>
                       </div>
                       <br />
 
                       <div className="row">
                         <div className="col-sm-12 col-md-6 col-lg-6">
                           {/*sunita-task-3160-added column for proper alignment*/}
                           <button
                             class="btn add-button"
                             type="button"
                             onClick={this.downloadFile}
                             style={{
                               backgroundColor: "#056eb8",
                               color: "white",
                               height: "32px",
                               fontSize: "14px",
                               fontFamily: "sans-serif",
                               fontWeight: "400",
                               borderRadius: "4px",
                             }}
                             disabled={
                               this.state.leadDetails.length > 0 ? false : true
                             }
                           >
                             {/*sunita-task-3271-added  css as per requirement */}
                             <i
                               class="fa fa-cloud-download"
                               aria-hidden="true"
                             ></i>
                             &nbsp;Download Leads
                           </button>
                         </div>
                         <div className="col-sm-12 col-md-6 col-lg-6">
                           <span className="mobileCss">
                             Download Leads:{" "}
                             {this.state.getLeadCounts > 0
                               ? this.state.getLeadCounts
                               : 0}{" "}
                             &nbsp; | &nbsp; New Leads :{" "}
                             {this.state.leadDetailsCount -
                               this.state.getLeadCounts}
                           </span>
                         </div>
                       </div>
                       <div className="row">
                         <div
                           className="col-sm-12 col-md-6 col-lg-6"
                           id="lead_button1"
                         >
                           {/*sunita-task-3160-added column as per requirement */}
                           {/*sunita-task-3271-added css as per requirement */}
                           <label
                             class=" btn btn-defaulttab"
                             style={{
                               cursor:
                                 this.state.leadDetails.length > 0
                                   ? "default"
                                   : "no-drop",
                               height: "32px",
                               fontFamily: "sans-serif",
                               fontSize: "14px",
                               fontWeight: "400",
                               borderRadius: "4px",
                               backgroundColor: "transparent",
                               color: "#193D8F",
                               border: "1px solid #193D8F",
                             }}
                             disabled={
                               this.state.leadDetails.length > 0 ? false : true
                             }
                           >
                             Upload Lead
                             <input
                               disabled={
                                 this.state.leadDetails.length > 0 ? false : true
                               }
                               id="abm1"
                               key={this.state.filesData1}
                               style={{ display: "none" }}
                               type="file"
                               onChange={this.fileupload}
                             />{" "}
                             {/*-sunita-task-3271-added for file*/}
                           </label>
                           <span
                             id="fileName1"
                             style={{
                               width: "150px",
                               textOverflow: "ellipsis",
                               display: "contents",
                               cursor:
                                 this.state.leadDetails.length > 0
                                   ? "default"
                                   : "no-drop",
                             }}
                             disabled={
                               this.state.leadDetails.length > 0 ? false : true
                             }
                           >
                             No File Choosen
                           </span>
                           {/*sunita-task-3271-added as per requirement */}
                         </div>
                       </div>
 
                       <br />
                       <div
                         class="row"
                         style={{
                           marginBottom: "6px",
                           display: "flex",
                           flexDirection: "row",
                           alignItems: "center",
                         }}
                       >
                         {/*sunita-task-3160-added row and column as per requirement */}
                         <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9 col-xl-10">
                           <span
                             style={{
                               fontWeight: "bold",
                               marginTop: "115px",
                               color: "#056eb8",
                             }}
                           >
                             Online Lead Review & Instant Status Update :-
                             <br />
                           </span>
                           {/*Aman-4947-added condition of filterleadDetailsCount to display filter lead count with date*/}
                           <span style={{ display: "flex" }}>
                             Total Leads :{" "}
                             {this.state.filterleadDetailsSatus === true ? (
                               <div>
                                 &nbsp;
                                 <b>
                                   {this.state.filterleadDetailsCount}
                                 </b> from{" "}
                                 <b>{this.state.stectedStartDateforDisplay}</b>{" "}
                                 to <b>{this.state.stectedEndDateforDisplay}</b>
                               </div>
                             ) : (
                               <b>&nbsp;{this.state.leadDetails.length}</b>
                             )}
                           </span>
                         </div>
 
                         <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 btn_Submit">
                           {/*sunita-task-3160-added button as per requirement */}
                           <button
                             className="btn add-button"
                             id="submitButton"
                             onClick={this.handleSubmit}
                             disabled={
                               this.state.leadDetails.length > 0 ? false : true
                             }
                             style={{
                               align: "right",
                               paddingBottom: "6px",
                               fontFamily: "sans-serif",
                               fontSize: "14px",
                               fontWeight: "400",
                               borderRadius: "4px",
                             }}
                           >
                             Submit{" "}
                             {/*sunita-task-3271-added css as per requirement */}
                           </button>
                         </div>
                       </div>
 
                       {/*sunita-task-3271-added some attributes as per requirement in table */}
                       {/* Sandeep-task-3441-added loader */}
                       <div id={this.state.loader2}></div>
                       <Table
                         {...this.state.tableState}
                         columns={
                           this.state.marketingChannelFlag === true
                             ? qaLeadDetails2
                             : qaLeadDetails
                         }
                         rowClassName={"qaLeadDetails"}
                         onChange={onChange}
                         class="ant-table-thead"
                         className={tableCSS}
                         dataSource={
                           this.state.filterleadDetailsSatus === true
                             ? this.state.filterleadDetails
                             : this.state.leadDetails
                         } //Aman-4947-added filterleadDetailsSatus to display filter lead or total lead in the table
                         //4562 -(as discussed with ram sir, commented the pagination for this_issue) Prod_Issue - AC, AQA, ANC, DIQA, Publisher, PQA, Advertiser -Leads Review-Leads Rejected Issue
                         //pagination={false}
                         //Priyanka-Because of unresponsive popup reverting task 4562
                         pagination={{
                           pageSize: this.state.pageSize2,
                           position: "bottom",
                         }}
                         loading={this.state.loading1}
                         scroll={{ x: 1300, y: 1333 }}
                         bordered={true}
                       >
                         {" "}
                         {/* y:1333 - 4562 -change the table height */}
                         {/*className={tableCSS + " " + 'myFont'} >{/*sunita-task-3180-removed css as not needed*/}
                       </Table>
                       {/* <p> */}
                       {/* 4562 -(as discussed with ram sir, commented the pagination for this_issue) Prod_Issue - AC, AQA, ANC, DIQA, Publisher, PQA, Advertiser -Leads Review-Leads Rejected Issue */}
                       {/* sunita-task-3271-added below code for paginaion */}
                       {/* Priyanka-Because of unresponsive popup reverting task 4562 */}
                       <div class="row">
                         <div
                           class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                           style={{
                             marginTop: "-52px",
                             marginLeft: "5px",
                             fontSize: "13px",
                             color: "#4F4F4F",
                             fontFamily: "sans-serif",
                           }}
                         >
                           &nbsp;&nbsp;&nbsp;Records per page:&nbsp;
                           <select
                             defaultValue={this.state.pageSize2}
                             onChange={this.handleChangePageSize2}
                             id="pacing1"
                             class="input-small"
                             className="form-control"
                             name="pacing"
                             style={{
                               width: "71px",
                               height: "30px",
                               display: "initial",
                               fontSize: "13px",
                               fontFamily: "sans-serif",
                               color: "#4F4F4F",
                             }}
                           >
                             {/* sunita-task-3271-added css as per requirement  */}
                             <option value="100" selected>
                               100
                             </option>
                             <option value="200">200</option>
                             <option value="300">300</option>
                           </select>
                         </div>
                       </div>
                       {/* </p> */}
 
                       <br />
                       <div className="row">
                         <div
                           className="col-sm-12 col-md-6 col-lg-8"
                           style={{ textAlign: "left" }}
                         ></div>
                         <div
                           className="col-sm-12 col-md-6 col-lg-4"
                           style={{ textAlign: "end", marginBottom: "40px" }}
                         >
                           {/*sunita-task-3271-added css as per requirement */}
                           {/*sunita-task-3422-changed the id as it's giving pblm bcz same id's are given. */}
                           <button
                             className="btn  add-button"
                             id="submitButton1"
                             onClick={this.handleSubmit}
                             disabled={
                               this.state.leadDetails.length > 0 ? false : true
                             }
                             style={{
                               align: "right",
                               paddingBottom: "6px",
                               fontFamily: "sans-serif",
                               fontSize: "14px",
                               fontWeight: "400",
                               borderRadius: "4px",
                             }}
                           >
                             Submit
                           </button>
                         </div>
                       </div>
                     </div>
                     {/* </div>{/*3rd */}
                     {/* </div>2nd */}
                     {/* </div> 1st */}
                     {/* </div> */}
                   </div>
                 </div>
               </div>
             </Tab>
             <Tab eventKey={2} title="PV Review">
               <div class="container-fluid " style={{ fontFamily: "roboto" }}>
                 <div class="row" style={{ paddingTop: "10px" }}>
                   {/*sunita-task-3271-added code as per requirement */}
                   {/* Rutuja Jagtap task-3879 added Campaign Specification button for DIQA role */}
 
                   <div class="col-xs-12 col-sm-12  col-lg-4 col-xl-4"></div>
                   <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                     <center>
                       <label id="labelDI">PV Review</label>
                     </center>
                     {/* sunita-task-3271- added  code as per new screen requirement */}
                   </div>
                   <div
                     class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"
                     style={{ textAlign: "center", marginTop: "6px" }}
                   >
                     <button
                       class="btn add-button"
                       onClick={this.displayCampaignSpecification}
                     >
                       {" "}
                       Campaign Specification
                     </button>
                     &nbsp;
                     {/* Nilesh-5055- Add alert button*/}
                     {/* <span class="badgelink"> */}
                                         <Badge
                                             count={this.state.unreadAlerts.length <= 10 ? this.state.unreadAlerts.length : "10+"}
                                             overflowCount={10}
                                             offset={[1, -5]}>
                                              <button
                       class="btn add-button"
                       onClick={this.unreadAlerts.bind(this)}
                       style={{marginLeft: "15px"}}
                     >
                       {" "}
                       Alerts
                     </button>
                                         </Badge>{" "}
                                     {/* </span> */}
                   </div>
                 </div>
 
                 {/* <div class="row"> */}
                 {/* sunita-task-3271- added below code as per new screen requirement */}
                 <div class="card card-signin my-1">
                   <div class="card-body" style={{ padding: "5px" }}>
                     {/* <div class="col-sm-12 col-md-4 col-lg-3"> */}
                     <div
                       class="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 qacampaignDetailsDiv "
                       style={{
                         background: "#FFFFFF",
                         borderRight: "2px solid #eee",
                         padding: "0px",
                         height: "auto",
                       }}
                     >
                       {" "}
                       {/*sunita-task-3271-added code as per requirement */}
                       {/* <div><h4>Campaign List</h4> <hr style={{ marginBottom: "0px" }} /></div> */}
                       <div>
                         <center>
                           <label
                             className="campLeadHeading"
                             style={{
                               fontSize: "17px",
                               color: "#14254A",
                               marginTop: "10px",
                               fontWeight: "500",
                               fontFamily: "roboto",
                             }}
                           >
                             Campaigns for Lead Review :
                           </label>
                         </center>
                       </div>
                       {/* sunita-task-3271-added below code for  search */}
                       <div class="row">
                         <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                           <span>
                             <input
                               type="text"
                               className="inputstyle4"
                               name="search"
                               placeholder="Search Campaign"
                               style={{
                                 backgroundImage: "url(searchIcon.png)",
                                 backgroundPosition: "15px 6px",
                                 backgroundSize: "15px 15px",
                                 backgroundRepeat: "no-repeat",
                                 width: "280px",
                                 height: "28px",
                                 fontSize: "12px",
                                 // float: 'left',
                                 // marginLeft: '17px',
                                 border: "1px solid #CCCCCC",
                                 padding: "12px 20px 15px 36px",
                                 backgroundColor: "#FFFFFF",
                                 // marginBottom: '5px',
                                 // borderBottom: '1px solid #eee',
                                 fontFamily: "sans-serif",
                                 borderRadius: "2px",
                               }}
                               onChange={this.handleChangeSearch}
                               onPressEnter={this.handleChangeSearch}
                             ></input>
                           </span>
                         </div>
                       </div>
                       <br />
                       <hr />
                       {/* sunita-task-3271-added below condition as search table data */}
                       <div
                         style={{
                           overflowY: "scroll",
                           height: "auto",
                           background: "#FFFFFF",
                           padding: "0px",
                           borderRight: "2px solid #eee",
                           marginTop: "-9px",
                         }}
                       >
                         {" "}
                         {/*sunita-task-3271-added col and it's data as per requirement */}
                         {this.state.tableSearchText === "" ? (
                           <div>
                             {this.state.campList.map((campList) => (
                               <div
                                 style={{
                                   borderBottom: "1px solid #eee",
                                   padding: "5px 15px",
                                 }}
                                 className="campNames"
                               >
                                 {/* 4610-Nilesh Add agency camp ID i.e clientCampID */}
                                 {/* kiran-4752-removing console warning-added "#/" */}
                                 <a
                                   href="#/"
                                   className="reviewcampdata"
                                   id={campList.campID}
                                   onClick={this.displayLeads}
                                 >
                                   {campList.campID} - {campList.clientCampID} -{" "}
                                   {campList.campaignName}{" "}
                                 </a>
                               </div>
                             ))}
                           </div>
                         ) : this.state.campListSearch.length > 0 ? (
                           <div>
                             {this.state.campListSearch.map((campListSearch) => (
                               <div
                                 style={{
                                   borderBottom: "1px solid #eee",
                                   padding: "5px 15px",
                                 }}
                                 className="campNames"
                               >
                                 {/* 4610-Nilesh Add agency camp ID i.e clientCampID */}
                                 <a
                                   href="#/"
                                   className="reviewcampdata"
                                   id={campListSearch.campID}
                                   onClick={this.displayLeads}
                                 >
                                   {campListSearch.campID} -{" "}
                                   {campListSearch.clientCampID} -{" "}
                                   {campListSearch.campaignName}{" "}
                                 </a>
                               </div>
                             ))}
                           </div>
                         ) : (
                           <center>
                             <label
                               style={{
                                 color: "red",
                                 fontSize: "medium",
                                 marginTop: "34px",
                               }}
                             >
                               No Data Exist
                             </label>
                           </center>
                         )}
                       </div>
                     </div>
 
                     {/* <br /> */}
                     {/* <div id="class one Div" class="col-sm-12 col-md-8 col-lg-9">
               <div class="card" >
                 <div className="card-body card-body2"> 
                   {/* <div> */}
                     {/*sunita-task-3271-added below code as per requirement  */}
                     {/* Rutuja Jagtap task-3879 Responsive*/}
                     <div
                       className="col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 qacampaignDetails"
                       style={{ background: "#FFFFFF", paddingTop: "20px" }}
                     >
                       {/*sunita-task-3271-added code as per requirement */}
                       <div
                         id="pleaseWait"
                         style={{
                           color: "red",
                           fontWeight: "bold",
                           fontSize: "13px",
                           left: "400px",
                           top: "200px",
                         }}
                         className={this.state.loading}
                       ></div>
                       <div>
                         {this.state.campDetails.map((details) => {
                           return (
                             <div>
                               {/*sunita-task-3271-added below label css changes for campID,campname,start date,end date and type as per new requirement for alignment*/}
                               <div
                                 className="row"
                                 style={{ marginTop: "-9px" }}
                               >
                                 {/* <div className="col-sm-12 col-md-6 col-lg-8"> */}
                                 <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                                   <label
                                     style={{
                                       fontSize: "14px",
                                       color: "black",
                                       fontWeight: "600",
                                     }}
                                   >
                                     Campaign ID :
                                   </label>{" "}
                                   &nbsp;<span>{details.campID}</span>
                                 </div>
                                 {/* </div> */}
                                 {/* <div className="row"> */}
                                 {/* <div className="col-sm-12 col-md-8 col-lg-8"> */}
                                 <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-8">
                                   <label
                                     style={{
                                       fontSize: "14px",
                                       color: "black",
                                       fontWeight: "600",
                                     }}
                                   >
                                     Campaign Name :{" "}
                                   </label>
                                   &nbsp;
                                   <span className="reviewcampdata1">
                                     {" "}
                                     {details.campaignName}
                                   </span>
                                 </div>
                                 {/* </div> */}
 
                                 {/* <div className="row"> */}
                                 {/* <div className="col-sm-12 col-md-6 col-lg-3"> */}
                                 <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                                   <label
                                     style={{
                                       fontSize: "14px",
                                       color: "black",
                                       fontWeight: "600",
                                     }}
                                   >
                                     Campaign Type :
                                   </label>
                                   &nbsp;<span> {details.ABM}</span>
                                 </div>
 
                                 {/* <div className="col-sm-12 col-md-6 col-lg-3"> */}
                                 <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                                   <label
                                     style={{
                                       fontSize: "14px",
                                       color: "black",
                                       fontWeight: "600",
                                     }}
                                   >
                                     Start Date :
                                   </label>
                                   &nbsp;<span> {details.startDate}</span>
                                 </div>
 
                                 {/* <div className="col-sm-12 col-md-6 col-lg-3"> */}
                                 <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                                   <label
                                     style={{
                                       fontSize: "14px",
                                       color: "black",
                                       fontWeight: "600",
                                     }}
                                   >
                                     End Date :
                                   </label>
                                   &nbsp;<span>{details.endDate}</span>
                                 </div>
                               </div>
                             </div>
                           );
                         })}
                       </div>
 
                       <br />
 
                       <hr />
 
                       <div className="row" style={{ marginLeft: "0px" }}>
                         <div>
                           {/*sunita-task-3271-added below changes as per new requirement screen for instructions*/}
                           <h5>
                             <span
                               style={{ fontWeight: "bold", color: "#F28C0F" }}
                             >
                               Offline Lead Review Instructions :-{" "}
                             </span>
                             <br />
                           </h5>
                           <span>
                             1. Click on <b>"Download Leads" </b> to get Lead's
                             File.
                           </span>
                           <br />
 
                           <span>
                             2. After reviewing the lead, Please update status as{" "}
                             <b> "PV ACCEPTED" OR "PV REJECTED" </b>
                             in Status column in Lead's File.
                           </span>
                         </div>
                         <div>
                           <span>
                             3. If you rejecting the lead then please{" "}
                             <b> add reason of rejection </b> in reason column in
                             Lead's File.
                           </span>
                           <br />
                           <span>
                             4. Click on <b>"Upload Lead"</b> to upload reviewed
                             Lead's File.
                           </span>
                         </div>
                       </div>
                       <br />
 
                       <div className="row">
                         <div className="col-sm-12 col-md-6 col-lg-6">
                           {/*sunita-task-3160-added column for proper alignment*/}
                           <button
                             class="btn add-button"
                             type="button"
                             onClick={this.downloadFile}
                             style={{
                               backgroundColor: "#056eb8",
                               color: "white",
                               height: "32px",
                               fontSize: "14px",
                               fontFamily: "sans-serif",
                               fontWeight: "400",
                               borderRadius: "4px",
                             }}
                             disabled={
                               this.state.leadDetails.length > 0 ? false : true
                             }
                           >
                             {/*sunita-task-3271-added  css as per requirement */}
                             <i
                               class="fa fa-cloud-download"
                               aria-hidden="true"
                             ></i>
                             &nbsp;Download Leads
                           </button>
                         </div>
                         <div className="col-sm-12 col-md-6 col-lg-6">
                           <span className="mobileCss">
                             Download Leads:{" "}
                             {this.state.getLeadCounts > 0
                               ? this.state.getLeadCounts
                               : 0}{" "}
                             &nbsp; | &nbsp; New Leads :{" "}
                             {this.state.leadDetailsCount -
                               this.state.getLeadCounts}
                           </span>
                         </div>
                       </div>
                       <div className="row">
                         <div
                           className="col-sm-12 col-md-6 col-lg-6"
                           id="lead_button1"
                         >
                           {/*sunita-task-3160-added column as per requirement */}
                           {/*sunita-task-3271-added css as per requirement */}
                           <label
                             class=" btn btn-defaulttab"
                             style={{
                               cursor:
                                 this.state.leadDetails.length > 0
                                   ? "default"
                                   : "no-drop",
                               height: "32px",
                               fontFamily: "sans-serif",
                               fontSize: "14px",
                               fontWeight: "400",
                               borderRadius: "4px",
                               backgroundColor: "transparent",
                               color: "#193D8F",
                               border: "1px solid #193D8F",
                             }}
                             disabled={
                               this.state.leadDetails.length > 0 ? false : true
                             }
                           >
                             Upload Lead
                             <input
                               disabled={
                                 this.state.leadDetails.length > 0 ? false : true
                               }
                               id="abm1"
                               key={this.state.filesData1}
                               style={{ display: "none" }}
                               type="file"
                               onChange={this.fileupload}
                             />{" "}
                             {/*-sunita-task-3271-added for file*/}
                           </label>
                           <span
                             id="fileName2"
                             style={{
                               width: "150px",
                               textOverflow: "ellipsis",
                               display: "contents",
                               cursor:
                                 this.state.leadDetails.length > 0
                                   ? "default"
                                   : "no-drop",
                             }}
                             disabled={
                               this.state.leadDetails.length > 0 ? false : true
                             }
                           >
                             No File Choosen
                           </span>
                           {/*sunita-task-3271-added as per requirement */}
                         </div>
                       </div>
 
                       <br />
                       <div class="row" style={{ marginBottom: "6px" }}>
                         {/*sunita-task-3160-added row and column as per requirement */}
                         <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9 col-xl-10">
                           <span
                             style={{
                               fontWeight: "bold",
                               marginTop: "115px",
                               color: "#056eb8",
                             }}
                           >
                             Online Lead Review & Instant Status Update :-
                             <br />
                           </span>
                           <span>
                             Total Leads :{this.state.leadDetails.length}
                           </span>
                         </div>
 
                         {/* Priyanka-4633-Renaming submit button for second tab */}
                         <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 btn_Submit">
                           {/*sunita-task-3160-added button as per requirement */}
                           <button
                             className="btn add-button"
                             id="submitButtonPV"
                             onClick={this.handleSubmit}
                             disabled={
                               this.state.leadDetails.length > 0 ? false : true
                             }
                             style={{
                               align: "right",
                               paddingBottom: "6px",
                               fontFamily: "sans-serif",
                               fontSize: "14px",
                               fontWeight: "400",
                               borderRadius: "4px",
                             }}
                           >
                             Submit{" "}
                             {/*sunita-task-3271-added css as per requirement */}
                           </button>
                         </div>
 
                         {this.state.reasonErrorDisplayFlag === true ? (
                           <span style={{ color: "red", marginLeft: "2%" }}>
                             Please enter reason for Rejection
                           </span>
                         ) : (
                           ""
                         )}
                       </div>
 
                       {/*sunita-task-3271-added some attributes as per requirement in table */}
                       {/* Sandeep-task-3441-added loader */}
                       <div id={this.state.loader2}></div>
                       <Table
                         {...this.state.tableState}
                         columns={
                           this.state.marketingChannelFlag === true
                             ? pvLeadDetails2
                             : pvLeadDetails
                         }
                         rowClassName={"qaLeadDetails"}
                         onChange={onChange}
                         class="ant-table-thead"
                         className={tableCSS}
                         dataSource={this.state.leadDetails}
                         //4562 -(as discussed with ram sir, commented the pagination for this_issue) Prod_Issue - AC, AQA, ANC, DIQA, Publisher, PQA, Advertiser -Leads Review-Leads Rejected Issue
                         pagination={false}
                         //pagination={{ pageSize: this.state.pageSize2, position: "bottom" }}
                         //loading={this.state.loading1}
                         scroll={{ x: 1300, y: 450 }}
                         bordered={true}
                       >
                         {" "}
                         {/* y:1333 - 4562 -change the table height */}
                         {/*className={tableCSS + " " + 'myFont'} >{/*sunita-task-3180-removed css as not needed*/}
                       </Table>
                       {/* <p> */}
                       {/* 4562 -(as discussed with ram sir, commented the pagination for this_issue) Prod_Issue - AC, AQA, ANC, DIQA, Publisher, PQA, Advertiser -Leads Review-Leads Rejected Issue */}
                       {/* sunita-task-3271-added below code for paginaion */}
                       {/* <div class="row">
                         <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{ marginTop: '-52px', marginLeft: "5px", fontSize: '13px', color: '#4F4F4F', fontFamily: 'sans-serif' }}>
                           &nbsp;&nbsp;&nbsp;Records per page:&nbsp;
                           <select
                             defaultValue={this.state.pageSize2}
                             onChange={this.handleChangePageSize2}
                             id="pacing1"
                             class="input-small"
                             className="form-control"
                             name="pacing" style={{ width: '71px', height: '30px', display: 'initial', fontSize: '13px', fontFamily: 'sans-serif', color: '#4F4F4F' }}> /*sunita-task-3271-added css as per requirement /
                             <option value="100" selected>100</option>
                             <option value="200" >200</option>
                             <option value="300">300</option>
                           </select>
                         </div>
                       </div> */}
                       {/* </p> */}
 
                       <br />
                       <div className="row">
                         <div
                           className="col-sm-12 col-md-6 col-lg-8"
                           style={{ textAlign: "left" }}
                         ></div>
                         <div
                           className="col-sm-12 col-md-6 col-lg-4"
                           style={{ textAlign: "end", marginBottom: "40px" }}
                         >
                           {/*sunita-task-3271-added css as per requirement */}
                           {/*sunita-task-3422-changed the id as it's giving pblm bcz same id's are given. */}
                           {/* Priyanka-4633-Renaming submit button for second tab */}
                           <button
                             className="btn  add-button"
                             id="submitButtonPV1"
                             onClick={this.handleSubmit}
                             disabled={
                               this.state.leadDetails.length > 0 ? false : true
                             }
                             style={{
                               align: "right",
                               paddingBottom: "6px",
                               fontFamily: "sans-serif",
                               fontSize: "14px",
                               fontWeight: "400",
                               borderRadius: "4px",
                             }}
                           >
                             Submit
                           </button>
                         </div>
                       </div>
                     </div>
                     {/* </div>{/*3rd */}
                     {/* </div>2nd */}
                     {/* </div> 1st */}
                     {/* </div> */}
                   </div>
                 </div>
               </div>
             </Tab>
           </Tabs>
           <Footer />
         </div>
       </div>
     );
   }
 }
 dcReview.propTypes = {
   logoutUser: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
 };
 
 const mapStateToProps = (state) => ({
   auth: state.auth,
 });
 export default connect(mapStateToProps, { logoutUser })(
   withRouter(dcReview)
 );
 
 // export default  AgencyUnreadAlerts;
 