/* Copyright(c) 2024 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Rutuja
@Description:Email Config DIQA
date: 07-03-2024
*/
import React from "react";
import {
    Checkbox,
    
  } from "antd";
  import { connect } from "react-redux";
  import Navigation from "../layouts/navPage";
  import PropTypes from "prop-types";
import Footer from "../layouts/footer";
import "./publisherEmailConfig.css";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
import { FaArrowAltCircleLeft } from "react-icons/fa";

const Swal = require("sweetalert2");

const leadActivityForDIQA = [
  { id: "Lead Review", name: "leadReview", value: "No" },
];
const diqaActivityForCR = [
  { id: "Creative Review", name: "creativeReview", value: "No" },
];


class DIQAEmailConfig extends React.Component {
   

    componentWillMount() {
      if (!this.props.auth.isAuthenticated) {
        this.props.history.push("/userLogin");
      } else {
        const { user } = this.props.auth;
        this.setState({ role: user.role });
      }
      fetch("/userConfiguration/getEmailConfigurationInfo", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => res.json())
      .then((emailConfigData) => {
        this.setState({ emailConfigData: emailConfigData });
      
      var tempArray = [];
      this.state.emailConfigData.forEach(function(obj) {
        let keys = Object.keys(obj);
        keys.forEach(function(key) {
          
          var a = obj[key];
          if (a === "Yes") { 
            tempArray.push(key);
          }
        })
      })
      var resultLead = leadActivityForDIQA.filter(function(b) {
        var name = b.name.toString();
        return tempArray.includes(name);
      });
      const leadReviewData = resultLead.map(function(a) {
        return a.name;
      });
      var resultLead1 = diqaActivityForCR.filter(function(b) {
        var name = b.name.toString();
        return tempArray.includes(name);
      });
      const creativeReviewData = resultLead1.map(function(a) {
        return a.name;
      });
      this.setState({
        diqaActivityForCR: creativeReviewData,
        leadActivityForDIQA: leadReviewData,
      }) 
    })
    }
    //Rutuja -4975 handle change for publisher Related activity notification
    diqaOnChangeForNC = (diqaActivityForCR) => {
      this.setState({
        diqaActivityForCR,
       
      });
    };
     //Rutuja -4975 handle change for lead Related activity notification
    leadOnCheckChange = (leadActivityForDIQA) => {
      this.setState({
        leadActivityForDIQA
      });
    };
    //Rutuja 4975- handle chng change for discard button
    handleRefreshPage(e) {
      Swal.fire({
        title: "Are you sure you want to discard the changes?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
        confirmButtonText: "Discard",
      }).then((result) => {
        if (result.value) {
          //Priyanka-5465-Role wise redirect to dashboard and lead review
          const { user } = this.props.auth;
          if (user.role === "DC") {
            window.location.href = "/qualityDashboard"; 
          } else {
            window.location.href = "/qualityLeadsReview";
          }
        } else if (result.dismiss === "close") {
          window.location.href = "#";
        } else {
          window.location.href = "#";
        }
      });
    }
//Rutuja 4795 handle change for submit button
    handleSubmit = (e) => {
      //camp
      e.preventDefault();
  
      var finalCheckedList = [];
      var dataForDIQA = {};
      var dataForLead = {};
      var diqaActivityForCR = this.state.diqaActivityForCR;
      var leadActivityForDIQA = this.state.leadActivityForDIQA;

      if(diqaActivityForCR!==undefined)
      {
        let temp = this.state.diqaActivityForCR;
        dataForDIQA[temp] = "Yes";
      }
      else{
        dataForDIQA["creativeReview"] = "No";
      }

      if(leadActivityForDIQA !== undefined)
      {
        let temp = this.state.leadActivityForDIQA;
        dataForDIQA[temp] = "Yes";
      }
      else{
        dataForDIQA["leadReview"] = "No";
      }
      
        finalCheckedList.push(dataForDIQA);

  
  
      let configData = {
        activity: finalCheckedList,
      }; 
      fetch("/userConfiguration/emailConfigurationInfo", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(configData),
      })
        .then((res) => res.json())
        .then((response) => {
          Swal.fire({
            text: "Email Configuration Done Successfully",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
            },
          });
        });
    };

render() {
    return (
        
        <div>
             <Navigation />
             <div
          className="container-fluid"
          id="Container-PUB"
          style={{ paddingTop: "85px", paddingBottom: "60px" }}
        >
           <div class="col-md-1 col-sm-1 col-xs-1">
           {this.props.auth.user.role === "DC" ? (
              <a
                class="TabOne"
                href={"/qualityDashboard"}
                
                style={{ color: "#056eb8" }}
              >
                <FaArrowAltCircleLeft
                  size={50}
                  style={{ float: "left", paddingTop: "20px" }}
                  title="Back to Dashboard"
                  
                />
              </a>
            ):(
              <a
              class="TabOne"
              href={"/qualityLeadsReview"}
            
              style={{ color: "#056eb8" }}
              >
                <FaArrowAltCircleLeft
                  size={50}
                  style={{ float: "left", paddingTop: "20px" }}
                  title="Back to Lead Review"
                />
              </a>
            )}
            </div>
                <div class="col-md-10 col-sm-10 col-xs-10">
             <p style={{ paddingTop: "20px",paddingLeft:"20" }}>
            <h3 align="center" id="labelDIPUB">
              Email Notification
            </h3>
          </p>
          </div>
        </div>
        
        <div class="container-PUB">
            <form className="emailformPUB" onSubmit={this.handleSubmit}>
             

              <div class="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                  <p>
                    <h4 class="hFourP" style={{ fontFamily: "roboto" }}>
                      Publisher Related Activity Notification
                      
                    </h4>
                  </p>

                  <div className="background" id="pub_pnc_back">
                      <Checkbox.Group
                        className="pub_pnc"
                        id="single"
                        style={{
                          height: "25px",
                        }}
                        options={diqaActivityForCR.map((diqaActivityForCR) => ({
                          label: diqaActivityForCR.id,
                          value: diqaActivityForCR.name,
                        }))}
                        value={this.state.diqaActivityForCR}
                        onChange={this.diqaOnChangeForNC}
                      />
                    </div>

                </div>
              </div>

              <br />
              <br />
              
              <div class="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                  <p>
                    <h4 class="hFourP" style={{ fontFamily: "roboto" }}>
                    Lead Related Activity Notification     
                                   
                    </h4>
                  </p>

                  <div className="background" id="pub_pnc_back">
                      <Checkbox.Group
                        className="pub_pnc"
                        id="single"
                        style={{
                          height: "25px",
                        }}
                        options={leadActivityForDIQA.map((leadActivityForDIQA) => ({
                          label: leadActivityForDIQA.id,
                          value: leadActivityForDIQA.name,
                        }))}
                        value={this.state.leadActivityForDIQA}
                        onChange={this.leadOnCheckChange}
                      />
                    </div>

                </div>
              </div>
             
              <br />
              <br />

              <br />
              <div class="row">
                <div className="col-xs-10 col-sm-10 col-md-10 col-lg-12 col-xl-10">
                  <button
                    type="primary"
                    htmltype="submit"
                    id="submitBtn-1"
                    class="btn add-button"
                    style={{ float: "right" }}
                  >
                    Submit
                  </button>

                  <button
                    type="button"
                    data-dismiss="modal"
                    class="can-btn-email-1"
                    style={{ float: "right", border: "solid #4893d4" }}
                    onClick={this.handleRefreshPage}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        <Footer />
        </div>
    )
}
}
DIQAEmailConfig.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  export default connect(
    mapStateToProps,
    { logoutUser }
  )(withRouter(DIQAEmailConfig));