/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author Sanjana Shende,Raunak Thakkar,Snehal More,Shivani Pathak
 *@fileName :NewEditCampaign.js
 *Desc: New UI for Edit Campaign
 */
import React, { Component } from "react";
import * as $ from "jquery";
import { Steps, Radio, Icon, Tooltip, Checkbox, Input } from "antd";
import NavigationBar from "../layouts/navPage";
import Footer from "../layouts/footer";
import "./createCampaignNew.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Picky from "react-picky";
import { FaArrowAltCircleLeft, FaSearch } from "react-icons/fa";
import "react-picky/dist/picky.css";
import styled from "styled-components";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3767-added default header
const Swal = require("sweetalert2");
//use for fetch url value
//Sandeep-task-3309-added code to get countryRegion array of data on respective page
const BeneluxOptions =
  require("../campaignCreationFile/countryRegionArray").BeneluxOptions;
const DACHOptions =
  require("../campaignCreationFile/countryRegionArray").DACHOptions;
const NordicOptions =
  require("../campaignCreationFile/countryRegionArray").NordicOptions;
const APACOptions =
  require("../campaignCreationFile/countryRegionArray").APACOptions;
const NorthernAfricaCountriesOptions =
  require("../campaignCreationFile/countryRegionArray").NorthernAfricaCountriesOptions;
const ANZOptions =
  require("../campaignCreationFile/countryRegionArray").ANZOptions;
const CentralOrMiddleAfricanOptions =
  require("../campaignCreationFile/countryRegionArray").CentralOrMiddleAfricanOptions;
const SouthernAfricaCountriesOptions =
  require("../campaignCreationFile/countryRegionArray").SouthernAfricaCountriesOptions;
const EastAfricanCountriesOptions =
  require("../campaignCreationFile/countryRegionArray").EastAfricanCountriesOptions;
const WesternAfricaOptions =
  require("../campaignCreationFile/countryRegionArray").WesternAfricaOptions;
const MiddleEastOptions =
  require("../campaignCreationFile/countryRegionArray").MiddleEastOptions;
const EasternEuropeOptions =
  require("../campaignCreationFile/countryRegionArray").EasternEuropeOptions;
const NorthernEuropeOptions =
  require("../campaignCreationFile/countryRegionArray").NorthernEuropeOptions;
const SouthernEuropeOptions =
  require("../campaignCreationFile/countryRegionArray").SouthernEuropeOptions;
const WesternEuropeOptions =
  require("../campaignCreationFile/countryRegionArray").WesternEuropeOptions;
const CentralAsiaOptions =
  require("../campaignCreationFile/countryRegionArray").CentralAsiaOptions;
const EastAsiaOptions =
  require("../campaignCreationFile/countryRegionArray").EastAsiaOptions;
const SouthAsiaOptions =
  require("../campaignCreationFile/countryRegionArray").SouthAsiaOptions;
const SoutheastAsiaOptions =
  require("../campaignCreationFile/countryRegionArray").SoutheastAsiaOptions;
const WesternAsiaOptions =
  require("../campaignCreationFile/countryRegionArray").WesternAsiaOptions;
const SouthAmericaOptions =
  require("../campaignCreationFile/countryRegionArray").SouthAmericaOptions;
const AntarcticOptions =
  require("../campaignCreationFile/countryRegionArray").AntarcticOptions;
const NorthAmericaOptions =
  require("../campaignCreationFile/countryRegionArray").NorthAmericaOptions;
const MelanesiaOptions =
  require("../campaignCreationFile/countryRegionArray").MelanesiaOptions;
const PolynesiaOptions =
  require("../campaignCreationFile/countryRegionArray").PolynesiaOptions;
const MicronesiaOptions =
  require("../campaignCreationFile/countryRegionArray").MicronesiaOptions;
const SouthernAtlanticOceanOptions =
  require("../campaignCreationFile/countryRegionArray").SouthernAtlanticOceanOptions;
const RegionOption =
  require("../campaignCreationFile/countryRegionArray").RegionOption;
const CheckboxGroup = Checkbox.Group;

const Select = styled(Picky)`
  background-color: #bd141480 !important;
  .picky_input {
    width: 20% !important;
  }
  .picky__dropdown {
    background-color: #bd141480 !important;
  }
  ,
  .option {
    font-size: 2em;
  }
`;
const PickyStyle = styled(Picky)`
  background-color: #bd141480 !important;
  .picky {
    width: 20% !important;
  }
`;
//snehal-task-2798-UI/UX-Changes-3.1
const HtmlTooltip = styled(Tooltip)`
	   backgroundColor: '#f5f5f9',
	   color: 'rgba(0, 0, 0, 0.87)',
	   maxWidth: 220,
	   fontSize: '12px',
	   border: '1px solid #dadde9',
	   `;
const creatives = [
  { id: "Newsletter", name: "Newsletter" },
  { id: "Landing Page", name: "Landing Page" },
  { id: "Call Script", name: "Call Script" },
];
// var creativeValueArray=[];
var jobLevelArray = [];
var jobFunctionArray = [];
var employeeSizeArrayEdit = [];
var companyRevenueEdit = [];
var industryEdit = [];
var jobTitleEdit = [];
var count = 0;
var statefilelengthback;
var cityfilelengthback;
var zipfilelengthback;
var excludedIndustryFilelengthback; //snehal-task-3647-Industry Exclusion on DI - FE
var industryTextCount = 1;
var monthArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
var diStandardFields = ["Campaign ID", "Status", "Reason"]; //kiran-4873-created array for system fields

const employeeSizeOption = [
  { id: "1-50", name: "1-50" },
  { id: "51-100", name: "51-100" },
  { id: "101-200", name: "101-200" },
  { id: "201-500", name: "201-500" },
  { id: "501-1000", name: "501-1000" },
  { id: "1001-5000", name: "1001-5000" },
  { id: "5001-10000", name: "5001-10000" },
  { id: "10001+", name: "10001+" },
];

const companyRevenueOption = [
  { id: "<$1M", name: "<$1M" },
  { id: "$1M - $5M", name: "$1M - $5M" },
  { id: "$5M - $10M", name: "$5M - $10M" },
  { id: "$10M - $25M", name: "$10M - $25M" },
  { id: "$25M - $50M", name: "$25M - $50M" },
  { id: "$50M - $100M", name: "$50M - $100M" },
  { id: "$100M - $250M", name: "$100M - $250M" },
  { id: "$250M - $500M", name: "$250M - $500M" },
  { id: "$500M - $1B", name: "$500M - $1B" },
  { id: "$1B - $5B", name: "$1B - $5B" },
  { id: "$5B - $10B", name: "$5B - $10B" },
  { id: ">$10B", name: ">$10B" },
];

//snehal-task-3139-Industry- Add All/Any option in industry drop down- Create/Edit/Back/ Lead Validation(added All option)
const industryOptions = [
  { id: "Any", name: "Any" },
  {
    id: "Accommodation and Food Services",
    name: "Accommodation and Food Services",
  },
  {
    id: "Administrative and Support and Waste Management and Remediation Services",
    name: "Administrative and Support and Waste Management and Remediation Services",
  },
  {
    id: "Advertising and Printing & Publishing",
    name: "Advertising and Printing & Publishing",
  },
  { id: "Aerospace & Defense", name: "Aerospace & Defense" },
  { id: "Accounting", name: "Accounting" },
  {
    id: "Agriculture and Forestry and Fishing and Hunting",
    name: "Agriculture and Forestry and Fishing and Hunting",
  },
  { id: "Apparel & Fashion", name: "Apparel & Fashion" },
  {
    id: "Arts and Entertainment and and Recreation",
    name: "Arts and Entertainment and and Recreation",
  },
  { id: "Automotive", name: "Automotive" },
  { id: "Business Services", name: "Business Services" },
  { id: "Construction", name: "Construction" },
  { id: "Engineering and Construction", name: "Engineering and Construction" },
  { id: "Education & Training", name: "Education & Training" },
  { id: "Energy & Utilities", name: "Energy & Utilities" },
  { id: "Financial Services & Banking", name: "Financial Services & Banking" },
  // { id: 'Finance and Insurance', name: 'Finance and Insurance' },
  { id: "Food & Beverage", name: "Food & Beverage" },
  {
    id: "Government and Public Administration",
    name: "Government and Public Administration",
  },
  // { id: 'Health Care and Social Assistance', name: 'Health Care and Social Assistance' },
  { id: "Healthcare", name: "Healthcare" },
  { id: "Hospitality & Travel", name: "Hospitality & Travel" },
  // { id: 'Information Technology & Services', name: 'Information Technology & Services'},
  { id: "Information", name: "Information" },
  { id: "Insurance", name: "Insurance" },
  { id: "Legal Solutions", name: "Legal Solutions" },
  {
    id: "Management of Companies and Enterprises",
    name: "Management of Companies and Enterprises",
  },
  { id: "Manufacturing", name: "Manufacturing" },
  { id: "Marketing", name: "Marketing" },
  { id: "Media & Entertainment", name: "Media & Entertainment" },
  { id: "Medical Devices & Equipment", name: "Medical Devices & Equipment" },
  { id: "Mining", name: "Mining" },
  // { id: 'Mining, Quarrying, and Oil and Gas Extraction', name: 'Mining, Quarrying, and Oil and Gas Extraction' },
  { id: "Not-for-Profit", name: "Not-for-Profit" },
  { id: "Pharmaceuticals & Biotech", name: "Pharmaceuticals & Biotech" },
  {
    id: "Professional and Scientific & Technical Services",
    name: "Professional and Scientific & Technical Services",
  },
  { id: "Real Estate", name: "Real Estate" },
  // { id: 'Real Estate and Rental and Leasing', name: 'Real Estate and Rental and Leasing' },
  { id: "Recreation", name: "Recreation" },
  // { id: 'Retail Trade', name: 'Retail Trade' },
  { id: "Retail", name: "Retail" },
  { id: "Software", name: "Software" },
  { id: "Telecommunications", name: "Telecommunications" },
  { id: "Transportation & Logistics", name: "Transportation & Logistics" },
  // { id: 'Transportation and Warehousing', name: 'Transportation and Warehousing' },
  { id: "Utilities", name: "Utilities" },
  {
    id: "Venture Capital & Private Equity",
    name: "Venture Capital & Private Equity",
  },
  { id: "Wholesale & Distribution", name: "Wholesale & Distribution" },
  // { id: 'Wholesale Trade', name: 'Wholesale Trade' },
  { id: "Consumer Goods & Services", name: "Consumer Goods & Services" },
  {
    id: "Scientific & Technical Service",
    name: "Scientific & Technical Service",
  },
  { id: "Other", name: "Other" },
];

const jobLevelOptions = [
  { id: "C-Level", name: "C-Level" },
  { id: "VP", name: "VP" },
  { id: "Director", name: "Director" },
  { id: "Head", name: "Head" },
  { id: "Manager", name: "Manager" },
  { id: "Staff", name: "Staff" },
];

const jobFunctionOptions = [
  { id: "Business", name: "Business" },
  { id: "Chain Supply", name: "Chain Supply" },
  { id: "Customer", name: "Customer" },
  { id: "Executive", name: "Executive" },
  { id: "Finance & Admin", name: "Finance & Admin" },
  { id: "HR", name: "HR" },
  { id: "IT", name: "IT" },
  { id: "IT Security", name: "IT Security" },
  { id: "Legal", name: "Legal" },
  { id: "Marketing", name: "Marketing" },
  { id: "Network", name: "Network" },
  { id: "Operation", name: "Operation" },
  { id: "Other", name: "Other" },
  { id: "Procurement", name: "Procurement" },
  { id: "Product", name: "Product" },
  { id: "Sales", name: "Sales" },
  { id: "Security & Compliance", name: "Security & Compliance" },
  { id: "Support", name: "Support" },
];

class EditExistingCampaign extends Component {
  constructor() {
    super();
    this.state = {
      error: {},
      campID: "",
      parentCampID: "",
      fromBack: false,
      creativeValueArray: [],
      current: 0, //counter of stepper
      display1: "block",
      display2: "none",
      display3: "none",
      display4: "none",
      displayStateFile: "none",
      displayCityFile: "none",
      displayZipCodeFile: "none",
      pacingUnit: "Number",
      values: [],
      callAuditdisable: true,
      creativesHrsdisable: true,
      creativesReqDisable: true,
      // BasicDetailArray:[{campaignName:"",clientName:"",clientCampID:"",campaignStatus:"Active",startDate:"",endDate:"",firstLeadDeliveryDate:"",noOfLeadPerDomain:"",leadAllocation:"",cpl:"",budget:"",currency:"USD"}],
      BasicDetailArrayEdit: [
        {
          campaignName: "",
          clientName: "",
          clientCampID: "0", //Sandeep task-3281-added default value 0 to client camp id
          campaignStatus: "Active",
          startDate: "",
          endDate: "",
          firstLeadDeliveryDate: "",
          noOfLeadPerDomain: "",
          leadAllocation: "",
          CPL: "",
          budget: "",
          currency: "USD",
        },
      ],
      basicCampaignDetailNoChangeArray: [],
      deliveryCampaignDetailNoChangeArray: [],
      campaignSpecDetailNoChangeArray: [],
      questionDetailNoChangeArray: [],
      deleteCustomQuestionArray: [],
      // DeliveryOption:[{pacing:"Monthly",campaignReportingDay:"Monday",campaignType:"open",leadInteractionDays:"",marketingChannel:"Email",callAudit:"No",requiredLPApproval:"",creativesApprovalRequiredHrs:"12",creativeApprovalRequiredFor:"Agency"}],
      DeliveryOptionEdit: [
        {
          pacing: "Monthly",
          campaignReportingDay: "Monday",
          abm: "Open",
          leadInteractionDays: "",
          marketingChannel: "Email",
          callAudit: "No",
          requiredLPApproval: "",
          creativesApprovalRequiredHrs: "12",
          creativeApprovalRequiredFor: "Agency",
          subContracting: "Yes", // User Story : 3188 : Karan Jagtap : default should be 'Yes'
        },
      ],
      pacingDetailsEdit: [],
      pacingCampaignNoChangeArray: [],
      CustomQuestionsEdit: [],
      customQuestionDetails: [],
      cnameDisplay: "none",
      clientDisplay: "none",

      startDateDisplay: "none",
      startDateDisplay2: "none",
      startDateDisplayRfp: "none",

      endDateDisplay: "none",
      endDateDisplay2: "none",
      endDateDisplay3: "none",

      firstLeadDeliveryDateDisplay: "none",
      firstLeadDeliveryDateDisplay2: "none",
      firstLeadDeliveryDateDisplay3: "none",
      firstLeadDeliveryDateDisplay4: "none",

      leadAllocationDisplay: "none",
      cplDisplay: "none",
      cBudgetDisplay: "none",
      cCurrencyDisplay: "none",
      noOfLeadPerDomainDisplay: "none",
      cnameDisplay2: "none",
      cplDisplay2: "none",
      cplDisplay3: "none",
      leadAllocationDisplay2: "none",
      leadAllocationDisplay3: "none",
      cBudgetDisplay2: "none",
      leadInteractionDaysDisplay: "none",
      leadInteractionDaysDisplay2: "none",
      creatives: [],
      creativesApprovalRequiredDisplay1: "none",
      pacingEndDateDisplay1: "none",
      pacingEndDateDisplay2: "none",
      pacingEndDateDisplay3: "none",
      pacingEndDateDisplay4: "none",
      leadCountDisplay: "none",
      leadCountDisplay2: "none",
      leadCountDisplay3: "none",
      leadPercentageDisplay: "none",
      leadPercentageDisplay1: "none",
      industryDisplay: "none",
      displayExcludedIndustryFile: "none", //snehal-task-3647-Industry Exclusion on DI - FE
      customIndustryTextDisplay: "none",
      excludedIndustryFileArray: "none", //snehal-task-3647-Industry Exclusion on DI - FE
      excludedIndustryDisplay: "none", //snehal-task-3647-Industry Exclusion on DI - FE
      employeeSizeDisplay: "none",
      customEmployeeSizeTextDisplay: "none",
      customCompanyRevenueTextDisplay: "none",
      companyRevenueRangeDisplay6: "none",
      employeeSizeRangeDisplay2: "none",
      employeeSizeRangeDisplay3: "none",
      employeeSizeRangeDisplay4: "none",
      employeeSizeRangeDisplay5: "none",
      companyRevenueRangeDisplay2: "none",
      companyRevenueRangeDisplay3: "none",
      companyRevenueRangeDisplay4: "none",
      companyRevenueRangeDisplay5: "none",
      companyRevenueDisplay: "none",
      pacingEndDateDisplay: "none",
      jobTitleDisplay: "none",
      jobTitleDisplay2: "none",
      customJobLevelDisplay: "none",
      customJobFunctionDisplay: "none",
      regionCountriesDisplay: "none",
      employeeSizetofrom: [],
      companyRevenuetofrom: [],
      customEmployeeSizeText: [],
      customCompanyRevenueText: [],
      customIndustryText: "",
      customIndustry: "",
      region: [],
      RegioncheckedList: "",
      BeneluxcheckedList: [],
      DACHcheckedList: [],
      NordiccheckedList: [],
      NorthernAfricaCountriescheckedList: [],
      CentralOrMiddleAfricancheckedList: [],
      SouthernAfricaCountriescheckedList: [],
      EastAfricanCountriescheckedList: [],
      WesternAfricacheckedList: [],
      middleEastCheckedList: [],
      EasternEuropecheckedList: [],
      NorthernEuropecheckedList: [],
      SouthernEuropecheckedList: [],
      WesternEuropecheckedList: [],
      CentralAsiacheckedList: [],
      EastAsiacheckedList: [],
      SouthAsiacheckedList: [],
      SoutheastAsiacheckedList: [],
      WesternAsiacheckedList: [],
      SouthAmericacheckedList: [],
      AntarcticcheckedList: [],
      NorthAmericacheckedList: [],
      AustraliaAndNewZealandcheckedList: [],
      MelanesiacheckedList: [],
      PolynesiacheckedList: [],
      MicronesiacheckedList: [],
      SouthernAtlanticOceancheckedList: [],
      nordicChecked: false,
      beneluxChecked: false,
      dachChecked: false,
      anzChecked: false,
      apacChecked: false,
      northerneuropeChecked: false,
      westernEuropeChecked: false,
      southAsiaChecked: false,
      middleEastChecked: false,
      customeFlag: false,
      indeterminate: false,
      checkAllRegion: false,
      checkAll: false,
      checkAll1: false,
      checkAll2: false,
      checkAll3: false,
      checkAll4: false,
      checkAll5: false,
      checkAll6: false,
      checkAll7: false,
      checkAll8: false,
      checkAll9: false,
      checkAll10: false,
      checkAll11: false,
      checkAll12: false,
      checkAll13: false,
      checkAll14: false,
      checkAll15: false,
      checkAll16: false,
      checkAll17: false,
      checkAll18: false,
      checkAll19: false,
      checkAll20: false,
      checkAll21: false,
      checkAll22: false,
      checkAll23: false,
      checkAll24: false,
      checkAll25: false,
      checkAll27: false,
      industry: [],
      industryDB: [],
      employeeSize: [],
      employeeSizeDB: [],
      companyRevenue: [],
      companyRevenueDB: [],
      jobLevel: [],
      jobLevelDB: [],
      jobFunction: [],
      jobFunctionDB: [],
      jobTitle: "",
      otherSpecifications: "",
      tableSearchText: "",
      inputcreativesApproval: "",
      tableSearchText: "",
      stateFile: "",
      cityFile: "",
      zipcodeFile: "",
      excludedIndustryFile: "", //snehal-task-3647-Industry Exclusion on DI - FE
      excludedIndustry: "", //snehal-task-3647-Industry Exclusion on DI - FE
      stateFileCheck: "No",
      cityFileCheck: "No",
      zipcodeFileCheck: "No",
      excludedIndustryFlag: "No", //snehal-task-3647-Industry Exclusion on DI - FE
      stateDisplay: "none",
      cityDisplay: "none",
      zipCodeDisplay: "none",
      displaySkipandNext: "none",
      displaySaveandNext: "block",
      companyRevenueDisplay: "none",
      customQuestionAliasName: "No", //snehal-task-3112-Custom Question Alias
      customQuestionAliasNameNoChange: "", //snehal-task-3112-Custom Question Alias
      checkedQue: false, //snehal-task-3167-Edit campaign--Alias issue
      clientNameDetails: [],
      jobTitlechk: "No",
      customJobLevel: [],
      customJobFunction: [],
      employeeSizeNotApplicable: "Applicable",
      IndustryNotApplicable: "Applicable",
      drpdisabled: "",
      drpdisabled1: "",
      campaignSpecificationDetailsEdit: [],
      statefileerrors: {},
      cityfileerrors: {},
      zipcodefileerrors: {},
      zipfileerrors: {},
      statefileHeadererrors: {},
      cityfileHeadererrors: {},
      cityfileArray: {},
      statefileArray: {},
      zipfileArray: {},
      zipfileHeadererrors: {},
      excludedIndustryFileerrors: {}, //snehal-task-3647-Industry Exclusion on DI - FE
      excludedIndustryFileHeadererrors: {}, //snehal-task-3647-Industry Exclusion on DI - FE
      excludedIndustryFileArray: {}, //snehal-task-3647-Industry Exclusion on DI - FE
      prevSd: "",
      prevEd: "",
      biddingDate: "",
      indeterminateAmr1: "",
      indeterminateAmr2: "",
      indeterminateEur1: "",
      indeterminateEur2: "",
      indeterminateEur3: "",
      indeterminateEur4: "",
      indeterminateAsia1: "",
      indeterminateAsia2: "",
      indeterminateAsia3: "",
      indeterminateAsia4: "",
      indeterminateAsia5: "",
      indeterminateAfr1: "",
      indeterminateAfr2: "",
      indeterminateAfr3: "",
      indeterminateAfr4: "",
      indeterminateAfr5: "",
      indeterminateMeast: "",
      indeterminateAnt1: "",
      indeterminateMel: "",
      indeterminatePoly1: "",
      indeterminateMic1: "",
      indeterminateSAtlan: "",
      isCheckedGeoLead: false,
      isCheckednonEnglish: false,
      geoLeadChk: "No",
      nonEnglishChecked: "No",
      geoLeadDisplay: "none",
      countrywiseLeadDisplay: "none",
      countrywiseLeadDisplay2: "none",
      countrywiseLeadDisplay3: "none",
      countrywiseLeadDisplay4: "none",
      customQuestionDisplay1: "none", //snehal-task-3112-Custom Question Alias
      customQuestionDisplay2: "none", //snehal-task-3112-Custom Question Alias
      customQuestionDisplay3: "none", //snehal-task-3112-Custom Question Alias
      customQuestionDisplay4: "none", //snehal-task-3112-Custom Question Alias
      checkAmericaSelectAll: false,
      nordicDisabled: false,
      beneluxDisabled: false,
      dachDisabled: false,
      ANZdisabled: false,
      ApacDisabled: false,
      basicCampaignEditDeatilsMail: [],
      deliveryCampaignEditDetailsMail: [],
      specificationCampEditDetailsMail: [],
      pacingCampaignEditDetailsMail: [],
      deletePacingEditDetailsMail: [],
      countryWiseAllocationArray: [],
      getTotalCountriesCount: [], //snehal-task-3608-Akshay sir--Country/region visibility--Campaign all flow
      tempCountryAllocation: [],
      getAllocatedLead: [],
      totalAllocatedLead: 0,
      allocatedCPL: 0,
      editLeadAllocation: false,
      editCPLAllocation: false,
      apiStartDate: "",
      apiEndDate: "",
      advCampId: "",
      spanlist: {},
      backtoEdit: false,
      apiLeadInteractionDays: "", //snehal-task2952-Prod Issue-Internal-Lead Interaction Days validation issue-(skip and next issue)
      agencyID: "", //3257-Sonali-added to insert in campaignLog table
      campaignStatus: "", //3257-Sonali-added to insert in campaignLog table
      customQuestionDisplay5: "none", //kiran-4873-added for showing error msg
      diStandardFields: [],
      columnSequenceCheck: false, //Nilesh-5310-to set column sequence check value
    };
    this.commonhandleChange = this.commonhandleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.backFunction = this.backFunction.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.DeliveryOptionHandleChange =
      this.DeliveryOptionHandleChange.bind(this);
    this.validateDeliveryOption = this.validateDeliveryOption.bind(this);
    this.pacingMonthlyCalculation = this.pacingMonthlyCalculation.bind(this);
    this.pacingHandleChange = this.pacingHandleChange.bind(this);
    this.industryHandleChange = this.industryHandleChange.bind(this);
    this.employeeHandleChange = this.employeeHandleChange.bind(this);
    this.validateCampaignSpecification =
      this.validateCampaignSpecification.bind(this);
    this.customEmplyeeSizeTextChange =
      this.customEmplyeeSizeTextChange.bind(this); //snehal-task-2798-UI/UX-Changes-3.1
    this.customCompanyRevenueTextChange =
      this.customCompanyRevenueTextChange.bind(this); //snehal-task-2798-UI/UX-Changes-3.1
    this.customJobLevelTextChange = this.customJobLevelTextChange.bind(this); //snehal-task-2798-UI/UX-Changes-3.1
    this.customJobFunctionTextChange =
      this.customJobFunctionTextChange.bind(this); //snehal-task-2798-UI/UX-Changes-3.1
    this.revenuehandleChange = this.revenuehandleChange.bind(this);
    this.jobLevelHandleChange = this.jobLevelHandleChange.bind(this);
    this.jobFunctionHandleChange = this.jobFunctionHandleChange.bind(this);
    this.fileHandleChange = this.fileHandleChange.bind(this);
    this.skipAndNext = this.skipAndNext.bind(this);
    this.showForm = this.showForm.bind(this);
    this.dynamicStateFile = this.dynamicStateFile.bind(this);
    this.dynamicZipCodeFile = this.dynamicZipCodeFile.bind(this);
    this.dynamicCityFile = this.dynamicCityFile.bind(this);
    this.customIndustryTextChange = this.customIndustryTextChange.bind(this);
    this.stateFilehandleChangeBack = this.stateFilehandleChangeBack.bind(this);
    this.zipcodeFilehandleChangeBack =
      this.zipcodeFilehandleChangeBack.bind(this);
    this.dynamicExcludedIndustryFile =
      this.dynamicExcludedIndustryFile.bind(this); //snehal-task-3647-Industry Exclusion on DI - FE
    this.excludedIndustryFilehandleChangeBack =
      this.excludedIndustryFilehandleChangeBack.bind(this); //snehal-task-3647-Industry Exclusion on DI - FE
    this.cityFilehandleChangeBack = this.cityFilehandleChangeBack.bind(this);
    this.handleclient = this.handleclient.bind(this);
    this.handlestepTwo = this.handlestepTwo.bind(this);
    this.handlestepThree = this.handlestepThree.bind(this);
    this.handleChangeCountryRegionSearch =
      this.handleChangeCountryRegionSearch.bind(this);
    this.getTotalCountries = this.getTotalCountries.bind(this); //snehal-task-3608-Akshay sir--Country/region visibility--Campaign all flow
    this.disperse = this.disperse.bind(this);
    this.imperse = this.imperse.bind(this);
    this.handleQuestionAlias = this.handleQuestionAlias.bind(this);
    this.checkDach = this.checkDach.bind(this);
    this.checkBenelux = this.checkBenelux.bind(this);
    this.checkColumnSequence = this.checkColumnSequence.bind(this); //Nilesh-5310
  }
  /**
   * @author Raunak Thakkar
   * @param  Description  for dis-selecting sub remaining sub-regions
   */
  checkBenelux(WesternEuropecheckedList) {
    let bool = true;
    BeneluxOptions.forEach((ele) => {
      if (!WesternEuropecheckedList.includes(ele)) {
        bool = false;
        return bool;
      }
    });
    return bool;
  }

  /**
   * @author Raunak Thakkar
   * @param  Description  for dis-selecting sub remaining sub-regions
   */
  checkDach(WesternEuropecheckedList) {
    let bool = true;
    DACHOptions.forEach((ele) => {
      if (!WesternEuropecheckedList.includes(ele)) {
        bool = false;
        return bool;
      }
    });
    return bool;
  }
  /**
   * @author Raunak Thakkar
   * @param  Description  for dis-selecting sub remaining sub-regions
   */
  imperse(centralAsia, eastAsia, southAsia, southeastAsia) {
    if (this.state.apacChecked === true) {
      this.setState(
        {
          indeterminateAsia1: false,
          indeterminateAsia2: false,
          indeterminateAsia3: false,
          indeterminateAsia4: false,
        },
        () => {
          ["Central Asia", "East Asia", "South Asia", "Southeast Asia"].forEach(
            (ele) => {
              if (this.state.region.indexOf(ele) > -1) {
                this.state.region.splice(this.state.region.indexOf(ele), 1);
              }
            }
          );
        }
      );
    }
  }
  /**
   * @author Raunak Thakkar
   * @param  Description  for selecting sub remaining sub-regions against 2864 and 2950
   */
  disperse(centralAsia, eastAsia, southAsia, southeastAsia) {
    if (this.state.apacChecked === true) {
      if (centralAsia === true && !this.state.region.includes("Central Asia")) {
        this.state.region.push("Central Asia");
      }
      if (eastAsia === true && !this.state.region.includes("East Asia")) {
        this.state.region.push("East Asia");
      }
      if (southAsia === true && !this.state.region.includes("South Asia")) {
        this.state.region.push("South Asia");
      }
      if (
        southeastAsia === true &&
        !this.state.region.includes("Southeast Asia")
      ) {
        this.state.region.push("Southeast Asia");
      }
      this.setState(
        {
          indeterminateAsia1: centralAsia == true ? true : false,
          indeterminateAsia2: eastAsia == true ? true : false,
          indeterminateAsia3: southAsia == true ? true : false,
          indeterminateAsia4: southeastAsia == true ? true : false,
        },
        () => {
          if (this.state.region.includes("apac")) {
            this.state.region.splice(this.state.region.indexOf("apac"), 1);
          }
        }
      );
    }
  }
  /**
   * @author Raunak Thakkar
   * @param  event
   * @description dynamically search the country
   */
  handleChangeCountryRegionSearch(e) {
    let colorCount = 0;
    for (const [key, value] of Object.entries(this.state.spanlist)) {
      if (
        e.target.value.length > 0 &&
        RegExp(/^[^1234567890^!@#$%*()_={}\n]+$/).test(e.target.value)
      ) {
        if (key.includes(e.target.value.toLowerCase())) {
          value[1].style.backgroundColor = "yellow";
          colorCount += 1;
        } else {
          value[1].style.backgroundColor = "#ffffff";
        }
      } else {
        value[1].style.backgroundColor = "#ffffff";
      }
    }
    if (
      !RegExp(/^[^1234567890^!@#$%*()_={}\n]+$/).test(e.target.value) &&
      colorCount == 0
    ) {
      document.getElementById("colorCount").innerHTML = "Invalid characters";
      document.getElementById("colorCount").style.color = "red";

      if (e.target.value.length == 0) {
        document.getElementById("colorCount").innerHTML = colorCount + "/252"; //mufiz-Task-Prod Issue-Agency-Custom Region and Countries---Country Count issue and Non-Selected Region showing in Region column.
        document.getElementById("colorCount").style.color = "black";
        return;
      }
    } else {
      if (colorCount > 0) {
        document.getElementById("colorCount").innerHTML = colorCount + "/252";
        document.getElementById("colorCount").style.color = "black";
      }

      if (colorCount == 0) {
        document.getElementById("colorCount").innerHTML = "No search found";
        document.getElementById("colorCount").style.color = "red";
      }
    }
  }
  handlestepThree() {
    var nordicArray = [];
    var beneluxArray = [];
    var dachArray = [];
    var anzArray = [];
    var apacArray = [];
    if (
      this.state.campaignSpecificationDetailsEdit[0]
        .requiredCountryWiseAllocation == "No" ||
      this.state.campaignSpecificationDetailsEdit[0]
        .requiredCountryWiseAllocation == null
    ) {
      this.setState({ geoLeadDisplay: "none" });
    } else {
      this.setState({ geoLeadDisplay: "block" });
    }

    if (
      this.state.campaignSpecificationDetailsEdit[0].region &&
      this.state.campaignSpecificationDetailsEdit
    ) {
      var regionBackEnd = [];
      regionBackEnd = this.state.campaignSpecificationDetailsEdit[0].region
        .toLocaleString() //Sandeep-task-3351-removed lower case from region bcz getting issue in backend
        .split(","); //2999-converting all the elements present in lower case
      // checklist for countries
      let backEndcounrties = [];
      backEndcounrties =
        this.state.campaignSpecificationDetailsEdit[0].country.split(",");
      this.state.NorthernAfricaCountriescheckedList = backEndcounrties.filter(
        function (item) {
          return NorthernAfricaCountriesOptions.includes(item);
        }
      );
      this.state.CentralOrMiddleAfricancheckedList = backEndcounrties.filter(
        function (item) {
          return CentralOrMiddleAfricanOptions.includes(item);
        }
      );
      this.state.SouthernAfricaCountriescheckedList = backEndcounrties.filter(
        function (item) {
          return SouthernAfricaCountriesOptions.includes(item);
        }
      );
      this.state.EastAfricanCountriescheckedList = backEndcounrties.filter(
        function (item) {
          return EastAfricanCountriesOptions.includes(item);
        }
      );
      this.state.WesternAfricacheckedList = backEndcounrties.filter(function (
        item
      ) {
        return WesternAfricaOptions.includes(item);
      });
      this.state.EasternEuropecheckedList = backEndcounrties.filter(function (
        item
      ) {
        return EasternEuropeOptions.includes(item);
      });
      this.state.NorthernEuropecheckedList = backEndcounrties.filter(function (
        item
      ) {
        return NorthernEuropeOptions.includes(item);
      });
      //  this.state.NorthernEuropecheckedList=backEndcounrties.filter(function(item){return NordicOptions.includes(item)});
      nordicArray = backEndcounrties.filter(function (item) {
        return NordicOptions.includes(item);
      });
      apacArray = backEndcounrties.filter(function (item) {
        return APACOptions.includes(item);
      });
      this.state.SouthernEuropecheckedList = backEndcounrties.filter(function (
        item
      ) {
        return SouthernEuropeOptions.includes(item);
      });
      this.state.WesternEuropecheckedList = backEndcounrties.filter(function (
        item
      ) {
        return WesternEuropeOptions.includes(item);
      });
      //  this.state.WesternEuropecheckedList=backEndcounrties.filter(function(item){return BeneluxOptions.includes(item)});
      beneluxArray = backEndcounrties.filter(function (item) {
        return BeneluxOptions.includes(item);
      });
      //  this.state.WesternEuropecheckedList=backEndcounrties.filter(function(item){return DACHOptions.includes(item)});
      dachArray = backEndcounrties.filter(function (item) {
        return DACHOptions.includes(item);
      });

      this.state.CentralAsiacheckedList = backEndcounrties.filter(function (
        item
      ) {
        return CentralAsiaOptions.includes(item);
      });
      this.state.EastAsiacheckedList = backEndcounrties.filter(function (item) {
        return EastAsiaOptions.includes(item);
      });
      this.state.SouthAsiacheckedList = backEndcounrties.filter(function (
        item
      ) {
        return SouthAsiaOptions.includes(item);
      });
      //  this.state.SouthAsiacheckedList=backEndcounrties.filter(function(item){return ANZOptions.includes(item)});
      anzArray = backEndcounrties.filter(function (item) {
        return ANZOptions.includes(item);
      });
      this.state.SoutheastAsiacheckedList = backEndcounrties.filter(function (
        item
      ) {
        return SoutheastAsiaOptions.includes(item);
      });
      this.state.WesternAsiacheckedList = backEndcounrties.filter(function (
        item
      ) {
        return WesternAsiaOptions.includes(item);
      });
      this.state.SouthAmericacheckedList = backEndcounrties.filter(function (
        item
      ) {
        return SouthAmericaOptions.includes(item);
      });
      this.state.AntarcticcheckedList = backEndcounrties.filter(function (
        item
      ) {
        return AntarcticOptions.includes(item);
      });
      this.state.NorthAmericacheckedList = backEndcounrties.filter(function (
        item
      ) {
        return NorthAmericaOptions.includes(item);
      });
      //  this.state.AustraliaAndNewZealandcheckedList=backEndcounrties.filter(function(item){return AustraliaAndNewZealandOptions.includes(item)});
      this.state.MelanesiacheckedList = backEndcounrties.filter(function (
        item
      ) {
        return MelanesiaOptions.includes(item);
      });
      this.state.middleEastCheckedList = backEndcounrties.filter(function (
        item
      ) {
        return MiddleEastOptions.includes(item);
      });
      this.state.PolynesiacheckedList = backEndcounrties.filter(function (
        item
      ) {
        return PolynesiaOptions.includes(item);
      });
      this.state.MicronesiacheckedList = backEndcounrties.filter(function (
        item
      ) {
        return MicronesiaOptions.includes(item);
      });
      this.state.SouthernAtlanticOceancheckedList = backEndcounrties.filter(
        function (item) {
          return SouthernAtlanticOceanOptions.includes(item);
        }
      );
      if (this.state.NorthernAfricaCountriescheckedList.length > 0) {
        this.state.region.push("Northern Africa Countries");
        this.setState({ indeterminateAfr1: true });
      }
      if (this.state.CentralOrMiddleAfricancheckedList.length > 0) {
        this.state.region.push("Central or Middle African");
        this.setState({ indeterminateAfr2: true });
      }
      if (this.state.SouthernAfricaCountriescheckedList.length > 0) {
        this.state.region.push("Southern Africa countries"); //Nilesh-5276- "Countries" replace to "countries" because in below code using indexOf() method and its a case-sensetive.
        this.setState({ indeterminateAfr3: true });
      }
      if (this.state.EastAfricanCountriescheckedList.length > 0) {
        this.state.region.push("East African countries"); //Nilesh-5276- "Countries" replace to "countries" because in below code using indexOf() method and its a case-sensetive.
        this.setState({ indeterminateAfr4: true });
      }
      if (this.state.WesternAfricacheckedList.length > 0) {
        this.state.region.push("Western Africa");
        this.setState({ indeterminateAfr5: true });
      }
      if (this.state.EasternEuropecheckedList.length > 0) {
        this.state.region.push("Eastern Europe");
        this.setState({ indeterminateEur1: true });
      }
      if (
        this.state.NorthernEuropecheckedList.length > 0 &&
        !regionBackEnd.includes("nordic")
      ) {
        this.state.region.push("Northern Europe");
        this.setState({ indeterminateEur2: true });
      } //2950 raunak- selecting appropriate region
      if (this.state.SouthernEuropecheckedList.length > 0) {
        this.state.region.push("Southern Europe");
        this.setState({ indeterminateEur3: true });
      }
      if (this.state.WesternEuropecheckedList.length > 0) {
        //2950 raunak- selecting appropriate region
        this.setState({
          indeterminateEur4:
            (JSON.stringify(this.state.WesternEuropecheckedList.sort()) ===
              JSON.stringify([...DACHOptions, ...BeneluxOptions].sort())) |
            (JSON.stringify(this.state.WesternEuropecheckedList.sort()) ===
              JSON.stringify([...DACHOptions].sort())) |
            (JSON.stringify(this.state.WesternEuropecheckedList.sort()) ===
              JSON.stringify([...BeneluxOptions].sort()))
              ? false
              : true,
          region: regionBackEnd,
          checkAll23:
            regionBackEnd.includes("benelux") &&
            this.checkBenelux(this.state.WesternEuropecheckedList) === true
              ? true
              : false,
          beneluxChecked:
            regionBackEnd.includes("benelux") &&
            this.checkBenelux(this.state.WesternEuropecheckedList) === true
              ? true
              : false,
          checkAll24:
            regionBackEnd.includes("dach") &&
            this.checkDach(this.state.WesternEuropecheckedList) === true
              ? true
              : false,
          dachChecked:
            regionBackEnd.includes("dach") &&
            this.checkDach(this.state.WesternEuropecheckedList) === true
              ? true
              : false,
        });
      }
      if (
        this.state.CentralAsiacheckedList.length > 0 &&
        !regionBackEnd.includes("apac")
      ) {
        this.state.region.push("Central Asia");
        this.setState({ indeterminateAsia1: true });
      } //2950 raunak- selecting appropriate region
      if (
        this.state.EastAsiacheckedList.length > 0 &&
        !regionBackEnd.includes("apac")
      ) {
        this.state.region.push("East Asia");
        this.setState({ indeterminateAsia2: true });
      } //2950 raunak- selecting appropriate region
      if (
        this.state.SouthAsiacheckedList.length > 0 &&
        !regionBackEnd.includes("apac") &&
        !regionBackEnd.includes("anz")
      ) {
        this.state.region.push("South Asia");
        this.setState({ indeterminateAsia3: true });
      } //2950 raunak- selecting appropriate region
      if (
        this.state.SoutheastAsiacheckedList.length > 0 &&
        !regionBackEnd.includes("apac")
      ) {
        this.state.region.push("Southeast Asia");
        this.setState({ indeterminateAsia4: true });
      } //2950 raunak- selecting appropriate region
      if (this.state.WesternAsiacheckedList.length > 0) {
        this.state.region.push("Western Asia");
        this.setState({ indeterminateAsia5: true });
      }
      if (this.state.SouthAmericacheckedList.length > 0) {
        this.state.region.push("South America");
        this.setState({ indeterminateAmr1: true });
      }
      if (this.state.AntarcticcheckedList.length > 0) {
        this.state.region.push("Antarctic");
        this.setState({ indeterminateAnt1: true });
      }
      if (this.state.NorthAmericacheckedList.length > 0) {
        this.state.region.push("North America");
        this.setState({ indeterminateAmr2: true });
      }
      if (this.state.AustraliaAndNewZealandcheckedList.length > 0) {
        this.state.region.push("Australia and New Zealand");
      }
      if (this.state.MelanesiacheckedList.length > 0) {
        this.state.region.push("Melanesia");
        this.setState({ indeterminateMel: true });
      }
      if (this.state.middleEastCheckedList.length > 0) {
        this.state.region.push("Middle East");
        this.setState({ indeterminateMeast: true });
      }
      if (this.state.PolynesiacheckedList.length > 0) {
        this.state.region.push("Polynesia");
        this.setState({ indeterminatePoly1: true });
      }
      if (this.state.MicronesiacheckedList.length > 0) {
        this.state.region.push("Micronesia");
        this.setState({ indeterminateMic1: true });
      }
      if (this.state.SouthernAtlanticOceancheckedList.length > 0) {
        this.state.region.push("Southern Atlantic Ocean");
        this.setState({ indeterminateSAtlan: true });
      }

      if (
        this.state.NorthernAfricaCountriescheckedList.length ==
        NorthernAfricaCountriesOptions.length
      ) {
        this.setState({ checkAll1: true, indeterminateAfr1: false });
      }
      if (
        this.state.CentralOrMiddleAfricancheckedList.length ==
        CentralOrMiddleAfricanOptions.length
      ) {
        this.setState({ checkAll2: true, indeterminateAfr2: false });
      }
      if (
        this.state.SouthernAfricaCountriescheckedList.length ==
        SouthernAfricaCountriesOptions.length
      ) {
        this.setState({ checkAll3: true, indeterminateAfr3: false });
      }
      if (
        this.state.EastAfricanCountriescheckedList.length ==
        EastAfricanCountriesOptions.length
      ) {
        this.setState({ checkAll4: true, indeterminateAfr4: false });
      }
      if (
        this.state.WesternAfricacheckedList.length ==
        WesternAfricaOptions.length
      ) {
        this.setState(
          { checkAll5: true, indeterminateAfr5: false },
          function () {
            if (
              this.state.checkAll1 == true &&
              this.state.checkAll2 == true &&
              this.state.checkAll3 == true &&
              this.state.checkAll4 == true &&
              this.state.checkAll5 == true
            ) {
              this.setState({ checkAfricaSelectAll: true });
            }
          }
        );
      }
      if (
        this.state.EasternEuropecheckedList.length ==
        EasternEuropeOptions.length
      ) {
        this.setState({ checkAll6: true, indeterminateEur1: false });
      }
      if (
        this.state.NorthernEuropecheckedList.length ==
        NorthernEuropeOptions.length
      ) {
        this.setState({ checkAll7: true, indeterminateEur2: false });
      }
      if (nordicArray.length == NordicOptions.length) {
        if (regionBackEnd.includes("nordic")) {
          this.state.region.push("nordic");
          this.setState({ checkAll25: true });
        }
      }
      if (apacArray.length == APACOptions.length) {
        if (regionBackEnd.includes("apac")) {
          this.state.region.push("apac");
          this.setState({ checkAll26: true });
        }
      }
      if (
        this.state.SouthernEuropecheckedList.length ==
        SouthernEuropeOptions.length
      ) {
        this.setState({ checkAll8: true, indeterminateEur3: false });
      }
      if (
        this.state.WesternEuropecheckedList.length ==
        WesternEuropeOptions.length
      ) {
        this.setState(
          { checkAll9: true, indeterminateEur4: false },
          function () {
            if (
              this.state.checkAll6 == true &&
              this.state.checkAll7 == true &&
              this.state.checkAll8 == true &&
              this.state.checkAll9 == true
            ) {
              this.setState({
                checkEuropeSelectAll: true,
                beneluxDisabled: true,
                dachDisabled: true,
                nordicDisabled: true,
              });
            }
          }
        );
      }

      //   if(beneluxArray.length==BeneluxOptions.length)
      //   {

      //    if(regionBackEnd.includes("benelux"))
      //    {
      //      this.state.region.push("benelux");
      //      this.setState({checkAll23:true})
      //   }
      //  }

      // if(dachArray.length==DACHOptions.length){

      //  if(regionBackEnd.includes("dach"))
      //  {
      //    this.state.region.push("dach");
      //    this.setState({checkAll24:true})
      // }
      // }

      if (
        this.state.CentralAsiacheckedList.length == CentralAsiaOptions.length
      ) {
        if (!regionBackEnd.includes("apac")) {
          this.setState({
            checkAll10: true,
            indeterminateAsia1: false,
            ApacDisabled: true,
            ANZdisabled: true,
          });
        }
      }

      if (this.state.EastAsiacheckedList.length == EastAsiaOptions.length) {
        this.setState({
          checkAll11: true,
          indeterminateAsia2: false,
          ApacDisabled: true,
          ANZdisabled: true,
        });
      }
      if (this.state.SouthAsiacheckedList.length == SouthAsiaOptions.length) {
        this.setState({
          checkAll12: true,
          indeterminateAsia3: false,
          ApacDisabled: true,
          ANZdisabled: true,
        });
      }
      if (anzArray.length == ANZOptions.length) {
        if (regionBackEnd.includes("anz")) {
          this.state.region.push("anz");
          this.setState({ checkAll18: true });
        }
      }

      if (
        this.state.SoutheastAsiacheckedList.length ==
        SoutheastAsiaOptions.length
      ) {
        this.setState({
          checkAll13: true,
          indeterminateAsia4: false,
          ApacDisabled: true,
          ANZdisabled: true,
        });
      }
      if (
        this.state.WesternAsiacheckedList.length == WesternAsiaOptions.length
      ) {
        this.setState(
          { checkAll14: true, indeterminateAsia5: false },
          function () {
            if (
              this.state.checkAll10 == true &&
              this.state.checkAll11 == true &&
              this.state.checkAll12 == true &&
              this.state.checkAll13 == true &&
              this.state.checkAll14 == true
            ) {
              this.setState({
                checkAsiaSelectAll: true,
                ApacDisabled: true,
                ANZdisabled: true,
              });
            }
          }
        );
      }
      if (
        this.state.SouthAmericacheckedList.length == SouthAmericaOptions.length
      ) {
        this.setState({ checkAll15: true, indeterminateAmr1: false });
      }
      if (this.state.AntarcticcheckedList.length == AntarcticOptions.length) {
        this.setState({ checkAll16: true, indeterminateAnt1: false });
      }
      if (
        this.state.NorthAmericacheckedList.length == NorthAmericaOptions.length
      ) {
        this.setState(
          { checkAll17: true, indeterminateAmr2: false },
          function () {
            if (
              this.state.checkAll15 == true &&
              this.state.checkAll17 == true
            ) {
              this.setState({ checkAmericaSelectAll: true });
            }
          }
        );
      }

      if (this.state.MelanesiacheckedList.length == MelanesiaOptions.length) {
        this.setState({ checkAll19: true, indeterminateMel: false });
      }
      if (this.state.PolynesiacheckedList.length == PolynesiaOptions.length) {
        this.setState({ checkAll20: true, indeterminatePoly1: false });
      }

      if (this.state.middleEastCheckedList.length == MiddleEastOptions.length) {
        this.setState({ checkAll27: true, indeterminateMeast: false });
      }

      if (this.state.MicronesiacheckedList.length == MicronesiaOptions.length) {
        this.setState({ checkAll21: true, indeterminateMic1: false });
      }
      if (
        this.state.SouthernAtlanticOceancheckedList.length ==
        SouthernAtlanticOceanOptions.length
      ) {
        this.setState(
          { checkAll22: true, indeterminateSAtlan: false },
          function () {
            if (
              this.state.checkAll1 == true &&
              this.state.checkAll2 == true &&
              this.state.checkAll3 == true &&
              this.state.checkAll4 == true &&
              this.state.checkAll5 == true &&
              this.state.checkAll6 == true &&
              this.state.checkAll7 == true &&
              this.state.checkAll8 == true &&
              this.state.checkAll9 == true &&
              this.state.checkAll15 == true &&
              this.state.checkAll17 == true &&
              this.state.checkAll10 == true &&
              this.state.checkAll11 == true &&
              this.state.checkAll12 == true &&
              this.state.checkAll13 == true &&
              this.state.checkAll14 == true &&
              this.state.checkAll16 == true &&
              this.state.checkAll19 == true &&
              this.state.checkAll20 == true &&
              this.state.checkAll21 == true &&
              this.state.checkAll22 == true &&
              this.state.checkAll27 == true
            ) {
              this.setState({
                dachDisabled: true,
                ANZdisabled: true,
                beneluxDisabled: true,
                nordicDisabled: true,
                ApacDisabled: true,
                checkRegionSelectAll: true,
              });
            }
          }
        );
      }
      this.getTotalCountries(); //snehal-task-3608-Akshay sir--Country/region visibility--Campaign all flow
      ///Job Level Array
    }
    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].jobLevel
    ) {
      var str = this.state.campaignSpecificationDetailsEdit[0].jobLevel;
      var str_array = str.split("|");
      var jobLevelArray = []; //Aman-4882-defined the array localy to get corect correct values for selected count
      for (var i = 0; i < str_array.length; i++) {
        if (str_array[i] !== "") {
          //jobLevelArray.push({ id: str_array[i], name: str_array[i] });
          const size = { id: str_array[i], name: str_array[i] }; //Aman-4882-defined new Var size to take ID,Name from str_array and push in below array
          jobLevelArray.push(size);
          this.state.jobLevelDB.push(str_array[i]);
        }
      }
      this.setState({ jobLevel: jobLevelArray });
    } //Job level End

    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].jobFunction
    ) {
      var str = this.state.campaignSpecificationDetailsEdit[0].jobFunction;
      var str_array = str.split("|");
      var jobFunctionArray = []; //Aman-4882-defined the array localy to get corect correct values for selected count
      for (var i = 0; i < str_array.length; i++) {
        if (str_array[i] !== "") {
          // jobFunctionArray.push({ id: str_array[i], name: str_array[i] });
          const size = { id: str_array[i], name: str_array[i] }; //Aman-4882-defined new Var size to take ID,Name from str_array and push in below array
          jobFunctionArray.push(size);
          this.state.jobFunctionDB.push(str_array[i]);
        }
      }
      this.setState({ jobFunction: jobFunctionArray }, function () {});
    } //Job function End

    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].employeeSize
    ) {
      var str = this.state.campaignSpecificationDetailsEdit[0].employeeSize;
      var str_array = str.split("|");
      var employeeSizeArrayEdit = []; //Aman-4882-defined the array localy to get corect correct values for selected count
      for (var i = 0; i < str_array.length; i++) {
        if (str_array[i] !== "") {
          //employeeSizeArrayEdit.push({ id: str_array[i], name: str_array[i] });
          const size = { id: str_array[i], name: str_array[i] }; //Aman-4882-defined new Var size to take ID,Name from str_array and push in below array
          employeeSizeArrayEdit.push(size);
          this.state.employeeSizeDB.push(str_array[i]);
        }
      }
      this.setState({ employeeSize: employeeSizeArrayEdit });
    } //Employee Size End

    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].companyRevenue
    ) {
      var str = this.state.campaignSpecificationDetailsEdit[0].companyRevenue;
      var str_array = str.split("|");
      var companyRevenueEdit = []; //Aman-4882-defined the array localy to get corect correct values for selected count
      for (var i = 0; i < str_array.length; i++) {
        if (str_array[i] !== "") {
          //companyRevenueEdit.push({ id: str_array[i], name: str_array[i] });
          const size = { id: str_array[i], name: str_array[i] }; //Aman-4882-defined new Var size to take ID,Name from str_array and push in below array
          companyRevenueEdit.push(size);
          this.state.companyRevenueDB.push(str_array[i]);
        }
      }
      this.setState({ companyRevenue: companyRevenueEdit }, function () {});
    } //Company Revenue End
    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].industry
    ) {
      var str = this.state.campaignSpecificationDetailsEdit[0].industry;
      var str_array = str.split("|");
      var industryEdit = []; //Aman-4882-defined the array localy to get corect correct values for selected count
      for (var i = 0; i < str_array.length; i++) {
        if (str_array[i] !== "") {
          //industryEdit.push({ id: str_array[i], name: str_array[i] });
          const size = { id: str_array[i], name: str_array[i] }; //Aman-4882-defined new Var size to take ID,Name from str_array and push in below array
          industryEdit.push(size);
          this.state.industryDB.push(str_array[i]);
        }
      }
      this.setState({ industry: industryEdit }, function () {});
    } //Industry End

    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].jobTitle
    ) {
      var str = this.state.campaignSpecificationDetailsEdit[0].jobTitle;
      this.setState({ jobTitle: str }, function () {});
    } //Job Title End
    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].otherSpecs
    ) {
      var str = this.state.campaignSpecificationDetailsEdit[0].otherSpecs;
      this.setState({ otherSpecifications: str }, function () {});
    } //other specs End
    //snehal-task-2798-UI/UX-Changes-3.1
    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].customEmpSize
    ) {
      var str = this.state.campaignSpecificationDetailsEdit[0].customEmpSize;
      if (str.charAt(0) === "|") {
        str = str.substr(1);
      } /* //  snehal-task-3343----edit campaign---employee size, company revenue */
      this.setState({ customEmployeeSizeText: str }, function () {});
    } //custom employee size end
    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].nonEnglishCheck
    ) {
      str = this.state.campaignSpecificationDetailsEdit[0].nonEnglishCheck;
      this.setState({ nonEnglishChecked: str });
    } //Non-english check-Chaitanya-4906-getting nonEnglishCheck value from BE
    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].customCompRevenue
    ) {
      var str =
        this.state.campaignSpecificationDetailsEdit[0].customCompRevenue;
      if (str.charAt(0) === "|") {
        str = str.substr(1);
      } /* //  snehal-task-3343----edit campaign---employee size, company revenue */
      this.setState({ customCompanyRevenueText: str }, function () {});
    } //custom company revenue end
    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].customIndustry
    ) {
      var str = this.state.campaignSpecificationDetailsEdit[0].customIndustry;
      this.setState({ customIndustryText: str }, function () {});
    } //custom industry end
    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].customJobLevel
    ) {
      var str = this.state.campaignSpecificationDetailsEdit[0].customJobLevel;
      this.setState({ customJobLevelText: str });
    } //custom job level end
    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].customJobFunction
    ) {
      var str =
        this.state.campaignSpecificationDetailsEdit[0].customJobFunction;
      this.setState({ customJobFunctionText: str });
    } //custom job function end
    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].state == "Yes"
    ) {
      var filestate =
        this.state.campaignSpecificationDetailsEdit[0].stateFileName;
      var str = this.state.campaignSpecificationDetailsEdit[0].state;
      var statefile = document.getElementById("StateFile");
      if (str == "Yes") {
        this.setState({
          displayStateFile: "block",
          stateFileCheck: "Yes",
          stateFileBack: filestate,
          stateFile: filestate,
        });
        document.getElementById("State").checked = true;
        var link;
        var statefilename = "";
        var arrayLength = 1;
        var HTML = "<table>";
        for (var j = 0; j < arrayLength; j++) {
          link =
            '<a class="removeFile" href="#" data-fileid=' +
            '"' +
            filestate +
            '"' +
            '><i class="fa fa-times"></i></a>';
          HTML +=
            "<tr><td>" +
            link +
            "&nbsp;&nbsp;&nbsp;&nbsp;" +
            filestate +
            "</td></tr>";
        }
        HTML += "</table>";
        document.getElementById("stateList").innerHTML = HTML;
        $(document).on(
          "click",
          ".removeFile",
          function (e) {
            this.dynamicStateFile(e);
          }.bind(this)
        );
      } else {
        this.setState({ displayStateFile: "none", stateFileCheck: "No" });
        document.getElementById("State").checked = false;
        statefile.style.display = "none";
      }
    }
    //state file
    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].city == "Yes"
    ) {
      var filecity =
        this.state.campaignSpecificationDetailsEdit[0].cityFileName;
      var str = this.state.campaignSpecificationDetailsEdit[0].city;
      var CityFile = document.getElementById("CityFile");
      if (str == "Yes") {
        cityfilelengthback = filecity.length;
        this.setState({
          displayCityFile: "block",
          cityFileCheck: "Yes",
          cityFileBack: filecity,
          cityFile: filecity,
        });
        document.getElementById("City").checked = true;
        var link;
        var cityfilename = "";
        var arrayLength = 1;
        var HTML = "<table>";
        for (var j = 0; j < arrayLength; j++) {
          link =
            '<a class="removeFilecity" href="#" data-fileid=' +
            '"' +
            filecity +
            '"' +
            '><i class="fa fa-times"></i></a>';
          HTML +=
            "<tr><td>" +
            link +
            "&nbsp;&nbsp;&nbsp;&nbsp;" +
            filecity +
            "</td></tr>";
        }
        HTML += "</table>";
        document.getElementById("cityList").innerHTML = HTML;
        $(document).on(
          "click",
          ".removeFilecity",
          function (e) {
            this.dynamicCityFile(e);
          }.bind(this)
        );
      } else {
        this.setState({ displayCityFile: "none", cityFileCheck: "No" });
        document.getElementById("City").checked = false;
        CityFile.style.display = "none";
      }
    } // city file

    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].zipCode == "Yes"
    ) {
      var fileZipCode =
        this.state.campaignSpecificationDetailsEdit[0].zipCodeFileName;
      var str = this.state.campaignSpecificationDetailsEdit[0].zipCode;
      var ZipCodeFile = document.getElementById("ZipFile");
      if (str == "Yes") {
        zipfilelengthback = fileZipCode.length;
        this.setState({
          displayZipCodeFile: "block",
          zipcodeFileCheck: "Yes",
          zipCodeFileBack: fileZipCode,
          zipcodeFile: fileZipCode,
        });
        document.getElementById("ZipCode").checked = true;
        var link;
        var zipfilename = "";
        var arrayLength = 1;
        var HTML = "<table>";
        for (var j = 0; j < arrayLength; j++) {
          link =
            '<a class="removeFilezip" href="#" data-fileid=' +
            '"' +
            fileZipCode +
            '"' +
            '><i class="fa fa-times"></i></a>';
          HTML +=
            "<tr><td>" +
            link +
            "&nbsp;&nbsp;&nbsp;&nbsp;" +
            fileZipCode +
            "</td></tr>";
        }
        HTML += "</table>";
        document.getElementById("zipcodeList").innerHTML = HTML;
        $(document).on(
          "click",
          ".removeFilezip",
          function (e) {
            this.dynamicZipCodeFile(e);
          }.bind(this)
        );
      } else {
        this.setState({ displayZipCodeFile: "none", zipcodeFileCheck: "No" });
        document.getElementById("ZipCode").checked = false;
        ZipCodeFile.style.display = "none";
      }
    }
    //snehal-task-3647-Industry Exclusion on DI - FE
    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length &&
      this.state.campaignSpecificationDetailsEdit[0].excludedIndustryFlag ==
        "Yes"
    ) {
      var fileExcludedIndustry =
        this.state.campaignSpecificationDetailsEdit[0].excludedIndustryFileName;
      var str =
        this.state.campaignSpecificationDetailsEdit[0].excludedIndustryFlag;
      var excludedIndustryFile = document.getElementById("IndustryFileBlock");
      if (str == "Yes") {
        excludedIndustryFilelengthback = fileExcludedIndustry.length;
        this.setState({
          displayExcludedIndustryFile: "block",
          excludedIndustryFlag: "Yes",
          excludedIndustryFileBack: fileExcludedIndustry,
          excludedIndustryFile: fileExcludedIndustry,
        });
        document.getElementById("excludedIndustry").checked = true;
        var link;
        var excludedIndustryFileName = "";
        var arrayLength = 1;
        var HTML = "<table>";
        for (var j = 0; j < arrayLength; j++) {
          link =
            '<a class="removeFileExcludedIndustry" href="#" data-fileid=' +
            '"' +
            fileExcludedIndustry +
            '"' +
            '><i class="fa fa-times"></i></a>';
          HTML +=
            "<tr><td>" +
            link +
            "&nbsp;&nbsp;&nbsp;&nbsp;" +
            fileExcludedIndustry +
            "</td></tr>";
        }
        HTML += "</table>";
        document.getElementById("excludedIndustryList").innerHTML = HTML;
        $(document).on(
          "click",
          ".removeFileExcludedIndustry",
          function (e) {
            this.dynamicExcludedIndustryFile(e);
          }.bind(this)
        );
      } else {
        this.setState({
          displayExcludedIndustryFile: "none",
          excludedIndustryFlag: "No",
        });
        document.getElementById("excludedIndustry").checked = false;
        excludedIndustryFile.style.display = "none";
      }
    }
    if (
      this.state.campaignSpecificationDetailsEdit &&
      this.state.campaignSpecificationDetailsEdit.length
    ) {
      var employeeSizeLength =
        this.state.campaignSpecificationDetailsEdit[0].employeeSize.length || 0;

      var customEmpSizeLength =
        this.state.campaignSpecificationDetailsEdit[0].customEmpSize.length ||
        0;

      if (employeeSizeLength == 0 && customEmpSizeLength == 0) {
        document.getElementById("empSizeNA").checked = true;
        this.setState({
          employeeSizeNotApplicable: "Not_Applicable",
          drpdisabled: "drpdisable",
        });
      } else {
        this.setState({
          employeeSizeNotApplicable: "Applicable",
          drpdisabled: "",
        });
      }

      var industryLength =
        this.state.campaignSpecificationDetailsEdit[0].industry.length;
      var customIndustryLength =
        this.state.campaignSpecificationDetailsEdit[0].customIndustry.length;
      //snehal-task-3647-Industry Exclusion on DI - FE
      var excludedIndustryFlag =
        this.state.campaignSpecificationDetailsEdit[0].excludedIndustryFlag;
      if (
        industryLength == 0 &&
        customIndustryLength == 0 &&
        excludedIndustryFlag === "No"
      ) {
        document.getElementById("indSizeNA").checked = true;
        this.setState({
          IndustryNotApplicable: "Not_Applicable",
          drpdisabled1: "drpdisable",
        });
      } else
        this.setState({
          IndustryNotApplicable: "Applicable",
          drpdisabled1: "",
        });
    }
  }
  handlestepTwo() {
    if (this.state.DeliveryOptionEdit && this.state.DeliveryOptionEdit.length) {
      if (
        this.state.DeliveryOptionEdit[0].marketingChannel == "TeleMarketing" ||
        this.state.DeliveryOptionEdit[0].marketingChannel ==
          "Email/Telemarketing"
      ) {
        this.setState({ callAuditdisable: false });
      }
    }
    if (this.state.DeliveryOptionEdit && this.state.DeliveryOptionEdit.length) {
      var str = this.state.DeliveryOptionEdit[0].requiredLPApproval;
      if (
        str === "undefined" ||
        str === undefined ||
        str === null ||
        str === "null" ||
        str === ""
      ) {
      } else {
        if (str.charAt(0) === ",") {
          str = str.substr(1);
        }

        var str_array = str.split(",");
        for (var i = 0; i < str_array.length; i++) {
          if (str_array[i] != "") {
            this.state.creativeValueArray.push({
              id: str_array[i],
              name: str_array[i],
            });
          }
        }
        this.setState({ creatives: this.state.creativeValueArray });
        this.state.creativeValueArray.length > 0
          ? this.setState({
              creativesHrsdisable: false,
              creativesReqDisable: false,
              displaySkipandNext: "none",
              displaySaveandNext: "block",
            })
          : this.setState({
              creativesHrsdisable: true,
              creativesReqDisable: true,
              displaySkipandNext: "block",
              displaySaveandNext: "none",
            });
      }

      // User Story : 3188 : Karan Jagtap : Sub-Contracting value
      const { subContracting } = this.state.DeliveryOptionEdit[0];
      let val = "";
      if (
        subContracting === undefined ||
        subContracting === null ||
        subContracting === "undefined" ||
        subContracting === "null" ||
        subContracting === ""
      ) {
        val = "Yes";
      } else {
        val = subContracting;
      }
      this.setState({
        DeliveryOptionEdit: [
          { ...this.state.DeliveryOptionEdit[0], subContracting: val },
        ],
      });
    }
  }
  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      const { isAuthenticated, user } = this.props.auth;
      var userID = user.id;
      var parsed = this.props.location.state;
      var campID, parentCampID, rfpCampaignID;
      if (parsed !== undefined) {
        campID = parsed.campID;
        if (parsed.hasOwnProperty("parentCampID")) {
          parentCampID = parsed.parentCampID;
        }
        if (parsed.hasOwnProperty("rfpCampaignID")) {
          rfpCampaignID = parsed.rfpCampaignID;
        }
      }
      let data = {};
      this.setState(
        {
          campID: campID,
          rfpCampaignID: rfpCampaignID,
          parentCampID: parentCampID,
          agencyID: userID, //Sonali-3257-added agencyID to insert at BE
        },
        function () {
          data = {
            campID: campID,
          }; // karan-task-3767-added default header
        }
      );
      this.checkColumnSequence(campID); //Nilesh-5310- Passed campID
      fetch("/campaign/clientNameDetails") // karan-task-3767-added default header
        .then((res) => res.json())
        .then((clientNameDetails) => {
          fetch("campaignNew/backCampaignDetails", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((campaignDetail) => {
              fetch("campaignNew/pacingDetailsForEdit", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
              })
                .then((res) => res.json())
                .then((pacingDetails) => {
                  fetch("campaignNew/deliveryOptionCampaignDetails", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(data),
                  })
                    .then((res) => res.json())
                    .then((deliveryOptionDetails) => {
                      fetch("campaignNew/getCountryWiseLead?campID=" + campID)
                        .then((res) => res.json())
                        .then((tempCountryAllocation) => {
                          fetch("campaignNew/campaignSpecificationDetails", {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(data),
                          })
                            .then((res) => res.json())
                            .then((campaignSpecificationDetails) => {
                              fetch("campaignNew/questionDetailsForEdit", {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify(data),
                              })
                                .then((res) => res.json())
                                .then((customQuestionDetails) => {
                                  fetch(
                                    "campaignNew/ChkAllocationCPL?campID=" +
                                      campID
                                  )
                                    .then((res) => res.json())
                                    .then((getAllocatedLead) => {
                                      if (pacingDetails.length <= 0) {
                                        let DeliveryOptionEdit = [
                                          ...deliveryOptionDetails,
                                        ];
                                        if (
                                          DeliveryOptionEdit[0].callAudit ===
                                          "Yes"
                                        ) {
                                          DeliveryOptionEdit[0].callAudit =
                                            "Yes";
                                        } else {
                                          DeliveryOptionEdit[0].callAudit =
                                            "No";
                                        }
                                        if (
                                          campaignDetail[0].nonEnglishCheck ==
                                          "Yes"
                                        ) {
                                          this.state.isCheckednonEnglish = true;
                                        }
                                        //Chaitanya-4991-When nonEnglishCheck value is Yes isCheckednonEnglish will be true
                                        this.setState(
                                          {
                                            clientNameDetails:
                                              clientNameDetails,
                                            BasicDetailArrayEdit:
                                              campaignDetail,
                                            basicCampaignDetailNoChangeArray:
                                              campaignDetail,
                                            biddingDate:
                                              campaignDetail[0].biddingEndDate,
                                            geoLeadChk:
                                              campaignDetail[0]
                                                .requiredCountryWiseAllocation,
                                            apiStartDate:
                                              campaignDetail[0].startDate,
                                            apiEndDate:
                                              campaignDetail[0].endDate,
                                            advCampId:
                                              campaignDetail[0]
                                                .advertiserCampID,
                                            DeliveryOptionEdit:
                                              deliveryOptionDetails,
                                            deliveryCampaignDetailNoChangeArray:
                                              deliveryOptionDetails,
                                            tempCountryAllocation:
                                              tempCountryAllocation,
                                            countryWiseAllocationArray:
                                              tempCountryAllocation,
                                            campaignSpecificationDetailsEdit:
                                              campaignSpecificationDetails,
                                            campaignSpecDetailNoChangeArray:
                                              campaignSpecificationDetails,
                                            jobTitlechk:
                                              campaignSpecificationDetails[0]
                                                .jobTitleValidation,
                                            geoLeadChk:
                                              campaignSpecificationDetails[0]
                                                .requiredCountryWiseAllocation,
                                            totalAllocatedLead:
                                              getAllocatedLead[0].allocatedLead,
                                            allocatedCPL:
                                              getAllocatedLead[0].CPL,
                                            CustomQuestionsEdit:
                                              customQuestionDetails,
                                            apiLeadInteractionDays:
                                              DeliveryOptionEdit[0]
                                                .leadInteractionDays,
                                            questionDetailNoChangeArray:
                                              customQuestionDetails,
                                            customQuestionAliasName:
                                              campaignDetail[0]
                                                .customQuestionAliasName,
                                            customQuestionAliasNameNoChange:
                                              campaignDetail[0]
                                                .customQuestionAliasName,
                                            campaignStatus:
                                              campaignDetail[0].campaignStatus, //Sonali-3257-added campaignStatus to insert at BE
                                          },
                                          function () {
                                            //snehal-task-3112-Custom Question Alias
                                            //snehal-task-2989-Job title ---check box issue(added set state for jobTitleChk)
                                            //snehal-task2952-Prod Issue-Internal-Lead Interaction Days validation issue-(skip and next issue)-Added variable-apiLeadInteractionDays:DeliveryOptionEdit[0].leadInteractionDays
                                            if (
                                              this.state.BasicDetailArrayEdit[0]
                                                .noOfLeadPerDomain == 0
                                            ) {
                                              this.state.BasicDetailArrayEdit[0].noOfLeadPerDomain =
                                                "";
                                            } else {
                                            }
                                            //snehal-task-3167-Edit campaign--Alias issue
                                            if (
                                              campaignDetail[0]
                                                .customQuestionAliasName ==
                                              "Yes"
                                            ) {
                                              this.setState({
                                                checkedQue: true,
                                              });
                                            } else {
                                              this.setState({
                                                checkedQue: false,
                                              });
                                            }
                                            this.handlestepTwo();
                                            this.handlestepThree();
                                            this.display_pacing_table_fun(); //Snehal-Task-2937-Function call for Display or hide pacing table
                                            this.pacingMonthlyCalculation();
                                          }
                                        );
                                      } else {
                                        this.setState(
                                          {
                                            clientNameDetails:
                                              clientNameDetails,
                                            BasicDetailArrayEdit:
                                              campaignDetail,
                                            basicCampaignDetailNoChangeArray:
                                              campaignDetail,
                                            biddingDate:
                                              campaignDetail[0].biddingEndDate,
                                            geoLeadChk:
                                              campaignDetail[0]
                                                .requiredCountryWiseAllocation,
                                            apiStartDate:
                                              campaignDetail[0].startDate,
                                            apiEndDate:
                                              campaignDetail[0].endDate,
                                            advCampId:
                                              campaignDetail[0]
                                                .advertiserCampID,
                                            DeliveryOptionEdit:
                                              deliveryOptionDetails,
                                            deliveryCampaignDetailNoChangeArray:
                                              deliveryOptionDetails,
                                            tempCountryAllocation:
                                              tempCountryAllocation,
                                            countryWiseAllocationArray:
                                              tempCountryAllocation,
                                            campaignSpecificationDetailsEdit:
                                              campaignSpecificationDetails,
                                            campaignSpecDetailNoChangeArray:
                                              campaignSpecificationDetails,
                                            geoLeadChk:
                                              campaignSpecificationDetails[0]
                                                .requiredCountryWiseAllocation,
                                            jobTitlechk:
                                              campaignSpecificationDetails[0]
                                                .jobTitleValidation,
                                            totalAllocatedLead:
                                              getAllocatedLead[0].allocatedLead,
                                            allocatedCPL:
                                              getAllocatedLead[0].CPL,
                                            pacingDetailsEdit: pacingDetails,
                                            pacingCampaignNoChangeArray:
                                              pacingDetails,
                                            CustomQuestionsEdit:
                                              customQuestionDetails,
                                            apiLeadInteractionDays:
                                              deliveryOptionDetails[0]
                                                .leadInteractionDays,
                                            customQuestionAliasName:
                                              campaignDetail[0]
                                                .customQuestionAliasName,
                                            customQuestionAliasNameNoChange:
                                              campaignDetail[0]
                                                .customQuestionAliasName,
                                            questionDetailNoChangeArray:
                                              customQuestionDetails,
                                          },
                                          function () {
                                            //snehal-task-2989-Job title ---check box issue(added set state for jobTitleChk)
                                            //snehal-task-3112-Custom Question Alias
                                            if (
                                              this.state.BasicDetailArrayEdit[0]
                                                .noOfLeadPerDomain == 0
                                            ) {
                                              this.state.BasicDetailArrayEdit[0].noOfLeadPerDomain =
                                                "";
                                            } else {
                                            } //snehal-task-3167-Edit campaign--Alias issue
                                            if (
                                              campaignDetail[0]
                                                .customQuestionAliasName ==
                                              "Yes"
                                            ) {
                                              this.setState({
                                                checkedQue: true,
                                              });
                                            } else {
                                              this.setState({
                                                checkedQue: false,
                                              });
                                            }
                                            this.setState({
                                              pacingUnit:
                                                this.state.pacingDetailsEdit[0]
                                                  .pacingUnit,
                                            }); //snehal-task-3028- pacing unit issue
                                            //snehal-task2952-Prod Issue-Internal-Lead Interaction Days validation issue-(skip and next issue)-Added variable-apiLeadInteractionDays:deliveryOptionDetails[0].leadInteractionDays
                                            this.display_pacing_table_fun(); //Snehal-Task-2937-Function call for Display or hide pacing table
                                            this.handlestepTwo();
                                            this.handlestepThree();
                                          }
                                        );
                                      }
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    }
  } // end of componentWillMount
  componentDidMount() {
    for (
      let i = 0;
      i <
      document.getElementById("myCountryDiv").getElementsByTagName("span")
        .length;
      i++
    ) {
      if (
        document.getElementById("myCountryDiv").getElementsByTagName("span")[i]
          .className === ""
      ) {
        if (
          document
            .getElementById("myCountryDiv")
            .getElementsByTagName("span")
            [i].innerText.toLowerCase() != "select all"
        ) {
          this.state.spanlist[
            document
              .getElementById("myCountryDiv")
              .getElementsByTagName("span")
              [i].innerText.toLowerCase()
          ] = [
            document
              .getElementById("myCountryDiv")
              .getElementsByTagName("span")
              [i].innerText.toLowerCase(),
            document
              .getElementById("myCountryDiv")
              .getElementsByTagName("span")[i],
          ];
        }
      }
    }
  }

  dynamicStateFile(e) {
    e.preventDefault();
    /**
     * @author Narendra Phadke
     * @param  Delete File From Database
     */
    this.setState({ stateFile: "" });
    var campID = this.state.campID;
    let state = "No";
    let typeOfFile = "state";
    let data = {
      campID: campID,
      state: state,
      typeOfFile: typeOfFile,
    };

    fetch("campaign/createCampaignDeleteFile", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      //mode:'no-cors',
      //body: data
      body: JSON.stringify(data),
    });

    document.getElementById("stateList").innerHTML = "";

    statefilelengthback = 0;
  }

  dynamicCityFile(e) {
    e.preventDefault();
    /**
     * @author Narendra Phadke
     * @param  Delete File From Database
     */
    this.setState({ cityFile: "" });
    var campID = this.state.campID;
    let city = "No";
    let typeOfFile = "city";
    let data = {
      campID: campID,
      city: city,
      typeOfFile: typeOfFile,
    };

    fetch("campaign/createCampaignDeleteFile", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      //mode:'no-cors',
      //body: data
      body: JSON.stringify(data),
    });
    document.getElementById("cityList").innerHTML = "";
    cityfilelengthback = 0;
  }

  dynamicZipCodeFile(e) {
    e.preventDefault();
    /**
     * @author Narendra Phadke
     * @param  Delete File From Database
     */
    this.setState({ zipcodeFile: "" });
    var campID = this.state.campID;
    let zipCode = "No";
    let typeOfFile = "zipCode";
    let data = {
      campID: campID,
      zipCode: zipCode,
      typeOfFile: typeOfFile,
    };

    fetch("campaign/createCampaignDeleteFile", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      //mode:'no-cors',
      //body: data
      body: JSON.stringify(data),
    });
    var zipcodeFile = document.getElementById("zipcodeList");
    zipcodeFile.innerHTML = "";
    zipfilelengthback = 0;
  }
  //snehal-task-3647-Industry Exclusion on DI - FE
  /**
   * @author Snehal More
   * @param  Delete File From Database
   */
  dynamicExcludedIndustryFile(e) {
    e.preventDefault();
    var campID = this.state.campID;
    let excludedIndustryFlag = "No";
    let typeOfFile = "ExcludedIndustryFile";
    let data = {
      campID: campID,
      excludedIndustryFlag: excludedIndustryFlag,
      typeOfFile: typeOfFile,
    };
    fetch("campaign/createCampaignDeleteFile", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    var excludedIndustryFile = document.getElementById("excludedIndustryList");
    excludedIndustryFile.innerHTML = "";
    excludedIndustryFilelengthback = 0;
    this.state.excludedIndustryFile = ""; //snehal-task-3663-validations for exclude industray
  }
  showForm(current) {
    this.setState({ current: current }, function () {
      if (this.state.current == 0) {
        this.setState({
          display1: "block",
          display2: "none",
          display3: "none",
          display4: "none",
        });
      }
      if (this.state.current == 1) {
        this.setState({
          display1: "none",
          display2: "block",
          display3: "none",
          display4: "none",
        });
      }
      if (this.state.current == 2) {
        this.setState({
          display1: "none",
          display2: "none",
          display3: "block",
          display4: "none",
        });
      }
      if (this.state.current == 3) {
        this.setState({
          display1: "none",
          display2: "none",
          display3: "none",
          display4: "block",
        });
      }
    });
  }
  //end of showForm
  fileHandleChange(e) {
    const { name, id } = e.target;
    if (name == "State") {
      var statefile = document.getElementById("StateFile");
      this.state.displayStateFile == "none"
        ? (statefile.style.display = "block")
        : (statefile.style.display = "none");
      this.state.displayStateFile == "none"
        ? this.setState({ displayStateFile: "block", stateFileCheck: "Yes" })
        : this.setState({
            displayStateFile: "none",
            stateFileCheck: "No",
            stateDisplay: "none",
          });
    }
    if (name == "City") {
      var cityfile = document.getElementById("CityFile");
      this.state.displayCityFile == "none"
        ? (cityfile.style.display = "block")
        : (cityfile.style.display = "none");
      this.state.displayCityFile == "none"
        ? this.setState({ displayCityFile: "block", cityFileCheck: "Yes" })
        : this.setState({
            displayCityFile: "none",
            cityFileCheck: "No",
            cityDisplay: "none",
          });
    }
    if (name == "ZipCode") {
      var zipfile = document.getElementById("ZipFile");
      this.state.displayZipCodeFile == "none"
        ? (zipfile.style.display = "block")
        : (zipfile.style.display = "none");
      this.state.displayZipCodeFile == "none"
        ? this.setState({
            displayZipCodeFile: "block",
            zipcodeFileCheck: "Yes",
          })
        : this.setState({
            displayZipCodeFile: "none",
            zipcodeFileCheck: "No",
            zipCodeDisplay: "none",
          });
    }
    //snehal-task-3647-Industry Exclusion on DI - FE
    if (name == "excludedIndustry") {
      this.state.displayExcludedIndustryFile == "none"
        ? this.setState({
            displayExcludedIndustryFile: "block",
            excludedIndustryFlag: "Yes",
            excludedIndustryDisplay: "none",
          })
        : this.setState({
            displayExcludedIndustryFile: "none",
            excludedIndustryFlag: "No",
            excludedIndustryFileDisplay: "none",
            excludedIndustryFileValidate: true,
            excludedIndustryDisplay: "none",
          });
    }
    if (name == "stateFile") {
      let files2 = e.target.files;
      let fileData2 = e.target.files[0];
      if (files2.length > 0) {
        this.setState(
          { stateFile: fileData2, stateDisplay: "none" },
          function () {}
        );
      }
    }
    if (name == "cityFile") {
      let files = e.target.files;
      let fileData = e.target.files[0];
      if (files.length > 0) {
        this.setState(
          { cityFile: fileData, cityDisplay: "none" },
          function () {}
        );
      }
    }
    if (name == "zipcodeFile") {
      let files = e.target.files;
      let fileData = e.target.files[0];
      if (files.length > 0) {
        this.setState(
          { zipcodeFile: fileData, zipCodeDisplay: "none" },
          function () {}
        );
      }
    }
    //snehal-task-3647-Industry Exclusion on DI - FE
    if (name == "excludedIndustryFile") {
      let files = e.target.files;
      let fileData = e.target.files[0];
      if (files.length > 0) {
        this.setState(
          { excludedIndustryFile: fileData, excludedIndustryDisplay: "none" },
          function () {}
        );
      }
    }
  } //filehandlechangeend

  geoleadChange = () => {
    this.setState(
      {
        isCheckedGeoLead: !this.state.isCheckedGeoLead,
      },
      function () {
        if (this.state.isCheckedGeoLead == false) {
          this.setState({
            geoLeadChk: "No",
            geoLeadDisplay: "none",
          });
        } else {
          this.setState({
            geoLeadChk: "Yes",
            geoLeadDisplay: "block",
          });
        }
      }
    );
  };
  stateFilehandleChangeBack(e) {
    /**
     * @author Narendra Phadke
     * @param  Description  Get the zip code file
     */
    var campID = this.state.campID;

    let statefileerrors = {};
    let statefileArray = {};
    let statefileHeadererrors = {};
    let stateValidate = true;
    let filesData = e.target.files;
    let filesData1 = e.target.files[0];
    statefilelengthback = filesData.length;
    var names = [];
    if (filesData.length > 0) {
      statefileArray["headerError"] = "";
      statefileHeadererrors["headerError"] = "";
      this.setState({
        stateFile: filesData1,
        stateDisplay: "none",
        statefileArray: statefileArray,
        statefileHeadererrors: statefileHeadererrors,
      });
      if (statefilelengthback !== undefined) {
        var allowedFiles = [".xls", ".xlsx", ".csv"];
        var stateFile = document.getElementById("stateFile");

        var regex = new RegExp(
          "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
        );

        if (!regex.test(stateFile.value.toLowerCase())) {
          statefileerrors["fileextensions"] =
            "Please upload files having extensions: " +
            allowedFiles.join(", ") +
            " only";
          stateValidate = false;

          this.setState({
            statefileerrors: statefileerrors,
            stateDisplay: "none",
          });
        } else {
          this.setState({ statefileerrors: "" });
        }
      }

      for (var i = 0, l = statefilelengthback; i < l; i++) {
        names[i] = filesData[i].name;
      }

      var link;
      var statefilename = "";
      var arrayLength = names.length;
      var HTML = "<table>";
      for (var j = 0; j < arrayLength; j++) {
        link =
          '<a class="removeFilestate2" href="#" data-fileid=' +
          '"' +
          names[j] +
          '"' +
          '><i class="fa fa-times"></i></a>';
        HTML +=
          "<tr><td>" +
          link +
          "&nbsp;&nbsp;&nbsp;&nbsp;" +
          names[j] +
          "</td></tr>";
      }
      HTML += "</table>";
      document.getElementById("stateList").innerHTML = HTML;

      $(document).on(
        "click",
        ".removeFilestate2",
        function (e) {
          statefileerrors["fileextensions"] = "";
          statefileArray["headerError"] = "";
          statefileHeadererrors["headerError"] = "";
          this.setState({
            stateFile: "",
            statefileerrors: statefileerrors,
            statefileArray: statefileArray,
            statefileHeadererrors: statefileHeadererrors,
            stateFile: "",
          });
        }.bind(this)
      );

      $(document).on("click", ".removeFilestate2", function (e) {
        e.preventDefault();
        var clickedFile = $(this).parent().children("a").data("fileid");
        var j = 0;
        for (j; j < names.length; j++) {
          var temp = j;

          if (names[temp] === clickedFile) {
            statefilename = names[temp];
            names.splice(j, 1);
            $(this).parent().remove();
            arrayLength = names.length;
            /**
             * @author Narendra Phadke
             * @param  Delete File From Database
             */

            let state = "No";
            let typeOfFile = "state";
            let data = {
              campID: campID,
              state: state,
              typeOfFile: typeOfFile,
            };

            fetch("campaign/createCampaignDeleteFile", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              //mode:'no-cors',
              //body: data
              body: JSON.stringify(data),
            });
          }
        }
      });
      let state = "Yes";
      let typeOfFile = "state";
      var data = new FormData();
      data.append("campID", campID);
      //data.append("names",names);
      data.append("value", state);
      data.append("typeOfFile", typeOfFile);
      data.append("file", filesData1);

      let self = this;
      fetch("campaign/createCampaignUploadFiles", {
        method: "POST",
        body: data,
      })
        .then(function (response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.json();
        })
        .then(function (response) {
          if (response.success === "failState") {
            statefileHeadererrors["headerError"] = "Invalid file header";
            //Sandeep-task-3397-error msg issue
            self.setState(
              {
                statefileHeadererrors: statefileHeadererrors,
                stateValidate: false,
                stateDisplay: "none",
              },
              function () {}
            );
          } else {
            statefileArray["headerError"] = "File uploaded successfully";
            self.setState(
              {
                statefileArray: statefileArray,
                stateValidate: true,
                stateDisplay: "none",
              },
              function () {}
            );
          }
        })
        .catch(function (err) {
          console.log(err);
        });
      document.getElementById("stateFile").value = "";
    }
  }
  //city file upload start
  cityFilehandleChangeBack(e) {
    /**
     * @author Narendra Phadke
     * @param  Description  Get the zip code file
     */
    var campID = this.state.campID;
    let cityfileerrors = {};
    let cityfileArray = {};
    let cityfileHeadererrors = {};
    let cityValidate = true;
    let filesData = e.target.files;
    let filesData1 = e.target.files[0];
    cityfilelengthback = filesData.length;
    var names = [];
    if (filesData.length > 0) {
      cityfileArray["headerError"] = "";
      cityfileHeadererrors["headerError"] = "";
      this.setState({
        cityFile: filesData1,
        cityDisplay: "none",
        cityfileArray: cityfileArray,
        cityfileHeadererrors: cityfileHeadererrors,
      });

      if (cityfilelengthback !== undefined) {
        var allowedFiles = [".xls", ".xlsx", ".csv"];
        var cityFile = document.getElementById("cityFile");

        var regex = new RegExp(
          "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
        );

        if (!regex.test(cityFile.value.toLowerCase())) {
          cityfileerrors["fileextensions"] =
            "Please upload files having extensions: " +
            allowedFiles.join(", ") +
            " only";
          cityValidate = false;

          this.setState({ cityfileerrors: cityfileerrors });
        } else {
          this.setState({ cityfileerrors: "" });
        }
      }

      for (var i = 0, l = cityfilelengthback; i < l; i++) {
        names[i] = filesData[i].name;
      }

      var link;
      var cityfilename = "";
      var arrayLength = names.length;
      var HTML = "<table>";
      for (var j = 0; j < arrayLength; j++) {
        link =
          '<a class="removeFilecity2" href="#" data-fileid=' +
          '"' +
          names[j] +
          '"' +
          '><i class="fa fa-times"></i></a>';
        HTML +=
          "<tr><td>" +
          link +
          "&nbsp;&nbsp;&nbsp;&nbsp;" +
          names[j] +
          "</td></tr>";
      }
      HTML += "</table>";
      document.getElementById("cityList").innerHTML = HTML;

      $(document).on(
        "click",
        ".removeFilecity2",
        function (e) {
          cityfileerrors["fileextensions"] = "";
          cityfileHeadererrors["headerError"] = "";
          cityfileArray["headerError"] = "";
          this.setState({
            cityFile: "",
            cityfileerrors: cityfileerrors,
            cityfileHeadererrors: cityfileHeadererrors,
            cityfileArray: cityfileArray,
          });
        }.bind(this)
      );

      $(document).on("click", ".removeFilecity2", function (e) {
        e.preventDefault();
        var clickedFile = $(this).parent().children("a").data("fileid");
        var j = 0;
        for (j; j < names.length; j++) {
          var temp = j;

          if (names[temp] === clickedFile) {
            cityfilename = names[temp];
            names.splice(j, 1);
            $(this).parent().remove();
            arrayLength = names.length;
            /**
             * @author Narendra Phadke
             * @param  Delete File From Database
             */

            let city = "No";
            let typeOfFile = "city";
            let data = {
              campID: campID,
              city: city,
              typeOfFile: typeOfFile,
            };

            fetch("campaign/createCampaignDeleteFile", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              //mode:'no-cors',
              //body: data
              body: JSON.stringify(data),
            });
          }
        }
      });
      let city = "Yes";
      let typeOfFile = "city";
      var data = new FormData();
      data.append("campID", campID);
      //data.append("names",names);
      data.append("value", city);
      data.append("typeOfFile", typeOfFile);
      data.append("file", filesData1);
      let self = this;
      fetch("campaign/createCampaignUploadFiles", {
        method: "POST",
        body: data,
      })
        .then(function (response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.json();
        })
        .then(function (response) {
          if (response.success === "failCity") {
            cityfileHeadererrors["headerError"] = "Invalid file header";
            //Sandeep-task-3397-error msg issue
            self.setState(
              {
                cityfileHeadererrors: cityfileHeadererrors,
                cityValidate: false,
              },
              function () {}
            );
          } else {
            cityfileArray["headerError"] = "File uploaded successfully";
            self.setState(
              { cityfileArray: cityfileArray, cityValidate: true },
              function () {}
            );
          }
        })
        .catch(function (err) {
          console.log(err);
        });
      document.getElementById("cityFile").value = "";
    }
  }
  //zipcodefileupload
  zipcodeFilehandleChangeBack(e) {
    /**
     * @author Narendra Phadke
     * @param  Description  Get the zip code file
     */
    var campID = this.state.campID;
    let zipfileerrors = {};
    let zipfileHeadererrors = {};
    let zipValidate = true;
    let zipfileArray = {};
    let filesData = e.target.files;
    let filesData1 = e.target.files[0];
    zipfilelengthback = filesData.length;
    var names = [];

    if (filesData.length > 0) {
      zipfileArray["headerError"] = "";
      zipfileHeadererrors["headerError"] = "";
      this.setState({
        zipcodeFile: filesData1,
        zipCodeDisplay: "none",
        zipfileArray: zipfileArray,
        zipfileHeadererrors: zipfileHeadererrors,
      });
      if (zipfilelengthback !== undefined) {
        var allowedFiles = [".xls", ".xlsx", ".csv"];
        var zipcodeFile = document.getElementById("zipcodeFile");

        var regex = new RegExp(
          "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
        );

        if (!regex.test(zipcodeFile.value.toLowerCase())) {
          zipfileerrors["fileextensions"] =
            "Please upload files having extensions: " +
            allowedFiles.join(", ") +
            " only";
          zipValidate = false;

          this.setState({ zipfileerrors: zipfileerrors });
        } else {
          this.setState({ zipfileerrors: "" });
        }
      }

      for (var i = 0, l = zipfilelengthback; i < l; i++) {
        names[i] = filesData[i].name;
      }

      var link;
      var zipfilename = "";
      var arrayLength = names.length;
      var HTML = "<table>";
      for (var j = 0; j < arrayLength; j++) {
        link =
          '<a class="removeFilezipcode2" href="#" data-fileid=' +
          '"' +
          names[j] +
          '"' +
          '><i class="fa fa-times"></i></a>';
        HTML +=
          "<tr><td>" +
          link +
          "&nbsp;&nbsp;&nbsp;&nbsp;" +
          names[j] +
          "</td></tr>";
      }
      HTML += "</table>";
      document.getElementById("zipcodeList").innerHTML = HTML;

      $(document).on(
        "click",
        ".removeFilezipcode2",
        function (e) {
          zipfileerrors["fileextensions"] = "";
          zipfileHeadererrors["headerError"] = "";
          zipfileArray["headerError"] = "";
          this.setState({
            zipcodeFile: "",
            zipfileerrors: zipfileerrors,
            zipfileHeadererrors: zipfileHeadererrors,
            zipfileArray: zipfileArray,
          });
        }.bind(this)
      );

      $(document).on("click", ".removeFilezipcode2", function (e) {
        e.preventDefault();
        var clickedFile = $(this).parent().children("a").data("fileid");
        var j = 0;
        for (j; j < names.length; j++) {
          var temp = j;

          if (names[temp] === clickedFile) {
            zipfilename = names[temp];
            names.splice(j, 1);
            $(this).parent().remove();
            arrayLength = names.length;
            /**
             * @author Narendra Phadke
             * @param  Delete File From Database
             */

            let zipCode = "No";
            let typeOfFile = "zipCode";
            let data = {
              campID: campID,
              zipCode: zipCode,
              typeOfFile: typeOfFile,
            };

            fetch("campaign/createCampaignDeleteFile", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              //mode:'no-cors',
              //body: data
              body: JSON.stringify(data),
            });
          }
        }
      });
      let zipCode = "Yes";
      let typeOfFile = "zipCode";
      var data = new FormData();
      data.append("campID", campID);
      //data.append("names",names);
      data.append("value", zipCode);
      data.append("typeOfFile", typeOfFile);
      data.append("file", filesData1);
      let self = this;
      fetch("campaign/createCampaignUploadFiles", {
        method: "POST",
        body: data,
      })
        .then(function (response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.json();
        })
        .then(function (response) {
          if (response.success === "failZip") {
            zipfileHeadererrors["headerError"] = "Invalid file header";
            //Sandeep-task-3397-error msg issue
            self.setState({
              zipfileHeadererrors: zipfileHeadererrors,
              zipValidate: false,
            });
          } else {
            zipfileArray["headerError"] = "File uploaded successfully";
            self.setState({ zipfileArray: zipfileArray }, function () {});
          }
        })
        .catch(function (err) {
          console.log(err);
        });
      document.getElementById("zipcodeFile").value = "";
    }
  }
  //snehal-task-3647-Industry Exclusion on DI - FE
  /**
   * @author Snehal more
   * @param  Description  Get the excluded Industry file
   */
  excludedIndustryFilehandleChangeBack(e) {
    var campID = this.state.campID;
    let excludedIndustryFileerrors = {};
    let excludedIndustryFileArray = {};
    let excludedIndustryFileHeadererrors = {};
    let excludedIndustryValidate = true;
    let filesData = e.target.files;
    let filesData1 = e.target.files[0];
    excludedIndustryFilelengthback = filesData.length;
    var names = [];
    if (filesData.length > 0) {
      excludedIndustryFileArray["headerError"] = "";
      excludedIndustryFileHeadererrors["headerError"] = "";
      this.setState(
        {
          excludedIndustryFile: filesData1,
          excludedIndustryDisplay: "none",
          excludedIndustryFileArray: excludedIndustryFileArray,
          excludedIndustryFileHeadererrors: excludedIndustryFileHeadererrors,
        },
        function () {}
      );

      if (excludedIndustryFilelengthback !== undefined) {
        var allowedFiles = [".xls", ".xlsx", ".csv"];
        var excludedIndustryFile = document.getElementById(
          "excludedIndustryFile"
        );

        var regex = new RegExp(
          "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
        );

        if (!regex.test(excludedIndustryFile.value.toLowerCase())) {
          excludedIndustryFileerrors["fileextensions"] =
            "Please upload files having extensions: " +
            allowedFiles.join(", ") +
            " only";
          this.setState({
            excludedIndustryFileerrors: excludedIndustryFileerrors,
            excludedIndustryValidate: false,
          });
        } else {
          this.setState({
            excludedIndustryFileerrors: "",
            excludedIndustryValidate: true,
          });
        }
      }
      for (var i = 0, l = excludedIndustryFilelengthback; i < l; i++) {
        names[i] = filesData[i].name;
      }
      var link;
      var excludedIndustryFileName = "";
      var arrayLength = names.length;
      var HTML = "<table>";
      for (var j = 0; j < arrayLength; j++) {
        link =
          '<a class="removeFileexcludedIndustry2" href="#" data-fileid=' +
          '"' +
          names[j] +
          '"' +
          '><i class="fa fa-times"></i></a>';
        HTML +=
          "<tr><td>" +
          link +
          "&nbsp;&nbsp;&nbsp;&nbsp;" +
          names[j] +
          "</td></tr>";
      }
      HTML += "</table>";
      document.getElementById("excludedIndustryList").innerHTML = HTML;
      $(document).on(
        "click",
        ".removeFileexcludedIndustry2",
        function (e) {
          excludedIndustryFileerrors["fileextensions"] = "";
          excludedIndustryFileHeadererrors["headerError"] = ""; //bysonali
          excludedIndustryFileArray["headerError"] = ""; //bysonali
          this.setState({
            excludedIndustrycodeFile: "",
            excludedIndustryFileerrors: excludedIndustryFileerrors,
            excludedIndustryFileHeadererrors: excludedIndustryFileHeadererrors,
            excludedIndustryFileArray: excludedIndustryFileArray,
          });
        }.bind(this)
      );
      $(document).on("click", ".removeFileexcludedIndustry2", function (e) {
        e.preventDefault();
        var clickedFile = $(this).parent().children("a").data("fileid");
        var j = 0;
        for (j; j < names.length; j++) {
          var temp = j;

          if (names[temp] === clickedFile) {
            excludedIndustryFileName = names[temp];
            names.splice(j, 1);
            $(this).parent().remove();
            arrayLength = names.length;
            let excludedIndustry = "No";
            let typeOfFile = "ExcludedIndustryFile";
            let data = {
              campID: campID,
              excludedIndustry: excludedIndustry,
              typeOfFile: typeOfFile,
            };
            fetch("campaign/createCampaignDeleteFile", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            });
          }
        }
      });
      let excludedIndustryFlag = "Yes";
      let typeOfFile = "ExcludedIndustryFile";
      var data = new FormData();
      data.append("campID", campID);
      data.append("value", excludedIndustryFlag);
      data.append("typeOfFile", typeOfFile);
      data.append("file", filesData1);
      let self = this;
      fetch("campaign/createCampaignUploadFiles", {
        method: "POST",
        body: data,
      })
        .then(function (response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.json();
        })
        .then(function (response) {
          if (response.success === "failIndustry") {
            excludedIndustryFileHeadererrors["headerError"] =
              "Invalid file header";
            self.setState({
              excludedIndustryFileHeadererrors:
                excludedIndustryFileHeadererrors,
              excludedIndustryValidate: false,
            });
          } else {
            excludedIndustryFileArray["headerError"] =
              "Files uploaded successfully";
            self.setState({
              excludedIndustryFileArray: excludedIndustryFileArray,
              excludedIndustryValidate: true,
            });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
      document.getElementById("excludedIndustryFile").value = "";
    }
  }
  skipAndNext() {
    const { isAuthenticated, user } = this.props.auth;
    this.setState({ current: this.state.current + 1 }, function () {
      if (this.state.current === 2) {
        let data = {
          deliveryOptionDetail: this.state.DeliveryOption,
          campID: this.state.campID,
        };
        fetch("/campaignNew/skipDeliveryOptionStep", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {});
        //snehal-task2952-Prod Issue-Internal-Lead Interaction Days validation issue-when i put invalid no in lead interation and click on save and next shows error but when i click on skip&next with error it goes to new page
        //so added condition when click on skip the error will disapper and value will be blank
        if (this.state.DeliveryOptionEdit[0].leadInteractionDays !== "") {
          var pattern = new RegExp(/^[0-9]\d*$/);
          if (
            !pattern.test(this.state.DeliveryOptionEdit[0].leadInteractionDays)
          ) {
            this.state.DeliveryOptionEdit[0].leadInteractionDays =
              this.state.apiLeadInteractionDays;
            this.setState({
              leadInteractionDaysDisplay: "none",
              leadInteractionDaysDisplay2: "none",
            });
            document.getElementById("leadInteractionDays").style.border =
              "1px solid lightgray";
          }
        }
        this.setState({
          display1: "none",
          display2: "none",
          display3: "block",
          display4: "none",
          displaySkipandNext: "none",
          displaySaveandNext: "block",
        });
        //Sanjana-Task-2634-Create camp flow - step 1.3-create campaign--Page display issue,scroll page to TOP
        document.body.scrollTop = (0, 0);
        document.documentElement.scrollTop = (0, 0);
      }
    });
    if (this.state.current == "3") {
      let data = {
        campID: this.state.campID,
        specificationEditDetails: this.state.specificationCampEditDetailsMail,
        deliveryEditDetails: this.state.deliveryCampaignEditDetailsMail,
        basicEditDetails: this.state.basicCampaignEditDeatilsMail,
        pacingEditDetails: this.state.pacingCampaignEditDetailsMail,
        deletePacingEditDetails: this.state.deletePacingEditDetailsMail,
      }; // karan-task-3767-added default header
      fetch("/campaignNew/skipCustomQuestionStepEdit", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((response) => {
          var campID = this.state.campID;

          // window.location.href="./WelcomeEdit?campID="+campId+'&parentCampID='+parsed.parentCampID+'&reallocationID='+parsed.reallocationID
          this.props.history.push("/welcomeEdit", {
            campID,
            parentCampID: this.state.parentCampID,
          }); // karan-task-3714-vapt header and query params
        });
    }
  }

  ///////////////////////////////////////////////////////
  onChangeRegion = (RegioncheckedList) => {
    this.setState({
      RegioncheckedList,
      indeterminate:
        !!RegioncheckedList.length &&
        RegioncheckedList.length < RegionOption.length,
      checkAllRegion: RegioncheckedList.length === RegionOption.length,
    });
  };

  onCheckAllChangeRegion = (e) => {
    this.setState({
      RegioncheckedList: e.target.checked ? RegionOption : [],
      indeterminate: false,
      checkAllRegion: e.target.checked,
    });
  };

  onCheckAllChangeBenelux = (e) => {
    var region = "benelux";
    if (!this.state.region.includes("benelux")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("benelux");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    var countryArray = [];
    if (e.target.checked) {
      //benelux checked
      for (var i = 0; i < 3; i++) {
        countryArray.push(BeneluxOptions[i]);
      }

      if (this.state.dachChecked) {
        //dach already checked
        for (var i = 0; i < 3; i++) {
          countryArray.push(DACHOptions[i]);
        }
        this.setState(
          {
            WesternEuropecheckedList: this.state.westernEuropeChecked
              ? WesternEuropeOptions
              : countryArray,
            indeterminate: false,
            checkAll23: e.target.checked,
            beneluxChecked: !this.state.beneluxChecked,
          },
          function () {
            Swal.fire("Benelux Countries Selected");
          }
        );
      } else {
        this.setState(
          {
            WesternEuropecheckedList: this.state.westernEuropeChecked
              ? WesternEuropeOptions
              : countryArray,
            indeterminate: false,
            checkAll23: e.target.checked,
            beneluxChecked: !this.state.beneluxChecked,
          },
          function () {
            Swal.fire("Benelux Countries Selected");
          }
        );
      }
    } else {
      if (this.state.dachChecked) {
        //benelux checked
        this.setState(
          {
            WesternEuropecheckedList: this.state.westernEuropeChecked
              ? WesternEuropeOptions
              : DACHOptions,
            indeterminate: false,
            checkAll23: e.target.checked,
            beneluxChecked: !this.state.beneluxChecked,
          },
          function () {
            Swal.fire("Benelux Countries Removed");
          }
        );
      } else {
        this.setState(
          {
            WesternEuropecheckedList: this.state.westernEuropeChecked
              ? WesternEuropeOptions
              : [],
            indeterminate: false,
            checkAll23: e.target.checked,
            beneluxChecked: !this.state.beneluxChecked,
          },
          function () {
            Swal.fire("Benelux Countries Removed");
          }
        );
      }
    }
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // onChangeDACH = DACHcheckedList => {
  //   var region="dach";

  //   if(DACHcheckedList.length>0)
  //   {
  //       if(!this.state.region.includes("dach")){
  //        this.state.region.push(region)
  //   }
  // }
  //   this.setState({
  //     DACHcheckedList,
  //     indeterminate:
  //       !!DACHcheckedList.length && DACHcheckedList.length < DACHOptions.length,
  //     checkAll24: DACHcheckedList.length === DACHOptions.length
  //   });
  //   if(DACHcheckedList.length==0)
  //   {
  //       var index=this.state.region.indexOf("dach")
  //       if (index > -1) {
  //           this.state.region.splice(index, 1);
  //        }
  //   }
  // };

  onCheckAllChangeDACH = (e) => {
    var region = "dach";
    if (!this.state.region.includes("dach")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("dach");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    var countryArray = [];
    if (e.target.checked) {
      //dach checked
      for (var i = 0; i < 3; i++) {
        countryArray.push(DACHOptions[i]);
      }

      if (this.state.beneluxChecked) {
        //benelux checked
        for (var i = 0; i < 3; i++) {
          countryArray.push(BeneluxOptions[i]);
        }

        this.setState(
          {
            WesternEuropecheckedList: this.state.westernEuropeChecked
              ? WesternEuropeOptions
              : countryArray,
            indeterminate: false,
            checkAll24: e.target.checked,
            dachChecked: !this.state.dachChecked,
          },
          function () {
            Swal.fire("DACH Countries Selected");
          }
        );
      } else {
        this.setState(
          {
            WesternEuropecheckedList: this.state.westernEuropeChecked
              ? WesternEuropeOptions
              : countryArray,
            indeterminate: false,
            checkAll24: e.target.checked,
            dachChecked: !this.state.dachChecked,
          },
          function () {
            Swal.fire("DACH Countries Selected");
          }
        );
      }
    } else {
      if (this.state.beneluxChecked) {
        //benelux checked
        this.setState(
          {
            WesternEuropecheckedList: this.state.westernEuropeChecked
              ? WesternEuropeOptions
              : DACHOptions,
            indeterminate: false,
            checkAll24: e.target.checked,
            dachChecked: !this.state.dachChecked,
          },
          function () {
            Swal.fire("DACH Countries Removed");
          }
        );
      } else {
        this.setState(
          {
            WesternEuropecheckedList: this.state.westernEuropeChecked
              ? WesternEuropeOptions
              : [],
            indeterminate: false,
            checkAll24: e.target.checked,
            dachChecked: !this.state.dachChecked,
          },
          function () {
            Swal.fire("DACH Countries Removed");
          }
        );
      }
    }
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  // onChangeNordic = NordiccheckedList => {
  //   var region="nordic";

  //   if(NordiccheckedList.length>0)
  //   {
  //       if(!this.state.region.includes("nordic")){
  //        this.state.region.push(region)
  //   }
  // }
  //   this.setState({
  //     NordiccheckedList,
  //     indeterminate:
  //       !!NordiccheckedList.length && NordiccheckedList.length < NordicOptions.length,
  //     checkAll25: NordiccheckedList.length === NordicOptions.length
  //   });
  //   if(NordiccheckedList.length==0)
  //   {
  //       var index=this.state.region.indexOf("nordic")
  //       if (index > -1) {
  //           this.state.region.splice(index, 1);
  //        }
  //   }
  // };

  onCheckAllChangeNordic = (e) => {
    var region = "nordic";
    if (!this.state.region.includes("nordic")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("nordic");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    if (e.target.checked) {
      this.setState(
        {
          NorthernEuropecheckedList: e.target.checked
            ? this.state.northerneuropeChecked
              ? NorthernEuropeOptions
              : NordicOptions
            : [],
          NordicOptions: [],
          indeterminate: false,
          checkAll25: e.target.checked,
          nordicChecked: !this.state.nordicChecked,
        },
        function () {
          Swal.fire("Nordic Countries Selected");
        }
      );
    } else {
      this.setState(
        {
          NorthernEuropecheckedList:
            e.target.checked === false
              ? this.state.northerneuropeChecked
                ? NorthernEuropeOptions
                : []
              : [],
          indeterminate: false,
          checkAll25: e.target.checked,
          nordicChecked: !this.state.nordicChecked,
        },
        function () {
          Swal.fire("Nordic Countries Removed");
        }
      );
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////
  // onChangeAustraliaAndNewZealand  = AustraliaAndNewZealandcheckedList => {
  //     var region="Australia and New Zealand";
  //     if(AustraliaAndNewZealandcheckedList.length>0)
  //     {if(!this.state.region.includes("Australia and New Zealand")){
  //         this.state.region.push(region)

  //     }}
  //     this.setState({
  //         AustraliaAndNewZealandcheckedList,
  //       indeterminate:
  //         !!AustraliaAndNewZealandcheckedList.length &&
  //         AustraliaAndNewZealandcheckedList.length < AustraliaAndNewZealandOptions.length,
  //       checkAll18: AustraliaAndNewZealandcheckedList.length === AustraliaAndNewZealandOptions.length
  //     });
  //     if(AustraliaAndNewZealandcheckedList.length==0){

  //         var index=this.state.region.indexOf("Australia and New Zealand")
  //         if (index > -1) {
  //             this.state.region.splice(index, 1);
  //          }
  //     }
  //   };

  onCheckAllChangeAustraliaAndNewZealand = (e) => {
    var region = "anz";

    if (!this.state.region.includes("anz")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("anz");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    if (e.target.checked) {
      this.setState(
        {
          SouthAsiacheckedList: e.target.checked
            ? this.state.southAsiaChecked
              ? SouthAsiaOptions
              : ANZOptions
            : [],
          ANZOptions: [],
          indeterminate: false,
          checkAll18: e.target.checked,
          anzChecked: !this.state.anzChecked,
        },
        function () {
          Swal.fire("ANZ Countries Selected");
        }
      );
    } else {
      this.setState(
        {
          SouthAsiacheckedList:
            e.target.checked === false
              ? this.state.southAsiaChecked
                ? SouthAsiaOptions
                : []
              : [],
          indeterminate: false,
          checkAll18: e.target.checked,
          anzChecked: !this.state.anzChecked,
        },
        function () {
          Swal.fire("ANZ Countries Removed");
        }
      );
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////onCheckAllChangeAPAC
  onCheckAllChangeAPAC = (e) => {
    var region = "apac";
    if (e.target.checked == true) {
      this.setState({ ANZdisabled: true });
    } else {
      this.setState({ ANZdisabled: false });
    }

    if (!this.state.region.includes("apac")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    } else {
      var index = this.state.region.indexOf("apac");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    var countryArray = [];
    var countryArray1 = [];
    var countryArray2 = [];
    var countryArray3 = [];
    if (e.target.checked) {
      if (this.state.anzChecked == true || this.state.checkAll18 == true) {
        var index = this.state.region.indexOf("anz");
        if (index > -1) {
          this.state.region.splice(index, 1);
        }
      }

      // ["Kazakhstan","Kyrgyzstan","Tajikistan","Uzbekistan"]

      // ["China","South Korea","Japan","Hong Kong","Taiwan"]

      // ["Sri Lanka","Bangladesh","India","Afghanistan","Pakistan","Bhutan","Nepal","Australia","New Zealand"]

      // ["Indonesia","Malaysia","Singapore","Thailand","Tibet"]
      if (this.state.southAsiaChecked) {
        countryArray = SouthAsiaOptions;
      } else {
        countryArray = [
          "Sri Lanka",
          "Bangladesh",
          "India",
          "Afghanistan",
          "Pakistan",
          "Bhutan",
          "Nepal",
          "Australia",
          "New Zealand",
        ];
      }

      if (this.state.southeastAsiaChecked) {
        countryArray1 = SoutheastAsiaOptions;
      } else {
        countryArray1 = [
          "Indonesia",
          "Malaysia",
          "Singapore",
          "Thailand",
          "Tibet",
        ];
      }

      if (this.state.eastAsiaChecked) {
        countryArray2 = EastAsiaOptions;
      } else {
        countryArray2 = [
          "China",
          "South Korea",
          "Japan",
          "Hong Kong",
          "Taiwan",
        ];
      }

      if (this.state.centralAsiaChecked) {
        countryArray3 = CentralAsiaOptions;
      } else {
        countryArray3 = [
          "Kazakhstan",
          "Kyrgyzstan",
          "Tajikistan",
          "Turkmenistan",
          "Uzbekistan",
        ];
      }

      //SoutheastAsiacheckedList:countryArray1,
      this.setState(
        {
          SouthAsiacheckedList: countryArray,
          SoutheastAsiacheckedList: countryArray1,
          EastAsiacheckedList: countryArray2,
          CentralAsiacheckedList: countryArray3,
          indeterminate: false,
          checkAll26: e.target.checked,
          apacChecked: !this.state.apacChecked,
        },
        function () {
          Swal.fire("APAC Countries Selected");
        }
      );
    } else {
      if (this.state.southAsiaChecked) {
        countryArray = SouthAsiaOptions;
      } else {
        countryArray = [];
      }

      if (this.state.southeastAsiaChecked) {
        countryArray1 = SoutheastAsiaOptions;
      } else {
        countryArray1 = [];
      }

      if (this.state.eastAsiaChecked) {
        countryArray2 = EastAsiaOptions;
      } else {
        countryArray2 = [];
      }

      if (this.state.centralAsiaChecked) {
        countryArray3 = CentralAsiaOptions;
      } else {
        countryArray3 = [];
      }

      this.setState(
        {
          SouthAsiacheckedList: countryArray,
          SoutheastAsiacheckedList: countryArray1,
          EastAsiacheckedList: countryArray2,
          CentralAsiacheckedList: countryArray3,
          indeterminate: false,
          checkAll26: e.target.checked,
          apacChecked: !this.state.apacChecked,
        },
        function () {
          Swal.fire("APAC Countries Removed");
        }
      );

      if (this.state.anzChecked == true || this.state.checkAll18 == true) {
        this.state.region.push("anz");
        this.setState(
          {
            SouthAsiacheckedList: this.state.checkAll18
              ? this.state.southAsiaChecked
                ? SouthAsiaOptions
                : ANZOptions
              : [],
            ANZOptions: [],
            indeterminate: false,
          },
          function () {
            Swal.fire("APAC Countries Removed"); // Rutuja 4290- popup show wrong msg
          }
        );
      }
    }
  };

  ////////////////////////////////////////////////////////////
  onChangeNorthernAfricaCountries = (NorthernAfricaCountriescheckedList) => {
    var region = "Northern Africa Countries";

    if (NorthernAfricaCountriescheckedList.length > 0) {
      if (!this.state.region.includes("Northern Africa Countries")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        NorthernAfricaCountriescheckedList,
        indeterminateAfr1:
          !!NorthernAfricaCountriescheckedList.length &&
          NorthernAfricaCountriescheckedList.length <
            NorthernAfricaCountriesOptions.length,
        checkAll1:
          NorthernAfricaCountriescheckedList.length ===
          NorthernAfricaCountriesOptions.length,
      },
      function () {
        this.onCheckAfricaSelectAll();
      }
    );
    if (NorthernAfricaCountriescheckedList.length == 0) {
      var index = this.state.region.indexOf("Northern Africa Countries");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  /**
   * @author Narendra Phadke
   * @param  Description  For Select All of Europe Region
   */
  onCheckAfricaSelectAll = (e) => {
    if (
      this.state.checkAll1 === true &&
      this.state.checkAll2 === true &&
      this.state.checkAll3 === true &&
      this.state.checkAll4 === true &&
      this.state.checkAll5 === true
    ) {
      this.setState({ checkAfricaSelectAll: true }, function () {
        this.onCheckRegionSelectAll(e);
      });
    } else {
      this.setState({ checkAfricaSelectAll: false }, function () {
        this.onCheckRegionSelectAll(e);
      });
    }
  }; //End OF Select all Handle change of region

  /**
   * @author Narendra Phadke
   * @param  Description  Select All for Africa Region
   */
  onCheckAllChangeAfricaRegion = (e) => {
    this.onCheckAllChangeNorthernAfricaCountries(e);
    this.onCheckAllChangeCentralOrMiddleAfrican(e);
    this.onCheckAllChangeSouthernAfricaCountries(e);
    this.onCheckAllChangeEastAfricanCountries(e);
    this.onCheckAllChangeWesternAfrica(e);
  };

  onCheckAllChangeNorthernAfricaCountries = (e) => {
    var region = "Northern Africa Countries";
    if (!this.state.region.includes("Northern Africa Countries")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Northern Africa Countries");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        NorthernAfricaCountriescheckedList: e.target.checked
          ? NorthernAfricaCountriesOptions
          : [],
        indeterminateAfr1: false,
        checkAll1: e.target.checked,
      },
      function () {
        this.onCheckAfricaSelectAll(e);
      }
    );
  };
  //////////////////////////////////////////////////////
  onChangeCentralOrMiddleAfrican = (CentralOrMiddleAfricancheckedList) => {
    var region = "Central or Middle African";
    if (CentralOrMiddleAfricancheckedList.length > 0) {
      if (!this.state.region.includes("Central or Middle African")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        CentralOrMiddleAfricancheckedList,
        indeterminateAfr2:
          !!CentralOrMiddleAfricancheckedList.length &&
          CentralOrMiddleAfricancheckedList.length <
            CentralOrMiddleAfricanOptions.length,
        checkAll2:
          CentralOrMiddleAfricancheckedList.length ===
          CentralOrMiddleAfricanOptions.length,
      },
      function () {
        this.onCheckAfricaSelectAll();
      }
    );
    if (CentralOrMiddleAfricancheckedList.length == 0) {
      var index = this.state.region.indexOf("Central or Middle African");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };
  onCheckAllChangeCentralOrMiddleAfrican = (e) => {
    var region = "Central or Middle African";
    if (!this.state.region.includes("Central or Middle African")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Central or Middle African");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        CentralOrMiddleAfricancheckedList: e.target.checked
          ? CentralOrMiddleAfricanOptions
          : [],
        indeterminateAfr2: false,
        checkAll2: e.target.checked,
      },
      function () {
        this.onCheckAfricaSelectAll(e);
      }
    );
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onChangeSouthernAfricaCountries = (SouthernAfricaCountriescheckedList) => {
    var region = "Southern Africa countries";
    if (SouthernAfricaCountriescheckedList.length > 0) {
      if (!this.state.region.includes("Southern Africa countries")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        SouthernAfricaCountriescheckedList,
        indeterminateAfr3:
          !!SouthernAfricaCountriescheckedList.length &&
          SouthernAfricaCountriescheckedList.length <
            SouthernAfricaCountriesOptions.length,
        checkAll3:
          SouthernAfricaCountriescheckedList.length ===
          SouthernAfricaCountriesOptions.length,
      },
      function () {
        this.onCheckAfricaSelectAll();
      }
    );
    if (SouthernAfricaCountriescheckedList.length == 0) {
      var index = this.state.region.indexOf("Southern Africa countries");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeSouthernAfricaCountries = (e) => {
    var region = "Southern Africa countries";
    if (!this.state.region.includes("Southern Africa countries")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Southern Africa countries");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        SouthernAfricaCountriescheckedList: e.target.checked
          ? SouthernAfricaCountriesOptions
          : [],
        indeterminateAfr3: false,
        checkAll3: e.target.checked,
      },
      function () {
        this.onCheckAfricaSelectAll(e);
      }
    );
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onChangeEastAfricanCountries = (EastAfricanCountriescheckedList) => {
    var region = "East African countries";
    if (EastAfricanCountriescheckedList.length > 0) {
      if (!this.state.region.includes("East African countries")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        EastAfricanCountriescheckedList,
        indeterminateAfr4:
          !!EastAfricanCountriescheckedList.length &&
          EastAfricanCountriescheckedList.length <
            EastAfricanCountriesOptions.length,
        checkAll4:
          EastAfricanCountriescheckedList.length ===
          EastAfricanCountriesOptions.length,
      },
      function () {
        this.onCheckAfricaSelectAll();
      }
    );
    if (EastAfricanCountriescheckedList.length == 0) {
      var index = this.state.region.indexOf("East African countries");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeEastAfricanCountries = (e) => {
    var region = "East African countries";
    if (!this.state.region.includes("East African countries")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("East African countries");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        EastAfricanCountriescheckedList: e.target.checked
          ? EastAfricanCountriesOptions
          : [],
        indeterminateAfr4: false,
        checkAll4: e.target.checked,
      },
      function () {
        this.onCheckAfricaSelectAll(e);
      }
    );
  };
  ///////////////////////////////////////////////////////////////

  onChangeWesternAfrica = (WesternAfricacheckedList) => {
    var region = "Western Africa";
    if (WesternAfricacheckedList.length > 0) {
      if (!this.state.region.includes("Western Africa")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        WesternAfricacheckedList,
        indeterminateAfr5:
          !!WesternAfricacheckedList.length &&
          WesternAfricacheckedList.length < WesternAfricaOptions.length,
        checkAll5:
          WesternAfricacheckedList.length === WesternAfricaOptions.length,
      },
      function () {
        this.onCheckAfricaSelectAll();
      }
    );
    if (WesternAfricacheckedList.length == 0) {
      var index = this.state.region.indexOf("Western Africa");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeWesternAfrica = (e) => {
    var region = "Western Africa";
    if (!this.state.region.includes("Western Africa")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Western Africa");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        WesternAfricacheckedList: e.target.checked ? WesternAfricaOptions : [],
        indeterminateAfr5: false,
        checkAll5: e.target.checked,
      },
      function () {
        this.onCheckAfricaSelectAll(e);
      }
    );
  };
  /////////////////////////////////////////////////////////////////////////////
  onChangeEasternEurope = (EasternEuropecheckedList) => {
    var region = "Eastern Europe";
    if (EasternEuropecheckedList.length > 0) {
      if (!this.state.region.includes("Eastern Europe")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        EasternEuropecheckedList,
        indeterminateEur1:
          !!EasternEuropecheckedList.length &&
          EasternEuropecheckedList.length < EasternEuropeOptions.length,
        checkAll6:
          EasternEuropecheckedList.length === EasternEuropeOptions.length,
      },
      function () {
        this.onCheckEuropeSelectAll();
      }
    );
    if (EasternEuropecheckedList.length == 0) {
      var index = this.state.region.indexOf("Eastern Europe");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  /**
   * @author Narendra Phadke
   * @param  Description  Add Select All for Europe region
   */
  onCheckAllChangeEurope = (e) => {
    //Call all function of sub region
    this.onCheckAllChangeEasternEurope(e);
    this.onCheckAllChangeNorthernEurope(e);
    this.onCheckAllChangeSouthernEurope(e);
    this.onCheckAllChangeWesternEurope(e);
    if (e.target.checked == true) {
      this.setState({
        nordicDisabled: true,
        beneluxDisabled: true,
        dachDisabled: true,
      });
    } else {
      this.setState({
        nordicDisabled: false,
        beneluxDisabled: false,
        dachDisabled: false,
      });
    }
  };
  /**
   * @author Narendra Phadke
   * @param  Description  For Select All of Europe Region
   */
  onCheckEuropeSelectAll = (e) => {
    if (
      this.state.checkAll6 === true &&
      this.state.checkAll7 === true &&
      this.state.checkAll8 === true &&
      this.state.checkAll9 === true
    ) {
      this.setState({ checkEuropeSelectAll: true }, function () {
        this.onCheckRegionSelectAll(e);
      });
    } else {
      this.setState({ checkEuropeSelectAll: false }, function () {
        this.onCheckRegionSelectAll(e);
      });
    }
  }; //End OF Select all Handle change of region
  onCheckAllChangeEasternEurope = (e) => {
    var region = "Eastern Europe";
    if (!this.state.region.includes("Eastern Europe")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Eastern Europe");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        EasternEuropecheckedList: e.target.checked ? EasternEuropeOptions : [],
        indeterminateEur1: false,
        checkAll6: e.target.checked,
      },
      function () {
        this.onCheckEuropeSelectAll(e);
      }
    );
  };

  //////////////////////////////////////////////////////////////////
  onChangeNorthernEurope = (NorthernEuropecheckedList) => {
    if (this.state.nordicChecked == true || this.state.checkAll25 == true) {
      if (NorthernEuropecheckedList.length < NordicOptions.length) {
        var ind = this.state.region.indexOf("nordic");
        if (ind > -1) {
          this.state.region.splice(ind, 1);
        }
        this.setState({ nordicChecked: false, checkAll25: false });
      }
    }
    var region = "Northern Europe";
    if (NorthernEuropecheckedList.length > 0) {
      if (!this.state.region.includes("Northern Europe")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        NorthernEuropecheckedList,
        indeterminateEur2:
          !!NorthernEuropecheckedList.length &&
          NorthernEuropecheckedList.length < NorthernEuropeOptions.length,
        checkAll7:
          NorthernEuropecheckedList.length === NorthernEuropeOptions.length,
      },
      function () {
        this.onCheckEuropeSelectAll();
      }
    );
    if (NorthernEuropecheckedList.length == 0) {
      var index = this.state.region.indexOf("Northern Europe");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
      //shivani -task 3347- added below condition if "northern europe" in small case, we'll compare it and remove.
      var index = this.state.region.indexOf("northern europe");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeNorthernEurope = (e) => {
    var region = "Northern Europe";
    if (e.target.checked == true) {
      this.setState({ nordicDisabled: true });
    } else {
      this.setState({ nordicDisabled: false });
    }
    if (!this.state.region.includes("Northern Europe")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Northern Europe");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    if (e.target.checked) {
      if (this.state.nordicChecked) {
        this.setState({ checkAll25: false, nordicDisabled: true });
        if (this.state.beneluxChecked) {
          var index = this.state.region.indexOf("nordic");
          if (index > -1) {
            this.state.region.splice(index, 1);
          }
        }
      }
      this.setState(
        {
          NorthernEuropecheckedList: e.target.checked
            ? NorthernEuropeOptions
            : [],
          indeterminateEur2: false,
          checkAll7: e.target.checked,
          northerneuropeChecked: !this.state.northerneuropeChecked,
        },
        function () {
          this.onCheckEuropeSelectAll(e);
        }
      );
    } else {
      if (this.state.nordicChecked) {
        this.setState({
          NorthernEuropecheckedList: NordicOptions,
          indeterminateEur2: false,
          checkAll7: e.target.checked,
          northerneuropeChecked: !this.state.northerneuropeChecked,
          checkAll25: true,
          nordicDisabled: false,
        });
      } else {
        this.setState(
          {
            NorthernEuropecheckedList: [],
            indeterminateEur2: false,
            checkAll7: e.target.checked,
            northerneuropeChecked: !this.state.northerneuropeChecked,
            nordicDisabled: false,
          },
          function () {
            this.onCheckEuropeSelectAll(e);
          }
        );
      }
    }
  };

  /////////////////////////////////////////////////////////////
  onChangeSouthernEurope = (SouthernEuropecheckedList) => {
    var region = "Southern Europe";
    if (SouthernEuropecheckedList.length > 0) {
      if (!this.state.region.includes("Southern Europe")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        SouthernEuropecheckedList,
        indeterminateEur3:
          !!SouthernEuropecheckedList.length &&
          SouthernEuropecheckedList.length < SouthernEuropeOptions.length,
        checkAll8:
          SouthernEuropecheckedList.length === SouthernEuropeOptions.length,
      },
      function () {
        this.onCheckEuropeSelectAll();
      }
    );
    if (SouthernEuropecheckedList.length == 0) {
      var index = this.state.region.indexOf("Southern Europe");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeSouthernEurope = (e) => {
    var region = "Southern Europe";
    if (!this.state.region.includes("Southern Europe")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Southern Europe");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        SouthernEuropecheckedList: e.target.checked
          ? SouthernEuropeOptions
          : [],
        indeterminateEur3: false,
        checkAll8: e.target.checked,
      },
      function () {
        this.onCheckEuropeSelectAll(e);
      }
    );
  };

  ///////////////////////////////////////////////////////////////////

  onChangeWesternEurope = (WesternEuropecheckedList) => {
    if (this.state.beneluxChecked == true || this.state.checkAll23 == true) {
      if (WesternEuropecheckedList.length < BeneluxOptions.length) {
        var ind = this.state.region.indexOf("benelux");
        if (ind > -1) {
          this.state.region.splice(ind, 1);
        }
        this.setState({ beneluxChecked: false, checkAll23: false });
      }
    }
    if (this.state.dachChecked == true || this.state.checkAll24 == true) {
      if (WesternEuropecheckedList.length < DACHOptions.length) {
        var ind = this.state.region.indexOf("dach");
        if (ind > -1) {
          this.state.region.splice(ind, 1);
        }
        this.setState({ dachChecked: false, checkAll24: false });
      }
    }
    var region = "Western Europe";
    if (WesternEuropecheckedList.length > 0) {
      if (!this.state.region.includes("Western Europe")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        WesternEuropecheckedList,
        indeterminateEur4:
          !!WesternEuropecheckedList.length &&
          WesternEuropecheckedList.length < WesternEuropeOptions.length,
        checkAll9:
          WesternEuropecheckedList.length === WesternEuropeOptions.length,
        dachDisabled:
          WesternEuropecheckedList.length === WesternEuropeOptions.length,
        beneluxDisabled:
          WesternEuropecheckedList.length === WesternEuropeOptions.length,
      },
      function () {
        this.onCheckEuropeSelectAll();
        ["benelux", "dach"].forEach((ele) => {
          if (this.state.region.includes(ele)) {
            this.state.region.splice(this.state.region.indexOf(ele), 1);
          }
        });
        DACHOptions.map(
          function (ele) {
            if (!WesternEuropecheckedList.includes(ele)) {
              this.setState({ dachChecked: false, checkAll24: false });
            }
          }.bind(this)
        );
        BeneluxOptions.map(
          function (ele) {
            if (!WesternEuropecheckedList.includes(ele)) {
              this.setState({ beneluxChecked: false, checkAll23: false });
            }
          }.bind(this)
        );
      }
    );
    if (WesternEuropecheckedList.length == 0) {
      var index = this.state.region.indexOf("Western Europe");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
      //shivani -task 3347- added below condition if "western europe" in small case, we'll compare it and remove .
      var index = this.state.region.indexOf("western europe");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeWesternEurope = (e) => {
    var region = "Western Europe";
    if (e.target.checked == true) {
      this.setState({ dachDisabled: true, beneluxDisabled: true });
    } else {
      this.setState({ dachDisabled: false, beneluxDisabled: false });
    }
    if (!this.state.region.includes("Western Europe")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Western Europe");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    if (e.target.checked) {
      if (this.state.beneluxChecked && this.state.dachChecked) {
        this.setState({
          checkAll23: false,
          checkAll24: false,
          beneluxDisabled: true,
          dachDisabled: true,
        });
        if (this.state.beneluxChecked) {
          var index = this.state.region.indexOf("benelux");
          if (index > -1) {
            this.state.region.splice(index, 1);
          }
        }

        if (this.state.dachChecked) {
          var index = this.state.region.indexOf("dach");
          if (index > -1) {
            this.state.region.splice(index, 1);
          }
        }
      }
      // end of both checked
      else if (this.state.beneluxChecked && !this.state.dachChecked) {
        this.setState({ checkAll23: false, beneluxDisabled: true });
        if (this.state.beneluxChecked) {
          var index = this.state.region.indexOf("benelux");
          if (index > -1) {
            this.state.region.splice(index, 1);
          }
        }
      } else if (!this.state.beneluxChecked && this.state.dachChecked) {
        this.setState({ checkAll24: false, dachDisabled: true });
        if (this.state.dachChecked) {
          var index = this.state.region.indexOf("dach");
          if (index > -1) {
            this.state.region.splice(index, 1);
          }
        }
      }
      this.setState(
        {
          WesternEuropecheckedList: e.target.checked
            ? WesternEuropeOptions
            : [],
          indeterminateEur4: false,
          checkAll9: e.target.checked,
          westernEuropeChecked: !this.state.westernEuropeChecked,
        },
        function () {
          this.onCheckEuropeSelectAll(e);
        }
      );
    } else {
      var countryArray = [
        "Belgium",
        "Netherlands",
        "Luxembourg",
        "Austria",
        "Germany",
        "Switzerland",
      ];
      if (this.state.beneluxChecked && this.state.dachChecked) {
        this.setState({
          WesternEuropecheckedList: countryArray,
          indeterminateEur4: false,
          checkAll9: e.target.checked,
          westernEuropeChecked: !this.state.westernEuropeChecked,
          beneluxDisabled: false,
          dachDisabled: false,
          checkAll23: true,
          checkAll24: true,
        });
      } else if (this.state.beneluxChecked && !this.state.dachChecked) {
        this.setState(
          {
            WesternEuropecheckedList: BeneluxOptions,
            indeterminateEur4: false,
            checkAll9: e.target.checked,
            westernEuropeChecked: !this.state.westernEuropeChecked,
            beneluxDisabled: false,
            checkAll23: true,
          },
          () => {
            this.state.region.push("benelux");
          }
        ); // Raunak Thakkar task 2950-Prod Issue-Internal-Edit Campaign/Incomplete Campaign-Country Region validation issue
      }
      if (this.state.dachChecked && !this.state.beneluxChecked) {
        this.setState(
          {
            WesternEuropecheckedList: DACHOptions,
            indeterminateEur4: false,
            checkAll9: e.target.checked,
            westernEuropeChecked: !this.state.westernEuropeChecked,
            dachDisabled: false,
            checkAll24: true,
          },
          () => {
            this.state.region.push("dach");
          }
        ); // Raunak Thakkar task 2950-Prod Issue-Internal-Edit Campaign/Incomplete Campaign-Country Region validation issue
      } else if (!this.state.dachChecked && !this.state.beneluxChecked) {
        this.setState(
          {
            WesternEuropecheckedList: [],
            indeterminateEur4: false,
            checkAll9: e.target.checked,
            westernEuropeChecked: !this.state.westernEuropeChecked,
            beneluxDisabled: false,
            dachDisabled: false,
          },
          function () {
            this.onCheckEuropeSelectAll(e);
          }
        );
      }
    }
  };
  /////////////////////////////////////////////////////////
  onChangeCentralAsia = (CentralAsiacheckedList) => {
    var region = "Central Asia";
    if (CentralAsiacheckedList.length > 0) {
      if (!this.state.region.includes("Central Asia")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        CentralAsiacheckedList,
        indeterminateAsia1:
          !!CentralAsiacheckedList.length &&
          CentralAsiacheckedList.length < CentralAsiaOptions.length,
        checkAll10: CentralAsiacheckedList.length === CentralAsiaOptions.length,
      },
      function () {
        this.onCheckAsiaSelectAll();
        this.disperse(false, true, true, true); //2950 to disselect apac and check the subregion
        if (this.state.apacChecked === true) {
          this.setState({
            apacChecked: false,
            indeterminateAsia1: true,
            checkAll26: false,
          });
        }
      }
    );
    if (CentralAsiacheckedList.length == 0) {
      var index = this.state.region.indexOf("Central Asia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  /**
   * @author Narendra Phadke
   * @param  Description  For Select All of Europe Region
   */
  onCheckAsiaSelectAll = (e) => {
    if (
      this.state.checkAll10 === true &&
      this.state.checkAll11 === true &&
      this.state.checkAll12 === true &&
      this.state.checkAll13 === true &&
      this.state.checkAll14 === true
    ) {
      this.setState({ checkAsiaSelectAll: true }, function () {
        this.onCheckRegionSelectAll(e);
      });
    } else {
      this.setState({ checkAsiaSelectAll: false }, function () {
        this.onCheckRegionSelectAll(e);
      });
    }
  }; //End OF Select all Handle change of region

  /**
   * @author Narendra Phadke
   * @param  Description  handle change for select all of ASIA
   */
  onCheckAllChangeAsia = (e) => {
    this.onCheckAllChangeCentralAsia(e);
    this.onCheckAllChangeEastAsia(e);
    this.onCheckAllChangeSouthAsia(e);
    this.onCheckAllChangeSoutheastAsia(e);
    this.onCheckAllChangeWesternAsia(e);
    if (e.target.checked == true) {
      this.setState({ ApacDisabled: true, ANZdisabled: true }, function () {
        if (this.state.apacChecked == true || this.state.checkAll26 == true) {
          var index = this.state.region.indexOf("apac");
          if (index > -1) {
            this.state.region.splice(index, 1);
          }
        }
      });
    } else {
      this.setState({ ApacDisabled: false, ANZdisabled: false }, function () {
        if (this.state.apacChecked == true || this.state.checkAll26 == true) {
          this.state.region.push("apac");
          var countryArray = [];
          var countryArray1 = [];
          var countryArray2 = [];
          var countryArray3 = [];
          if (this.state.southAsiaChecked) {
            countryArray = SouthAsiaOptions;
          } else {
            countryArray = APACOptions;
          }

          if (this.state.southeastAsiaChecked) {
            countryArray1 = SoutheastAsiaOptions;
          } else {
            countryArray1 = APACOptions;
          }

          if (this.state.eastAsiaChecked) {
            countryArray2 = EastAsiaOptions;
          } else {
            countryArray2 = APACOptions;
          }

          if (this.state.centralAsiaChecked) {
            countryArray3 = CentralAsiaOptions;
          } else {
            countryArray3 = APACOptions;
          }
          //SoutheastAsiacheckedList:countryArray1,
          this.setState(
            {
              SouthAsiacheckedList: countryArray,
              SoutheastAsiacheckedList: countryArray1,
              EastAsiacheckedList: countryArray2,
              CentralAsiacheckedList: countryArray3,
              indeterminate: false,
              checkAll26: this.state.apacChecked,
              apacChecked: this.state.apacChecked,
              anzChecked: false,
              checkAll18: false,
            },
            function () {
              Swal.fire("APAC Countries Selected");
            }
          );
        }
      });
    }
  };

  onCheckAllChangeCentralAsia = (e) => {
    if (
      e.target.checked == true ||
      this.state.checkAll11 == true ||
      this.state.checkAll12 == true ||
      this.state.checkAll13 == true
    ) {
      this.setState({ ApacDisabled: true, ANZdisabled: true });
    } else {
      this.setState({ ApacDisabled: false, ANZdisabled: false });
    }
    var region = "Central Asia";
    if (!this.state.region.includes("Central Asia")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
        this.disperse(false, true, true, true); //2864-raunak when some other sub-region is selected and already apac is selected so to select other selected sub-region
      }
    }
    if (e.target.checked == false) {
      this.imperse(); //2950-raunak when apac is internally checked but check all is also checked
      var index = this.state.region.indexOf("Central Asia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
    this.setState(
      {
        CentralAsiacheckedList: e.target.checked ? CentralAsiaOptions : [],
        indeterminateAsia1: false,
        checkAll10: e.target.checked,
      },
      function () {
        this.onCheckAsiaSelectAll(e);
        if (
          e.target.checked == false &&
          this.state.apacChecked == true &&
          this.state.checkAll26 == true
        ) {
          var countryArray3 = [];
          if (this.state.centralAsiaChecked) {
            countryArray3 = CentralAsiaOptions;
          } else {
            countryArray3 = APACOptions;
          }
          //SoutheastAsiacheckedList:countryArray1,
          this.setState(
            {
              CentralAsiacheckedList: countryArray3,
              indeterminate: false,
              checkAll26: this.state.apacChecked,
              apacChecked: this.state.apacChecked,
              anzChecked: false,
              checkAll18: false,
              ANZdisabled: true,
            },
            function () {}
          );
        }
      }
    );
  };

  ///////////////////////////////////////////////////////////////////////////////////////
  onChangeEastAsia = (EastAsiacheckedList) => {
    var region = "East Asia";
    if (EastAsiacheckedList.length > 0) {
      if (!this.state.region.includes("East Asia")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        EastAsiacheckedList,
        indeterminateAsia2:
          !!EastAsiacheckedList.length &&
          EastAsiacheckedList.length < EastAsiaOptions.length,
        checkAll11: EastAsiacheckedList.length === EastAsiaOptions.length,
      },
      function () {
        this.onCheckAsiaSelectAll();
        this.disperse(true, false, true, true); //2950 to disselect apac and check the subregion
        if (this.state.apacChecked === true) {
          this.setState({
            apacChecked: false,
            checkAll26: false,
            indeterminateAsia2: true,
          });
        }
      }
    );
    if (EastAsiacheckedList.length == 0) {
      var index = this.state.region.indexOf("East Asia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeEastAsia = (e) => {
    if (
      e.target.checked == true ||
      this.state.checkAll10 == true ||
      this.state.checkAll12 == true ||
      this.state.checkAll13 == true
    ) {
      this.setState({ ApacDisabled: true, ANZdisabled: true });
    } else {
      this.setState({ ApacDisabled: false, ANZdisabled: false });
    }
    var region = "East Asia";
    if (!this.state.region.includes("East Asia")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
        this.disperse(true, false, true, true); //2864-raunak when some other sub-region is selected and already apac is selected so to select other selected sub-region
      }
    }
    if (e.target.checked == false) {
      this.imperse(); //2950-raunak when apac is internally checked but check all is also checked
      var index = this.state.region.indexOf("East Asia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        EastAsiacheckedList: e.target.checked ? EastAsiaOptions : [],
        indeterminateAsia2: false,
        checkAll11: e.target.checked,
      },
      function () {
        this.onCheckAsiaSelectAll(e);
        if (
          e.target.checked == false &&
          this.state.apacChecked == true &&
          this.state.checkAll26 == true
        ) {
          var countryArray2 = [];
          if (this.state.eastAsiaChecked) {
            countryArray2 = EastAsiaOptions;
          } else {
            countryArray2 = APACOptions;
          }
          //SoutheastAsiacheckedList:countryArray1,
          this.setState(
            {
              EastAsiacheckedList: countryArray2,
              indeterminate: false,
              checkAll26: this.state.apacChecked,
              apacChecked: this.state.apacChecked,
              anzChecked: false,
              checkAll18: false,
              ANZdisabled: true,
            },
            function () {}
          );
        }
      }
    );
  };
  ////////////////////////////////////////////////////////////////////////////////

  onChangeSouthAsia = (SouthAsiacheckedList) => {
    if (this.state.anzChecked == true) {
      if (SouthAsiacheckedList.length < APACOptions.length) {
        var ind = this.state.region.indexOf("anz");
        if (ind > -1) {
          this.state.region.splice(ind, 1);
        }
        this.setState({ anzChecked: false });
      }
    }
    if (this.state.apacChecked == true) {
      if (SouthAsiacheckedList.length < APACOptions.length) {
        var ind = this.state.region.indexOf("apac");
        if (ind > -1) {
          this.state.region.splice(ind, 1);
        }
        this.setState({ apacChecked: false });
      }
    }
    var region = "South Asia";
    if (SouthAsiacheckedList.length > 0) {
      if (!this.state.region.includes("South Asia")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        SouthAsiacheckedList,
        indeterminateAsia3:
          !!SouthAsiacheckedList.length &&
          SouthAsiacheckedList.length < SouthAsiaOptions.length,
        checkAll12: SouthAsiacheckedList.length === SouthAsiaOptions.length,
        ANZdisabled: SouthAsiacheckedList.length === SouthAsiaOptions.length,
        ApacDisabled: SouthAsiacheckedList.length === SouthAsiaOptions.length,
      },
      function () {
        this.onCheckAsiaSelectAll();
        this.disperse(true, true, false, true); //2950 to disselect apac and check the subregion
        if (this.state.apacChecked === true) {
          this.setState({
            apacChecked: false,
            checkAll26: false,
            indeterminateAsia3: true,
          });
        }
        if (this.state.region.includes("anz")) {
          this.state.region.splice(this.state.region.indexOf("anz"), 1);
        }
        ANZOptions.map(
          function (ele) {
            if (!SouthAsiacheckedList.includes(ele)) {
              this.setState({ checkAll18: false, anzChecked: false });
            }
          }.bind(this)
        );
      }
    );
    if (SouthAsiacheckedList.length == 0) {
      var index = this.state.region.indexOf("South Asia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeSouthAsia = (e) => {
    var region = "South Asia";
    if (
      e.target.checked == true ||
      this.state.checkAll10 == true ||
      this.state.checkAll11 == true ||
      this.state.checkAll13 == true
    ) {
      this.setState({ ApacDisabled: true, ANZdisabled: true });
    } else {
      this.setState({ ApacDisabled: false, ANZdisabled: false });
    }
    if (!this.state.region.includes("South Asia")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
        this.disperse(true, true, false, true); //2864-raunak when some other sub-region is selected and already apac is selected so to select other selected sub-region
      }
    }
    if (e.target.checked == false) {
      this.imperse(); //2950-raunak when apac is internally checked but check all is also checked
      var index = this.state.region.indexOf("South Asia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    if (e.target.checked) {
      this.setState(
        {
          SouthAsiacheckedList: e.target.checked ? SouthAsiaOptions : [],
          indeterminateAsia3: false,
          checkAll12: e.target.checked,
          southAsiaChecked: !this.state.southAsiaChecked,
        },
        function () {
          this.onCheckAsiaSelectAll(e);
        }
      );
    } else {
      this.setState(
        {
          SouthAsiacheckedList:
            e.target.checked === false
              ? this.state.anzChecked
                ? ANZOptions
                : []
              : [],

          indeterminateAsia3: false,
          checkAll12: e.target.checked,
          southAsiaChecked: !this.state.southAsiaChecked,
        },
        function () {
          this.onCheckAsiaSelectAll(e);
          if (
            e.target.checked == false &&
            this.state.apacChecked == true &&
            this.state.checkAll26 == true
          ) {
            var countryArray = [];
            if (this.state.southAsiaChecked) {
              countryArray = SouthAsiaOptions;
            } else {
              countryArray = APACOptions;
            }
            //SoutheastAsiacheckedList:countryArray1,
            this.setState(
              {
                SouthAsiacheckedList: countryArray,
                indeterminate: false,
                checkAll26: this.state.apacChecked,
                apacChecked: this.state.apacChecked,
                anzChecked: false,
                checkAll18: false,
                ANZdisabled: true,
              },
              function () {}
            );
          }
        }
      );
    }

    // this.setState({
    //     SouthAsiacheckedList: e.target.checked ?SouthAsiaOptions : [],
    //   indeterminate: false,
    //   checkAll12: e.target.checked
    // });
  };

  ////////////////////////////////////////////////////////////////////////////////
  onChangeSoutheastAsia = (SoutheastAsiacheckedList) => {
    var region = "Southeast Asia";
    if (SoutheastAsiacheckedList.length > 0) {
      if (!this.state.region.includes("Southeast Asia")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        SoutheastAsiacheckedList,
        indeterminateAsia4:
          !!SoutheastAsiacheckedList.length &&
          SoutheastAsiacheckedList.length < SoutheastAsiaOptions.length,
        checkAll13:
          SoutheastAsiacheckedList.length === SoutheastAsiaOptions.length,
      },
      function () {
        this.onCheckAsiaSelectAll();
        this.disperse(true, true, true, false); //2950 to disselect apac and check the subregion
        if (this.state.apacChecked === true) {
          this.setState({
            apacChecked: false,
            checkAll26: false,
            indeterminateAsia4: true,
          });
        }
      }
    );
    if (SoutheastAsiacheckedList.length == 0) {
      var index = this.state.region.indexOf("Southeast Asia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeSoutheastAsia = (e) => {
    if (
      e.target.checked == true ||
      this.state.checkAll10 == true ||
      this.state.checkAll11 == true ||
      this.state.checkAll12 == true
    ) {
      this.setState({ ApacDisabled: true, ANZdisabled: true });
    } else {
      this.setState({ ApacDisabled: false, ANZdisabled: false });
    }
    var region = "Southeast Asia";
    if (!this.state.region.includes("Southeast Asia")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
        this.disperse(true, true, true, false); //2864-raunak when some other sub-region is selected and already apac is selected so to select other selected sub-region
      }
    }
    if (e.target.checked == false) {
      this.imperse(); //2950-raunak when apac is internally checked but check all is also checked
      var index = this.state.region.indexOf("Southeast Asia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        SoutheastAsiacheckedList: e.target.checked ? SoutheastAsiaOptions : [],
        indeterminateAsia4: false,
        checkAll13: e.target.checked,
      },
      function () {
        this.onCheckAsiaSelectAll(e);
        if (
          e.target.checked == false &&
          this.state.apacChecked == true &&
          this.state.checkAll26 == true
        ) {
          var countryArray1 = [];
          if (this.state.southeastAsiaChecked) {
            countryArray1 = SoutheastAsiaOptions;
          } else {
            countryArray1 = APACOptions;
          }
          //SoutheastAsiacheckedList:countryArray1,
          this.setState(
            {
              SoutheastAsiacheckedList: countryArray1,
              indeterminate: false,
              checkAll26: this.state.apacChecked,
              apacChecked: this.state.apacChecked,
              anzChecked: false,
              checkAll18: false,
              ANZdisabled: true,
            },
            function () {}
          );
        }
      }
    );
  };

  ////////////////////////////////////////////////////////////////////////////////
  onChangeWesternAsia = (WesternAsiacheckedList) => {
    var region = "Western Asia";
    if (WesternAsiacheckedList.length > 0) {
      if (!this.state.region.includes("Western Asia")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        WesternAsiacheckedList,
        indeterminateAsia5:
          !!WesternAsiacheckedList.length &&
          WesternAsiacheckedList.length < WesternAsiaOptions.length,
        checkAll14: WesternAsiacheckedList.length === WesternAsiaOptions.length,
      },
      function () {
        this.onCheckAsiaSelectAll();
      }
    );
    if (WesternAsiacheckedList.length == 0) {
      var index = this.state.region.indexOf("Western Asia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeWesternAsia = (e) => {
    var region = "Western Asia";
    if (!this.state.region.includes("Western Asia")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Western Asia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        WesternAsiacheckedList: e.target.checked ? WesternAsiaOptions : [],
        indeterminateAsia5: false,
        checkAll14: e.target.checked,
      },
      function () {
        this.onCheckAsiaSelectAll(e);
      }
    );
  };
  ////////////////////////////////////////////////////////////////////////////////////
  onChangeSouthAmerica = (SouthAmericacheckedList) => {
    var region = "South America";
    if (SouthAmericacheckedList.length > 0) {
      if (!this.state.region.includes("South America")) {
        this.state.region.push(region);
      }
    }

    this.setState(
      {
        SouthAmericacheckedList,
        indeterminateAmr1:
          !!SouthAmericacheckedList.length &&
          SouthAmericacheckedList.length < SouthAmericaOptions.length,
        checkAll15:
          SouthAmericacheckedList.length === SouthAmericaOptions.length,
      },
      function () {
        this.onCheckAmericaSelectAll();
      }
    );
    if (SouthAmericacheckedList.length == 0) {
      var index = this.state.region.indexOf("South America");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  /**
   * @author Narendra Phadke
   * @param  Description Region select all option
   */
  oncheckAllChangeRegionSelectAll = (e) => {
    //call each of region function
    this.onCheckAllChangeSouthAmerica(e);
    this.onCheckAllChangeNorthAmerica(e);
    this.onCheckAllChangeEasternEurope(e);
    this.onCheckAllChangeNorthernEurope(e);
    this.onCheckAllChangeSouthernEurope(e);
    this.onCheckAllChangeWesternEurope(e);
    this.onCheckAllChangeCentralAsia(e);
    this.onCheckAllChangeEastAsia(e);
    this.onCheckAllChangeSouthAsia(e);
    this.onCheckAllChangeSoutheastAsia(e);
    this.onCheckAllChangeWesternAsia(e);
    this.onCheckAllChangeNorthernAfricaCountries(e);
    this.onCheckAllChangeCentralOrMiddleAfrican(e);
    this.onCheckAllChangeSouthernAfricaCountries(e);
    this.onCheckAllChangeEastAfricanCountries(e);
    this.onCheckAllChangeWesternAfrica(e);
    this.onCheckAllChangeMiddleEast(e);

    this.onCheckAllChangeAntarctic(e);
    this.onCheckAllChangeMelanesia(e);
    this.onCheckAllChangePolynesia(e);
    this.onCheckAllChangeMicronesia(e);
    this.onCheckAllChangeSouthernAtlanticOcean(e);
    // this.onCheckRegionSelectAll(e);
    if (e.target.checked === true) {
      this.setState({ checkRegionSelectAll: true });
    } else {
      this.setState({
        checkRegionSelectAll: false,
        ANZdisabled: false,
        ApacDisabled: false,
      });
    }
  }; //End of region select all

  /**
   * @author Narendra Phadke
   * @param  Description  For Only All Region Selected
   */
  onCheckRegionSelectAll = (e) => {
    // if(this.state.checkAll1 === true && this.state.checkAll2===true && this.state.checkAll3===true && this.state.checkAll4===true && this.state.checkAll5===true && this.state.checkAll6===true && this.state.checkAll7===true && this.state.checkAll8===true && this.state.checkAll9===true && this.state.checkAll10===true && this.state.checkAll11===true && this.state.checkAll12===true && this.state.checkAll13===true && this.state.checkAll14===true && this.state.checkAll15===true && this.state.checkAll16===true && this.state.checkAll17===true && this.state.checkAll18===true && this.state.checkAll19===true && this.state.checkAll20===true && this.state.checkAll21===true && this.state.checkAll22===true){

    if (
      this.state.checkAmericaSelectAll === true &&
      this.state.checkAsiaSelectAll === true &&
      this.state.checkEuropeSelectAll === true &&
      this.state.checkAfricaSelectAll === true &&
      this.state.checkAll16 === true &&
      this.state.checkAll19 === true &&
      this.state.checkAll20 === true &&
      this.state.checkAll21 === true &&
      this.state.checkAll22 === true &&
      this.state.checkAll27 === true
    ) {
      this.setState({ checkRegionSelectAll: true });
    } else {
      this.setState({ checkRegionSelectAll: false });
    }
  }; //End OF Select all Handle change of region

  /**
   * @author Narendra Phadke
   * @param  Description  For Select All of America Region
   */
  onCheckAmericaSelectAll = (e) => {
    if (this.state.checkAll15 === true && this.state.checkAll17 === true) {
      this.setState({ checkAmericaSelectAll: true }, function () {
        this.onCheckRegionSelectAll(e);
      });
    } else {
      this.setState({ checkAmericaSelectAll: false }, function () {
        this.onCheckRegionSelectAll(e);
      });
    }
  }; //End OF Select all Handle change of region

  /**
   * @author Narendra Phadke
   * @param  Description  America Region for select all
   */
  onCheckAllChangeAmericaRegion = (e) => {
    //call each of region function
    this.onCheckAllChangeSouthAmerica(e);
    this.onCheckAllChangeNorthAmerica(e);
  };

  onCheckAllChangeSouthAmerica = (e) => {
    var region = "South America";
    if (!this.state.region.includes("South America")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("South America");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        SouthAmericacheckedList: e.target.checked ? SouthAmericaOptions : [],
        indeterminateAmr1: false,
        checkAll15: e.target.checked,
      },
      function () {
        this.onCheckAmericaSelectAll(e);
      }
    );
  };
  ////////////////////////////////////////////////////////////////////////////////////
  onChangeAntarctic = (AntarcticcheckedList) => {
    var region = "Antarctic";
    if (AntarcticcheckedList.length > 0) {
      if (!this.state.region.includes("Antarctic")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        AntarcticcheckedList,
        indeterminateAnt1:
          !!AntarcticcheckedList.length &&
          AntarcticcheckedList.length < AntarcticOptions.length,
        checkAll16: AntarcticcheckedList.length === AntarcticOptions.length,
      },
      function () {
        this.onCheckRegionSelectAll();
      }
    );
    if (AntarcticcheckedList.length == 0) {
      var index = this.state.region.indexOf("Antarctic");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeAntarctic = (e) => {
    var region = "Antarctic";
    if (!this.state.region.includes("Antarctic")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Antarctic");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        AntarcticcheckedList: e.target.checked ? AntarcticOptions : [],
        indeterminateAnt1: false,
        checkAll16: e.target.checked,
      },
      function () {
        this.onCheckRegionSelectAll(e);
      }
    );
  };
  ////////////////////////////////////////////////////////////////////////////////////
  onChangeNorthAmerica = (NorthAmericacheckedList) => {
    var region = "North America";
    if (NorthAmericacheckedList.length > 0) {
      if (!this.state.region.includes("North America")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        NorthAmericacheckedList,
        indeterminateAmr2:
          !!NorthAmericacheckedList.length &&
          NorthAmericacheckedList.length < NorthAmericaOptions.length,
        checkAll17:
          NorthAmericacheckedList.length === NorthAmericaOptions.length,
      },
      function () {
        this.onCheckAmericaSelectAll();
      }
    );
    if (NorthAmericacheckedList.length == 0) {
      var index = this.state.region.indexOf("North America");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeNorthAmerica = (e) => {
    var region = "North America";
    if (!this.state.region.includes("North America")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("North America");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        NorthAmericacheckedList: e.target.checked ? NorthAmericaOptions : [],
        indeterminateAmr2: false,
        checkAll17: e.target.checked,
      },
      function () {
        this.onCheckAmericaSelectAll(e);
      }
    );
  };

  ////////////////////////////////////////////////////////////////////////////////////
  onChangeMelanesia = (MelanesiacheckedList) => {
    var region = "Melanesia";
    if (MelanesiacheckedList.length > 0) {
      if (!this.state.region.includes("Melanesia")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        MelanesiacheckedList,
        indeterminateMel:
          !!MelanesiacheckedList.length &&
          MelanesiacheckedList.length < MelanesiaOptions.length,
        checkAll19: MelanesiacheckedList.length === MelanesiaOptions.length,
      },
      function () {
        this.onCheckRegionSelectAll();
      }
    );
    if (MelanesiacheckedList.length == 0) {
      var index = this.state.region.indexOf("Melanesia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeMelanesia = (e) => {
    var region = "Melanesia";
    if (!this.state.region.includes("Melanesia")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Melanesia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        MelanesiacheckedList: e.target.checked ? MelanesiaOptions : [],
        indeterminateMel: false,
        checkAll19: e.target.checked,
      },
      function () {
        this.onCheckRegionSelectAll(e);
      }
    );
  };
  //////////////////////////////////////////////////////////////////////////////////////
  onChangePolynesia = (PolynesiacheckedList) => {
    var region = "Polynesia";
    if (PolynesiacheckedList.length > 0) {
      if (!this.state.region.includes("Polynesia")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        PolynesiacheckedList,
        indeterminatePoly1:
          !!PolynesiacheckedList.length &&
          PolynesiacheckedList.length < PolynesiaOptions.length,
        checkAll20: PolynesiacheckedList.length === PolynesiaOptions.length,
      },
      function () {
        this.onCheckRegionSelectAll();
      }
    );
    if (PolynesiacheckedList.length == 0) {
      var index = this.state.region.indexOf("Polynesia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangePolynesia = (e) => {
    var region = "Polynesia";
    if (!this.state.region.includes("Polynesia")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Polynesia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        PolynesiacheckedList: e.target.checked ? PolynesiaOptions : [],
        indeterminatePoly1: false,
        checkAll20: e.target.checked,
      },
      function () {
        this.onCheckRegionSelectAll(e);
      }
    );
  };
  //////////////////////////////////////////////////////////////////////////////////////

  onChangeMiddleEast = (middleEastCheckedList) => {
    var region = "Middle East";
    if (middleEastCheckedList.length > 0) {
      if (!this.state.region.includes("Middle East")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        middleEastCheckedList,
        indeterminateMeast:
          !!middleEastCheckedList.length &&
          middleEastCheckedList.length < MiddleEastOptions.length,
        checkAll27: middleEastCheckedList.length === MiddleEastOptions.length,
      },
      function () {
        this.onCheckRegionSelectAll();
      }
    );
    if (middleEastCheckedList.length == 0) {
      var index = this.state.region.indexOf("Middle East");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeMiddleEast = (e) => {
    var region = "Middle East";
    if (!this.state.region.includes("Middle East")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Middle East");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        middleEastCheckedList: e.target.checked ? MiddleEastOptions : [],
        indeterminateMeast: false,
        checkAll27: e.target.checked,
      },
      function () {
        this.onCheckRegionSelectAll(e);
      }
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////

  onChangeMicronesia = (MicronesiacheckedList) => {
    var region = "Micronesia";
    if (MicronesiacheckedList.length > 0) {
      if (!this.state.region.includes("Micronesia")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        MicronesiacheckedList,
        indeterminateMic1:
          !!MicronesiacheckedList.length &&
          MicronesiacheckedList.length < MicronesiaOptions.length,
        checkAll21: MicronesiacheckedList.length === MicronesiaOptions.length,
      },
      function () {
        this.onCheckRegionSelectAll();
      }
    );
    if (MicronesiacheckedList.length == 0) {
      var index = this.state.region.indexOf("Micronesia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeMicronesia = (e) => {
    var region = "Micronesia";
    if (!this.state.region.includes("Micronesia")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Micronesia");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        MicronesiacheckedList: e.target.checked ? MicronesiaOptions : [],
        indeterminateMic1: false,
        checkAll21: e.target.checked,
      },
      function () {
        this.onCheckRegionSelectAll(e);
      }
    );
  };
  ////////////////////////////////////////////////////////////////////////////////////////
  onChangeSouthernAtlanticOcean = (SouthernAtlanticOceancheckedList) => {
    var region = "Southern Atlantic Ocean";
    if (SouthernAtlanticOceancheckedList.length > 0) {
      if (!this.state.region.includes("Southern Atlantic Ocean")) {
        this.state.region.push(region);
      }
    }
    this.setState(
      {
        SouthernAtlanticOceancheckedList,
        indeterminateSAtlan:
          !!SouthernAtlanticOceancheckedList.length &&
          SouthernAtlanticOceancheckedList.length <
            SouthernAtlanticOceanOptions.length,
        checkAll22:
          SouthernAtlanticOceancheckedList.length ===
          SouthernAtlanticOceanOptions.length,
      },
      function () {
        this.onCheckRegionSelectAll();
      }
    );
    if (SouthernAtlanticOceancheckedList.length == 0) {
      var index = this.state.region.indexOf("Southern Atlantic Ocean");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }
  };

  onCheckAllChangeSouthernAtlanticOcean = (e) => {
    var region = "Southern Atlantic Ocean";
    if (!this.state.region.includes("Southern Atlantic Ocean")) {
      if (e.target.checked == true) {
        this.state.region.push(region);
      }
    }
    if (e.target.checked == false) {
      var index = this.state.region.indexOf("Southern Atlantic Ocean");
      if (index > -1) {
        this.state.region.splice(index, 1);
      }
    }

    this.setState(
      {
        SouthernAtlanticOceancheckedList: e.target.checked
          ? SouthernAtlanticOceanOptions
          : [],
        indeterminateSAtlan: false,
        checkAll22: e.target.checked,
      },
      function () {
        this.onCheckRegionSelectAll(e);
      }
    );
  };
  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////
  // Chaitanya-4906-Changed industryHandleChange condition for non-english checked

  industryHandleChange = (value) => {
    if (this.state.nonEnglishChecked === "No") {
      var industryArray = [];
      const name = "industry";
      for (var j = 0; j < value.length; j++) {
        industryArray.push(value[j].name);
      }
      this.setState({
        industry: value,
        industryDB: industryArray,
        industryDisplay: "none",
        inputClassIndustry: "validate",
      });
    } else {
    }
  };
  ////////////////////////////////////////////////////////////////////
  employeeHandleChange = (value) => {
    var employeeSizeArray = [];
    const name = "employeeSize";
    for (var j = 0; j < value.length; j++) {
      employeeSizeArray.push(value[j].name);
    }

    this.setState({
      employeeSize: value,
      employeeSizeDB: employeeSizeArray,
      employeeSizeDisplay: "none",
      customEmployeeSizeTextDisplay: "none",
      inputClassDropDownEmployeeSize: "validate",
    });
  };
  ///////////////////////////////////////////////////////////////////

  /////////////////////////////////
  // Chaitanya-4906-Changed revenuehandleChange condition for non-english checked
  //Rutuja -4930 as requirment changed for company reveneue removing 4906
  revenuehandleChange = (value) => {
    // if (this.state.nonEnglishChecked === "No") {
    var companyRevenueArray = [];
    const name = "companyRevenue";
    for (var j = 0; j < value.length; j++) {
      companyRevenueArray.push(value[j].name);
    }

    this.setState({
      companyRevenue: value,
      companyRevenueDB: companyRevenueArray,
      companyRevenueDisplay: "none",
      customCompanyRevenueTextDisplay: "none",
      inputClassDropDowncompanyRevenue: "validate",
    });
    // } else { //4930
    // } //4930
  };
  //////////////////////////////////////////////////////////////////
  // Chaitanya-4906-Changed jobLevelHandleChange condition for non-english checked
  jobLevelHandleChange = (value) => {
    if (this.state.nonEnglishChecked === "No") {
      var jobLevelArray = [];
      const name = "jobLevel";
      for (var j = 0; j < value.length; j++) {
        jobLevelArray.push(value[j].name);
      }

      this.setState({ jobLevel: value, jobLevelDB: jobLevelArray });
    } else {
    }
  };
  ///////////////////////////////////
  // Chaitanya-4906-Changed jobFunctionHandleChange condition for non-english checked
  jobFunctionHandleChange = (value) => {
    if (this.state.nonEnglishChecked === "No") {
      var jobFunctionArray = [];
      const name = "jobFunction";
      for (var j = 0; j < value.length; j++) {
        jobFunctionArray.push(value[j].name);
      }

      this.setState({ jobFunction: value, jobFunctionDB: jobFunctionArray });
    } else {
    }
  };

  ////////////////////////////////////////////////
  //snehal-task-2798-UI/UX-Changes-3.1
  customEmplyeeSizeTextChange(e) {
    const { name, value } = e.target;
    document.getElementById("customEmployeeSizeText").style.border =
      "1px solid lightgray"; // {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(text area red added code) */}
    this.setState({
      customEmployeeSizeText: value,
      inputClassDropDownEmployeeSize: "validate",
      customEmployeeSizeTextDisplay: "none",
      employeeSizeDisplay: "none",
    });
  }
  ////////////////////////////////////////////
  //snehal-task-2798-UI/UX-Changes-3.1
  customCompanyRevenueTextChange(e) {
    const { name, value } = e.target;
    document.getElementById("companyRevenueText").style.border =
      "1px solid lightgray"; // {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(text area red added code) */}
    this.setState({
      customCompanyRevenueText: value,
      inputClassDropDowncompanyRevenue: "validate",
      customCompanyRevenueTextDisplay: "none",
      companyRevenueDisplay: "none",
    });
  }
  ////////////////////////////////
  //snehal-task-2798-UI/UX-Changes-3.1
  customIndustryTextChange(e) {
    const { name, value } = e.target;
    //let customIndustryTextTemp = [...this.state.customIndustryText];
    //let customIndustryTextTemp = [...this.state.customIndustryText];
    // customIndustryTextTemp[i] = { ...customIndustryTextTemp[i], [name]: value };
    document.getElementById("customIndustryText").style.border =
      "1px solid lightgray"; // {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(text area red added code) */}
    this.setState({
      customIndustryText: value,
      inputClassIndustry: "validate",
      customIndustryTextDisplay: "none",
      industryDisplay: "none",
    });

    // this.setState({ customIndustryText: value,inputClassIndustry: "validate",customIndustryTextDisplay:"none",industryDisplay:"none"})

    //    const{name,value,id}=e.target;
    //  let customIndustryTextTemp = [...this.state.customIndustryText];
    //  customIndustryTextTemp[i] = { ...customIndustryTextTemp[i], [name]: value };

    //   this.setState({ customIndustryText: customIndustryTextTemp,inputClassIndustry: "validate",customIndustryTextDisplay:"none",industryDisplay:"none"},function(){})
  }
  /////////////////////////////
  //snehal-task-2798-UI/UX-Changes-3.1
  customJobLevelTextChange(e) {
    const { name, value } = e.target;
    document.getElementById("jobLevelText").style.border =
      "1px solid lightgray"; // {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(text area red added code) */}
    this.setState({ customJobLevelText: value, customJobLevelDisplay: "none" });
  }
  ////////////////////////////////////
  //snehal-task-2798-UI/UX-Changes-3.1
  customJobFunctionTextChange(e) {
    const { name, value } = e.target;
    document.getElementById("jobFunctionText").style.border =
      "1px solid lightgray"; // {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(text area red added code) */}
    this.setState({
      customJobFunctionText: value,
      customJobFunctionDisplay: "none",
    });
  }
  ////////////////////////////////////
  //snehal-task-3112-Custom Question Alias
  handleQuestionAlias(e) {
    const { name, value } = e.target;
    if (name == "customQuestionAliasName") {
      this.state.customQuestionAliasName == "No"
        ? this.setState({
            customQuestionAliasName: "Yes",
            customeFlag: true,
            customQuestionDisplay1: "none",
            customQuestionDisplay2: "none",
            customQuestionDisplay3: "none",
            customQuestionDisplay4: "none",
            checkedQue: true,
            customQuestionDisplay5: "none", //kiran-4873-added for not showing error on checkbox check/uncheck
          })
        : this.setState({
            customQuestionAliasName: "No",
            customeFlag: true,
            customQuestionDisplay1: "none",
            customQuestionDisplay2: "none",
            customQuestionDisplay3: "none",
            customQuestionDisplay4: "none",
            checkedQue: false,
            customQuestionDisplay5: "none", //kiran-4873-added for not showing error on checkbox check/uncheck
          }); //snehal-task-3165-Create campaign--Custom question alias checkbox issue
      //snehal-task-3167-Edit campaign--Alias issue
    }
  }
  //////////////////
  commonhandleChange(e) {
    const { name, value } = e.target;
    if (name == "jobChk") {
      this.state.jobTitlechk == "No"
        ? this.setState({ jobTitlechk: "Yes" })
        : this.setState({ jobTitlechk: "No" });
    }
    if (name == "campaignName") {
      document.getElementById("campaignName").style.border =
        "1px solid lightgray";
      this.setState({ cnameDisplay: "none", cnameDisplay2: "none" });
      let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
      BasicDetailArrayEdit[0] = {
        ...BasicDetailArrayEdit[0],
        ["campaignName"]: value,
      };
      this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
      this.setState({ BasicDetailArrayEdit: BasicDetailArrayEdit });
    }
    if (name == "clientName") {
      document.getElementById("client").style.border = "1px solid lightgray";
      this.setState({ clientDisplay: "none" });
      let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
      BasicDetailArrayEdit[0] = {
        ...BasicDetailArrayEdit[0],
        ["clientName"]: value,
      };
      this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
      this.setState({ BasicDetailArrayEdit: BasicDetailArrayEdit });
    }

    if (name == "clientCampID") {
      let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
      BasicDetailArrayEdit[0] = {
        ...BasicDetailArrayEdit[0],
        ["clientCampID"]: value,
      };
      this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
      this.setState({ BasicDetailArrayEdit: BasicDetailArrayEdit });
    }
    if (name == "campaignStatus") {
      let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
      BasicDetailArrayEdit[0] = {
        ...BasicDetailArrayEdit[0],
        ["campaignStatus"]: value,
      };
      this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
      this.setState({ BasicDetailArrayEdit: BasicDetailArrayEdit });
    }
    if (name == "startDate") {
      document.getElementById("startDate").style.border = "1px solid lightgray";
      this.setState({
        startDateDisplay: "none",
        startDateDisplay2: "none",
        startDateDisplayRfp: "none",
        endDateDisplay3: "none",
        firstLeadDeliveryDateDisplay4: "none",
      });
      document.getElementById("endDate").style.border = "1px solid lightgray";
      document.getElementById("firstLeadDeliveryDate").style.border =
        "1px solid lightgray";
      let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
      BasicDetailArrayEdit[0] = {
        ...BasicDetailArrayEdit[0],
        ["startDate"]: value,
      };
      this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
      this.setState({ BasicDetailArrayEdit: BasicDetailArrayEdit });
    }
    if (name == "endDate") {
      document.getElementById("endDate").style.border = "1px solid lightgray";
      this.setState({
        endDateDisplay: "none",
        endDateDisplay2: "none",
        endDateDisplay3: "none",
        firstLeadDeliveryDateDisplay4: "none",
        firstLeadDeliveryDateDisplay2: "none",
      });
      document.getElementById("firstLeadDeliveryDate").style.border =
        "1px solid lightgray";
      let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
      BasicDetailArrayEdit[0] = {
        ...BasicDetailArrayEdit[0],
        ["endDate"]: value,
      };
      this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
      this.setState({ BasicDetailArrayEdit: BasicDetailArrayEdit });
    }
    if (name == "firstLeadDeliveryDate") {
      document.getElementById("firstLeadDeliveryDate").style.border =
        "1px solid lightgray";
      this.setState({
        firstLeadDeliveryDateDisplay: "none",
        firstLeadDeliveryDateDisplay2: "none",
        firstLeadDeliveryDateDisplay3: "none",
        firstLeadDeliveryDateDisplay4: "none",
      });
      let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
      BasicDetailArrayEdit[0] = {
        ...BasicDetailArrayEdit[0],
        ["firstLeadDeliveryDate"]: value,
      };
      this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
      this.setState({ BasicDetailArrayEdit: BasicDetailArrayEdit });
    }
    if (name === "noOfLeadPerDomain") {
      document.getElementById("noOfLeadPerDomain").style.border =
        "1px solid lightgray";
      this.setState({ noOfLeadPerDomainDisplay: "none" });
      let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
      BasicDetailArrayEdit[0] = {
        ...BasicDetailArrayEdit[0],
        ["noOfLeadPerDomain"]: value,
      };
      this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
      this.setState({ BasicDetailArrayEdit: BasicDetailArrayEdit });
    }
    if (name == "leadAllocation") {
      document.getElementById("leadAllocation").style.border =
        "1px solid lightgray";
      this.setState({
        leadAllocationDisplay: "none",
        leadAllocationDisplay2: "none",
        leadAllocationDisplay3: "none",
        cBudgetDisplay: "none",
        cBudgetDisplay2: "none",
      });
      document.getElementById("budget").style.border = "1px solid lightgray";
      let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
      BasicDetailArrayEdit[0] = {
        ...BasicDetailArrayEdit[0],
        ["leadAllocation"]: value,
        ["budget"]: newbudget,
      };
      this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
      this.setState({ BasicDetailArrayEdit: BasicDetailArrayEdit });
      if (this.state.BasicDetailArrayEdit[0].CPL !== "") {
        var leadAllocation = this.state.BasicDetailArrayEdit[0].leadAllocation;
        var CPL = this.state.BasicDetailArrayEdit[0].CPL;

        if (leadAllocation === 0 || leadAllocation == "") {
          newbudget = 0;
        } else {
          var newbudget = value * CPL;
          newbudget = parseFloat(newbudget);
          if (isNaN(newbudget)) {
            newbudget = 0;
          } else {
            newbudget = newbudget.toFixed(2); // snehal-task-2900-Single & multiple xlsx camp---camp budget validationissue.
          }
        }

        let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
        BasicDetailArrayEdit[0] = {
          ...BasicDetailArrayEdit[0],
          ["budget"]: newbudget,
        };
        this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
        this.setState({ BasicDetailArrayEdit: BasicDetailArrayEdit });
      }
    }
    if (name == "CPL") {
      document.getElementById("CPL").style.border = "1px solid lightgray";
      this.setState({
        cplDisplay: "none",
        cplDisplay2: "none",
        cplDisplay3: "none",
        cBudgetDisplay: "none",
        cBudgetDisplay2: "none",
      });
      document.getElementById("budget").style.border = "1px solid lightgray";

      let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
      BasicDetailArrayEdit[0] = {
        ...BasicDetailArrayEdit[0],
        ["CPL"]: value,
        ["budget"]: newbudget,
      };
      this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
      this.setState({ BasicDetailArrayEdit: BasicDetailArrayEdit });

      var enteredLeadAllocation =
        this.state.BasicDetailArrayEdit[0].leadAllocation;
      var newbudget = value * this.state.BasicDetailArrayEdit[0].leadAllocation;
      newbudget = newbudget.toFixed(2);

      if (enteredLeadAllocation == 0 || enteredLeadAllocation == "") {
        newbudget = 0;
      } else {
        let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
        BasicDetailArrayEdit[0] = {
          ...BasicDetailArrayEdit[0],
          ["CPL"]: value,
          ["budget"]: newbudget,
        };
        this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
        this.setState(
          { BasicDetailArrayEdit: BasicDetailArrayEdit },
          function () {}
        );
      }
    }
    if (name == "budget") {
      document.getElementById("CPL").style.border = "1px solid lightgray";
      this.setState({
        cplDisplay: "none",
        cplDisplay2: "none",
        cBudgetDisplay: "none",
        cBudgetDisplay2: "none",
      });
      document.getElementById("budget").style.border = "1px solid lightgray";
      var newcpl;
      var leadAllocation = this.state.BasicDetailArrayEdit[0].leadAllocation;

      if (leadAllocation == 0 || leadAllocation == "") {
        newcpl = 0;
      } else {
        newcpl = value / this.state.BasicDetailArrayEdit[0].leadAllocation;
        newcpl = newcpl.toFixed(2);
      }

      let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
      BasicDetailArrayEdit[0] = {
        ...BasicDetailArrayEdit[0],
        ["budget"]: value,
        ["CPL"]: newcpl,
      };
      this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
      this.setState({ BasicDetailArrayEdit: BasicDetailArrayEdit });
    }
    if (name == "currency") {
      document.getElementById("currency").style.border = "1px solid lightgray";
      this.setState({ cCurrencyDisplay: "none" });
      let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
      BasicDetailArrayEdit[0] = {
        ...BasicDetailArrayEdit[0],
        ["currency"]: value,
      };
      this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
      this.setState({ BasicDetailArrayEdit: BasicDetailArrayEdit });
    }

    if (name == "jobTitle") {
      document.getElementById("jobTitle").style.border = "1px solid lightgray";
      this.setState({ jobTitleDisplay: "none", jobTitleDisplay2: "none" });
      let fields = this.state;
      fields[e.target.name] = e.target.value;
      this.setState({ fields }, function () {});
    }

    if (name == "otherSpecifications") {
      let fields = this.state;
      fields[e.target.name] = e.target.value;
      this.setState({ fields }, function () {});
    }
    // if(name=="industryNA"){
    //     this.state.IndustryNotApplicable=="Applicable"?this.setState({IndustryNotApplicable:'Not_Applicable',drpdisabled1:'drpdisable'}):this.setState({IndustryNotApplicable:'Applicable',drpdisabled1:''})
    // }
    if (name == "industryNA") {
      // {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(text area red added code) */}
      document.getElementById("customIndustryText").style.border =
        "1px solid lightgray"; // {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(text area red added code) */}
      this.state.IndustryNotApplicable == "Applicable"
        ? this.setState(
            {
              IndustryNotApplicable: "Not_Applicable",
              drpdisabled1: "drpdisable",
              industryDisplay: "none",
              customIndustryTextDisplay: "none",
              inputClassIndustry: "validate",
              excludedIndustryFlag: "No",
            },
            function () {
              document.getElementById("excludedIndustryList").innerHTML = "";
              document.getElementById("excludedIndustry").checked = false;
              if (this.state.displayExcludedIndustryFile == "block") {
                this.setState({
                  displayExcludedIndustryFile: "none",
                  excludedIndustryFlag: "No",
                  excludedIndustryFileDisplay: "none",
                  excludedIndustryFileValidate: false,
                  excludedIndustryDisplay: "none",
                });
              }
              //snehal-task-3846-Prod issue--exclusion Ind--check box issue.
            }
          )
        : this.setState({
            IndustryNotApplicable: "Applicable",
            drpdisabled1: "",
            industryDisplay: "none",
            customIndustryTextDisplay: "none",
            inputClassIndustry: "validate",
          });
    }
    // if(name=="employeeSizeNA"){
    //     this.state.employeeSizeNotApplicable=="Applicable"?this.setState({employeeSizeNotApplicable:'Not_Applicable',drpdisabled:'drpdisable'}):this.setState({employeeSizeNotApplicable:'Applicable',drpdisabled:''})
    // }
    if (name == "employeeSizeNA") {
      // {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(text area red added code) */}
      document.getElementById("customEmployeeSizeText").style.border =
        "1px solid lightgray"; // {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(text area red added code) */}
      this.state.employeeSizeNotApplicable == "Applicable"
        ? this.setState({
            employeeSizeNotApplicable: "Not_Applicable",
            drpdisabled: "drpdisable",
            customEmployeeSizeTextDisplay: "none",
            employeeSizeDisplay: "none",
            inputClassDropDownEmployeeSize: "validate",
          })
        : this.setState({
            employeeSizeNotApplicable: "Applicable",
            drpdisabled: "",
            customEmployeeSizeTextDisplay: "none",
            employeeSizeDisplay: "none",
            inputClassDropDownEmployeeSize: "validate",
          });
    }
  }
  //End of this.commonhandleChange
  selectCreativesMultipleOption(creativevalue) {
    this.setState(
      {
        creativeValueArray: creativevalue,
        creativesApprovalRequiredDisplay1: "none",
        inputcreativesApproval: "validate",
      },

      function () {
        let creativeValueArrayTemp = [];
        for (var p = 0; p < this.state.creativeValueArray.length; p++) {
          creativeValueArrayTemp.push(this.state.creativeValueArray[p].id);
        }
        creativeValueArrayTemp.length > 0
          ? this.setState({
              creativesHrsdisable: false,
              creativesReqDisable: false,
              displaySkipandNext: "none",
              displaySaveandNext: "block",
            })
          : this.setState({
              creativesHrsdisable: true,
              creativesReqDisable: true,
              displaySkipandNext: "block",
              displaySaveandNext: "none",
            });
        let DeliveryOptionEdit = [...this.state.DeliveryOptionEdit];

        //sunita-task-3358-added below condition for creativeapprovalrequiredfor as it's getting blank on edit side..
        if (
          DeliveryOptionEdit[0].creativeApprovalRequiredFor === "" &&
          creativeValueArrayTemp.length > 0
        ) {
          DeliveryOptionEdit[0] = {
            ...DeliveryOptionEdit[0],
            ["creativeApprovalRequiredFor"]: "Agency",
          };
        }
        DeliveryOptionEdit[0] = {
          ...DeliveryOptionEdit[0],
          ["requiredLPApproval"]: creativeValueArrayTemp,
        };
        this.setState({
          DeliveryOptionEdit: DeliveryOptionEdit,
          creatives: creativevalue,
        });
      }
    );
  }
  DeliveryOptionHandleChange(e) {
    const { name, value } = e.target;
    if (name == "pacing") {
      let DeliveryOptionEdit = [...this.state.DeliveryOptionEdit];
      DeliveryOptionEdit[0] = { ...DeliveryOptionEdit[0], ["pacing"]: value };
      this.state.DeliveryOptionEdit = [...DeliveryOptionEdit];
      this.setState(
        {
          DeliveryOptionEdit: DeliveryOptionEdit,
          displaySkipandNext: "none",
          displaySaveandNext: "block",
        },
        function () {}
      );
    }
    if (name == "campaignReportingDay") {
      let DeliveryOptionEdit = [...this.state.DeliveryOptionEdit];
      DeliveryOptionEdit[0] = {
        ...DeliveryOptionEdit[0],
        ["campaignReportingDay"]: value,
      };
      this.state.DeliveryOptionEdit = [...DeliveryOptionEdit];
      this.setState(
        {
          DeliveryOptionEdit: DeliveryOptionEdit,
          displaySkipandNext: "none",
          displaySaveandNext: "block",
        },
        function () {}
      );
    }
    if (name == "abm") {
      let DeliveryOptionEdit = [...this.state.DeliveryOptionEdit];
      DeliveryOptionEdit[0] = { ...DeliveryOptionEdit[0], ["abm"]: value };
      this.state.DeliveryOptionEdit = [...DeliveryOptionEdit];
      this.setState(
        {
          DeliveryOptionEdit: DeliveryOptionEdit,
          displaySkipandNext: "none",
          displaySaveandNext: "block",
        },
        function () {}
      );
    }
    if (name == "leadInteractionDays") {
      document.getElementById("leadInteractionDays").style.border =
        "1px solid lightgray";
      this.setState({
        leadInteractionDaysDisplay: "none",
        leadInteractionDaysDisplay2: "none",
      });
      let DeliveryOptionEdit = [...this.state.DeliveryOptionEdit];
      DeliveryOptionEdit[0] = {
        ...DeliveryOptionEdit[0],
        ["leadInteractionDays"]: value,
      };
      this.state.DeliveryOptionEdit = [...DeliveryOptionEdit];
      this.setState(
        {
          DeliveryOptionEdit: DeliveryOptionEdit,
          displaySkipandNext: "none",
          displaySaveandNext: "block",
        },
        function () {}
      );
    }
    if (name == "marketingChannel") {
      let DeliveryOptionEdit = [...this.state.DeliveryOptionEdit];
      DeliveryOptionEdit[0] = {
        ...DeliveryOptionEdit[0],
        ["marketingChannel"]: value,
      };
      this.state.DeliveryOptionEdit = [...DeliveryOptionEdit];
      this.setState(
        {
          DeliveryOptionEdit: DeliveryOptionEdit,
          displaySkipandNext: "none",
          displaySaveandNext: "block",
          creativesApprovalRequiredDisplay1: "none",
          inputcreativesApproval: "validate",
        },
        function () {
          this.state.DeliveryOptionEdit[0].marketingChannel ==
            "TeleMarketing" ||
          this.state.DeliveryOptionEdit[0].marketingChannel ==
            "Email/Telemarketing"
            ? this.setState({
                callAuditdisable: false,
                creativesApprovalRequiredDisplay1: "none",
                inputcreativesApproval: "validate",
              })
            : this.setState({
                callAuditdisable: true,
                creativesApprovalRequiredDisplay1: "none",
                inputcreativesApproval: "validate",
              }); //Snehal-task-2938 error message and border=none
        }
      );
    }
    if (name == "callAudit") {
      let DeliveryOptionEdit = [...this.state.DeliveryOptionEdit];
      DeliveryOptionEdit[0] = {
        ...DeliveryOptionEdit[0],
        ["callAudit"]: value,
      };
      this.state.DeliveryOptionEdit = [...DeliveryOptionEdit];
      this.setState(
        {
          DeliveryOptionEdit: DeliveryOptionEdit,
          displaySkipandNext: "none",
          displaySaveandNext: "block",
        },
        function () {}
      );
    }

    if (name == "creativesApprovalRequiredHrs") {
      let DeliveryOptionEdit = [...this.state.DeliveryOptionEdit];
      DeliveryOptionEdit[0] = {
        ...DeliveryOptionEdit[0],
        ["creativesApprovalRequiredHrs"]: value,
      };
      this.state.DeliveryOptionEdit = [...DeliveryOptionEdit];
      this.setState(
        {
          DeliveryOptionEdit: DeliveryOptionEdit,
          displaySkipandNext: "none",
          displaySaveandNext: "block",
        },
        function () {}
      );
    }
    if (name == "creativeApprovalRequiredFor") {
      let DeliveryOptionEdit = [...this.state.DeliveryOptionEdit];
      DeliveryOptionEdit[0] = {
        ...DeliveryOptionEdit[0],
        ["creativeApprovalRequiredFor"]: value,
      };
      this.state.DeliveryOptionEdit = [...DeliveryOptionEdit];
      this.setState(
        {
          DeliveryOptionEdit: DeliveryOptionEdit,
          displaySkipandNext: "none",
          displaySaveandNext: "block",
        },
        function () {}
      );
    }
    /**
     * User Story : 3188
     * @author : Karan Jagtap
     * @description : to manage the radio button changes for Sub-Contracting values
     */
    if (name === "subContracting") {
      this.setState({
        DeliveryOptionEdit: [
          {
            ...this.state.DeliveryOptionEdit[0],
            subContracting: e.target.value,
          },
        ],
      });
    }
  }

  customQuestionHandleChange(i, e) {
    const { name, value } = e.target;
    //snehal-task-3112-Custom Question Alias
    if (name == "aliasName") {
      let CustomQuestionsEdit = [...this.state.CustomQuestionsEdit];
      CustomQuestionsEdit[i] = {
        ...CustomQuestionsEdit[i],
        ["aliasName"]: value,
      };
      if (e.target.name === "aliasName" && e.target.value.length > 0) {
        this.setState(
          {
            CustomQuestionsEdit: CustomQuestionsEdit,
            customeFlag: true,
            displaySkipandNext: "none",
            displaySaveandNext: "block",
            customQuestionDisplay1: "none",
            customQuestionDisplay2: "none",
            customQuestionDisplay3: "none",
            customQuestionDisplay4: "none",
            customQuestionDisplay5: "none", //kiran-4873 -added for not showing error msg after click on save
          },
          function () {}
        );
      } else {
        this.setState(
          {
            CustomQuestionsEdit: CustomQuestionsEdit,
            customeFlag: true,
            displaySkipandNext: "none",
            displaySaveandNext: "block",
            customQuestionDisplay1: "none",
            customQuestionDisplay2: "none",
            customQuestionDisplay3: "none",
            customQuestionDisplay4: "none",
            customQuestionDisplay5: "none",
          },
          function () {}
        );
      }
      // if(e.target.name==="aliasName" && e.target.value.length>0 ){
      //   this.setState({ CustomQuestionsEdit:CustomQuestionsEdit,customeFlag:true,displaySkipandNext:'none',displaySaveandNext:'block',customQuestionDisplay1:'none',customQuestionDisplay2:'none',customQuestionDisplay3:'none'},function(){
      //   });
      // }
      // else{
      //   this.setState({ CustomQuestionsEdit:CustomQuestionsEdit,customeFlag:false,displaySkipandNext:'none',displaySaveandNext:'block',customQuestionDisplay1:'none',customQuestionDisplay2:'none',customQuestionDisplay3:'none'},function(){
      //   });
      // }
    }
    if (name == "question") {
      let CustomQuestionsEdit = [...this.state.CustomQuestionsEdit];
      CustomQuestionsEdit[i] = {
        ...CustomQuestionsEdit[i],
        ["customQuestion"]: value,
      };
      if (e.target.name === "question" && e.target.value.length > 0) {
        this.setState(
          {
            CustomQuestionsEdit: CustomQuestionsEdit,
            customeFlag: true,
            displaySkipandNext: "none",
            displaySaveandNext: "block",
            customQuestionDisplay1: "none",
            customQuestionDisplay2: "none",
            customQuestionDisplay3: "none",
            customQuestionDisplay4: "none",
            customQuestionDisplay5: "none",
          },
          function () {}
        );
      } else {
        this.setState(
          {
            CustomQuestionsEdit: CustomQuestionsEdit,
            customeFlag: true,
            displaySkipandNext: "none",
            displaySaveandNext: "block",
            customQuestionDisplay1: "none",
            customQuestionDisplay2: "none",
            customQuestionDisplay3: "none",
            customQuestionDisplay4: "none",
            customQuestionDisplay5: "none",
          },
          function () {}
        );
      }
      // if(e.target.name==="question" && e.target.value.length>0 ){
      //   this.setState({ CustomQuestionsEdit:CustomQuestionsEdit,customeFlag:true,displaySkipandNext:'none',displaySaveandNext:'block',customQuestionDisplay1:'none',customQuestionDisplay2:'none',customQuestionDisplay3:'none'},function(){
      //   });
      // }
      // else{
      //   this.setState({ CustomQuestionsEdit:CustomQuestionsEdit,customeFlag:false,displaySkipandNext:'none',displaySaveandNext:'block',customQuestionDisplay1:'none',customQuestionDisplay2:'none',customQuestionDisplay3:'none'},function(){
      // });
      // }
    }
    if (name == "answer") {
      let CustomQuestionsEdit = [...this.state.CustomQuestionsEdit];
      CustomQuestionsEdit[i] = { ...CustomQuestionsEdit[i], ["answer"]: value };
      this.setState(
        {
          CustomQuestionsEdit: CustomQuestionsEdit,
          displaySkipandNext: "none",
          displaySaveandNext: "block",
          customeFlag: true,
        },
        function () {}
      );
    }
    if (name == "disAllowAnswer") {
      let CustomQuestionsEdit = [...this.state.CustomQuestionsEdit];
      CustomQuestionsEdit[i] = {
        ...CustomQuestionsEdit[i],
        ["disAllowAnswer"]: value,
      };
      this.setState(
        {
          CustomQuestionsEdit: CustomQuestionsEdit,
          displaySkipandNext: "none",
          displaySaveandNext: "block",
          customeFlag: true,
        },
        function () {}
      );
    }
  }
  addCustomQuestion(e) {
    //Aman-4917-(added numericCustomQuestionIDs) to take customQuestionID from CustomQuestionsEdit and commented below code
    // var count1 =
    //   this.state.CustomQuestionsEdit.length > 0
    //     ? parseInt(
    //         this.state.CustomQuestionsEdit[
    //           this.state.CustomQuestionsEdit.length - 1
    //         ].customQuestionID
    //       )
    //     : 1;
    let numericCustomQuestionIDs = this.state.CustomQuestionsEdit.map((item) =>
      parseInt(item.customQuestionID)
    ).filter((value) => !isNaN(value));

    let maxCustomQuestionID = Math.max(...numericCustomQuestionIDs, 0); //Aman-4917-added to check is id or 0
    let count1 = maxCustomQuestionID + 1; //count1 = count1 + 1; //Aman-4917-added to add count by 1
    let customQuestion = [...this.state.CustomQuestionsEdit];
    let newCustomQuestion = {
      customQuestionID: count1.toString(),
      customQuestion: "",
      answer: "",
      disAllowAnswer: "",
    };
    customQuestion.push(newCustomQuestion);
    this.setState({ CustomQuestionsEdit: customQuestion }, function () {});
  }
  removecustomQuestion(i, e) {
    count = count - 1;
    let CustomQuestionsEdit = [...this.state.CustomQuestionsEdit];
    var fortempLength = this.state.CustomQuestionsEdit.length - 1;
    var customQuestionID = e.target.getAttribute("id");
    CustomQuestionsEdit.splice(i, 1);
    this.setState({
      CustomQuestionsEdit: CustomQuestionsEdit,
      customeFlag: true,
    });
    if (customQuestionID !== undefined || customQuestionID != "") {
      this.state.deleteCustomQuestionArray.push(customQuestionID);
    }
    //snehal-task-3167-Edit campaign--Alias issue
    if (String(fortempLength) === "0") {
      e.target.customQuestionAliasName = "No";
      this.setState({
        customQuestionAliasName: e.target.customQuestionAliasName,
        checkedQue: false,
      });
    }
    // this.setState({CustomQuestionsEdit:temp})
  }

  pacingMonthlyCalculation() {
    var d1,
      d2 = "";
    // this.state.pacingDetailsEdit=[]
    this.setState({ pacingDetailsEdit: [] }, function () {
      d1 = new Date(this.state.BasicDetailArrayEdit[0].startDate);
      d2 = new Date(this.state.BasicDetailArrayEdit[0].endDate);
      var months;
      let pacingDetailsEdit = [];
      var currentmonth,
        rowId =
          this.state.pacingDetailsEdit.length > 0
            ? this.state.pacingDetailsEdit[0].pacingID
            : 0;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth() + 1;
      months += d2.getMonth();
      months = months + 2;
      var indexpreviousMonth = d1.getMonth();
      let pacingTempObject = {};
      for (var n = 0; n < months; n++) {
        if (indexpreviousMonth > 11) {
          indexpreviousMonth = 0;
        }
        currentmonth = monthArray[indexpreviousMonth];
        pacingTempObject = {
          rowId: rowId,
          pacingMonth: currentmonth,
          pacingLeadCount: "",
          pacingPercentage: "",
          pacingEndDate: "",
          pacingCarryForward: "no",
        }; //snehal-task-2937 value change(spelling change)
        indexpreviousMonth++;
        rowId++;
        pacingDetailsEdit.push({ ...pacingTempObject });
      }
      this.setState({ pacingDetailsEdit: pacingDetailsEdit });
    });
  }
  pacingHandleChange(e) {
    const { name, value, id } = e.target;
    const { leadAllocation } = this.state.BasicDetailArrayEdit[0];
    if (name == "pacingLeadCount") {
      this.setState({ leadCountDisplay: "none" });
      this.setState({ leadCountDisplay2: "none", leadCountDisplay3: "none" });
      let newleadPercentage = (value / parseInt(leadAllocation)) * 100;
      newleadPercentage = parseInt(newleadPercentage);
      let pacingDetailsEdit = [...this.state.pacingDetailsEdit];
      pacingDetailsEdit[id] = {
        ...pacingDetailsEdit[id],
        ["pacingLeadCount"]: value,
        ["pacingPercentage"]: newleadPercentage,
      };
      this.setState({ pacingDetailsEdit: pacingDetailsEdit });
    }
    if (name == "pacingPercentage") {
      // document.getElementById(id).style.border='1px solid lightgray';
      this.setState({
        leadPercentageDisplay: "none",
        leadPercentageDisplay1: "none",
      });
      let newleadCount = (value * parseInt(leadAllocation)) / 100;
      newleadCount = parseInt(newleadCount);
      let pacingDetailsEdit = [...this.state.pacingDetailsEdit];
      pacingDetailsEdit[id] = {
        ...pacingDetailsEdit[id],
        ["pacingPercentage"]: value,
        ["pacingLeadCount"]: newleadCount,
      };
      this.state.pacingDetailsEdit = [...pacingDetailsEdit];
      this.setState({ pacingDetailsEdit: pacingDetailsEdit });
    }
    if (name == "pacingEndDate") {
      //document.getElementsByClassName("classForPacingEndDate")[id].style.border='1px solid lightgray';
      this.setState({
        pacingEndDateDisplay1: "none",
        pacingEndDateDisplay2: "none",
        pacingEndDateDisplay3: "none",
        pacingEndDateDisplay4: "none",
      });
      let pacingDetailsEdit = [...this.state.pacingDetailsEdit];
      pacingDetailsEdit[id] = {
        ...pacingDetailsEdit[id],
        ["pacingEndDate"]: value,
      };
      this.setState({ pacingDetailsEdit: pacingDetailsEdit });
    }
    if (name == "pacingCarryForward") {
      let pacingDetailsTemp = [...this.state.pacingDetailsEdit];
      pacingDetailsTemp[id] = {
        ...pacingDetailsTemp[id],
        ["pacingCarryForward"]: e.target.value,
      };
      this.setState({ pacingDetailsEdit: pacingDetailsTemp });
    }
    if (name == "pacingUnit") {
      this.setState({
        pacingUnit: value,
        leadCountDisplay: "none",
        leadCountDisplay2: "none",
        leadPercentageDisplay: "none",
        leadPercentageDisplay1: "none",
      });
    }
  }
  /**
   * @author Snehal More
   * @param  Description Task-2937 handle the Monthly pacing allowcation table
   * @return Description when user enter start and end date of same month then table shound't display
   */
  //Start of function display_pacing_table_fun
  display_pacing_table_fun() {
    var dt1 = new Date(this.state.BasicDetailArrayEdit[0].startDate);
    var dt2 = new Date(this.state.BasicDetailArrayEdit[0].endDate);
    var start_month_compare = dt1.getMonth() + 1;
    var end_month_compare = dt2.getMonth() + 1;
    var start_year_compare = dt1.getFullYear();
    var end_year_compare = dt2.getFullYear();
    if (
      start_month_compare === end_month_compare &&
      start_year_compare === end_year_compare
    ) {
      this.setState({ display_pacing_table: false });
    } else {
      this.setState({ display_pacing_table: true });
    }
  }
  //End of function display_pacing_table_fun
  /**
   * @author Snehal More
   * @param  Description Task-2937 handle the Monthly pacing allowcation table
   * @return Description when user change start and end date delete all pacing record
   */
  //Start of function deletePacingData
  deletePacingData() {
    var campID = this.state.campID;
    let data = {
      campID: campID,
    };
    fetch("campaignNew/deleteCampaignPacing", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      //mode:'no-cors',
      //body: data
      body: JSON.stringify(data),
    });
  } //End of function deletePacingData
  validateForm() {
    let formIsvalid = true;
    let editLeadAllocation = false;
    let editCPLAllocation = false;
    let basicDetailform = true;
    // let editLeadAllocation=this.state.editLeadAllocation;
    // let editCPLAllocation=this.state.editCPLAllocation;
    let errors = {};

    const {
      campaignName,
      clientName,
      startDate,
      endDate,
      firstLeadDeliveryDate,
      leadAllocation,
      CPL,
      budget,
      currency,
      noOfLeadPerDomain,
      status,
    } = this.state.BasicDetailArrayEdit[0];
    // const{totalAllocatedLead}=this.state.totalAllocatedLead;
    // const{allocatedCPL}=this.state.allocatedCPL;
    //snehal-task-3335-stc--create campaign--step1-campaign name blank value issue
    //rutuja task - 4482 added validation for campaign Name
    if (campaignName == "") {
      document.getElementById("campaignName").style.border = "1px solid red";
      this.setState({ cnameDisplay: "block" });
      formIsvalid = false;
      basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      return basicDetailform;
    }
    if (campaignName !== "") {
      //rutuja task -4490 campaign name --space issue
      var pattern1 = new RegExp(
        /^(?=.*?[a-zA-Z])[a-zA-Z0-9[{(][-0-9a-zA-Z()|!/<>+,*&%'"_=.?:;^{}[\]\\ \n]+$/
      ); // rutuja task-4488 change pattern for not accepting combination of alphabets and @
      if (!pattern1.test(campaignName)) {
        document.getElementById("campaignName").style.border = "1px solid red";
        this.setState({ cnameDisplay2: "block" });
        formIsvalid = false;
        basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
        return basicDetailform;
      }
    }
    //end Campaign name validation

    if (clientName === "") {
      document.getElementById("client").style.border = "1px solid red";
      this.setState({ clientDisplay: "block" });
      formIsvalid = false;
      basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
    }

    if (startDate === "") {
      document.getElementById("startDate").style.border = "1px solid red";
      this.setState({ startDateDisplay: "block" });
      formIsvalid = false;
      basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
    }

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var month, day, year;
    month = "" + (today.getMonth() + 1);
    day = "" + today.getDate();
    year = today.getFullYear();

    var dt = new Date(date);
    dt.setMonth(dt.getMonth() - 1);
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (month - 1 === 0) {
      month = 13;
      year = year - 1;
    } //snehal-task-3111-STC--agency side--Create campaign--Dates validation issue(when month is january then one month old is dec)
    var updatedDate = [year, month - 1, day].join("-");
    //snehal-task-3244-STC--Agency side--Campaign list--edit campaign--Start date--validation issue(added status condition)
    if (
      Date.parse(startDate) < Date.parse(updatedDate) &&
      status !== "Assign" &&
      status !== "AllocatingInProgress"
    ) {
      document.getElementById("startDate").style.border = "1px solid red";
      this.setState({ startDateDisplay2: "block" });
      formIsvalid = false;
      basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
    } else {
      this.setState({ endDateDisplay2: "none" });
      formIsvalid = true;
    }

    if (this.state.rfpCampaignID) {
      if (Date.parse(this.state.biddingDate) >= Date.parse(startDate)) {
        // errors["biddingDateValidate"]=<li>Bidding date can't be greater than start date</li>;
        document.getElementById("startDate").style.border = "1px solid red";
        this.setState({ startDateDisplayRfp: "block" });
        formIsvalid = false;
        basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
        return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      }
    }

    if (endDate === "") {
      document.getElementById("endDate").style.border = "1px solid red";
      this.setState({ endDateDisplay: "block" });
      formIsvalid = false;
      basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
    }

    // if (Date.parse(endDate) < Date.parse(date) && status!='Assign')
    // {
    //     document.getElementById('endDate').style.border='1px solid red';
    //    this.setState({endDateDisplay2:"block"});
    //     formIsvalid=false;
    // }
    // else{
    //   this.setState({endDateDisplay2:"none"});
    //     formIsvalid=true;

    // }
    //snehal-task-3244-STC--Agency side--Campaign list--edit campaign--Start date--validation issue(added status condition)
    if (
      Date.parse(startDate) > Date.parse(endDate) &&
      status != "Assign" &&
      status !== "AllocatingInProgress"
    ) {
      document.getElementById("endDate").style.border = "1px solid red";
      this.setState({ endDateDisplay3: "block" });
      formIsvalid = false;
      basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
    } else {
      this.setState({ endDateDisplay3: "none" });
      formIsvalid = true;
    }

    if (firstLeadDeliveryDate === "") {
      document.getElementById("firstLeadDeliveryDate").style.border =
        "1px solid red";
      this.setState({ firstLeadDeliveryDateDisplay: "block" });
      formIsvalid = false;
      basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
    }
    //snehal-task-3244-STC--Agency side--Campaign list--edit campaign--Start date--validation issue(added status condition)
    if (
      Date.parse(firstLeadDeliveryDate) > Date.parse(endDate) &&
      status != "Assign" &&
      status !== "AllocatingInProgress"
    ) {
      document.getElementById("firstLeadDeliveryDate").style.border =
        "1px solid red";
      this.setState({ firstLeadDeliveryDateDisplay2: "block" });
      formIsvalid = false;
      basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
    } else {
      this.setState({ firstLeadDeliveryDateDisplay2: "none" });
      formIsvalid = true;
    }
    //snehal-task-3244-STC--Agency side--Campaign list--edit campaign--Start date--validation issue(added status condition)
    if (
      Date.parse(firstLeadDeliveryDate) < Date.parse(startDate) &&
      status != "Assign" &&
      status !== "AllocatingInProgress"
    ) {
      document.getElementById("firstLeadDeliveryDate").style.border =
        "1px solid red";
      this.setState({ firstLeadDeliveryDateDisplay4: "block" });

      formIsvalid = false;
      basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
    } else {
      this.setState({ firstLeadDeliveryDateDisplay3: "none" });
      formIsvalid = true;
    }

    // if (Date.parse(firstLeadDeliveryDate) <  Date.parse(date) && status!='Assign')
    // {
    //     document.getElementById('firstLeadDeliveryDate').style.border='1px solid red';
    //    this.setState({firstLeadDeliveryDateDisplay3:"block"});

    //     formIsvalid=false;
    // }
    // else{

    //   this.setState({firstLeadDeliveryDateDisplay3:"none"});

    //     formIsvalid=true;
    // }

    if (noOfLeadPerDomain !== "") {
      if (
        noOfLeadPerDomain === undefined ||
        noOfLeadPerDomain === "undefined" ||
        noOfLeadPerDomain === 0
      ) {
        //snehal-changes for 0 handling (BE data is 0)
      } else {
        var pattern = new RegExp(/^(|[1-9]\d*)$/);
        if (!pattern.test(noOfLeadPerDomain)) {
          document.getElementById("noOfLeadPerDomain").style.border =
            "1px solid red";
          this.setState({ noOfLeadPerDomainDisplay: "block" });
          formIsvalid = false;
          basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
          return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
        } else {
        }
      }
    }
    if (leadAllocation === "") {
      document.getElementById("leadAllocation").style.border = "1px solid red";
      this.setState({ leadAllocationDisplay: "block" });
      formIsvalid = false;
      basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
    }

    var pattern2 = new RegExp(/^(|[1-9]\d*)$/);
    if (!pattern2.test(leadAllocation)) {
      document.getElementById("leadAllocation").style.border = "1px solid red";
      this.setState({ leadAllocationDisplay2: "block" });
      formIsvalid = false;
      basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
    }

    // if(editLeadAllocation==true){
    //   formIsvalid=false;

    // }

    var totalAllocatedLead = this.state.totalAllocatedLead;

    var allocatedCPL = this.state.allocatedCPL;

    // if(editLeadAllocation===true || editCPLAllocation===true){
    //   formIsvalid=false;
    // }else{
    //   formIsvalid=true;
    // }

    if (this.state.BasicDetailArrayEdit[0].CPL === "") {
      document.getElementById("CPL").style.border = "1px solid red";
      this.setState({ cplDisplay: "block" });
      formIsvalid = false;
      basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
    }
    if (this.state.BasicDetailArrayEdit[0].CPL !== "") {
      var pattern3 = new RegExp(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/);

      if (!pattern3.test(CPL)) {
        document.getElementById("CPL").style.border = "1px solid red";
        this.setState({ cplDisplay2: "block" });
        formIsvalid = false;
        basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
        return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      }
    }

    // if(editCPLAllocation==true){
    //   formIsvalid=false;

    // }

    /*********** merged code for both cpl and lead**********/
    if (leadAllocation < totalAllocatedLead && CPL < allocatedCPL) {
      document.getElementById("leadAllocation").style.border = "1px solid red";
      document.getElementById("CPL").style.border = "1px solid red";
      this.setState({ leadAllocationDisplay3: "block", cplDisplay3: "block" });
      editLeadAllocation = false;
      editCPLAllocation = false;
      formIsvalid = false;
    } else if (leadAllocation < totalAllocatedLead || CPL < allocatedCPL) {
      if (leadAllocation < totalAllocatedLead) {
        document.getElementById("leadAllocation").style.border =
          "1px solid red";
        this.setState({ leadAllocationDisplay3: "block" });
        editLeadAllocation = false;
        return editLeadAllocation;
      } else {
        editLeadAllocation = true;
        this.setState({ leadAllocationDisplay3: "none" });
        formIsvalid = true;
      }

      if (CPL < allocatedCPL) {
        document.getElementById("CPL").style.border = "1px solid red";
        this.setState({ cplDisplay3: "block" });
        editCPLAllocation = false;

        return editCPLAllocation;
      } else {
        this.setState({ cplDisplay3: "none" });
        editCPLAllocation = true;
        formIsvalid = true;
      }
    } else {
      editLeadAllocation = true;
      editCPLAllocation = true;
      this.setState({ leadAllocationDisplay3: "none", cplDisplay3: "none" });
      formIsvalid = true;
    }

    if (budget === "") {
      document.getElementById("budget").style.border = "1px solid red";
      this.setState({ cBudgetDisplay: "block" });
      formIsvalid = false;
      basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
    }
    if (budget !== "") {
      var pattern4 = new RegExp(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/);
      if (!pattern4.test(budget)) {
        document.getElementById("budget").style.border = "1px solid red";
        this.setState({ cBudgetDisplay2: "block" });
        formIsvalid = false;
        basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
        return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      }
    }
    if (currency === "") {
      document.getElementById("currency").style.border = "1px solid red";
      this.setState({ cCurrencyDisplay: "block" });
      formIsvalid = false;
      basicDetailform = false; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
      return basicDetailform; //snehal-task2842-when user enter wrong data validations goes fail that time it will stop to use to fill correct data
    }
    return formIsvalid;
  }

  validateDeliveryOption() {
    let DeliveryOptionform = true;
    let leadCountTotal = 0;
    let leadPercentageTotal = 0;
    let pacingEndDate = "";

    const { leadInteractionDays, requiredLPApproval, marketingChannel } =
      this.state.DeliveryOptionEdit[0];
    const { endDate, startDate } = this.state.BasicDetailArrayEdit[0];

    if (leadInteractionDays !== "") {
      if (leadInteractionDays == 0) {
        document.getElementById("leadInteractionDays").style.border =
          "1px solid red";
        this.setState({ leadInteractionDaysDisplay: "block" });
        DeliveryOptionform = false;
        return DeliveryOptionform;
      }
      var pattern = new RegExp(/^[0-9]\d*$/);
      if (!pattern.test(leadInteractionDays)) {
        document.getElementById("leadInteractionDays").style.border =
          "1px solid red";
        this.setState({ leadInteractionDaysDisplay2: "block" });
        DeliveryOptionform = false;
        return DeliveryOptionform;
      }
    }
    var creativesName = [];
    for (var i = 0; i < this.state.creativeValueArray.length; i++) {
      creativesName.push(this.state.creativeValueArray[i].name);
    }
    if (marketingChannel == "Email") {
      if (this.state.creativeValueArray.length == 0) {
        this.setState({
          inputcreativesApproval: "validate",
          creativesApprovalRequiredDisplay1: "none",
        });
        DeliveryOptionform = true; //this is true
        return DeliveryOptionform;
      }
      if (creativesName.includes("Landing Page") && creativesName.length == 1) {
        this.setState({
          creativesApprovalRequiredDisplay1: "none",
          inputcreativesApproval: "validate",
        });
        DeliveryOptionform = true;
        return DeliveryOptionform; //thisis true
      }
      if (
        creativesName.includes("Landing Page") &&
        creativesName.includes("Newsletter") &&
        creativesName.length == 2
      ) {
        this.setState({
          creativesApprovalRequiredDisplay1: "none",
          inputcreativesApproval: "validate",
        });
        DeliveryOptionform = true;
        return DeliveryOptionform; //this is true
      } else {
        this.setState({
          creativesApprovalRequiredDisplay1: "block",
          inputcreativesApproval: "invalidate",
        });
        DeliveryOptionform = false;
        return DeliveryOptionform;
      }
    } // end of marketingChannel=="email"
    if (marketingChannel == "TeleMarketing") {
      this.setState({
        creativesApprovalRequiredDisplay1: "block",
        inputcreativesApproval: "invalidate",
      });
      DeliveryOptionform = false;
      if (creativesName.length == 0) {
        this.setState({
          creativesApprovalRequiredDisplay1: "none",
          inputcreativesApproval: "validate",
        });
        DeliveryOptionform = true;
        return DeliveryOptionform;
      }
      if (creativesName.includes("Call Script") && creativesName.length == 1) {
        this.setState({
          creativesApprovalRequiredDisplay1: "none",
          inputcreativesApproval: "validate",
        });
        DeliveryOptionform = true;
        return DeliveryOptionform;
      }
      return DeliveryOptionform;
    }
    if (marketingChannel == "Email/Telemarketing") {
      if (creativesName.length == 0) {
        this.setState({
          creativesApprovalRequiredDisplay1: "none",
          inputcreativesApproval: "validate",
        });
        DeliveryOptionform = true;
        return DeliveryOptionform;
      }
      if (creativesName.includes("Newsletter") && creativesName.length == 1) {
        this.setState({
          creativesApprovalRequiredDisplay1: "block",
          inputcreativesApproval: "invalidate",
        });
        DeliveryOptionform = false;
        return DeliveryOptionform;
      }
    }
    //pacing validation ends
  }
  validatePacing() {
    let PacingValidate = true;
    let leadPercentageTotal = 0;
    const { endDate, startDate, leadAllocation } =
      this.state.BasicDetailArrayEdit[0];
    if (this.state.pacingUnit === "Number") {
      var pacingtotalLeadCount = 0;
      for (var i = 0; i < this.state.pacingDetailsEdit.length; i++) {
        if (this.state.pacingDetailsEdit[i].pacingLeadCount !== "") {
          var pattern = new RegExp(/^(|[0-9]\d*)$/);
          if (pattern.test(this.state.pacingDetailsEdit[i].pacingLeadCount)) {
            if (!isNaN(this.state.pacingDetailsEdit[i].pacingLeadCount)) {
              pacingtotalLeadCount =
                pacingtotalLeadCount +
                parseInt(this.state.pacingDetailsEdit[i].pacingLeadCount);
            } else {
              //for display message of enter character
            }
          } else {
            this.setState({ leadCountDisplay3: "block" });
            PacingValidate = false;
            return PacingValidate;
            //display error
          }
        }
      }
      if (pacingtotalLeadCount > 0) {
        if (pacingtotalLeadCount != leadAllocation) {
          this.setState({ leadCountDisplay2: "block" });
          PacingValidate = false;
          return PacingValidate; //display error
        }
        for (var p = 0; p < this.state.pacingDetailsEdit.length; p++) {
          if (this.state.pacingDetailsEdit[p].pacingEndDate == "") {
            //document.getElementsByClassName("classForPacingEndDate")[p].style.border='1px solid red';
            this.setState({ pacingEndDateDisplay1: "block" });
            PacingValidate = false;
            return PacingValidate; //display error
          } else {
            if (
              Date.parse(this.state.pacingDetailsEdit[p].pacingEndDate) >
              Date.parse(endDate)
            ) {
              //document.getElementsByClassName("classForPacingEndDate")[p].style.border='1px solid red';
              this.setState({ pacingEndDateDisplay2: "block" });
              PacingValidate = false;
              return PacingValidate; //display error
            }
            if (
              Date.parse(this.state.pacingDetailsEdit[p].pacingEndDate) <
              Date.parse(startDate)
            ) {
              //document.getElementsByClassName("classForPacingEndDate")[p].style.border='1px solid red';
              this.setState({ pacingEndDateDisplay3: "block" });
              PacingValidate = false;
              return PacingValidate; //display error
            }
            if (
              monthArray.indexOf(
                this.state.pacingDetailsEdit[p].pacingMonth
              ) !==
              new Date(this.state.pacingDetailsEdit[p].pacingEndDate).getMonth()
            ) {
              //document.getElementsByClassName("classForPacingEndDate")[p].style.border='1px solid red';
              this.setState({ pacingEndDateDisplay4: "block" });
              PacingValidate = false;
              return PacingValidate; //display error
            }
          }
        }
      }
      for (var q = 0; q < this.state.pacingDetailsEdit.length; q++) {
        if (
          this.state.pacingDetailsEdit[q].pacingEndDate !== "" &&
          this.state.pacingDetailsEdit[q].pacingLeadCount == ""
        ) {
          this.setState({ leadCountDisplay: "block" });
          PacingValidate = false;
          return PacingValidate;
        }
      }
    }
    if (this.state.pacingUnit === "Percentage") {
      for (var i = 0; i < this.state.pacingDetailsEdit.length; i++) {
        if (this.state.pacingDetailsEdit[i].pacingPercentage !== "") {
          var pattern = new RegExp(/^(|[0-9]\d*)$/);
          if (pattern.test(this.state.pacingDetailsEdit[i].pacingPercentage)) {
            //display error
            if (!isNaN(this.state.pacingDetailsEdit[i].pacingPercentage)) {
              leadPercentageTotal =
                leadPercentageTotal +
                parseInt(this.state.pacingDetailsEdit[i].pacingPercentage);
            } else {
              //for display message of enter character
            }
          } else {
            this.setState({ leadPercentageDisplay1: "block" });
            PacingValidate = false;
            return PacingValidate;
            //display error
          }
        }
      }
      if (leadPercentageTotal > 0) {
        if (leadPercentageTotal != 100) {
          this.setState({ leadPercentageDisplay: "block" });
          PacingValidate = false;
        }
        for (var i = 0; i < this.state.pacingDetailsEdit.length; i++) {
          if (this.state.pacingDetailsEdit[i].pacingEndDate == "") {
            //document.getElementsByClassName("classForPacingEndDate")[i].style.border='1px solid red';
            this.setState({ pacingEndDateDisplay1: "block" });
            PacingValidate = false;
            return PacingValidate; //display error
          }
          if (
            Date.parse(this.state.pacingDetailsEdit[i].pacingEndDate) >
            Date.parse(endDate)
          ) {
            //document.getElementsByClassName("classForPacingEndDate")[i].style.border='1px solid red';
            this.setState({ pacingEndDateDisplay2: "block" });
            PacingValidate = false;
            // return PacingValidate//display error
          }
          if (
            Date.parse(this.state.pacingDetailsEdit[i].pacingEndDate) <
            Date.parse(startDate)
          ) {
            //document.getElementsByClassName("classForPacingEndDate")[i].style.border='1px solid red';
            this.setState({ pacingEndDateDisplay3: "block" });
            PacingValidate = false;
            // return PacingValidate//display error
          }
          if (
            monthArray.indexOf(this.state.pacingDetailsEdit[i].pacingMonth) !==
            new Date(this.state.pacingDetailsEdit[i].pacingEndDate).getMonth()
          ) {
            //document.getElementsByClassName("classForPacingEndDate")[i].style.border='1px solid red';
            this.setState({ pacingEndDateDisplay4: "block" });
            PacingValidate = false;
          }
        }
      }
      for (var q = 0; q < this.state.pacingDetailsEdit.length; q++) {
        if (
          this.state.pacingDetailsEdit[q].pacingEndDate !== "" &&
          this.state.pacingDetailsEdit[q].pacingPercentage == ""
        ) {
          this.setState({ leadPercentageDisplay: "block" });
          PacingValidate = false;
        }
      }
      return PacingValidate;
    }
  }
  //end of pacing validation

  validateCampaignSpecification() {
    let CampaignSpecificationform = true;
    let employeeSizePickyValidate = true;
    let employeeSizeTextValidate = true;
    let employeeSizeToFromValidate = true;
    let error = {};
    let finalFormIsValid = true; //Sandeep-task-3397-error msg issue
    let industrySpecificationform = true; //Sandeep-task-3397-error msg issue
    let regionSpecificationform = true; //Sandeep-task-3397-error msg issue
    let countryWiseAllocationform = true; //Sandeep-task-3397-error msg issue
    //let stateCityZipValidateform=true;
    let stateCityZipValidateform = true; //Sandeep-task-3397-error msg issue
    let stateValidateform = true; //Sandeep-task-3397-error msg issue
    let cityValidateform = true; //Sandeep-task-3397-error msg issue
    let zipValidateform = true; //Sandeep-task-3397-error msg issue
    let excludedIndustryValidateform = true; //snehal-task-3647-Industry Exclusion on DI - FE
    //Region/Country Validation

    if (
      this.state.region == "" ||
      this.state.region == undefined ||
      this.state.region == null
    ) {
      this.setState({ regionCountriesDisplay: "block" });
      regionSpecificationform = false; //Sandeep-task-3397-error msg issue
      //CampaignSpecificationform = false;
      //return CampaignSpecificationform;
    } else {
      this.setState({ regionCountriesDisplay: "none" });
    }

    //CountrtyWise chk box Validation

    if (this.state.geoLeadChk == "Yes") {
      if (this.state.countryWiseAllocationArray.length == 0) {
        this.setState({ countrywiseLeadDisplay: "block" });
        countryWiseAllocationform = false; //Sandeep-task-3397-error msg issue
        //CampaignSpecificationform = false;
        //return CampaignSpecificationform;
      } else {
        this.setState({
          countrywiseLeadDisplay: "none",
          countrywiseLeadDisplay2: "none",
        });
        let error = {};
        let geoWiseLeadAllocationLength =
          this.state.countryWiseAllocationArray.length;
        let campLeadAllocation =
          this.state.BasicDetailArrayEdit[0].leadAllocation;
        let geoWiseLeadsTotal = 0;
        var leadAllocationNumeric = true;
        var count = 0;
        for (let i = 0; i < geoWiseLeadAllocationLength; i++) {
          if (this.state.countryWiseAllocationArray[i].lead == "") {
            if (count === 0) {
              leadAllocationNumeric = false;
              error["requireLead"] = "Please enter countryviz lead allocation";
              //Sandeep-task-3397-error msg issue
              this.setState({ error: error, countrywiseLeadDisplay: "block" });
              //CampaignSpecificationform = false;
              //return CampaignSpecificationform;
              countryWiseAllocationform = false; //Sandeep-task-3397-error msg issue
            }
          } else {
            count++;
            if (geoWiseLeadAllocationLength <= campLeadAllocation) {
              var pattern = new RegExp(/^[1-9][0-9]*$/);
              if (
                !pattern.test(this.state.countryWiseAllocationArray[i].lead)
              ) {
                leadAllocationNumeric = false;
                error["requireLead"] = "";
                error["leadAllocationNumeric"] =
                  "Please enter positive numeric and non-zero value for lead allocation";
                this.setState({
                  error: error,
                  countrywiseLeadDisplay4: "block",
                });
                //CampaignSpecificationform = false;
                //return CampaignSpecificationform;
                countryWiseAllocationform = false; //Sandeep-task-3397-error msg issue
                break;
              } else {
                leadAllocationNumeric = true;
                error["requireLead"] = "";
                error["leadAllocationNumeric"] = "";
                this.setState({
                  error: error,
                  countrywiseLeadDisplay: "none",
                  countrywiseLeadDisplay2: "none",
                  countrywiseLeadDisplay3: "none",
                  countrywiseLeadDisplay4: "none",
                });
              }
            } else if (geoWiseLeadAllocationLength > campLeadAllocation) {
              var pattern = new RegExp(/^[0-9]*$/);
              if (
                !pattern.test(this.state.countryWiseAllocationArray[i].lead)
              ) {
                leadAllocationNumeric = false;
                error["requireLead"] = "";
                error["leadAllocationNumeric"] =
                  "Please enter positive numeric value  for lead allocation";
                this.setState({
                  error: error,
                  countrywiseLeadDisplay3: "block",
                });
                //CampaignSpecificationform = false;
                //return CampaignSpecificationform;
                countryWiseAllocationform = false; //Sandeep-task-3397-error msg issue
                break;
              } else {
                leadAllocationNumeric = true;
                error["requireLead"] = "";
                error["leadAllocationNumeric"] = "";
                this.setState({
                  error: error,
                  countrywiseLeadDisplay: "none",
                  countrywiseLeadDisplay2: "none",
                  countrywiseLeadDisplay3: "none",
                  countrywiseLeadDisplay4: "none",
                });
              }
            } else {
            }
            if (leadAllocationNumeric == true) {
              geoWiseLeadsTotal =
                parseInt(geoWiseLeadsTotal) +
                parseInt(this.state.countryWiseAllocationArray[i].lead);
            }
          }
        }

        if (leadAllocationNumeric == true) {
          if (geoWiseLeadsTotal > campLeadAllocation) {
            error["requireLead"] = "";
            error["geoWiseLeadAllocationError"] =
              "Please enter countryviz lead allocation equal to total lead allocation";
            //Sandeep-task-3397-error msg issue
            error["geoWiseLeadAllocationSuccess"] = "";
            this.setState({ error: error, countrywiseLeadDisplay2: "block" });
            //CampaignSpecificationform = false;
            //return CampaignSpecificationform;
            countryWiseAllocationform = false; //Sandeep-task-3397-error msg issue
          } else if (geoWiseLeadsTotal < campLeadAllocation) {
            error["requireLead"] = "";
            error["geoWiseLeadAllocationError"] =
              "Please enter countryviz lead allocation equal to total lead allocation";
            //Sandeep-task-3397-error msg issue
            error["geoWiseLeadAllocationSuccess"] = "";
            this.setState({ error: error, countrywiseLeadDisplay2: "block" });
            //CampaignSpecificationform = false;
            //return CampaignSpecificationform;
            countryWiseAllocationform = false; //Sandeep-task-3397-error msg issue
          } else {
            error["requireLead"] = "";
            error["geoWiseLeadAllocationSuccess"] =
              "CountryViz Lead Allocation Done Successfully";
            error["geoWiseLeadAllocationError"] = "";
            this.setState({
              error: error,
              countrywiseLeadDisplay2: "none",
              countrywiseLeadDisplay3: "none",
              countrywiseLeadDisplay: "none",
              countrywiseLeadDisplay4: "none",
            });
          }
        }
      }
    }

    // if(this.state.geoLeadChk=="Yes")
    // {
    //   this.setState({countrywiseLeadDisplay:"block"})
    // // for(var i=0;i<this.state.countryWiseAllocationArray.length;i++)
    // // {
    // //   if(this.state.countryWiseAllocationArray[i].lead==""||this.state.countryWiseAllocationArray[i].lead==undefined||this.state.countryWiseAllocationArray[i].lead==null)
    // // {
    // //     this.setState({countrywiseLeadDisplay:"block"})
    // //     CampaignSpecificationform=false;
    // //     return CampaignSpecificationform;
    // // }
    // // else
    // // {
    // //     this.setState({countrywiseLeadDisplay:"none"})
    // // }
    // // }// end of for
    // }
    // else
    // {
    //   this.setState({countrywiseLeadDisplay:"none"})
    // }

    //State City Zip Validation

    if (this.state.stateFileCheck === "Yes" && this.state.stateFile == "") {
      this.setState({ stateDisplay: "block" });
      //CampaignSpecificationform = false;
      //return CampaignSpecificationform;
      stateValidateform = false; //Sandeep-task-3397-error msg issue
    } else {
      this.setState({ stateDisplay: "none" });
      //CampaignSpecificationform = true; //3231 change in logic
      stateValidateform = true; //Sandeep-task-3397-error msg issue
      // if (this.state.stateFileCheck === "Yes") {
      // 	if (
      // 		this.state.statefileArray.headerError == "" ||
      // 		this.state.statefileArray.headerError == undefined
      // 	) {
      // 		CampaignSpecificationform = false;
      // 		return CampaignSpecificationform;
      // 	}
      // }
    }

    if (this.state.cityFileCheck === "Yes" && this.state.cityFile == "") {
      this.setState({ cityDisplay: "block" });
      //CampaignSpecificationform = false;
      //return CampaignSpecificationform;
      cityValidateform = false; //Sandeep-task-3397-error msg issue
    } else {
      this.setState({ cityDisplay: "none" });
      //CampaignSpecificationform = true //3231 change in logic
      cityValidateform = true; //Sandeep-task-3397-error msg issue
      // if (this.state.cityFileCheck === "Yes") {
      // 	if (
      // 		this.state.cityfileArray.headerError == "" ||
      // 		this.state.cityfileArray.headerError == undefined
      // 	) {
      // 		CampaignSpecificationform = false;
      // 		return CampaignSpecificationform;
      // 	}
      // }
    }

    if (this.state.zipcodeFileCheck == "Yes" && this.state.zipcodeFile == "") {
      this.setState({ zipCodeDisplay: "block" });
      //CampaignSpecificationform = false;
      //return CampaignSpecificationform;
      zipValidateform = false; //Sandeep-task-3397-error msg issue
    } else {
      this.setState({ zipCodeDisplay: "none" });
      //CampaignSpecificationform = true //3231 change in logic
      zipValidateform = true; //Sandeep-task-3397-error msg issue
      // if (this.state.zipcodeFileCheck == "Yes") {
      // 	if (
      // 		this.state.zipfileArray.headerError == "" ||
      // 		this.state.zipfileArray.headerError == undefined
      // 	) {
      // 		CampaignSpecificationform = false;
      // 		return CampaignSpecificationform;
      // 	}
      // }
    }
    //Sandeep-task-3424-added code for validation issue
    if (
      this.state.stateValidate === false ||
      this.state.cityValidate === false ||
      this.state.zipValidate === false
    ) {
      //CampaignSpecificationform = false;
      stateCityZipValidateform = false; //Sandeep-task-3397-error msg issue
      //return CampaignSpecificationform;
    } else {
      //CampaignSpecificationform = true;
      stateCityZipValidateform = true; //Sandeep-task-3397-error msg issue
    }
    //snehal-task-3647-Industry Exclusion on DI - FE
    if (
      this.state.excludedIndustryFlag == "Yes" &&
      this.state.excludedIndustryFile == ""
    ) {
      this.setState({ excludedIndustryDisplay: "block" });
      excludedIndustryValidateform = false;
    } else {
      this.setState({ excludedIndustryDisplay: "none" });
      if (this.state.excludedIndustryFlag == "Yes") {
        if (
          this.state.excludedIndustryFileArray.headerError == "" ||
          this.state.excludedIndustryFileArray.headerError == undefined
        ) {
          excludedIndustryValidateform = false;
        }
      }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //snehal-task-2798-UI/UX-Changes-3.1
    // {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(text area red added code)(start) */}
    // Validation for Employee Size
    if (this.state.employeeSizeNotApplicable == "Applicable") {
      if (this.state.employeeSize.length === 0) {
        employeeSizePickyValidate = false;
        if (this.state.customEmployeeSizeText.length === 0) {
          employeeSizeTextValidate = false;
          this.setState({
            employeeSizeDisplay: "block",
            inputClassDropDownEmployeeSize: "invalidate",
          });
          CampaignSpecificationform = false;
          //CampaignSpecificationform = false;
          //return CampaignSpecificationform;
        } // end of  if(this.state.customEmployeeSizeText.length===0)
        else {
          //snehal-task-3760-when i added comma in emp size so added validations for that
          //var pattern = new RegExp(/^[1-9+<>][0-9+,\-<>|\n]*$/);
          //var pattern = new RegExp(/^[1-9a-zA-Z() ,+<>][0-9a-zA-Z()+, \-<>|\n]*$/); //rutuja task - 4436  only accpet number,letters and other charters except special character
          var pattern = new RegExp(
            /^(?=.*?[a-zA-Z0-9])[0-9a-zA-Z()+, \-<>|\n]+$/
          ); //rutuja task-4464 changed the pattern for not accepting single , or .
          if (!pattern.test(this.state.customEmployeeSizeText)) {
            document.getElementById("customEmployeeSizeText").style.border =
              "1px solid red";
            this.setState({ customEmployeeSizeTextDisplay: "block" });
            employeeSizeTextValidate = false;
            //return employeeSizeTextValidate;
          } else {
            employeeSizeTextValidate = true;
            //rutuja 4436 commented below code as no more below validation required
            // 	var custEmpSize = this.state.customEmployeeSizeText;
            // 	var custEmpSize_array = custEmpSize.split(/[\n |]+/);
            // 	let custEmpSizeArray = [];
            // 	for (var i = 0; i < custEmpSize_array.length; i++) {
            // 		custEmpSizeArray.push({
            // 			id: custEmpSize_array[i],
            // 			name: custEmpSize_array[i],
            // 		});
            // 		//snehal-task-3365-agency side---create camp--company revenue issue(added regex and if condition)
            // 		var temp_array = custEmpSize_array[i];
            // 		var patternNegative = new RegExp(/^[-]/);
            // 		var patternNegative_2 = new RegExp(/[-]$/);
            // 		var patterncommastart = new RegExp(/^[,]/); //snehal-task-3760-when i added comma in emp size so added validations for that
            // 		var patterncommaend = new RegExp(/[,]$/); //snehal-task-3760-when i added comma in emp size so added validations for that
            // 		var pattern_space = new RegExp(/\d( )\d/);
            // 		//snehal-task-3365-agency side---create camp--company revenue issue
            // 		if (
            // 			patternNegative.test(temp_array) ||
            // 			patternNegative_2.test(temp_array) ||
            // 			pattern_space.test(temp_array) ||
            // 			patterncommaend.test(temp_array) ||
            // 			patterncommastart.test(temp_array)
            // 		) {
            // 			employeeSizeTextValidate = false;
            // 			document.getElementById("customEmployeeSizeText").style.border =
            // 				"1px solid red";
            // 			this.setState({ customEmployeeSizeTextDisplay: "block" });
            // 			//return employeeSizeTextValidate;
            // 			break; //Sandeep-task-3397-error msg issue
            // 		}
            // 		//snehal-task-3760-when i added comma in emp size so added validations for that
            // 		var pattern2 = new RegExp(/^[1-9][0-9-,]*$/);
            // 		var pattern_dash = new RegExp(/[-]+/);
            // 		if (pattern2.test(temp_array) && pattern_dash.test(temp_array)) {
            // 			var patterncomma = new RegExp(/[,]+/);
            // 			var custEmpSizeArray1 = temp_array.split(/[-]+/);
            // 			if (custEmpSizeArray1[1] == undefined) {
            // 				employeeSizeTextValidate = true;
            // 			} else {
            // 				var custEmpSizeArray_temp1 = custEmpSizeArray1[0];
            // 				var custEmpSizeArray_temp2 = custEmpSizeArray1[1];
            // 				if (patterncomma.test(custEmpSizeArray_temp1)) {
            // 					let pattern_emp_comma = new RegExp(
            // 						/^(\d{1,2})(,\d{2})*(,\d{3}){1}(\.\d{1,})?$/
            // 					);
            // 					if (pattern_emp_comma.test(custEmpSizeArray_temp1)) {
            // 						var custEmpSizeArray1 = custEmpSizeArray_temp1;
            // 						var custEmpSizeArray_temp1 = custEmpSizeArray1.replace(
            // 							/,/g,
            // 							""
            // 						);
            // 					} else {
            // 						employeeSizeTextValidate = false;
            // 						document.getElementById(
            // 							"customEmployeeSizeText"
            // 						).style.border = "1px solid red";
            // 						this.setState({ customEmployeeSizeTextDisplay: "block" });
            // 						break;
            // 					}
            // 				}
            // 				if (patterncomma.test(custEmpSizeArray_temp2)) {
            // 					let pattern_emp_comma = new RegExp(
            // 						/^(\d{1,2})(,\d{2})*(,\d{3}){1}(\.\d{1,})?$/
            // 					);
            // 					if (pattern_emp_comma.test(custEmpSizeArray_temp2)) {
            // 						var custEmpSizeArray2 = custEmpSizeArray_temp2;
            // 						var custEmpSizeArray_temp2 = custEmpSizeArray2.replace(
            // 							/,/g,
            // 							""
            // 						);
            // 					} else {
            // 						employeeSizeTextValidate = false;
            // 						document.getElementById(
            // 							"customEmployeeSizeText"
            // 						).style.border = "1px solid red";
            // 						this.setState({ customEmployeeSizeTextDisplay: "block" });
            // 						break;
            // 					}
            // 				}
            // 				if (
            // 					parseInt(custEmpSizeArray_temp1) >
            // 					parseInt(custEmpSizeArray_temp2)
            // 				) {
            // 					employeeSizeTextValidate = false;
            // 					document.getElementById(
            // 						"customEmployeeSizeText"
            // 					).style.border = "1px solid red";
            // 					this.setState({ customEmployeeSizeTextDisplay: "block" });
            // 					//return employeeSizeTextValidate;
            // 					break; //Sandeep-task-3397-error msg issue
            // 				} else {
            // 					employeeSizeTextValidate = true;
            // 				}
            // 			}
            // 		} else {
            // 			//snehal-task-3344-edit camp---error msg issue
            // 			//snehal-task-3760-when i added comma in emp size so added validations for that
            // 			let pattern_emp = new RegExp(
            // 				/^[0-9+<>]?[0-9]+?[0-9]+?[n]?[<>+]?$/
            // 			);
            // 			let pattern_empComma = new RegExp(
            // 				/^[0-9+<>]?[0-9,]+?[0-9]+?[n]?[<>+]?$/
            // 			);
            // 			let pattern_emp_comma = new RegExp(
            // 				/^(\d{1,2})(,\d{2})*(,\d{3}){1}(\.\d{1,})?$/
            // 			);
            // 			if (pattern_emp.test(temp_array)) {
            // 				employeeSizeTextValidate = true;
            // 			} else if (pattern_empComma.test(temp_array)) {
            // 				var temp_value = temp_array.replace("+", "");
            // 				var temp_value2 = temp_value.replace("<", "");
            // 				var temp_array = temp_value2.replace(">", "");
            // 				if (pattern_emp_comma.test(temp_array)) {
            // 					employeeSizeTextValidate = true;
            // 				} else {
            // 					employeeSizeTextValidate = false;
            // 					document.getElementById(
            // 						"customEmployeeSizeText"
            // 					).style.border = "1px solid red";
            // 					this.setState({ customEmployeeSizeTextDisplay: "block" });
            // 					//return employeeSizeTextValidate;//Sandeep-task-3397-error msg issue
            // 					break;
            // 				}
            // 			} else {
            // 				employeeSizeTextValidate = false;
            // 				document.getElementById(
            // 					"customEmployeeSizeText"
            // 				).style.border = "1px solid red";
            // 				this.setState({ customEmployeeSizeTextDisplay: "block" });
            // 				//return employeeSizeTextValidate;
            // 				break; //Sandeep-task-3397-error msg issue
            // 			}
            // 		}
            // 	}
          }
        } // end of  else(this.state.customEmployeeSizeText.length>0)
      } //end of if(this.state.employeeSize.length==0 )
      else {
        employeeSizePickyValidate = true;
        if (this.state.customEmployeeSizeText.length === 0) {
          employeeSizeTextValidate = true;
          CampaignSpecificationform = true;
        } // end of  if(this.state.customEmployeeSizeText.length===0)
        else {
          //snehal-task-3760-when i added comma in emp size so added validations for that
          //var pattern = new RegExp(/^[1-9a-zA-Z() ,+<>][0-9a-zA-Z()+, \-<>|\n]*$/); //rutuja task - 4436  only accpet number,letters and other charters except special character
          var pattern = new RegExp(
            /^(?=.*?[a-zA-Z0-9])[0-9a-zA-Z()+, \-<>|\n]+$/
          ); //rutuja task-4464 changed the pattern for not accepting single , or .

          if (!pattern.test(this.state.customEmployeeSizeText)) {
            document.getElementById("customEmployeeSizeText").style.border =
              "1px solid red";
            this.setState({ customEmployeeSizeTextDisplay: "block" });
            employeeSizeTextValidate = false;
            //return employeeSizeTextValidate;//Sandeep-task-3397-error msg issue
          } else {
            employeeSizeTextValidate = true;
            //rutuja 4436 commented below code as no more below validation required
            // var custEmpSize = this.state.customEmployeeSizeText;
            // var custEmpSize_array = custEmpSize.split(/[\n |]+/);
            // let custEmpSizeArray = [];
            // for (var i = 0; i < custEmpSize_array.length; i++) {
            // 	custEmpSizeArray.push({
            // 		id: custEmpSize_array[i],
            // 		name: custEmpSize_array[i],
            // 	});
            // 	//snehal-task-3365-agency side---create camp--company revenue issue(added regex and if condtion changes)
            // 	var temp_array = custEmpSize_array[i];
            // 	var patternNegative = new RegExp(/^[-]/);
            // 	var patternNegative_2 = new RegExp(/[-]$/);
            // 	var patterncommastart = new RegExp(/^[,]/); //snehal-task-3760-when i added comma in emp size so added validations for that
            // 	var patterncommaend = new RegExp(/[,]$/); //snehal-task-3760-when i added comma in emp size so added validations for that
            // 	var pattern_space = new RegExp(/\d( )\d/);
            // 	//snehal-task-3365-agency side---create camp--company revenue issue
            // 	if (
            // 		patternNegative.test(temp_array) ||
            // 		patternNegative_2.test(temp_array) ||
            // 		pattern_space.test(temp_array) ||
            // 		patterncommaend.test(temp_array) ||
            // 		patterncommastart.test(temp_array)
            // 	) {
            // 		employeeSizeTextValidate = false;
            // 		document.getElementById("customEmployeeSizeText").style.border =
            // 			"1px solid red";
            // 		this.setState({ customEmployeeSizeTextDisplay: "block" });
            // 		//return employeeSizeTextValidate;
            // 		break; //Sandeep-task-3397-error msg issue
            // 	}
            // 	//snehal-task-3760-when i added comma in emp size so added validations for that
            // 	var pattern2 = new RegExp(/^[1-9][0-9-,]*$/);
            // 	var pattern_dash = new RegExp(/[-]+/);

            // 	if (pattern2.test(temp_array) && pattern_dash.test(temp_array)) {
            // 		var patterncomma = new RegExp(/[,]+/);
            // 		var custEmpSizeArray1 = temp_array.split(/[-]+/);
            // 		if (custEmpSizeArray1[1] == undefined) {
            // 			employeeSizeTextValidate = true;
            // 		} else {
            // 			//snehal-task-3760-when i added comma in emp size so added validations for that
            // 			var custEmpSizeArray_temp1 = custEmpSizeArray1[0];
            // 			var custEmpSizeArray_temp2 = custEmpSizeArray1[1];
            // 			if (patterncomma.test(custEmpSizeArray_temp1)) {
            // 				let pattern_emp_comma = new RegExp(
            // 					/^(\d{1,2})(,\d{2})*(,\d{3}){1}(\.\d{1,})?$/
            // 				);
            // 				if (pattern_emp_comma.test(custEmpSizeArray_temp1)) {
            // 					var custEmpSizeArray1 = custEmpSizeArray_temp1;
            // 					var custEmpSizeArray_temp1 = custEmpSizeArray1.replace(
            // 						/,/g,
            // 						""
            // 					);
            // 				} else {
            // 					employeeSizeTextValidate = false;
            // 					document.getElementById(
            // 						"customEmployeeSizeText"
            // 					).style.border = "1px solid red";
            // 					this.setState({ customEmployeeSizeTextDisplay: "block" });
            // 					break;
            // 				}
            // 			}
            // 			if (patterncomma.test(custEmpSizeArray_temp2)) {
            // 				let pattern_emp_comma = new RegExp(
            // 					/^(\d{1,2})(,\d{2})*(,\d{3}){1}(\.\d{1,})?$/
            // 				);
            // 				if (pattern_emp_comma.test(custEmpSizeArray_temp2)) {
            // 					var custEmpSizeArray2 = custEmpSizeArray_temp2;
            // 					var custEmpSizeArray_temp2 = custEmpSizeArray2.replace(
            // 						/,/g,
            // 						""
            // 					);
            // 				} else {
            // 					employeeSizeTextValidate = false;
            // 					document.getElementById(
            // 						"customEmployeeSizeText"
            // 					).style.border = "1px solid red";
            // 					this.setState({ customEmployeeSizeTextDisplay: "block" });
            // 					break;
            // 				}
            // 			}
            // 			if (
            // 				parseInt(custEmpSizeArray_temp1) >
            // 				parseInt(custEmpSizeArray_temp2)
            // 			) {
            // 				employeeSizeTextValidate = false;
            // 				document.getElementById(
            // 					"customEmployeeSizeText"
            // 				).style.border = "1px solid red";
            // 				this.setState({ customEmployeeSizeTextDisplay: "block" });
            // 				//return employeeSizeTextValidate;
            // 				break; //Sandeep-task-3397-error msg issue
            // 			} else {
            // 				employeeSizeTextValidate = true;
            // 			}
            // 		}
            // 	} else {
            // 		//snehal-task-3344-edit camp---error msg issue
            // 		//snehal-task-3760-when i added comma in emp size so added validations for that
            // 		let pattern_emp = new RegExp(
            // 			/^[0-9+<>]?[0-9]+?[0-9]+?[n]?[<>+]?$/
            // 		);
            // 		let pattern_empComma = new RegExp(
            // 			/^[0-9+<>]?[0-9,]+?[0-9]+?[n]?[<>+]?$/
            // 		);
            // 		let pattern_emp_comma = new RegExp(
            // 			/^(\d{1,2})(,\d{2})*(,\d{3}){1}(\.\d{1,})?$/
            // 		);
            // 		if (pattern_emp.test(temp_array)) {
            // 			employeeSizeTextValidate = true;
            // 		} else if (pattern_empComma.test(temp_array)) {
            // 			var temp_value = temp_array.replace("+", "");
            // 			var temp_value2 = temp_value.replace("<", "");
            // 			var temp_array = temp_value2.replace(">", "");
            // 			if (pattern_emp_comma.test(temp_array)) {
            // 				employeeSizeTextValidate = true;
            // 			} else {
            // 				employeeSizeTextValidate = false;
            // 				document.getElementById(
            // 					"customEmployeeSizeText"
            // 				).style.border = "1px solid red";
            // 				this.setState({ customEmployeeSizeTextDisplay: "block" });
            // 				//return employeeSizeTextValidate;//Sandeep-task-3397-error msg issue
            // 				break;
            // 			}
            // 		} else {
            // 			employeeSizeTextValidate = false;
            // 			document.getElementById(
            // 				"customEmployeeSizeText"
            // 			).style.border = "1px solid red";
            // 			this.setState({ customEmployeeSizeTextDisplay: "block" });
            // 			//return employeeSizeTextValidate;
            // 			break; //Sandeep-task-3397-error msg issue
            // 		}
            // 	}
            // }
          }
        } // end of  if(this.state.customEmployeeSizeText.length>0)
      }
    } //end of notApplicable
    //////////////////////////////////////////////////
    //snehal-task-2798-UI/UX-Changes-3.1
    //validate Company revenue
    //snehal-task-3365-agency side---create camp--company revenue issue(changes done in validation for some failing scenarios regarding to company revenue)
    // {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(text area red added code) */}
    let companyRevenueTextValidate = true;
    if (this.state.customCompanyRevenueText.length > 0) {
      // Chaitanya-4906-When non-english checkbox checked will not validate company Revenue Text
      //Rutuja -4930 as requirment changed for company reveneue removing 4906
      // if (this.state.nonEnglishChecked === "No") { //4930
      //var pattern = new RegExp(/^[0-9+<>$]*[MmBb|\n]*[+-<>$]*$/);
      var pattern = new RegExp(
        // /^[0-9.<>$£]*[0-9 M m B b.+\-<>$£Kk|\n million billion Million Billion]*$/
        /^[0-9|<|>|$]*[0-9|<|>|$]*[0-9 M m B b < >]*/i
      );
      if (!pattern.test(this.state.customCompanyRevenueText)) {
        document.getElementById("companyRevenueText").style.border =
          "1px solid red";
        this.setState({ customCompanyRevenueTextDisplay: "block" });
        companyRevenueTextValidate = false;
        //return companyRevenueTextValidate;//Sandeep-task-3397-error msg issue
      } else {
        var custComapnyRevenue = this.state.customCompanyRevenueText;
        //snehal-task-3365-agency side---create camp--company revenue issue(change for space)
        var custComapnyRevenue_array = custComapnyRevenue.split(/[]+/); // Aman - 4593- (remove the "|" & "\n" special character from split- not required for new requirement) AC- Create/Edit/Incomplete Campaign - Company Revenue
        let custCompanyRevenueArray = [];
        for (var i = 0; i < custComapnyRevenue_array.length; i++) {
          custCompanyRevenueArray.push({
            id: custComapnyRevenue_array[i],
            name: custComapnyRevenue_array[i],
          });
          var temp_array = custComapnyRevenue_array[i];
          //snehal-task-3365-agency side---create camp--company revenue issue(change for space,zero and dot)
          var pattern_dollar = new RegExp(/^[$]\s+/);
          var pattern_space = new RegExp(/\d*[\s*]\d/);
          var pattern_dot_start = new RegExp(/^[.]/); //snehal-task-3365-agency side---create camp--company revenue issue(change for dot)
          var pattern_dot_end = new RegExp(/[.]$/); //snehal-task-3365-agency side---create camp--company revenue issue(change for dot)
          //snehal-task-3365-agency side---create camp--company revenue issue(change for space,zero and dot and related to that)
          if (
            pattern_space.test(temp_array) ||
            temp_array == "." ||
            temp_array == "0" ||
            parseFloat(temp_array) == "0" ||
            pattern_dot_start.test(temp_array) ||
            pattern_dot_end.test(temp_array) ||
            pattern_dollar.test(temp_array)
          ) {
            document.getElementById("companyRevenueText").style.border =
              "1px solid red";
            this.setState({ customCompanyRevenueTextDisplay: "block" });
            companyRevenueTextValidate = false;
            //return companyRevenueTextValidate;
            break; //Sandeep-task-3397-error msg issue
          }
          var temp_array = custComapnyRevenue_array[i]; //snehal-task-3365-agency side---create camp--company revenue issue(change for space)
          var pattern2 = new RegExp(/[-]+/);
          if (pattern2.test(temp_array)) {
            var pattern_to_from = new RegExp(
              /^[0-9|<|>|$]*[0-9|<|>|$]*[0-9 M m B b < >]*/i
              // /^[0-9.$£]*[0-9 M m B b.\-$£Kk|\n million billion Million Billion]*$/]
            );
            if (!pattern_to_from.test(temp_array)) {
              document.getElementById("companyRevenueText").style.border =
                "1px solid red";
              this.setState({ customCompanyRevenueTextDisplay: "block" });
              companyRevenueTextValidate = false;
              //return companyRevenueTextValidate;
              break; //Sandeep-task-3397-error msg issue
            } //snehal-task-3409-local and PAT---create and edit camp---company revenue issue(On priority)
            var custCompanyRevenueArray1 = temp_array.split(/[-]+/);
            var value1 = custCompanyRevenueArray1[0];
            var value2 = custCompanyRevenueArray1[1];
            if (value1.match(/[.]/g) || value2.match(/[.]/g)) {
              let val1 = value1.split(".").length;
              let val2 = value2.split(".").length;
              if (val1 > 2 || val2 > 2) {
                document.getElementById("companyRevenueText").style.border =
                  "1px solid red";
                this.setState({ customCompanyRevenueTextDisplay: "block" });
                companyRevenueTextValidate = false;
                //return companyRevenueTextValidate;
                break; //Sandeep-task-3397-error msg issue
              }
            }
            //snehal-task-3365-agency side---create camp--company revenue issue(if condtion changes)
            if (value1 == "" || value2 == "") {
              document.getElementById("companyRevenueText").style.border =
                "1px solid red";
              this.setState({ customCompanyRevenueTextDisplay: "block" });
              companyRevenueTextValidate = false;
              //return companyRevenueTextValidate;
              break; //Sandeep-task-3397-error msg issue
            }
            var matches1 = value1.match(
              /[$,£,m,M,b,B,K,k,million,billion,Million,Billion]/g
            );
            var matches2 = value2.match(
              /[$,£,m,M,b,B,K,k,million,billion,Million,Billion]/g
            );
            if (
              (matches1 == "M" ||
                matches1 == "m" ||
                matches1 == "b" ||
                matches1 == "B" ||
                matches1 == "M,i,l,l,i,o,n" ||
                matches1 == "m,i,l,l,i,o,n" ||
                matches1 == "B,i,l,l,i,o,n" ||
                matches1 == "b,i,l,l,i,o,n") &&
              (matches2 == "M" ||
                matches2 == "m" ||
                matches2 == "b" ||
                matches2 == "B" ||
                matches2 == "M,i,l,l,i,o,n" ||
                matches2 == "m,i,l,l,i,o,n" ||
                matches2 == "B,i,l,l,i,o,n" ||
                matches2 == "b,i,l,l,i,o,n")
            ) {
              if (
                ((matches1 == "M" ||
                  matches1 == "m" ||
                  matches1 == "M,i,l,l,i,o,n" ||
                  matches1 == "m,i,l,l,i,o,n") &&
                  (matches2 == "M" ||
                    matches2 == "m" ||
                    matches2 == "M,i,l,l,i,o,n" ||
                    matches2 == "m,i,l,l,i,o,n")) ||
                ((matches1 == "b" ||
                  matches1 == "B" ||
                  matches1 == "B,i,l,l,i,o,n" ||
                  matches1 == "b,i,l,l,i,o,n") &&
                  (matches2 == "B" ||
                    matches2 == "b" ||
                    matches2 == "B,i,l,l,i,o,n" ||
                    matches2 == "b,i,l,l,i,o,n"))
              ) {
                if (parseFloat(value1) >= parseFloat(value2)) {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                } else {
                  //snehal-task-3365-agency side---create camp--company revenue issue
                  let pattern_rev = new RegExp(/^.*[0-9].*$/);
                  if (pattern_rev.test(temp_array)) {
                    companyRevenueTextValidate = true;
                  } else {
                    document.getElementById("companyRevenueText").style.border =
                      "1px solid red";
                    this.setState({
                      customCompanyRevenueTextDisplay: "block",
                    });
                    companyRevenueTextValidate = false;
                    //return companyRevenueTextValidate;
                    break; //Sandeep-task-3397-error msg issue
                  }
                }
              }
              //snehal-task-3365-agency side---create camp--company revenue issue(when user add matches for first and not for second)
              else if (matches1 != "" && matches2 == "") {
                document.getElementById("companyRevenueText").style.border =
                  "1px solid red";
                companyRevenueTextValidate = false;
                this.setState({ customCompanyRevenueTextDisplay: "block" });
                //return companyRevenueTextValidate;
                break; //Sandeep-task-3397-error msg issue
              } else if (
                (matches1 == "b" ||
                  matches1 == "B" ||
                  matches1 == "B,i,l,l,i,o,n" ||
                  matches1 == "b,i,l,l,i,o,n") &&
                (matches2 == "M" ||
                  matches2 == "m" ||
                  matches2 == "M,i,l,l,i,o,n" ||
                  matches2 == "m,i,l,l,i,o,n")
              ) {
                companyRevenueTextValidate = false;
                document.getElementById("companyRevenueText").style.border =
                  "1px solid red";
                this.setState({ customCompanyRevenueTextDisplay: "block" });
                //return companyRevenueTextValidate;
                break; //Sandeep-task-3397-error msg issue
              } else if (
                (matches1 == "m" ||
                  matches1 == "M" ||
                  matches1 == "M,i,l,l,i,o,n" ||
                  matches1 == "m,i,l,l,i,o,n") &&
                (matches2 == "B" ||
                  matches2 == "b" ||
                  matches2 == "B,i,l,l,i,o,n" ||
                  matches2 == "b,i,l,l,i,o,n")
              ) {
                //snehal-task-3365-agency side---create camp--company revenue issue
                let pattern_rev = new RegExp(/^.*[0-9].*$/);
                if (pattern_rev.test(temp_array)) {
                  companyRevenueTextValidate = true;
                } else {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                }
              }
            } else if (
              (matches1 == "$,M" ||
                matches1 == "$,m" ||
                matches1 == "$,b" ||
                matches1 == "$,B" ||
                matches1 == "$,M,i,l,l,i,o,n" ||
                matches1 == "$,m,i,l,l,i,o,n" ||
                matches1 == "$,B,i,l,l,i,o,n" ||
                matches1 == "$,b,i,l,l,i,o,n" ||
                matches1 == "$,K" ||
                matches1 == "$,k" ||
                matches1 == "£,M" ||
                matches1 == "£,m" ||
                matches1 == "£,b" ||
                matches1 == "£,B" ||
                matches1 == "£,M,i,l,l,i,o,n" ||
                matches1 == "£,m,i,l,l,i,o,n" ||
                matches1 == "£,B,i,l,l,i,o,n" ||
                matches1 == "£,b,i,l,l,i,o,n" ||
                matches1 == "£,K" ||
                matches1 == "£,k") &&
              (matches2 == "M" ||
                matches2 == "m" ||
                matches2 == "b" ||
                matches2 == "B" ||
                matches2 == "M,i,l,l,i,o,n" ||
                matches2 == "m,i,l,l,i,o,n" ||
                matches2 == "B,i,l,l,i,o,n" ||
                matches2 == "b,i,l,l,i,o,n" ||
                matches2 == "£,M" ||
                matches2 == "£,m" ||
                matches2 == "£,b" ||
                matches2 == "£,B" ||
                matches2 == "£,M,i,l,l,i,o,n" ||
                matches2 == "£,m,i,l,l,i,o,n" ||
                matches2 == "£,B,i,l,l,i,o,n" ||
                matches2 == "£,b,i,l,l,i,o,n" ||
                matches2 == "£,K" ||
                matches2 == "£,k" ||
                matches2 == "$,M" ||
                matches2 == "$,m" ||
                matches2 == "$,b" ||
                matches2 == "$,B" ||
                matches2 == "$,M,i,l,l,i,o,n" ||
                matches2 == "$,m,i,l,l,i,o,n" ||
                matches2 == "$,B,i,l,l,i,o,n" ||
                matches2 == "$,b,i,l,l,i,o,n" ||
                matches2 == "$,K" ||
                matches2 == "$,k")
            ) {
              if (
                (matches1 == "$,M" ||
                  matches1 == "$,m" ||
                  matches1 == "$,M,i,l,l,i,o,n" ||
                  matches1 == "$,m,i,l,l,i,o,n") &&
                (matches2 == "M" ||
                  matches2 == "m" ||
                  matches2 == "M,i,l,l,i,o,n" ||
                  matches2 == "m,i,l,l,i,o,n" ||
                  matches2 == "$,M" ||
                  matches2 == "$,m" ||
                  matches2 == "$,M,i,l,l,i,o,n" ||
                  matches2 == "$,m,i,l,l,i,o,n")
              ) {
                var value_temp1 = value1.replace("$", "");
                var value_temp2 = value2.replace("$", "");
                if (parseFloat(value_temp1) >= parseFloat(value_temp2)) {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                } else {
                  //snehal-task-3365-agency side---create camp--company revenue issue
                  let pattern_rev = new RegExp(/^.*[0-9].*$/);
                  if (pattern_rev.test(temp_array)) {
                    companyRevenueTextValidate = true;
                  } else {
                    document.getElementById("companyRevenueText").style.border =
                      "1px solid red";
                    this.setState({
                      customCompanyRevenueTextDisplay: "block",
                    });
                    companyRevenueTextValidate = false;
                    //return companyRevenueTextValidate;
                    break; //Sandeep-task-3397-error msg issue
                  }
                }
              } else if (
                (matches1 == "$,M" ||
                  matches1 == "$,m" ||
                  matches1 == "$,M,i,l,l,i,o,n" ||
                  matches1 == "$,m,i,l,l,i,o,n") &&
                (matches2 == "$,B" ||
                  matches2 == "$,b" ||
                  matches2 == "$,B,i,l,l,i,o,n" ||
                  matches2 == "$,b,i,l,l,i,o,n")
              ) {
                //snehal-task-3365-agency side---create camp--company revenue issue
                let pattern_rev = new RegExp(/^.*[0-9].*$/);
                if (pattern_rev.test(temp_array)) {
                  companyRevenueTextValidate = true;
                } else {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                }
              } else if (
                (matches1 == "$,B" ||
                  matches1 == "$,b" ||
                  matches1 == "$,B,i,l,l,i,o,n" ||
                  matches1 == "$,b,i,l,l,i,o,n") &&
                (matches2 == "B" ||
                  matches2 == "b" ||
                  matches2 == "B,i,l,l,i,o,n" ||
                  matches2 == "b,i,l,l,i,o,n" ||
                  matches2 == "$,B" ||
                  matches2 == "$,b" ||
                  matches2 == "$,B,i,l,l,i,o,n" ||
                  matches2 == "$,b,i,l,l,i,o,n")
              ) {
                var value_temp1 = value1.replace("$", "");
                var value_temp2 = value2.replace("$", "");
                if (parseFloat(value_temp1) >= parseFloat(value_temp2)) {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                } else {
                  //snehal-task-3365-agency side---create camp--company revenue issue
                  let pattern_rev = new RegExp(/^.*[0-9].*$/);
                  if (pattern_rev.test(temp_array)) {
                    companyRevenueTextValidate = true;
                  } else {
                    document.getElementById("companyRevenueText").style.border =
                      "1px solid red";
                    this.setState({
                      customCompanyRevenueTextDisplay: "block",
                    });
                    companyRevenueTextValidate = false;
                    //return companyRevenueTextValidate;
                    break; //Sandeep-task-3397-error msg issue
                  }
                }
              } else if (
                (matches1 == "£,M" ||
                  matches1 == "£,m" ||
                  matches1 == "£,M,i,l,l,i,o,n" ||
                  matches1 == "£,m,i,l,l,i,o,n") &&
                (matches2 == "B" ||
                  matches2 == "b" ||
                  matches2 == "B,i,l,l,i,o,n" ||
                  matches2 == "b,i,l,l,i,o,n" ||
                  matches2 == "£,B" ||
                  matches2 == "£,b" ||
                  matches2 == "£,B,i,l,l,i,o,n" ||
                  matches2 == "£,b,i,l,l,i,o,n")
              ) {
                //snehal-task-3365-agency side---create camp--company revenue issue
                let pattern_rev = new RegExp(/^.*[0-9].*$/);
                if (pattern_rev.test(temp_array)) {
                  companyRevenueTextValidate = true;
                } else {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                }
              } else if (
                ((matches1 == "£,M" ||
                  matches1 == "£,m" ||
                  matches1 == "£,M,i,l,l,i,o,n" ||
                  matches1 == "£,m,i,l,l,i,o,n") &&
                  (matches2 == "£,M" ||
                    matches2 == "£,m" ||
                    matches2 == "£,M,i,l,l,i,o,n" ||
                    matches2 == "£,m,i,l,l,i,o,n")) ||
                ((matches1 == "£,B" ||
                  matches1 == "£,b" ||
                  matches1 == "£,B,i,l,l,i,o,n" ||
                  matches1 == "£,b,i,l,l,i,o,n") &&
                  (matches2 == "£,B" ||
                    matches2 == "£,b" ||
                    matches2 == "£,B,i,l,l,i,o,n" ||
                    matches2 == "£,b,i,l,l,i,o,n"))
              ) {
                var value_temp1 = value1.replace("£", "");
                var value_temp2 = value2.replace("£", "");
                if (parseFloat(value_temp1) >= parseFloat(value_temp2)) {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                } else {
                  //snehal-task-3365-agency side---create camp--company revenue issue
                  let pattern_rev = new RegExp(/^.*[0-9].*$/);
                  if (
                    pattern_rev.test(value_temp1) &&
                    pattern_rev.test(value_temp2)
                  ) {
                    companyRevenueTextValidate = true;
                  } else {
                    document.getElementById("companyRevenueText").style.border =
                      "1px solid red";
                    this.setState({
                      customCompanyRevenueTextDisplay: "block",
                    });
                    companyRevenueTextValidate = false;
                    //return companyRevenueTextValidate;
                    break; //Sandeep-task-3397-error msg issue
                  }
                }
              } else if (
                ((matches1 == "$,B" ||
                  matches1 == "$,b" ||
                  matches1 == "$,B,i,l,l,i,o,n" ||
                  matches1 == "$,b,i,l,l,i,o,n") &&
                  (matches2 == "M" ||
                    matches2 == "m" ||
                    matches2 == "M,i,l,l,i,o,n" ||
                    matches2 == "m,i,l,l,i,o,n" ||
                    matches2 == "$,M" ||
                    matches2 == "$,m" ||
                    matches2 == "$,M,i,l,l,i,o,n" ||
                    matches2 == "$,m,i,l,l,i,o,n")) ||
                ((matches1 == "£,B" ||
                  matches1 == "£,b" ||
                  matches1 == "£,B,i,l,l,i,o,n" ||
                  matches1 == "£,b,i,l,l,i,o,n") &&
                  (matches2 == "M" ||
                    matches2 == "m" ||
                    matches2 == "M,i,l,l,i,o,n" ||
                    matches2 == "m,i,l,l,i,o,n" ||
                    matches2 == "£,M" ||
                    matches2 == "£,m" ||
                    matches2 == "£,M,i,l,l,i,o,n" ||
                    matches2 == "£,m,i,l,l,i,o,n"))
              ) {
                document.getElementById("companyRevenueText").style.border =
                  "1px solid red";
                this.setState({ customCompanyRevenueTextDisplay: "block" });
                companyRevenueTextValidate = false;
                //return companyRevenueTextValidate;
                break; //Sandeep-task-3397-error msg issue
              } else if (
                (matches1 == "£,K" || matches1 == "£,k") &&
                (matches2 == "£,K" || matches2 == "£,k")
              ) {
                var value_temp1 = value1.replace("£", "");
                var value_temp2 = value2.replace("£", "");
                if (parseFloat(value_temp1) >= parseFloat(value_temp2)) {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  ///return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                } else {
                  //snehal-task-3365-agency side---create camp--company revenue issue
                  let pattern_rev = new RegExp(/^.*[0-9].*$/);
                  if (
                    pattern_rev.test(value_temp1) &&
                    pattern_rev.test(value_temp2)
                  ) {
                    companyRevenueTextValidate = true;
                  } else {
                    document.getElementById("companyRevenueText").style.border =
                      "1px solid red";
                    this.setState({
                      customCompanyRevenueTextDisplay: "block",
                    });
                    companyRevenueTextValidate = false;
                    //return companyRevenueTextValidate;
                    break; //Sandeep-task-3397-error msg issue
                  }
                }
              } else if (
                (matches1 == "$,K" || matches1 == "$,k") &&
                (matches2 == "$,K" || matches2 == "$,k")
              ) {
                var value_temp1 = value1.replace("$", "");
                var value_temp2 = value2.replace("$", "");
                if (parseFloat(value_temp1) >= parseFloat(value_temp2)) {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                } else {
                  //snehal-task-3365-agency side---create camp--company revenue issue
                  let pattern_rev = new RegExp(/^.*[0-9].*$/);
                  if (
                    pattern_rev.test(value_temp1) &&
                    pattern_rev.test(value_temp2)
                  ) {
                    companyRevenueTextValidate = true;
                  } else {
                    document.getElementById("companyRevenueText").style.border =
                      "1px solid red";
                    this.setState({
                      customCompanyRevenueTextDisplay: "block",
                    });
                    companyRevenueTextValidate = false;
                    //return companyRevenueTextValidate;
                    break; //Sandeep-task-3397-error msg issue
                  }
                }
              } else if (
                (matches1 == "$,K" || matches1 == "$,k") &&
                (matches2 == "$,M" ||
                  matches2 == "$,m" ||
                  matches2 == "$,b" ||
                  matches2 == "$,B" ||
                  matches2 == "$,M,i,l,l,i,o,n" ||
                  matches2 == "$,m,i,l,l,i,o,n" ||
                  matches2 == "$,B,i,l,l,i,o,n" ||
                  matches2 == "$,b,i,l,l,i,o,n")
              ) {
                var value_temp1 = value1.replace("$", "");
                var value_temp2 = value2.replace("$", "");
                let pattern_rev = new RegExp(/^.*[0-9].*$/);
                if (
                  pattern_rev.test(value_temp1) &&
                  pattern_rev.test(value_temp2)
                ) {
                  companyRevenueTextValidate = true;
                } else {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                }
              } else if (
                (matches1 == "£,K" || matches1 == "£,k") &&
                (matches2 == "£,M" ||
                  matches2 == "£,m" ||
                  matches2 == "£,b" ||
                  matches2 == "£,B" ||
                  matches2 == "£,M,i,l,l,i,o,n" ||
                  matches2 == "£,m,i,l,l,i,o,n" ||
                  matches2 == "£,B,i,l,l,i,o,n" ||
                  matches2 == "£,b,i,l,l,i,o,n")
              ) {
                var value_temp1 = value1.replace("£", "");
                var value_temp2 = value2.replace("£", "");
                let pattern_rev = new RegExp(/^.*[0-9].*$/);
                if (
                  pattern_rev.test(value_temp1) &&
                  pattern_rev.test(value_temp2)
                ) {
                  companyRevenueTextValidate = true;
                } else {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                }
              } else if (
                (matches1 == "$,K" || matches1 == "$,k") &&
                (matches2 == "$,M" ||
                  matches2 == "$,m" ||
                  matches2 == "$,b" ||
                  matches2 == "$,B" ||
                  matches2 == "$,M,i,l,l,i,o,n" ||
                  matches2 == "$,m,i,l,l,i,o,n" ||
                  matches2 == "$,B,i,l,l,i,o,n" ||
                  matches2 == "$,b,i,l,l,i,o,n")
              ) {
                var value_temp1 = value1.replace("$", "");
                var value_temp2 = value2.replace("$", "");
                //snehal-task-3365-agency side---create camp--company revenue issue
                let pattern_rev = new RegExp(/^.*[0-9].*$/);
                if (
                  pattern_rev.test(value_temp1) &&
                  pattern_rev.test(value_temp2)
                ) {
                  companyRevenueTextValidate = true;
                } else {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                }
              }
            } else if (matches1 == "$" && matches2 == "$") {
              var value_temp1 = value1.replace("$", "");
              var value_temp2 = value2.replace("$", "");
              if (parseFloat(value_temp1) >= parseFloat(value_temp2)) {
                document.getElementById("companyRevenueText").style.border =
                  "1px solid red";
                this.setState({ customCompanyRevenueTextDisplay: "block" });
                companyRevenueTextValidate = false;
                //return companyRevenueTextValidate;
                break; //Sandeep-task-3397-error msg issue
              } else {
                //snehal-task-3365-agency side---create camp--company revenue issue
                let pattern_rev = new RegExp(/^.*[0-9].*$/);
                if (
                  pattern_rev.test(value_temp1) &&
                  pattern_rev.test(value_temp2)
                ) {
                  companyRevenueTextValidate = true;
                } else {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                }
              }
            } else if (
              (matches1 == "K" || matches1 == "k") &&
              (matches2 == "K" || matches2 == "k")
            ) {
              if (parseFloat(value1) >= parseFloat(value2)) {
                document.getElementById("companyRevenueText").style.border =
                  "1px solid red";
                this.setState({ customCompanyRevenueTextDisplay: "block" });
                companyRevenueTextValidate = false;
                //return companyRevenueTextValidate;
                break; //Sandeep-task-3397-error msg issue
              } else {
                //snehal-task-3365-agency side---create camp--company revenue issue
                let pattern_rev = new RegExp(/^.*[0-9].*$/);
                if (pattern_rev.test(temp_array)) {
                  companyRevenueTextValidate = true;
                } else {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                }
              }
            } else if (matches1 == "£" && matches2 == "£") {
              var value_temp1 = value1.replace("£", "");
              var value_temp2 = value2.replace("£", "");
              if (parseFloat(value_temp1) >= parseFloat(value_temp2)) {
                document.getElementById("companyRevenueText").style.border =
                  "1px solid red";
                this.setState({ customCompanyRevenueTextDisplay: "block" });
                companyRevenueTextValidate = false;
                //return companyRevenueTextValidate;
                break; //Sandeep-task-3397-error msg issue
              } else {
                //snehal-task-3365-agency side---create camp--company revenue issue
                let pattern_rev = new RegExp(/^.*[0-9].*$/);
                if (
                  pattern_rev.test(value_temp1) &&
                  pattern_rev.test(value_temp2)
                ) {
                  companyRevenueTextValidate = true;
                } else {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                }
              }
            } else if (
              matches1 != "" &&
              (matches2 == "M" ||
                matches2 == "m" ||
                matches2 == "B" ||
                matches2 == "b" ||
                matches2 == "m,i,l,l,i,o,n" ||
                matches2 == "M,i,l,l,i,o,n" ||
                matches2 == "M,I,L,L,I,O,N" ||
                matches2 == "B" ||
                matches2 == "b" ||
                matches2 == "B,i,l,l,i,o,n" ||
                matches2 == "b,i,l,l,i,o,n")
            ) {
              companyRevenueTextValidate = true;
            } //snehal-task-3409-local and PAT---create and edit camp---company revenue issue(On priority)
            else {
              var pattern3 = new RegExp(/[0-9.]*$/); // Aman - 4593- (remove the "^" for accepting numbers inside char with number - new requirement) AC- Create/Edit/Incomplete Campaign - Company Revenue
              if (pattern3.test(value1) && pattern3.test(value2)) {
                if (parseFloat(value1) >= parseFloat(value2)) {
                  document.getElementById("companyRevenueText").style.border =
                    "1px solid red";
                  this.setState({ customCompanyRevenueTextDisplay: "block" });
                  companyRevenueTextValidate = false;
                  //return companyRevenueTextValidate;
                  break; //Sandeep-task-3397-error msg issue
                } else {
                  companyRevenueTextValidate = true;
                }
              } else {
                document.getElementById("companyRevenueText").style.border =
                  "1px solid red";
                this.setState({ customCompanyRevenueTextDisplay: "block" });
                companyRevenueTextValidate = false;
                //return companyRevenueTextValidate;
                break; //Sandeep-task-3397-error msg issue
              }
            }
          }
          //snehal-task-3409-Reff(2798)---local and PAT---create and edit camp---company revenue issue(On priority)(added validation in else)
          else {
            var pattern_number_diturb = new RegExp(
              /^[0-9.+<>$£]?[0-9.+<>$£][0-9]+(\.\d{1})?[0-9]?[0-9]?[ M m B b $ £ K k million billion Million Billion]?[+ < > ]?$/ //mufiz-Task-4355-Prod issue-Agency-Create Campaign-Company Revenue Custom values issue
            );
            if (!pattern_number_diturb.test(temp_array)) {
              var matches1 = temp_array.match(
                /[million,billion,Million,Billion]/g
              );
              // Aman - 4593- (Added new RegExp expression for accpting Char/num/symbol in Company Revenue - new requirement) AC- Create/Edit/Incomplete Campaign - Company Revenue
              let NewPattern0 = new RegExp(/^\$\d+M|B\$/gi);
              let NewPattern1 = new RegExp(/^\$\d+M|B\$/gi);
              let NewPattern2 = new RegExp(
                NewPattern0.source + "to" + NewPattern1.source
              );
              let NewPattern3 = new RegExp(/^[A-Za-z]+\s|\S\(\$\d+B|M\+\)$/gi);
              let NewPattern4 = new RegExp(
                /^[A-Za-z]+\s|\S\(\$\d+M\-\$\d+B\)|\(\$\d+M\>\$\d+B\)|\(\$\d+M\<\$\d+B\)$/gi
              );
              let pattern_rev = new RegExp(/^.*[0-9].*$/);
              // Aman - 4593- (Added new RegExp expression in if condition for accpting Char/num/symbol in Company Revenue - new requirement) AC- Create/Edit/Incomplete Campaign - Company Revenue
              if (
                pattern_rev.test(temp_array) ||
                NewPattern4.test(temp_array) ||
                NewPattern3.test(temp_array) ||
                (NewPattern2.test(temp_array) &&
                  (matches1 == "M,i,l,l,i,o,n" ||
                    matches1 == "m,i,l,l,i,o,n" ||
                    matches1 == "B,i,l,l,i,o,n" ||
                    matches1 == "b,i,l,l,i,o,n"))
              ) {
                var pattern_match_full = new RegExp(
                  /^[0-9+.<>$£]?[0-9+.<>$£][0-9]+\.?[0-9]+?[0-9]?[0-9]?[ M m B b]?[i]?[l]?[l]?[i]?[o]?[n]?[<>+]?$/
                );
                // Aman - 4593- (Added new RegExp expression in if condition for accpting Char/num/symbol in Company Revenue - new requirement) AC- Create/Edit/Incomplete Campaign - Company Revenue
                if (
                  pattern_match_full.test(temp_array) ||
                  NewPattern4.test(temp_array) ||
                  NewPattern3.test(temp_array) ||
                  NewPattern2.test(temp_array)
                ) {
                  companyRevenueTextValidate = true;
                } else {
                  var value_zero1 = temp_array.replace("$", "");
                  var value_zero2 = temp_array.replace("£", "");
                  var value_zero3 = temp_array.replace("<", "");
                  var value_zero4 = temp_array.replace(">", "");
                  var value_zero5 = temp_array.replace("+", "");
                  if (
                    parseFloat(temp_array) != "0" &&
                    parseFloat(value_zero1) != "0" &&
                    parseFloat(value_zero2) != "0" &&
                    parseFloat(value_zero3) != "0" &&
                    parseFloat(value_zero4) != "0" &&
                    parseFloat(value_zero5) != "0"
                  ) {
                    var pattern_one_digit = new RegExp(/^[1-9]$/);
                    if (
                      pattern_one_digit.test(parseFloat(temp_array)) ||
                      pattern_one_digit.test(parseFloat(value_zero1)) ||
                      pattern_one_digit.test(parseFloat(value_zero2)) ||
                      pattern_one_digit.test(parseFloat(value_zero3)) ||
                      pattern_one_digit.test(parseFloat(value_zero4)) ||
                      pattern_one_digit.test(parseFloat(value_zero5))
                    ) {
                      companyRevenueTextValidate = true;
                    } else {
                      companyRevenueTextValidate = false;
                      document.getElementById(
                        "companyRevenueText"
                      ).style.border = "1px solid red";
                      this.setState({
                        customCompanyRevenueTextDisplay: "block",
                      });
                      //return companyRevenueTextValidate;
                      break; //Sandeep-task-3397-error msg issue
                    }
                  } else {
                    companyRevenueTextValidate = false;
                    document.getElementById("companyRevenueText").style.border =
                      "1px solid red";
                    this.setState({
                      customCompanyRevenueTextDisplay: "block",
                    });
                    //return companyRevenueTextValidate;
                    break; //Sandeep-task-3397-error msg issue
                  }
                }
              } else {
                companyRevenueTextValidate = false;
                document.getElementById("companyRevenueText").style.border =
                  "1px solid red";
                this.setState({ customCompanyRevenueTextDisplay: "block" });
                //return companyRevenueTextValidate;
                break; //Sandeep-task-3397-error msg issue
              }
            } else {
              var value_zero1 = temp_array.replace("$", "");
              var value_zero2 = temp_array.replace("£", "");
              var value_zero3 = temp_array.replace("<", "");
              var value_zero4 = temp_array.replace(">", "");
              var value_zero5 = temp_array.replace("+", "");
              if (
                parseFloat(temp_array) != "0" &&
                parseFloat(value_zero1) != "0" &&
                parseFloat(value_zero2) != "0" &&
                parseFloat(value_zero3) != "0" &&
                parseFloat(value_zero4) != "0" &&
                parseFloat(value_zero5) != "0"
              ) {
                companyRevenueTextValidate = true;
              } else {
                companyRevenueTextValidate = false;
                document.getElementById("companyRevenueText").style.border =
                  "1px solid red";
                this.setState({ customCompanyRevenueTextDisplay: "block" });
                //return companyRevenueTextValidate;
                break; //Sandeep-task-3397-error msg issue
              }
            }
          }
        }
      }
      // } else { //4930
      // } //4930
    }
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Validation For Industry validation
    if (this.state.IndustryNotApplicable == "Applicable") {
      let industryPickyValidate;
      let industryTextValidate;
      let excludedIndustryValidate; //snehal-task-3647-Industry Exclusion on DI - FE
      if (this.state.industry.length == 0) {
        industryPickyValidate = false;
        // Chaitanya-4906-When non-english checkbox checked will not validate industry Text
        if (this.state.nonEnglishChecked === "No") {
          if (this.state.customIndustryText == "") {
            //snehal-task-3647-Industry Exclusion on DI - FE
            industryTextValidate = false;
            if (
              this.state.excludedIndustryFile == "" &&
              this.state.excludedIndustryFlag == "Yes"
            ) {
              excludedIndustryValidate = false;
              this.setState({ excludedIndustryDisplay: "block" }); //snehal-task-3663-validations for exclude industray
            } else {
              //snehal-task-3663-validations for exclude industray
              if (
                this.state.excludedIndustryFile == "" &&
                this.state.excludedIndustryFlag == "No"
              ) {
                excludedIndustryValidate = false;
              } else {
                excludedIndustryValidate = true;
              }
            }
          } else {
            //4433-validation changes-customIndustryText
            // var pattern = new RegExp(
            // 	/^(?=.*?[A-Za-z])[A-Za-z,()\|\/\+&@\-\.\n ]+$/
            // );
            // var pattern = new RegExp(
            // 	/^(?=.*?[a-zA-Z0-9])[a-zA-Z0-9,():_\|\/\&\-\.\n ]+$/
            // 	);
            //Rutuja task 4911 Changed the above pattern and added same pattern like created campaign  as it is not accpeting + sign in edit campaign
            //Chaitanya task 5252 added ; and ' in below regex
            var pattern = new RegExp(
              /^(?=.*?[a-zA-Z0-9])[a-zA-Z0-9,;'():_\|\/\+&@\-\.\n ]+$/
            );

            if (!pattern.test(this.state.customIndustryText)) {
              document.getElementById("customIndustryText").style.border =
                "1px solid red";
              this.setState({ customIndustryTextDisplay: "block" });
              industryTextValidate = false;
              industrySpecificationform = false; //Sandeep-task-3397-error msg issue
            } else {
              //snehal-task-3663-validations for exclude industray
              if (
                (this.state.excludedIndustryFile == "" ||
                  this.state.excludedIndustryFile == undefined) &&
                this.state.excludedIndustryFlag == "Yes"
              ) {
                excludedIndustryValidate = false;
                this.setState({ excludedIndustryDisplay: "block" });
              } else {
                if (
                  this.state.excludedIndustryFile == "" &&
                  this.state.excludedIndustryFlag == "No"
                ) {
                  excludedIndustryValidate = true;
                  industryTextValidate = true;
                } else {
                  industryTextValidate = true;
                  excludedIndustryValidate = true;
                }
              }
            }
          } // end of  if
          //snehal-task-3647-Industry Exclusion on DI - FE
          if (
            industryPickyValidate === true ||
            industryTextValidate === true ||
            excludedIndustryValidate === true
          ) {
            industryPickyValidate = true;
            CampaignSpecificationform = true;
            this.setState({
              industryDisplay: "none",
              inputClassIndustry: "validate",
            });
            // return CampaignSpecificationform
          } else {
            this.setState({
              industryDisplay: "block",
              inputClassIndustry: "invalidate",
            });
            industryPickyValidate = false;
            //CampaignSpecificationform = false;
            industrySpecificationform = false; //Sandeep-task-3397-error msg issue
          }
        } else {
          //Rutuja task- 4956 - if custom field value is blank in non english campaign then it must shows error
          if (this.state.customIndustryText == "") {
            document.getElementById("customIndustryText").style.border =
              "1px solid red";
            this.setState({ customIndustryTextDisplay: "block" });
            industryTextValidate = false;
            industrySpecificationform = false;
          }
        }
      } else {
        industryPickyValidate = true;
        // Chaitanya-4906-When non-english checkbox checked will not validate industry Text
        if (this.state.nonEnglishChecked === "No") {
          if (this.state.customIndustryText == "") {
            //snehal-task-3647-Industry Exclusion on DI - FE
            //snehal-task-3663-validations for exclude industray
            if (
              (this.state.excludedIndustryFile == "" ||
                this.state.excludedIndustryFile == undefined) &&
              this.state.excludedIndustryFlag == "Yes"
            ) {
              excludedIndustryValidate = false;
              this.setState({ excludedIndustryDisplay: "block" });
            } else {
              if (
                this.state.excludedIndustryFile == "" &&
                this.state.excludedIndustryFlag == "No"
              ) {
                excludedIndustryValidate = true;
              } else {
                excludedIndustryValidate = true;
              }
            }
            industryTextValidate = true;
          } else {
            //4433-validation changes-customIndustryText
            // var pattern = new RegExp(
            // 	/^(?=.*?[A-Za-z])[A-Za-z,()\|\/\+&@\-\.\n ]+$/
            // );
            // var pattern = new RegExp(
            //   /^(?=.*?[a-zA-Z0-9])[a-zA-Z0-9,():_\|\/\&\-\.\n ]+$/
            // );
            //Rutuja task 4931 Changed the above pattern and added same pattern like created campaign  as it is not accpeting + sign in edit campaign
            //Chaitanya task 5252 added ; and ' in below regex
            var pattern = new RegExp(
              /^(?=.*?[a-zA-Z0-9])[a-zA-Z0-9,;'():_\|\/\+&@\-\.\n ]+$/
            );
            if (!pattern.test(this.state.customIndustryText)) {
              document.getElementById("customIndustryText").style.border =
                "1px solid red";
              this.setState({ customIndustryTextDisplay: "block" });
              industryTextValidate = false;
              industrySpecificationform = false; //Sandeep-task-3397-error msg issue
              //snehal-task-3663-validations for exclude industray
              if (
                (this.state.excludedIndustryFile == "" ||
                  this.state.excludedIndustryFile == undefined) &&
                this.state.excludedIndustryFlag == "Yes"
              ) {
                excludedIndustryValidate = false;
                this.setState({ excludedIndustryDisplay: "block" });
              } else {
                if (
                  this.state.excludedIndustryFile == "" &&
                  this.state.excludedIndustryFlag == "No"
                ) {
                  excludedIndustryValidate = true;
                } else {
                  excludedIndustryValidate = true;
                }
              }
            } else {
              industryTextValidate = true;
              //snehal-task-3647-Industry Exclusion on DI - FE
              if (
                (this.state.excludedIndustryFile == "" ||
                  this.state.excludedIndustryFile == undefined) &&
                this.state.excludedIndustryFlag == "Yes"
              ) {
                excludedIndustryValidate = false;
                this.setState({ excludedIndustryDisplay: "block" });
              } else {
                if (
                  this.state.excludedIndustryFile == "" &&
                  this.state.excludedIndustryFlag == "No"
                ) {
                  excludedIndustryValidate = true;
                } else {
                  excludedIndustryValidate = true;
                }
              }
            }
          } // end of  if
          //snehal-task-3647-Industry Exclusion on DI - FE
          if (
            industryPickyValidate === true &&
            industryTextValidate === true &&
            excludedIndustryValidate === true
          ) {
            industryPickyValidate = true;
            CampaignSpecificationform = true;
            this.setState({
              industryDisplay: "none",
              inputClassIndustry: "validate",
            });
            // return CampaignSpecificationform
          } else {
            industryPickyValidate = false;
            //CampaignSpecificationform = false;
            industrySpecificationform = false; //Sandeep-task-3397-error msg issue
          }
        } else {
          //Rutuja task- 4956 - if custom field value is blank in non english campaign then it must shows error
          if (this.state.customIndustryText == "") {
            document.getElementById("customIndustryText").style.border =
              "1px solid red";
            this.setState({ customIndustryTextDisplay: "block" });
            industryTextValidate = false;
            industrySpecificationform = false;
          }
        }
      }
    }
    //////////////////////////////////////////
    //snehal-task-2798-UI/UX-Changes-3.1
    //validate custom job level
    let CampaignSpecificationform_jobLevel = true; //snehal-task-3663-validations for job level
    if (this.state.customJobLevelText != "") {
      //4433-validation changes-customJobLevelText
      //var pattern = new RegExp(/^(?=.*?[A-Za-z])[A-Za-z,():\|\/\+&@\-\.\n ]+$/);
      // Chaitanya-4906-When non-english checkbox checked will not validate custom job level
      if (this.state.nonEnglishChecked === "No") {
        var pattern = new RegExp(
          /^(?=.*?[a-zA-Z0-9])[a-zA-Z0-9,():_\|\/\&\-\.\n ]+$/
        );
        if (!pattern.test(this.state.customJobLevelText)) {
          document.getElementById("jobLevelText").style.border =
            "1px solid red";
          this.setState({ customJobLevelDisplay: "block" });
          CampaignSpecificationform_jobLevel = false; //snehal-task-3663-validations for job level
          //return CampaignSpecificationform;//Sandeep-task-3397-error msg issue
        } else {
          CampaignSpecificationform_jobLevel = true; //snehal-task-3663-validations for job level
        }
      } else {
      }
    } // end of job level validation
    ///////////////////////////////////////////////
    //snehal-task-2798-UI/UX-Changes-3.1
    //validate custom job function
    if (this.state.customJobFunctionText != "") {
      // Chaitanya-4906-When non-english checkbox checked will not validate custom job fuction
      if (this.state.nonEnglishChecked === "No") {
        var pattern = new RegExp(
          /^(?=.*?[A-Za-z])[A-Za-z,():\|\/\+&@\-\.\n ]+$/
        );
        if (!pattern.test(this.state.customJobFunctionText)) {
          document.getElementById("jobFunctionText").style.border =
            "1px solid red";
          this.setState({ customJobFunctionDisplay: "block" });
          CampaignSpecificationform = false;
          //return CampaignSpecificationform;//Sandeep-task-3397-error msg issue
        } else {
          CampaignSpecificationform = true;
        }
      } else {
      }
    } // end of job Function validation
    //Job title validation
    if (this.state.jobTitle == "") {
      document.getElementById("jobTitle").style.border = "1px solid red";
      this.setState({ jobTitleDisplay: "block" });
      CampaignSpecificationform = false;
      //return CampaignSpecificationform;//Sandeep-task-3397-error msg issue
    } else {
      //4433-validation changes-jobTitle
      //var pattern = new RegExp(/^[A-Za-z\n |,\-.&:()]+$/);
      // Chaitanya-4906-When non-english checkbox checked will not validate custom job title
      if (this.state.nonEnglishChecked === "No") {
        var pattern = new RegExp(
          // Chaitanya-4955-Added + in regexp same as like create camp
          //Chaitanya task 5252 added ; and ' in below regex
          /^(?=.*?[a-zA-Z0-9])[a-zA-Z0-9,;'():_\|\/\+&\-\.\n ]+$/
        );
        if (!pattern.test(this.state.jobTitle)) {
          document.getElementById("jobTitle").style.border = "1px solid red";
          this.setState({ jobTitleDisplay2: "block" });
          CampaignSpecificationform = false;
          //return CampaignSpecificationform;//Sandeep-task-3397-error msg issue
        }
      } else {
      }
    }
    //////////////////////////////////////
    //Sandeep-task-3397-error msg issue
    if (
      CampaignSpecificationform == true &&
      companyRevenueTextValidate == true &&
      employeeSizeTextValidate &&
      industrySpecificationform == true &&
      regionSpecificationform == true &&
      countryWiseAllocationform == true &&
      stateValidateform == true &&
      cityValidateform == true &&
      zipValidateform == true &&
      stateCityZipValidateform == true &&
      CampaignSpecificationform_jobLevel == true
    ) {
      finalFormIsValid = true;
      //Sandeep-task-3397-error msg issue
    } else {
      finalFormIsValid = false;
    }

    // return CampaignSpecificationform;
    return finalFormIsValid;
    //return CampaignSpecificationform;
  }
  // {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(text area red added code)(END) */}
  // end of validateCampaignSpecification
  //start of custom question validation-snehal-task-3112-Custom Question Alias(added function)
  validateCustomQuestion() {
    var customQuestionValid = true;
    var customQuestionValid1 = true;
    if (this.state.customQuestionAliasName === "Yes") {
      if (this.state.CustomQuestionsEdit.length == 0) {
        customQuestionValid = true;
      } else {
        var pattern = new RegExp(/^(|[0-9]\d*)$/);
        var tempAlias = [];
        for (var t = 0; t < this.state.CustomQuestionsEdit.length; t++) {
          var aliasName = this.state.CustomQuestionsEdit[t].aliasName;
          var customQuestion = this.state.CustomQuestionsEdit[t].customQuestion;
          if (aliasName !== "" && customQuestion == "") {
            this.setState({ customQuestionDisplay1: "block" });
            customQuestionValid = false;
            return customQuestionValid;
          } else if (
            (aliasName == "" && customQuestion !== "") ||
            ((aliasName == "undefined" || aliasName == undefined) &&
              customQuestion !== "")
          ) {
            this.setState({ customQuestionDisplay2: "block" });
            customQuestionValid = false;
            return customQuestionValid;
          } else if (
            (aliasName == "undefined" ||
              aliasName == undefined ||
              aliasName == "") &&
            customQuestion == ""
          ) {
            customQuestionValid = true;
          } else {
            if (!pattern.test(aliasName)) {
              customQuestionValid1 = true;
              tempAlias.push(aliasName);
            } else {
              this.setState({ customQuestionDisplay3: "block" });
              customQuestionValid = false;
              return customQuestionValid;
            }
          }
          var duplicates = [];
          var tempAlias1 = [...tempAlias].sort();
          for (let i = 0; i < tempAlias1.length; i++) {
            // if (tempAlias1[i + 1] === tempAlias1[i]) {
            if (
              tempAlias1[i] &&
              tempAlias1[i + 1] &&
              tempAlias1[i + 1].toString &&
              tempAlias1[i].toString &&
              tempAlias1[i + 1].toString().replace(/\s/g, "").toLowerCase() ===
                tempAlias1[i].toString().replace(/\s/g, "").toLowerCase()
            ) {
              //kiran-4873-checked condition if tempAlias1 is exist or not and also check with lowercase
              duplicates.push(tempAlias1[i]);
            }
          }
          if (customQuestionValid1 === true && duplicates.length > 0) {
            this.setState({ customQuestionDisplay4: "block" });
            customQuestionValid = false;
            //Kiran-4922-comparing alias name with diStandardFields in loop , if found equal showing error
            for (var i = 0; i < diStandardFields.length; i++) {
              if (
                aliasName.toString().replace(/\s/g, "").toLowerCase() ===
                diStandardFields[i].replace(/\s/g, "").toLowerCase()
              ) {
                this.setState({ customQuestionDisplay5: "block" });
                customQuestionValid = false;
              }
            }
            // return customQuestionValid;
          } else if (customQuestionValid1 === true && duplicates.length === 0) {
            //Kiran-4922-comparing alias name with diStandardFields in loop , if found equal showing error
            for (var i = 0; i < diStandardFields.length; i++) {
              if (
                aliasName.toString().replace(/\s/g, "").toLowerCase() ===
                diStandardFields[i].replace(/\s/g, "").toLowerCase()
              ) {
                this.setState({ customQuestionDisplay5: "block" });
                customQuestionValid = false;
              }
            }
          } else {
          }
        }
        return customQuestionValid;
      }
    }
    //Aman-5042-added condtion to show error customQuestions if it is duplicates
    if (this.state.customQuestionAliasName === "No") {
      if (this.state.CustomQuestionsEdit.length == 0) {
        customQuestionValid = true;
      } else {
        var tempcustomQuestion = [];
        for (var t = 0; t < this.state.CustomQuestionsEdit.length; t++) {
          var aliasName = this.state.CustomQuestionsEdit[t].aliasName;
          var customQuestion = this.state.CustomQuestionsEdit[t].customQuestion;
          tempcustomQuestion.push(customQuestion);
        }

        var duplicates = [];
        var tempcustomQuestion1 = [...tempcustomQuestion].sort();
        for (let i = 0; i < tempcustomQuestion1.length; i++) {
          if (
            tempcustomQuestion1[i] &&
            tempcustomQuestion1[i + 1] &&
            tempcustomQuestion1[i + 1].toString &&
            tempcustomQuestion1[i].toString &&
            tempcustomQuestion1[i + 1]
              .toString()
              .replace(/\s/g, "")
              .toLowerCase() ===
              tempcustomQuestion1[i].toString().replace(/\s/g, "").toLowerCase()
          ) {
            duplicates.push(tempcustomQuestion1[i]);
          }
        }
        if (customQuestionValid1 === true && duplicates.length > 0) {
          this.setState({ customQuestionDisplay4: "block" });
          customQuestionValid = false;
        }
        return customQuestionValid;
      }
    }
  }
  //end of custom question validations
  updateApi(e) {
    const { isAuthenticated, user } = this.props.auth;
    const { leadInteractionDays, requiredLPApproval } =
      this.state.DeliveryOptionEdit[0];
    var agencyID = user.id;
    var campID = this.state.campID;
    // Sandeep-task-3281-added code to set default value as 0
    let BasicDetailArrayEdit = [...this.state.BasicDetailArrayEdit];
    if (this.state.BasicDetailArrayEdit[0].clientCampID == "") {
      BasicDetailArrayEdit[0] = {
        ...BasicDetailArrayEdit[0],
        ["clientCampID"]: 0,
      };
      this.state.BasicDetailArrayEdit = [...BasicDetailArrayEdit];
      this.setState({ BasicDetailArrayEdit: BasicDetailArrayEdit });
    }
    if (this.state.current == 0) {
      let data = {
        campID: campID,
        campaignDetail: this.state.BasicDetailArrayEdit,
        campaignDetailNoChangeArray:
          this.state.basicCampaignDetailNoChangeArray,
      }; // karan-task-3767-added default header
      fetch("/campaignNew/editCampaignNew", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((response) => {
          this.setState({ basicCampaignEditDeatilsMail: response });
          Swal.fire({
            title: "Basic Details Saved",
            text: "Do you want to edit further details?",
            icon: "Success",
            type: "success", //Snehal-Task-2937-success icon on sweet alert
            showCancelButton: true,
            confirmButtonColor: "#193D8F",
            cancelButtonColor: "#193D8F",
            confirmButtonText: "Yes, Move Further!",
            cancelButtonText: "Back To Main",
            showCloseButton: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.value) {
              this.setState({ current: this.state.current + 1 }, function () {
                if (this.state.current === 1) {
                  this.display_pacing_table_fun(); //Snehal-Task-2937-Function call for Display or hide pacing table
                  this.setState({
                    display1: "none",
                    display2: "block",
                    display3: "none",
                    display4: "none",
                    displaySaveandNext: "none",
                    displaySkipandNext: "block",
                  });
                }
              });
            } else if (result.dismiss === "close") {
              window.location.href = "#";
            } else {
              var campID = this.state.campID;
              var rfpCampaignID = this.state.rfpCampaignID;
              if (rfpCampaignID) {
                if (rfpCampaignID == 0) {
                  // window.location.href = '/welcomeEdit?campID='+campID+'&parentCampID='+parsed.parentCampID+'&reallocationID='+parsed.reallocationID;
                  this.props.history.push("/welcomeEdit", {
                    campID,
                    parentCampID: this.state.parentCampID,
                  }); // karan-task-3714-vapt header and query params
                } else {
                  // window.location.href = '/welcomeEdit?campID='+campID+'&parentCampID='+parsed.parentCampID+'&reallocationID='+parsed.reallocationID+'&rfpCampaignID='+rfpCampaignID;
                  this.props.history.push("/welcomeEdit", {
                    campID,
                    parentCampID: this.state.parentCampID,
                    rfpCampaignID,
                  }); // karan-task-3714-vapt header and query params
                }
              } else {
                // window.location.href = '/welcomeEdit?campID='+campID+'&parentCampID='+parsed.parentCampID+'&reallocationID='+parsed.reallocationID;
                this.props.history.push("/welcomeEdit", {
                  campID,
                  parentCampID: this.state.parentCampID,
                }); // karan-task-3714-vapt header and query params
              }
            }
          });
        });
    }

    if (this.state.current == 1) {
      let data = {
        campaignDetail: this.state.DeliveryOptionEdit,
        campaignDetailNoChangeArray:
          this.state.deliveryCampaignDetailNoChangeArray,
        pacingCampaignDetails: this.state.pacingDetailsEdit,
        pacingCampaignNoChangeArray: this.state.pacingCampaignNoChangeArray,
        pacingUnit: this.state.pacingUnit,
        campID: this.state.campID,
        flow: "Edit",
      }; // karan-task-3767-added default header
      fetch("/campaignNew/editCampaignDeliveryOption", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((response) => {
          this.setState({
            deliveryCampaignEditDetailsMail: response[0],
            pacingCampaignEditDetailsMail: response[1],
            deletePacingEditDetailsMail: response[2],
          });
          Swal.fire({
            title: "Delivery Option Saved", //snehal-Task-2937 title change
            text: "Do you want to edit further details?",
            icon: "Success",
            type: "success", //Snehal-Task-2937-success icon on sweet alert
            showCancelButton: true,
            confirmButtonColor: "#193D8F",
            cancelButtonColor: "#193D8F",
            confirmButtonText: "Yes, Move Further!",
            cancelButtonText: "Back To Main",
            allowOutsideClick: false,
            showCloseButton: true,
          }).then((result) => {
            if (result.value) {
              this.setState({ current: this.state.current + 1 }, function () {
                if (this.state.current === 2) {
                  this.setState({
                    display1: "none",
                    display2: "none",
                    display3: "block",
                    display4: "none",
                    displaySaveandNext: "none",
                    displaySkipandNext: "block",
                  });
                  //Sanjana-Task-2634-Create camp flow - step 1.3-create campaign--Page display issue,scroll page to TOP
                  document.body.scrollTop = (0, 0);
                  document.documentElement.scrollTop = (0, 0);
                }
              });
            } else if (result.dismiss === "close") {
              window.location.href = "#";
            } else {
              var campID = this.state.campID;
              //  window.location.href = '/welcomeEdit?campID='+campID+'&parentCampID='+parsed.parentCampID+'&reallocationID='+parsed.reallocationID;
              var rfpCampaignID = this.state.rfpCampaignID;
              this.props.history.push("/welcomeEdit", {
                campID,
                parentCampID: this.state.parentCampID,
              }); // karan-task-3714-vapt header and query params
            }
          });
        });
    }
    if (this.state.current == 2) {
      var data = new FormData();
      data.append("region", this.state.region);
      data.append("beneluxCountry", this.state.BeneluxcheckedList);
      data.append("dachCountry", this.state.DACHcheckedList);
      data.append("nordicCountry", this.state.NordiccheckedList);
      data.append(
        "northAfricaCountry",
        this.state.NorthernAfricaCountriescheckedList
      );
      data.append(
        "southernAfricaCountry",
        this.state.SouthernAfricaCountriescheckedList
      );
      data.append(
        "eastAfricanCountry",
        this.state.EastAfricanCountriescheckedList
      );
      data.append("westAfricaCountry", this.state.WesternAfricacheckedList);
      data.append("easternEuropeCountry", this.state.EasternEuropecheckedList);
      data.append(
        "northernEuropeCountry",
        this.state.NorthernEuropecheckedList
      );
      data.append(
        "southernEuropeCountry",
        this.state.SouthernEuropecheckedList
      );
      data.append("westernEuropeCountry", this.state.WesternEuropecheckedList);
      data.append("centralAsiaCountry", this.state.CentralAsiacheckedList);
      data.append(
        "centralOrMiddleAfricanCountry",
        this.state.CentralOrMiddleAfricancheckedList
      );
      data.append("southAsiaCountry", this.state.SouthAsiacheckedList);
      data.append("eastAsiaCountry", this.state.EastAsiacheckedList);
      data.append("southeastAsiaCountry", this.state.SoutheastAsiacheckedList);
      data.append("westernAsiaCountry", this.state.WesternAsiacheckedList);

      data.append("southAmericaCountry", this.state.SouthAmericacheckedList);
      data.append("antarcticCountry", this.state.AntarcticcheckedList);

      data.append("northAmericaCountry", this.state.NorthAmericacheckedList);

      data.append(
        "australiaAndNewZealandCountry",
        this.state.AustraliaAndNewZealandcheckedList
      );
      data.append("melanesiaCountry", this.state.MelanesiacheckedList);

      data.append("middleeastcountry", this.state.middleEastCheckedList);

      data.append("polynesiaCountry", this.state.PolynesiacheckedList);

      data.append("micronesia", this.state.MicronesiacheckedList);
      data.append(
        "southernAtlanticOcean",
        this.state.SouthernAtlanticOceancheckedList
      );

      data.append("empSizeApplicable", this.state.employeeSizeNotApplicable);
      data.append("employeeSize", this.state.employeeSizeDB);
      data.append("customEmployeeSizeText", this.state.customEmployeeSizeText);
      data.append("companyRevenue", this.state.companyRevenueDB);
      data.append(
        "customCompanyRevenueText",
        this.state.customCompanyRevenueText
      );
      data.append("industryApplicable", this.state.IndustryNotApplicable);
      data.append("industry", this.state.industryDB);
      data.append("customIndustry", this.state.customIndustryText);
      data.append("otherSpecs", this.state.otherSpecifications);
      data.append("jobFunction", this.state.jobFunctionDB);
      data.append("customJobFunction", this.state.customJobFunctionText); //snehal-task-2798-UI/UX-Changes-3.1
      data.append("jobLevel", this.state.jobLevelDB);
      data.append("customJobLevel", this.state.customJobLevelText); //snehal-task-2798-UI/UX-Changes-3.1
      data.append("jobTitlechk", this.state.jobTitlechk);
      data.append("jobTitle", this.state.jobTitle);
      data.append("stateFileCheck", this.state.stateFileCheck);
      data.append("stateFileData", this.state.stateFile);
      data.append("cityFileCheck", this.state.cityFileCheck);
      data.append("cityFileData", this.state.cityFile);
      data.append("zipCodeFileCheck", this.state.zipcodeFileCheck);
      data.append("zipCodeFileData", this.state.zipcodeFile);
      data.append("campID", this.state.campID);
      data.append("excludedIndustryFlag", this.state.excludedIndustryFlag); //snehal-task-3647-Industry Exclusion on DI - FE
      data.append("excludedIndustryFile", this.state.excludedIndustryFile); //snehal-task-3647-Industry Exclusion on DI - FE
      data.append("campaignStatus", this.state.campaignStatus); //Sonali-3257-send at BE to insert into campaignLog
      data.append(
        "campaignDetailNoChangeArray",
        JSON.stringify(this.state.campaignSpecDetailNoChangeArray)
      );
      data.append(
        "campaignDetail",
        JSON.stringify(this.state.campaignSpecificationDetailsEdit)
      );
      data.append(
        "countryWiseAllocation",
        JSON.stringify(this.state.countryWiseAllocationArray)
      );
      data.append("requiredCountryWiseAllocation", this.state.geoLeadChk);
      data.append("nonEnglishChecked", this.state.nonEnglishChecked);
      fetch("/campaignNew/editCampaignSpecification", {
        method: "POST",
        // headers: { "Content-Type": "application/json" },
        body: data,
      })
        .then((res) => res.json())
        .then((response) => {
          this.setState({ specificationCampEditDetailsMail: response });
          Swal.fire({
            title: "Campaign Specification Details Saved",
            text: "Do you want to edit further details?",
            icon: "Success",
            type: "success", //Snehal-Task-2937-success icon on sweet alert
            showCancelButton: true,
            confirmButtonColor: "#193D8F",
            cancelButtonColor: "#193D8F",
            confirmButtonText: "Yes, Move Further!",
            cancelButtonText: "Back To Main",
            showCloseButton: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.value) {
              this.setState({ current: this.state.current + 1 }, function () {
                if (this.state.current === 3) {
                  this.setState({
                    display1: "none",
                    display2: "none",
                    display3: "none",
                    display4: "block",
                    displaySaveandNext: "none",
                    displaySkipandNext: "block",
                  });
                }
              });
            } else if (result.dismiss === "close") {
              window.location.href = "#";
            } else {
              var campID = this.state.campID;
              // window.location.href = '/welcomeEdit?campID='+campID+'&parentCampID='+parsed.parentCampID+'&reallocationID='+parsed.reallocationID;
              this.props.history.push("/welcomeEdit", {
                campID,
                parentCampID: this.state.parentCampID,
              }); // karan-task-3714-vapt header and query params
            }
            //Nilesh-5310-Add Swal fire when column sequence check value is Yes
            if (this.state.columnSequenceCheck === "Yes") {
              Swal.fire({
                title: "Column Sequence Map",
                text: "Column Sequence is Mapped for the Campaign. Do You want to Update Column Sequence?",
                icon: "warning",
                type: "warning",
                confirmButtonText: "Continue",
                // showCloseButton: true,
                allowOutsideClick: false,
                cancelButtonColor: "#d33",
                showCancelButton: true,
                cancelButtonText: "Cancel",
              }).then((result) => {
                if (result.value) {
                  var campID = this.state.campID;
                  this.props.history.push("/columnSequencing", {
                    campID,
                  });
                } else if (result.dismiss === "cancel") {
                  //Nilesh-5310- User clicked "Cancel" or dismissed the modal
                }
              });
            }
          });
        });
    }
    if (this.state.current == 3) {
      let data = {
        customQuestion: this.state.CustomQuestionsEdit,
        questionDetailNoChangeArray: this.state.questionDetailNoChangeArray,
        deleteCustomQuestionArray: this.state.deleteCustomQuestionArray,
        customQuestionAliasName: this.state.customQuestionAliasName,
        customQuestionAliasNameNoChange:
          this.state.customQuestionAliasNameNoChange,
        campID: this.state.campID,
        specificationEditDetails: this.state.specificationCampEditDetailsMail,
        deliveryEditDetails: this.state.deliveryCampaignEditDetailsMail,
        basicEditDetails: this.state.basicCampaignEditDeatilsMail,
        pacingEditDetails: this.state.pacingCampaignEditDetailsMail,
        deletePacingEditDetails: this.state.deletePacingEditDetailsMail,
        campaignStatus: this.state.campaignStatus, //Sonali-3257-send at BE to insert into campaignLog
      }; // karan-task-3767-added default header
      //snehal-task-3112-Custom Question Alias
      fetch("/campaignNew/editCampaignCustomQuestion", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((response) => {
          this.setState({ response: response });
          var campID = response.campID;
          //Nilesh-5310-Add Swal fire when column sequence check value is Yes
          if (this.state.columnSequenceCheck === "Yes") {
            Swal.fire({
              title: "Column Sequence Map",
              text: "Custom Question Details Saved. Column Sequence is Mapped for the Campaign. Do You want to Update Column Sequence?",
              icon: "warning",
              type: "warning",
              confirmButtonText: "Continue",
              // showCloseButton: true,
              cancelButtonColor: "#d33",
              showCancelButton: true,
              cancelButtonText: "Cancel",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.value) {
                var campID = this.state.campID;
                this.props.history.push("/columnSequencing", {
                  campID,
                });
              } else {
                var campID = this.state.campID;
                this.props.history.push("/welcomeEdit", {
                  campID,
                  parentCampID: this.state.parentCampID,
                });
              }
            });
          } else {
            Swal.fire({
              text: "Custom Question Details Saved",
              type: "success",
              confirmButtonText: "Yes, Move Further !",
              showCloseButton: true,
              allowOutsideClick: false,
              preConfirm: () => {
                this.props.history.push("/welcomeEdit", {
                  campID,
                  parentCampID: this.state.parentCampID,
                });
              },
            });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
  submitForm(e) {
    e.preventDefault();
    if (this.state.current == 0) {
      if (this.validateForm() === false) {
      } else {
        if (
          this.state.apiStartDate ==
            this.state.BasicDetailArrayEdit[0].startDate &&
          this.state.apiEndDate == this.state.BasicDetailArrayEdit[0].endDate
        ) {
          this.display_pacing_table_fun(); //Snehal-Task-2937-Function call for Display or hide pacing table
          this.updateApi(); //snehal-Task-2937 update info when no change in SD or ED
        } else {
          //snehal-Task-2937 sweet alert when user change start date or end date (alert for deletion of pacing data)
          Swal.fire({
            type: "question",
            title:
              "Are you sure about changing the start date OR end date? As this action will remove existing monthly pacing",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#193D8F",
            cancelButtonColor: "#193D8F",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            HTML: "<button>Cancle</button>",
            showCloseButton: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.value) {
              this.deletePacingData(); //snehal-Task-2937 Function call for delete pacing data when user change start date or end date
              this.updateApi(); //snehal-Task-2937 update info when change in SD or ED
              this.display_pacing_table_fun(); //Snehal-Task-2937-Function call for Display or hide pacing table
              this.pacingMonthlyCalculation();
              this.setState({
                apiStartDate: this.state.BasicDetailArrayEdit[0].startDate,
                apiEndDate: this.state.BasicDetailArrayEdit[0].endDate,
              });
            } else if (result.dismiss === "close") {
              window.location.href = "#";
            } else {
              window.location.href = "#";
            }
          });
        }
      }
    }
    if (this.state.current == 1) {
      if (
        this.validateDeliveryOption() === false ||
        this.validatePacing() === false
      ) {
      } else {
        this.updateApi();
      }
    }

    if (this.state.current == 2) {
      if (this.validateCampaignSpecification() === false) {
      } else {
        this.updateApi();
      }
    }
    if (this.state.current == 3) {
      //snehal-task-3112-Custom Question Alias
      if (this.validateCustomQuestion() === false) {
      } else {
        this.updateApi();
      }
    }
  }
  backFunction(e) {
    e.preventDefault(); // karan-task-3714-vapt header and query params
    // var campID=this.state.campID
    // window.location.href = '/createCampaign2?campID='+campID + '&step1';
    this.setState({ current: this.state.current - 1 }, function () {
      if (this.state.current === -1) {
        this.props.history.push("/welcomeEdit", {
          campID: this.state.campID,
          parentCampID: this.state.parentCampID,
        }); // karan-task-3714-vapt header and query params
      }
      if (this.state.current === 0) {
        var campID = this.state.campID;
        this.setState({
          display1: "block",
          display2: "none",
          display3: "none",
          display4: "none",
          displaySaveandNext: "block",
          displaySkipandNext: "none",
          fromBack: true,
        });
      }
      if (this.state.current === 1) {
        this.setState({
          display1: "none",
          display2: "block",
          display3: "none",
          display4: "none",
          fromBack: true,
        });
      }
      if (this.state.current === 2) {
        this.setState({
          display1: "none",
          display2: "none",
          display3: "block",
          display4: "none",
          fromBack: true,
          displaySaveandNext: "block",
          displaySkipandNext: "none",
          //Chaitanya-4991-Removed error msg for industry,custom Job Level,custom Job Functio, job Title.
          industryDisplay: "none",
          customIndustryTextDisplay: "none",
          customJobLevelDisplay: "none",
          customJobFunctionDisplay: "none",
          jobTitleDisplay2: "none",
          inputClassIndustry: "validate",
        });
        //Chaitanya-4991-Changed custom values text area border to lightgray
        document.getElementById("customIndustryText").style.border =
          "1px solid lightgray";
        document.getElementById("jobLevelText").style.border =
          "1px solid lightgray";
        document.getElementById("jobFunctionText").style.border =
          "1px solid lightgray";
        document.getElementById("jobTitle").style.border =
          "1px solid lightgray";
      }
    });
  }

  leadAllocationChange(i, e) {
    const { name, value } = e.target;
    let countryWiseAllocationArray = [...this.state.countryWiseAllocationArray];
    countryWiseAllocationArray[i] = {
      ...countryWiseAllocationArray[i],
      [name]: value,
    };
    this.setState({
      countryWiseAllocationArray,
      tempCountryAllocation: countryWiseAllocationArray,
    });
  }

  submitLead(e) {
    let error = {};

    let geoWiseLeadAllocationLength =
      this.state.countryWiseAllocationArray.length;
    let campLeadAllocation = this.state.BasicDetailArrayEdit[0].leadAllocation;
    let geoWiseLeadsTotal = 0;
    var leadAllocationNumeric = true;
    var count = 0;
    for (let i = 0; i < geoWiseLeadAllocationLength; i++) {
      if (this.state.countryWiseAllocationArray[i].lead == "") {
        if (count === 0) {
          leadAllocationNumeric = false;
          error["requireLead"] = "Please enter countryviz lead allocation";
          //Sandeep-task-3397-error msg issue
          this.setState({ error: error });
        }
      } else {
        count++;
        if (geoWiseLeadAllocationLength <= campLeadAllocation) {
          var pattern = new RegExp(/^[1-9][0-9]*$/);
          if (!pattern.test(this.state.countryWiseAllocationArray[i].lead)) {
            leadAllocationNumeric = false;
            error["requireLead"] = "";
            error["leadAllocationNumeric"] =
              "Please enter positive numeric and non-zero value for lead allocation";
            this.setState({ error: error });
            break;
          } else {
            leadAllocationNumeric = true;
            error["requireLead"] = "";
            error["leadAllocationNumeric"] = "";
            this.setState({ error: error });
          }
        } else if (geoWiseLeadAllocationLength > campLeadAllocation) {
          var pattern = new RegExp(/^[0-9]*$/);
          if (!pattern.test(this.state.countryWiseAllocationArray[i].lead)) {
            leadAllocationNumeric = false;
            error["requireLead"] = "";
            error["leadAllocationNumeric"] =
              "Please enter positive numeric value  for lead allocation";
            this.setState({ error: error });
            break;
          } else {
            leadAllocationNumeric = true;
            error["requireLead"] = "";
            error["leadAllocationNumeric"] = "";
            this.setState({ error: error });
          }
        } else {
        }
        if (leadAllocationNumeric == true) {
          geoWiseLeadsTotal =
            parseInt(geoWiseLeadsTotal) +
            parseInt(this.state.countryWiseAllocationArray[i].lead);
        }
      }
    }

    if (leadAllocationNumeric == true) {
      if (geoWiseLeadsTotal > campLeadAllocation) {
        error["requireLead"] = "";
        error["geoWiseLeadAllocationError"] =
          "Please enter countryviz lead allocation equal to total lead allocation";
        //Sandeep-task-3397-error msg issue
        error["geoWiseLeadAllocationSuccess"] = "";
        this.setState({ error: error });
      } else if (geoWiseLeadsTotal < campLeadAllocation) {
        error["requireLead"] = "";
        error["geoWiseLeadAllocationError"] =
          "Please enter countryviz lead allocation equal to total lead allocation";
        //Sandeep-task-3397-error msg issue
        error["geoWiseLeadAllocationSuccess"] = "";
        this.setState({ error: error });
      } else {
        error["requireLead"] = "";
        error["geoWiseLeadAllocationSuccess"] =
          "CountryViz Lead Allocation Done Successfully. Please Close the Pop-up to Continue."; //Chaitanya-4334-Prod Issue-Agency-CountryViz Lead allocation- Rename of 'Cancel' button as 'Close'
        error["geoWiseLeadAllocationError"] = "";
        this.setState({
          error: error,
          countrywiseLeadDisplay2: "none",
          countrywiseLeadDisplay: "none",
          countrywiseLeadDisplay3: "none",
          countrywiseLeadDisplay4: "none",
        });
      }
    }
    return leadAllocationNumeric;
  }

  removeJobLevel(e, i) {
    let Temp = [...this.state.customJobLevel];
    Temp.splice(i, 1);
    this.setState({ customJobLevel: Temp, customJobLevelDisplay: "none" });
  }
  addJobLevel() {
    var JLcount = this.state.customJobLevel.length;
    let customjobLevel = [...this.state.customJobLevel];
    let newCustomJobLevel = { id: JLcount.toString(), value: "" };
    customjobLevel.push(newCustomJobLevel);
    this.setState({ customJobLevel: customjobLevel });
  }
  removeJobFunction(i, e) {
    let temp = [...this.state.customJobFunction];
    temp.splice(i, 1);
    this.setState({
      customJobFunction: temp,
      customJobFunctionDisplay: "none",
    });
  }
  addJobFunction() {
    var Jfcount = this.state.customJobFunction.length;
    let customJobFunction = [...this.state.customJobFunction];
    let newCustomJobFunction = { id: Jfcount.toString(), value: "" };
    customJobFunction.push(newCustomJobFunction);
    this.setState({ customJobFunction: customJobFunction });
  }
  handleclient() {
    this.props.history.push("/addAdvertiser", {
      add: "step1new",
      campID: this.state.campID,
      parentCampID: this.state.parentCampID,
    }); // Sonali-task-4053-vapt header and query params

    // window.location.href='./addAdvertiser?add=+step1new&campID='+this.state.campID+'&parentCampID='+parsed.parentCampID+'&reallocationID='+parsed.reallocationID
    // window.location.href =
    // 	"./addAdvertiser?add=step1new&campID=" +//Somnath Task-2828 Remove + Sign
    // 	this.state.campID +
    // 	"&parentCampID=" +
    // 	this.state.parentCampID;
  }

  openCountryWiseLeadAllocation() {
    let commonCountryArray = this.state.SouthAmericacheckedList.concat(
      this.state.NorthAmericacheckedList,
      this.state.EasternEuropecheckedList,
      this.state.NorthernEuropecheckedList,
      this.state.SouthernEuropecheckedList,
      this.state.WesternEuropecheckedList,
      this.state.CentralAsiacheckedList,
      this.state.EastAsiacheckedList,
      this.state.SouthAsiacheckedList,
      this.state.SoutheastAsiacheckedList,
      this.state.WesternAsiacheckedList,
      this.state.NorthernAfricaCountriescheckedList,
      this.state.CentralOrMiddleAfricancheckedList,
      this.state.SouthernAfricaCountriescheckedList,
      this.state.EastAfricanCountriescheckedList,
      this.state.WesternAfricacheckedList,
      this.state.middleEastCheckedList,
      this.state.AntarcticcheckedList,
      this.state.MelanesiacheckedList,
      this.state.PolynesiacheckedList,
      this.state.MicronesiacheckedList,
      this.state.SouthernAtlanticOceancheckedList
    );

    let countryWiseAllocationArray = [];
    let len = commonCountryArray.length;
    let tempCountryAllocation = [...this.state.tempCountryAllocation];
    for (let i = 0; i < len; i++) {
      countryWiseAllocationArray.push({
        country: commonCountryArray[i],
        lead: "",
      });
    }
    countryWiseAllocationArray.map((x) =>
      Object.assign(
        x,
        tempCountryAllocation.find((y) => y.country == x.country)
      )
    );
    this.setState({
      countryWiseAllocationArray: countryWiseAllocationArray,
      countrywiseLeadDisplay2: "none",
      countrywiseLeadDisplay: "none",
      countrywiseLeadDisplay3: "none",
      countrywiseLeadDisplay4: "none",
    });
  } // End of openCountryWiseLeadAllocation tempCountryAllocation
  //snehal-task-3608-Akshay sir--Country/region visibility--Campaign all flow
  /**
   * @author Snehal More
   * @param  Description handle change to get country count only
   * @return Description handle change to get country cout only to display
   */
  getTotalCountries() {
    let getTotalCountriesCount = this.state.BeneluxcheckedList.concat(
      this.state.DACHcheckedList,
      this.state.NordiccheckedList,
      this.state.NorthernAfricaCountriescheckedList,
      this.state.CentralOrMiddleAfricancheckedList,
      this.state.SouthernAfricaCountriescheckedList,
      this.state.EastAfricanCountriescheckedList,
      this.state.WesternAfricacheckedList,
      this.state.middleEastCheckedList,
      this.state.EasternEuropecheckedList,
      this.state.NorthernEuropecheckedList,
      this.state.SouthernEuropecheckedList,
      this.state.WesternEuropecheckedList,
      this.state.CentralAsiacheckedList,
      this.state.EastAsiacheckedList,
      this.state.SouthAsiacheckedList,
      this.state.SoutheastAsiacheckedList,
      this.state.WesternAsiacheckedList,
      this.state.SouthAmericacheckedList,
      this.state.AntarcticcheckedList,
      this.state.NorthAmericacheckedList,
      this.state.AustraliaAndNewZealandcheckedList,
      this.state.MelanesiacheckedList,
      this.state.PolynesiacheckedList,
      this.state.MicronesiacheckedList,
      this.state.SouthernAtlanticOceancheckedList
    );
    this.setState({ getTotalCountriesCount });
    this.openCountryWiseLeadAllocation(); //snehal-task-3805-country lead allocation issue
  }

  // Chaitanya-4906-Function for non-english checkbox checked
  nonEnglishChange = () => {
    this.setState(
      {
        isCheckednonEnglish: !this.state.isCheckednonEnglish,
      },
      function () {
        if (this.state.isCheckednonEnglish == false) {
          // alert("Unchecked")
          this.setState({
            nonEnglishChecked: "No",
          });
        } else {
          // alert("Checked")
          this.setState({
            nonEnglishChecked: "Yes",
            //Rutuja task 4930 make below array to null for deselecting drop down value after selecting nonenglish check boxs
            industry: [], //4930
            industryDB: [], //4930
            jobLevel: [], //4930
            jobLevelDB: [], //4930
            jobFunction: [], //4930
            jobFunctionDB: [], //4930
          });
        }
      }
    );
  };

  /**
   * @author Nilesh Zinzurao - 5310
   * @param  Description fetch column sequence check value
   * @return Description set column sequence check value
   */
  checkColumnSequence(campID) {
    let data = { campID: campID };
    fetch("/columnMapping/getColumnSequenceCheck", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((existingColumnsSequence) => {
        if (
          existingColumnsSequence &&
          existingColumnsSequence.length > 0 &&
          existingColumnsSequence[0].columnSequenceCheck
        ) {
          if (
            existingColumnsSequence[0].columnSequenceCheck.toString() === "Yes"
          ) {
            this.setState({
              columnSequenceCheck:
                existingColumnsSequence[0].columnSequenceCheck,
            });
          }
        }
      });
  }

  render() {
    return (
      <div className="main">
        <NavigationBar />
        <div id="stepName" className="stepName">
          {/* //shivani-3283-passed ID for DI label consistency */}
          <label className="step newTitle" id="labelDI">
            STEP 1 : Edit Campaign Details (Campaign ID:{this.state.campID}){" "}
          </label>
          {/*sunita-task-3113-added campaign ID as per requirement */}
        </div>
        <div id="stepper" align="center">
          <Steps
            type="navigation"
            className="stepcss"
            current={this.state.current}
            onChange={this.showForm}
          >
            <Steps.Step
              title="Basic Details"
              icon={
                <img
                  style={{ width: "32px", height: "32px" }}
                  src="BasicDetails.PNG"
                />
              }
            />
            <Steps.Step
              title="Delivery Option"
              icon={
                <img
                  style={{ width: "32px", height: "32px" }}
                  src="deliveryoption.PNG"
                />
              }
            />
            <Steps.Step
              title="Campaign Specification"
              icon={
                <img
                  style={{ width: "32px", height: "32px" }}
                  src="CampSpec.PNG"
                />
              }
            />
            <Steps.Step
              title="Custom Questions"
              icon={
                <img
                  style={{ width: "32px", height: "32px" }}
                  src="Customque.PNG"
                />
              }
            />
          </Steps>
        </div>
        <div class="container" style={{ marginTop: "-45px" }}>
          {/* snehal-task-2940-Campaign Step1-back button alignment */}
          {/* <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" style={{ paddingLeft: '10px', marginTop: '13px' }} >
				 <a href="#" onClick={this.backFunction} style={{ color: '#056eb8' }}><FaArrowAltCircleLeft size={32} style={{ float: 'left' }} /></a>
			 </div>  */}

          <div
            class="card"
            className="cardStep1"
            style={{ display: this.state.display1, marginTop: "65px" }}
          >
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                {/* kiran - 4237- R-28.7-Prod issue - Agencyside - Create Campaign - all Steps lables position misplaced */}
                {/* snehal-task-2940-Campaign Step1-back button alignment */}
                <a
                  href="#"
                  onClick={this.backFunction}
                  style={{ color: "#056eb8" }}
                >
                  <FaArrowAltCircleLeft
                    size={25}
                    style={{ float: "left", marginRight: "10px" }}
                  />
                </a>{" "}
                {/* snehal-task-2940-Campaign Step1-back button alignment */}
                <h3
                  style={{
                    color: "#193D8F",
                    fontWeight: "600",
                    fontSize: "24px",
                  }}
                >
                  1 Basic Details
                </h3>
                {/* snehal-task-2940-Campaign Step1-back button alignment */}
              </div>
              <br />
              <br />
              <br />
            </div>
            {this.state.BasicDetailArrayEdit.map((BasicDetailArrayEdit) => {
              return (
                <form method="POST" id="createcampaign" name="Campaign">
                  <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12 ">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">Campaign name</label>
                      <span className="asterisk">*</span>&nbsp;&nbsp;
                      {/* Rutuja -task 4482 added tooltip */}
                      <HtmlTooltip
                        placement="right"
                        title={
                          <React.Fragment>
                            <li>
                              {
                                "Campaign name contains: 0-9 a-z A-Z  * & % () + ,' _ = . ? : ; ^ {} | ! / -  \\ <> [] "
                              }
                            </li>
                          </React.Fragment>
                        }
                      >
                        <Icon type="question-circle" />
                      </HtmlTooltip>
                      {/* {this.state.pID!=='' && BasicDetailArrayEdit.status == 'Assign'? */}
                      {BasicDetailArrayEdit.status === "AllocatingInProgress" ||
                      BasicDetailArrayEdit.status === "Assign" ? ( //sunita-task-3142-according to this status it will disable or enabled the filed.
                        <input
                          type="text"
                          id="campaignName"
                          className="form-control inputStyle"
                          name="campaignName"
                          required
                          disabled
                          onChange={this.commonhandleChange}
                          value={BasicDetailArrayEdit.campaignName}
                        ></input>
                      ) : (
                        <input
                          type="text"
                          id="campaignName"
                          className="form-control inputStyle"
                          name="campaignName"
                          required
                          onChange={this.commonhandleChange}
                          value={BasicDetailArrayEdit.campaignName}
                        ></input>
                      )}
                      {/* rutuja -task 4482 chnage error msg */}
                      <span
                        style={{ display: this.state.cnameDisplay }}
                        className="errorMessage"
                      >
                        Please enter campaign name
                      </span>
                      <span
                        style={{ display: this.state.cnameDisplay2 }}
                        className="errorMessage"
                      >
                        Please enter valid campaign name
                      </span>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">Advertiser name</label>
                      <span className="asterisk">*</span>
                      &nbsp;&nbsp;
                      <Tooltip placement="right" title="Add Advertiser">
                        <a onClick={this.handleclient}>
                          <Icon type="plus-circle" />
                        </a>
                      </Tooltip>
                      {/* <select id="client" name="clientName"  value={BasicDetailArrayEdit.clientName}
							  class="form-control inputStyle" required onChange={this.commonhandleChange}>
							 <option value="" disabled selected>Select Client</option>
								 {this.state.clientNameDetails.map(clientname=>{return(
								 <option value={clientname} >{clientname}</option>
							 )})}
								 </select> */}
                      {(this.state.advCampId === " " ||
                        this.state.advCampId === 0 ||
                        this.state.advCampId === "null" ||
                        this.state.advCampId === null ||
                        this.state.advCampId === "undefined" ||
                        this.state.advCampId === undefined) &&
                      BasicDetailArrayEdit.status !== "Assign" &&
                      BasicDetailArrayEdit.status !== "AllocatingInProgress" &&
                      BasicDetailArrayEdit.status !== "new" ? ( //sunita-task-3142-according to this status and avdcampid it will disable or enabled the filed.
                        //  {this.state.advCampId==''?
                        <select
                          id="client"
                          name="clientName"
                          value={BasicDetailArrayEdit.clientName}
                          class="form-control inputStyle"
                          required
                          onChange={this.commonhandleChange}
                        >
                          <option value="">Select Advertiser</option>
                          {this.state.clientNameDetails.map((clientname) => {
                            return (
                              <option value={clientname}>{clientname}</option>
                            );
                          })}
                        </select>
                      ) : (
                        <select
                          id="client"
                          name="clientName"
                          value={BasicDetailArrayEdit.clientName}
                          class="form-control inputStyle"
                          required
                          disabled
                          onChange={this.commonhandleChange}
                        >
                          <option value="">Select Advertiser </option>
                          {this.state.clientNameDetails.map((clientname) => {
                            return (
                              <option value={clientname}>{clientname}</option>
                            );
                          })}
                        </select>
                      )}
                      {/* {this.state.advCampId!=""?
								 <select id="client" name="clientName" value={BasicDetailArrayEdit.clientName}class="form-control inputStyle" required disabled onChange={this.commonhandleChange}>
							 <option value="">Select Client </option>
								 {this.state.clientNameDetails.map(clientname=>{return(
								 <option value={clientname} >{clientname}</option>
 
							 )})}
								 </select>:
								 <select id="client" name="clientName" value={BasicDetailArrayEdit.clientName}class="form-control inputStyle" required onChange={this.commonhandleChange}>
								 <option value="">Select Client</option>
									 {this.state.clientNameDetails.map(clientname=>{return(
									 <option value={clientname} >{clientname}</option>
			 
								 )})}
								   </select>} */}
                      <span
                        style={{ display: this.state.clientDisplay }}
                        className="errorMessage"
                      >
                        Please enter advertiser name
                      </span>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">Agency campaign ID</label>
                      <input
                        type="text"
                        id=""
                        name="clientCampID"
                        className="form-control inputStyle"
                        onChange={this.commonhandleChange}
                        value={BasicDetailArrayEdit.clientCampID}
                      ></input>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">Campaign status</label>
                      <select
                        id="campaignStatus"
                        name="campaignStatus"
                        value={BasicDetailArrayEdit.campaignStatus}
                        disabled
                        class="form-control inputStyle"
                        onChange={this.commonhandleChange}
                      >
                        <option value="Active" selected>
                          Active
                        </option>
                        <option value="Pending">Pending</option>
                        <option value="Pause">Pause</option>
                        <option value="Completed">Completed</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">Start date</label>
                      <span className="asterisk">*</span>

                      {/* {this.state.pID==''? */}
                      {BasicDetailArrayEdit.status === "AllocatingInProgress" ||
                      BasicDetailArrayEdit.status === "Assign" ? ( //sunita-task-3142-as per status it will disable or enable the field.
                        <input
                          type="date"
                          id="startDate"
                          name="startDate"
                          min=""
                          required
                          pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                          value={BasicDetailArrayEdit.startDate}
                          className="form-control inputStyle"
                          //required
                          disabled
                          onChange={this.commonhandleChange}
                        />
                      ) : (
                        <input
                          type="date"
                          id="startDate"
                          name="startDate"
                          min=""
                          required
                          pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                          value={BasicDetailArrayEdit.startDate}
                          className="form-control inputStyle"
                          //required
                          onChange={this.commonhandleChange}
                        />
                      )}

                      <span
                        style={{ display: this.state.startDateDisplay }}
                        className="errorMessage"
                      >
                        Please enter start date
                      </span>
                      <span
                        style={{ display: this.state.startDateDisplay2 }}
                        className="errorMessage"
                      >
                        Start date can not be older than a month time period
                      </span>

                      <span
                        style={{ display: this.state.startDateDisplayRfp }}
                        className="errorMessage"
                      >
                        Start date can not be less than or equal bidding date
                        i.e {this.state.biddingDate}
                      </span>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">End date</label>
                      <span className="asterisk">*</span>

                      {/* {this.state.pID==''? */}

                      {BasicDetailArrayEdit.status === "AllocatingInProgress" ||
                      BasicDetailArrayEdit.status === "Assign" ? ( //sunita-task-3142-as per status it will disable or enable the field
                        <input
                          type="date"
                          id="endDate"
                          name="endDate"
                          min=""
                          required
                          pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                          value={BasicDetailArrayEdit.endDate}
                          className="form-control inputStyle"
                          //required
                          disabled
                          onChange={this.commonhandleChange}
                        />
                      ) : (
                        <input
                          type="date"
                          id="endDate"
                          name="endDate"
                          min=""
                          required
                          pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                          value={BasicDetailArrayEdit.endDate}
                          className="form-control inputStyle"
                          //required
                          onChange={this.commonhandleChange}
                        />
                      )}

                      <span
                        style={{ display: this.state.endDateDisplay }}
                        className="errorMessage"
                      >
                        Please enter end date
                      </span>
                      {/* <span style={{display:this.state.endDateDisplay2}}className="errorMessage">
									 Please enter end date which is greater than the current
									 </span> */}
                      <span
                        style={{ display: this.state.endDateDisplay3 }}
                        className="errorMessage"
                      >
                        Please enter end date greater than start date
                      </span>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">
                        First lead delivery date
                      </label>
                      <span className="asterisk">*</span>

                      {/* {this.state.pID==''? */}
                      {BasicDetailArrayEdit.status === "AllocatingInProgress" ||
                      BasicDetailArrayEdit.status === "Assign" ? ( //sunita-task-3142-as per status it will disable or enable the field
                        <input
                          type="date"
                          id="firstLeadDeliveryDate"
                          name="firstLeadDeliveryDate"
                          min=""
                          required
                          pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                          value={BasicDetailArrayEdit.firstLeadDeliveryDate}
                          className="form-control inputStyle"
                          //required
                          disabled
                          onChange={this.commonhandleChange}
                        />
                      ) : (
                        <input
                          type="date"
                          id="firstLeadDeliveryDate"
                          name="firstLeadDeliveryDate"
                          min=""
                          required
                          pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                          value={BasicDetailArrayEdit.firstLeadDeliveryDate}
                          className="form-control inputStyle"
                          //required
                          onChange={this.commonhandleChange}
                        />
                      )}

                      <span
                        style={{
                          display: this.state.firstLeadDeliveryDateDisplay,
                        }}
                        className="errorMessage"
                      >
                        Please enter first lead delivery date
                      </span>
                      <span
                        style={{
                          display: this.state.firstLeadDeliveryDateDisplay2,
                        }}
                        className="errorMessage"
                      >
                        Please enter first lead delivery date less than end date
                      </span>
                      {/* <span style={{display:this.state.firstLeadDeliveryDateDisplay3}}className="errorMessage">
									 Please enter first lead delivery date greater than current date
									 </span> */}
                      <span
                        style={{
                          display: this.state.firstLeadDeliveryDateDisplay4,
                        }}
                        className="errorMessage"
                      >
                        Please enter first lead delivery date greater than start
                        date
                      </span>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">
                        No. of leads per domain
                      </label>
                      <input
                        type="text"
                        id="noOfLeadPerDomain"
                        name="noOfLeadPerDomain"
                        className="form-control inputStyle"
                        onChange={this.commonhandleChange}
                        value={BasicDetailArrayEdit.noOfLeadPerDomain}
                      ></input>
                      <span
                        style={{ display: this.state.noOfLeadPerDomainDisplay }}
                        className="errorMessage"
                      >
                        Please enter only numerical values
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">Lead allocation</label>
                      <span className="asterisk">*</span>
                      <input
                        type="text"
                        id="leadAllocation"
                        name="leadAllocation"
                        className="form-control inputStyle"
                        value={BasicDetailArrayEdit.leadAllocation}
                        //name="leadAllocation"
                        required
                        onChange={this.commonhandleChange}
                      ></input>
                      <span
                        style={{ display: this.state.leadAllocationDisplay }}
                        className="errorMessage"
                      >
                        Please enter lead allocation
                      </span>
                      <span
                        style={{ display: this.state.leadAllocationDisplay2 }}
                        className="errorMessage"
                      >
                        Please enter only numerical values
                      </span>
                      <span
                        style={{ display: this.state.leadAllocationDisplay3 }}
                        className="errorMessage"
                      >
                        {/* We already allocated Totalleads now you can't edit please enter previous leads */}
                        {/* Totalleads are already allocated, you can't edit it, Please enter previous leads */}
                        {/* Total leads have already allocated you can't enter less leads than total allocation.
								   Please enter previous leads */}
                        This campaign is already accepted by Publisher, So total
                        lead can not be less than from original value. So please
                        enter higher or same as original leads
                      </span>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">CPL</label>
                      <span className="asterisk">*</span>
                      <input
                        type="text"
                        id="CPL"
                        name="CPL"
                        value={BasicDetailArrayEdit.CPL}
                        className="form-control inputStyle"
                        onChange={this.commonhandleChange}
                        required
                      ></input>
                      <span
                        style={{ display: this.state.cplDisplay }}
                        className="errorMessage"
                      >
                        Please enter CPL
                      </span>
                      <span
                        style={{ display: this.state.cplDisplay2 }}
                        className="errorMessage"
                      >
                        Please enter only numerical values
                      </span>
                      <span
                        style={{ display: this.state.cplDisplay3 }}
                        className="errorMessage"
                      >
                        {/* CPL has already allocated, you can't edit it, Please enter previous CPL */}
                        {/* CPL has  already allocated you can't enter less CPL than allocated CPL.
								 Please enter previous CPL */}
                        This campaign is already accepted by Publisher, So CPL
                        can not be less than from original value. So please
                        enter higher or same as original CPL
                      </span>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">Campaign budget</label>
                      <span className="asterisk">*</span>
                      <input
                        type="text"
                        id="budget"
                        name="budget"
                        value={BasicDetailArrayEdit.budget}
                        className="form-control inputStyle"
                        onChange={this.commonhandleChange}
                        required
                      ></input>
                      <span
                        style={{ display: this.state.cBudgetDisplay }}
                        className="errorMessage"
                      >
                        Please enter campaign budget
                      </span>
                      <span
                        style={{ display: this.state.cBudgetDisplay2 }}
                        className="errorMessage"
                      >
                        Please enter only numerical values
                      </span>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">Campaign currency</label>
                      <span className="asterisk">*</span>
                      <select
                        id="currency"
                        name="currency"
                        class="form-control inputStyle"
                        onChange={this.commonhandleChange}
                        value={BasicDetailArrayEdit.currency}
                      >
                        <option value="USD">USD - US Dollar</option>
                        <option value="EUR">EUR - Euro</option>
                        <option value="GBP">GBP - British Pound</option>
                        <option value="INR">INR - Indian Rupee</option>
                        <option value="AUD">AUD - Australian Dollar</option>
                        <option value="CAD">CAD - Canadian Dollar</option>
                        <option value="SGD">SGD - Singapore Dollar</option>
                        <option value="CHF">CHF - Swiss Franc</option>
                        <option value="MYR">MYR - Malaysian Ringgit</option>
                        <option value="JPY">JPY - Japanese Yen</option>
                        <option value="CNY">CNY - Chinese Yuan Renminbi</option>
                      </select>
                      <span
                        style={{ display: this.state.cCurrencyDisplay }}
                        className="errorMessage"
                      >
                        Please enter campaign currency
                      </span>
                    </div>
                  </div>
                </form>
              );
            })}

            <div class="float-right">
              <button
                className="btn add-button "
                style={{ marginRight: "60px" }}
                onClick={this.submitForm}
              >
                Save And Next
              </button>
            </div>
            <br />
            <br />
          </div>
          <br />
          <br />
        </div>

        {/* end of 1st container */}
        {/* start of 2nd container */}
        <div class="container">
          <div
            class="card"
            className="cardStep1"
            style={{ display: this.state.display2 }}
          >
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                {/* kiran - 4237- R-28.7-Prod issue - Agencyside - Create Campaign - all Steps lables position misplaced */}
                {/* snehal-task-2940-Campaign Step1-back button alignment */}
                <a
                  href="#"
                  onClick={this.backFunction}
                  style={{ color: "#056eb8" }}
                >
                  <FaArrowAltCircleLeft
                    size={25}
                    style={{ float: "left", marginRight: "10px" }}
                  />
                </a>{" "}
                {/* snehal-task-2940-Campaign Step1-back button alignment */}
                <h3
                  style={{
                    color: "#193D8F",
                    fontWeight: "600",
                    fontSize: "24px",
                  }}
                >
                  2 Delivery Option
                </h3>
                {/* snehal-task-2940-Campaign Step1-back button alignment */}
              </div>
              <br />
              <br />
              <br />
            </div>
            {this.state.DeliveryOptionEdit.map((DeliveryOptionEdit) => {
              return (
                <form method="POST" id="createcampaign" name="Campaign">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 ">
                      <label className="labelStyle">Pacing</label>
                      <select
                        value={DeliveryOptionEdit.pacing}
                        onChange={this.DeliveryOptionHandleChange}
                        id="pacing"
                        className="form-control"
                        name="pacing"
                      >
                        <option value="Daily">Daily</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Monthly" selected>
                          Monthly
                        </option>
                        <option value="Quaterly">Quaterly</option>
                      </select>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12 ">
                      <label className="labelStyle">
                        Campaign reporting day
                      </label>
                      <select
                        value={DeliveryOptionEdit.campaignReportingDay}
                        onChange={this.DeliveryOptionHandleChange}
                        id="campaignReportingDay"
                        className="form-control"
                        name="campaignReportingDay"
                      >
                        <option value="Monday" selected>
                          Monday
                        </option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Any" selected>
                          Any
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 ">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">Campaign type </label>
                      <br />

                      <Radio.Group
                        onChange={this.DeliveryOptionHandleChange}
                        name="abm"
                      >
                        {/* value={DeliveryOptionEdit.abm}> */}
                        <Radio
                          value="Open"
                          id="campaignType1"
                          checked={DeliveryOptionEdit.abm === "Open"}
                        >
                          Open
                        </Radio>

                        <Radio
                          value="ABM"
                          id="campaignType2"
                          checked={DeliveryOptionEdit.abm === "ABM"}
                        >
                          ABM
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 ">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">
                        Lead interaction days
                      </label>
                      <br />
                      <input
                        type="text"
                        id="leadInteractionDays"
                        name="leadInteractionDays"
                        onChange={this.DeliveryOptionHandleChange}
                        className="form-control inputStyle"
                        value={DeliveryOptionEdit.leadInteractionDays}
                      ></input>
                      <span
                        style={{
                          display: this.state.leadInteractionDaysDisplay,
                        }}
                        className="errorMessage"
                      >
                        Please enter lead interaction days
                      </span>
                      <span
                        style={{
                          display: this.state.leadInteractionDaysDisplay2,
                        }}
                        className="errorMessage"
                      >
                        Please enter only numerical values
                      </span>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 ">
                      <label id="labelStyle">Marketing channel </label>
                      <select
                        value={DeliveryOptionEdit.marketingChannel}
                        onChange={this.DeliveryOptionHandleChange}
                        id="marketingChannel"
                        className="form-control  inputStyle"
                        name="marketingChannel"
                      >
                        <option value="TeleMarketing">TeleMarketing</option>
                        <option value="Email" selected>
                          Email
                        </option>
                        <option value="Email/Telemarketing">
                          Email/Telemarketing
                        </option>
                        <option value="Display">Display</option>
                        <option value="Programmatic">Programmatic</option>
                        <option value="Social">Social</option>
                        <option value="Hybrid">Hybrid</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 ">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">Call audit </label>
                      <br />

                      <Radio.Group
                        onChange={this.DeliveryOptionHandleChange}
                        name="callAudit"
                        value={DeliveryOptionEdit.callAudit}
                        disabled={this.state.callAuditdisable}
                      >
                        <Radio
                          value="Yes"
                          id=""
                          checked={DeliveryOptionEdit.callAudit === "Yes"}
                          onChange=""
                        >
                          Yes
                        </Radio>
                        <Radio
                          value="No"
                          id=""
                          checked={DeliveryOptionEdit.callAudit === "No"}
                          onChange=""
                        >
                          No
                        </Radio>
                      </Radio.Group>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 ">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">
                        Creatives approval required{" "}
                      </label>

                      <Picky
                        value={this.state.creatives}
                        options={creatives}
                        onChange={this.selectCreativesMultipleOption.bind(this)}
                        open={false}
                        valueKey="id"
                        labelKey="name"
                        multiple={true}
                        includeSelectAll={true}
                        includeFilter={false} //snehal more-task-2938- remove filter
                        dropdownHeight={400} //snehal more-task-2938- remove filter UI change
                        numberDisplayed={1}
                        className={
                          this.state.inputcreativesApproval + " " + "pickystyle"
                        }
                      />
                      <span
                        style={{
                          display: this.state.creativesApprovalRequiredDisplay1,
                        }}
                        className="errorMessage"
                      >
                        Please select appropriate creative
                      </span>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 ">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">
                        Creatives approval required (in hrs)
                      </label>
                      <select
                        id="CreativesApprovalRequiredHrs"
                        name="creativesApprovalRequiredHrs"
                        value={DeliveryOptionEdit.creativesApprovalRequiredHrs}
                        onChange={this.DeliveryOptionHandleChange}
                        class="form-control  inputStyle"
                        disabled={this.state.creativesHrsdisable}
                      >
                        <option value="12">12 </option>
                        <option value="24" selected>
                          24{" "}
                        </option>
                        <option value="48">48 </option>
                      </select>
                    </div>
                    {/* Start - Sub-Contracting row */}
                    <div class="col-lg-4 col-md-4 col-sm-12 ">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">
                        Sub-contracting{" "}
                        <span style={{ color: "red" }}>*&nbsp;</span>
                        <Tooltip
                          title="By submitting a proposal to Agency, you are agreeing that there will be no sub-contracting for this campaign"
                          placement="right"
                        >
                          <Icon
                            type="question-circle"
                            style={{ fontSize: 12 }}
                          />
                        </Tooltip>
                      </label>
                      <br />
                      <Radio.Group
                        name="subContracting"
                        class="form-control  inputStyle"
                        value={this.state.DeliveryOptionEdit[0].subContracting}
                        onChange={this.DeliveryOptionHandleChange}
                      >
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                      </Radio.Group>
                    </div>
                    {/* End - Sub-Contracting row */}
                    <div class="col-lg-4 col-md-4 col-sm-12 ">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">
                        Creatives approval required for
                      </label>
                      <select
                        id="CreativeApprovalRequiredfor"
                        name="creativeApprovalRequiredFor"
                        disabled={this.state.creativesReqDisable}
                        value={DeliveryOptionEdit.creativeApprovalRequiredFor}
                        onChange={this.DeliveryOptionHandleChange}
                        class="form-control inputStyle"
                      >
                        <option value="Agency" selected>
                          Agency
                        </option>
                        <option value="Advertiser">Advertiser</option>
                      </select>
                    </div>
                  </div>
                </form>
              );
            })}
          </div>
          <div style={{ display: this.state.display2 }}>
            {/* Snehal- task-2937 Start of monthly lead allowcation hide*/}

            {this.state.display_pacing_table === false ? (
              <div>
                <br />
                <br />
              </div>
            ) : (
              <div>
                {" "}
                {/* Snehal- task-2937 UI chnages start */}
                <div class="row">
                  <div class="col-md-4 offset-md- offset-lg-4">
                    <div class="title" style={{ marginBottom: "10px" }}>
                      <h1 style={{ fontSize: "25px", color: "#193D8F" }}>
                        {" "}
                        Monthly Lead Allocation{" "}
                      </h1>
                    </div>
                  </div>
                </div>
                <p align="center">
                  (Instruction:Monthly Lead Allocation data will get lost if you
                  change Start/End Date)
                </p>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-10 col-md-12 col-sm-12">
                      {/*snehal more-task-2937-Css changes */}
                      <label
                        id="label"
                        style={{
                          color: "#F28C0F",
                          fontWeight: "700",
                          fontSize: "17px",
                        }}
                      >
                        &nbsp;Total Leads:{" "}
                        {this.state.BasicDetailArrayEdit[0].leadAllocation}
                        &nbsp;
                      </label>
                      {this.state.leadAllocation}&nbsp;&nbsp;
                      <label
                        id="label"
                        style={{
                          color: "#F28C0F",
                          fontWeight: "700",
                          fontSize: "17px",
                        }}
                      >
                        &nbsp;Start date :{" "}
                        {this.state.BasicDetailArrayEdit[0].startDate}&nbsp;
                      </label>
                      {this.state.startDate}&nbsp;&nbsp;
                      <label
                        id="label"
                        style={{
                          color: "#F28C0F",
                          fontWeight: "700",
                          fontSize: "17px",
                        }}
                      >
                        &nbsp;End date :{" "}
                        {this.state.BasicDetailArrayEdit[0].endDate}&nbsp;
                      </label>
                      {this.state.endDate}
                    </div>
                    <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <label id="label">Unit&nbsp;:&nbsp;&nbsp;</label>
                      <select
                        value={this.state.pacingUnit}
                        onChange={this.pacingHandleChange}
                        id="unit"
                        name="pacingUnit"
                        style={{
                          height: "30px",
                          width: "73px",
                          borderRadius: "5px",
                        }}
                      >
                        <option value="Number" selected>
                          Num
                        </option>
                        <option value="Percentage">%</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <span
                        style={{ display: this.state.leadCountDisplay }}
                        className="errorMessage"
                      >
                        Please enter Lead Count{" "}
                      </span>
                      <span
                        style={{ display: this.state.leadCountDisplay2 }}
                        className="errorMessage"
                      >
                        Please enter Lead Count equals to total Leads
                      </span>
                      <span
                        style={{ display: this.state.leadCountDisplay3 }}
                        className="errorMessage"
                      >
                        Please enter only numerical values
                      </span>
                      <span
                        style={{ display: this.state.leadPercentageDisplay }}
                        className="errorMessage"
                      >
                        Please enter Lead Percentage less than 100
                      </span>
                      <span
                        style={{ display: this.state.leadPercentageDisplay1 }}
                        className="errorMessage"
                      >
                        Please enter only numerical values{" "}
                      </span>
                      <span
                        style={{ display: this.state.pacingEndDateDisplay1 }}
                        className="errorMessage"
                      >
                        Please enter pacing End Date{" "}
                      </span>
                      <span
                        style={{ display: this.state.pacingEndDateDisplay2 }}
                        className="errorMessage"
                      >
                        Please enter pacing End Date less than Campaign End Date
                      </span>
                      <span
                        style={{ display: this.state.pacingEndDateDisplay3 }}
                        className="errorMessage"
                      >
                        Please enter pacing End Date greater than Campaign Start
                        Date
                      </span>
                      <span
                        style={{ display: this.state.pacingEndDateDisplay4 }}
                        className="errorMessage"
                      >
                        Please enter same Pacing Month and Entered Date
                      </span>
                    </div>
                  </div>
                  {/* end of row */}
                  <div class="scrollmenuForCamp">
                    <div class="table-responsive-lg">
                      <table class=" table table-striped">
                        <thead>
                          <tr
                            class="info"
                            style={{ backgroundColor: "#808080" }}
                          >
                            <th
                              style={{
                                width: "220px",
                                backgroundColor: "#808080",
                              }}
                            >
                              Month
                            </th>
                            <th
                              style={{
                                width: "220px",
                                backgroundColor: "#808080",
                              }}
                            >
                              Lead count
                            </th>
                            <th
                              style={{
                                width: "220px",
                                backgroundColor: "#808080",
                              }}
                            >
                              Lead %
                            </th>
                            <th
                              style={{
                                width: "220px",
                                backgroundColor: "#808080",
                              }}
                            >
                              End date
                            </th>
                            <th
                              style={{
                                width: "220px",
                                backgroundColor: "#808080",
                              }}
                            >
                              Carry forward
                            </th>
                            <th style={{ backgroundColor: "#808080" }}></th>
                          </tr>
                        </thead>

                        <tbody style={{ backgroundColor: "white" }}>
                          {this.state.pacingDetailsEdit.map(
                            (pacingDetailsEdit, i) => {
                              const monthMap = {
                                January: 0,
                                February: 1,
                                March: 2,
                                April: 3,
                                May: 4,
                                June: 5,
                                July: 6,
                                August: 7,
                                September: 8,
                                October: 9,
                                November: 10,
                                December: 11,
                              };
                              const currentYear = new Date().getFullYear();
                              const monthIndex =
                                monthMap[pacingDetailsEdit.pacingMonth];

                              //Chaitanya-5388- Ensure that the year is incremented after December
                              let endYear = currentYear;
                              //Chaitanya-5388- If the month index is before the current month, set the end year to the next year
                              if (monthIndex < new Date().getMonth()) {
                                endYear++;
                              }
                              // First day of the pacing month
                              const firstDayOfMonth = new Date(
                                Date.UTC(endYear, monthIndex, 1)
                              );
                              // Use the First day as the default date
                              const defaultDate = firstDayOfMonth
                                .toISOString()
                                .slice(0, 10);
                              // Setting the initial date (default date)

                              return (
                                <tr style={{ backgroundColor: "white" }}>
                                  <td>
                                    <input
                                      type="text"
                                      id="pacingMonth"
                                      name="pacingMonth"
                                      value={pacingDetailsEdit.pacingMonth}
                                      disabled
                                      class="form-control"
                                      style={{
                                        width: "200px",
                                        borderRadius: "0px",
                                      }}
                                    />
                                  </td>

                                  <td>
                                    {this.state.pacingUnit == "Percentage" ? (
                                      <input
                                        type="text"
                                        value={
                                          pacingDetailsEdit.pacingLeadCount
                                        }
                                        key=""
                                        class="form-control "
                                        style={{
                                          width: "150px",
                                          borderRadius: "0px",
                                        }}
                                        id={pacingDetailsEdit.rowId}
                                        name="pacingLeadCount"
                                        onChange={this.pacingHandleChange}
                                        disabled={true}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        defaultValue={
                                          pacingDetailsEdit.pacingLeadCount
                                        }
                                        key=""
                                        class="form-control "
                                        style={{
                                          width: "150px",
                                          borderRadius: "0px",
                                          backgroundColor: "white",
                                        }}
                                        id={pacingDetailsEdit.rowId}
                                        name="pacingLeadCount"
                                        onChange={this.pacingHandleChange}
                                      />
                                    )}
                                  </td>

                                  <td>
                                    {this.state.pacingUnit == "Number" ? (
                                      <input
                                        type="text"
                                        value={
                                          pacingDetailsEdit.pacingPercentage
                                        }
                                        key=""
                                        name="pacingPercentage"
                                        class="form-control"
                                        style={{
                                          width: "120px",
                                          borderRadius: "0px",
                                        }}
                                        id={pacingDetailsEdit.rowId}
                                        onChange={this.pacingHandleChange}
                                        disabled={true}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        value={
                                          pacingDetailsEdit.pacingPercentage
                                        }
                                        key=""
                                        name="pacingPercentage"
                                        class="form-control"
                                        style={{
                                          width: "120px",
                                          borderRadius: "0px",
                                          backgroundColor: "white",
                                        }}
                                        id={pacingDetailsEdit.rowId}
                                        onChange={this.pacingHandleChange}
                                      />
                                    )}
                                  </td>

                                  <td>
                                    <input
                                      type="date"
                                      class="form-control"
                                      onChange={this.pacingHandleChange.bind(
                                        this
                                      )}
                                      id={pacingDetailsEdit.rowId}
                                      // value={pacingDetailsEdit.pacingEndDate}
                                      // min=""
                                      name="pacingEndDate"
                                      //Chitanya-5388-Calendar will display 1st day of month value
                                      value={pacingDetailsEdit.pacingEndDate}
                                      defaultValue={
                                        pacingDetailsEdit.pacingEndDate ||
                                        defaultDate
                                      }
                                      min={defaultDate}
                                      style={{
                                        width: "150px",
                                        borderRadius: "0px",
                                      }}
                                    />
                                  </td>
                                  <td>
                                    {this.state.pacingDetailsEdit.length ==
                                    i + 1 ? (
                                      <Radio.Group
                                        onChange={this.pacingHandleChange}
                                        name="pacingCarryForward"
                                        defaultValue={"no"}
                                        disabled={true}
                                      >
                                        <Radio
                                          value="yes"
                                          id={pacingDetailsEdit.rowId}
                                          checked=""
                                          onChange=""
                                          name="Yes"
                                        >
                                          Yes
                                        </Radio>
                                        {/* Snehal- task-2937 value spelling change */}
                                        <Radio
                                          value="no"
                                          id={pacingDetailsEdit.rowId}
                                          checked=""
                                          onChange=""
                                          name="No"
                                        >
                                          No
                                        </Radio>
                                        {/* Snehal- task-2937 value spelling change */}
                                      </Radio.Group>
                                    ) : (
                                      <Radio.Group
                                        onChange={this.pacingHandleChange}
                                        name="pacingCarryForward"
                                        value={
                                          pacingDetailsEdit.pacingCarryForward
                                        }
                                        id={pacingDetailsEdit.rowId}
                                      >
                                        {" "}
                                        {/* Snehal- task-2937 value of carry forwaed */}
                                        <Radio
                                          value="yes"
                                          id={pacingDetailsEdit.rowId}
                                          checked=""
                                          onChange=""
                                          name="Yes"
                                        >
                                          Yes
                                        </Radio>{" "}
                                        {/* Snehal- task-2937 value spelling change */}
                                        <Radio
                                          value="no"
                                          id={pacingDetailsEdit.rowId}
                                          checked=""
                                          onChange=""
                                          name="No"
                                        >
                                          No
                                        </Radio>{" "}
                                        {/* Snehal- task-2937 value spelling change */}
                                      </Radio.Group>
                                    )}
                                  </td>
                                  <td>
                                    <Tooltip
                                      title="Please enter 0 if not required "
                                      placement="right"
                                    >
                                      <Icon type="question-circle" />
                                    </Tooltip>
                                    {/* <span class="glyphicon glyphicon-minus-sign" id={i} name={pacingCampaignDetailsData.pacingID} onClick={(e)=>this.removePacingBack(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span> */}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Snehal- task-2937 UI chnages End */}
            {/*snehal more-task-2937- end of monthly lead allowcation hide*/}
            <button
              className="btn add-button"
              onClick={this.submitForm}
              style={{ float: "right", margin: "10px" }}
            >
              Save And Next
            </button>
            <button
              type="button"
              class="btn-outline-primary"
              onClick={this.skipAndNext}
              style={{
                float: "right",
                margin: "10px",
                height: "35px",
                borderRadius: "6px",
              }}
            >
              Skip And Next
            </button>
            <button
              id="backBtnOfCamp"
              className="btn add-button"
              onClick={this.backFunction}
              style={{ margin: "10px" }}
            >
              Back
            </button>
            <br />
            <br />
            <br />
          </div>
        </div>
        {/* start of 3rd container */}
        <div class="container">
          <div
            class="card"
            style={{ display: this.state.display3 }}
            className="cardStep1"
          >
            <div class="row">
              <div class="col-lg-8 col-md-8 col-sm-12">
                {/* snehal-task-2940-Campaign Step1-back button alignment */}
                <a
                  href="#"
                  onClick={this.backFunction}
                  style={{ color: "#056eb8" }}
                >
                  <FaArrowAltCircleLeft
                    size={25}
                    style={{ float: "left", marginRight: "10px" }}
                  />
                </a>{" "}
                {/* snehal-task-2940-Campaign Step1-back button alignment */}
                <h3
                  style={{
                    color: "#193D8F",
                    fontWeight: "600",
                    fontSize: "24px",
                  }}
                >
                  3 Campaign Specifications
                </h3>
                {/* snehal-task-2940-Campaign Step1-back button alignment */}
              </div>
              <br />
              <br />
              <br />
            </div>
            <form method="POST" id="createcampaign" name="Campaign">
              {/* //snehal-task-3608-Akshay sir--Country/region visibility--Campaign all flow (added onClick funtion to get total selected countries) */}
              <div class="row" style={{ width: "95%", marginLeft: "13px" }}>
                <legend>
                  <h4 style={{ color: "#F28C0F" }}>Section 1</h4>
                </legend>
                <hr
                  style={{
                    height: 2,
                    backgroundColor: "#f5f5f5",
                    borderWidth: 0,
                  }}
                />
              </div>
              <div class="row" style={{ marginLeft: "3px" }}>
                <div class="col-lg-6 col-md-6 col-sm-6 ">
                  {/* //shivani-task 3197-changed label format */}
                  <label className="labelStyle">
                    Select region and countries
                  </label>
                  <span className="asterisk">*</span>

                  <div
                    class="modal fade"
                    id="regionCountries"
                    role="dialog"
                    data-backdrop="static"
                    data-keyboard="false"
                  >
                    <div
                      class="modal-dialog modal-xl"
                      style={{ height: "555px", marginTop: "1px" }}
                    >
                      <div class="modal-content">
                        <div
                          class="modal-header custom-classPopup"
                          style={{ textAlign: "end" }}
                        >
                          <h4 class="modal-title" style={{ color: "#144999" }}>
                            <font color="white">
                              Select Region and Countries
                            </font>
                          </h4>
                          {/* <button type="button"  class="cancel-button" style={{fontSize:15,color:'#ffffff',float:"right"}} data-dismiss="modal">&times;</button> */}
                          <button
                            type="button"
                            className="btn add-button"
                            data-dismiss="modal"
                            onClick={this.getTotalCountries}
                            style={{
                              backgroundColor: "#144999",
                              border: "1px solid",
                            }}
                          >
                            Continue
                          </button>
                          {/* //snehal-task-3608-Akshay sir--Country/region visibility--Campaign all flow (added onClick funtion to get total selected countries) */}
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div
                              class="col-lg-4 col-md-12 col-sm-12"
                              style={{ height: "530px", overflowX: "scroll" }}
                            >
                              <div
                                style={{
                                  backgroundColor: " #f2f9f2",
                                  padding: "5px",
                                  zIndex: "1000",
                                  position: "sticky",
                                  top: 0,
                                }}
                              >
                                <h4 style={{ color: "orange" }}>
                                  <u>Re</u>gion
                                </h4>
                              </div>
                              <div
                                style={{
                                  borderBottom: "1px solid #E9E9E9",
                                  display: "grid",
                                }}
                              >
                                <Checkbox style={{ display: "none" }}>
                                  {" "}
                                  Select All{" "}
                                </Checkbox>

                                <Checkbox
                                  onChange={this.onCheckAllChangeBenelux}
                                  checked={this.state.checkAll23}
                                  disabled={this.state.beneluxDisabled}
                                >
                                  BENELUX
                                </Checkbox>

                                <Checkbox
                                  onChange={this.onCheckAllChangeDACH}
                                  checked={this.state.checkAll24}
                                  disabled={this.state.dachDisabled}
                                >
                                  DACH
                                </Checkbox>

                                <Checkbox
                                  onChange={this.onCheckAllChangeNordic}
                                  checked={this.state.checkAll25}
                                  disabled={this.state.nordicDisabled}
                                >
                                  NORDIC
                                </Checkbox>

                                <Checkbox
                                  onChange={
                                    this.onCheckAllChangeAustraliaAndNewZealand
                                  }
                                  checked={this.state.checkAll18}
                                  disabled={this.state.ANZdisabled}
                                >
                                  ANZ
                                </Checkbox>
                                {/* //snehal-task-3608-Akshay sir--Country/region visibility--Campaign all flow (apac is not checked so changed accordingly) */}
                                <Checkbox
                                  onChange={this.onCheckAllChangeAPAC}
                                  checked={this.state.checkAll26}
                                  disabled={this.state.ApacDisabled}
                                >
                                  APAC
                                </Checkbox>

                                <Checkbox
                                  style={{ display: "block" }}
                                  onChange={
                                    this.oncheckAllChangeRegionSelectAll
                                  }
                                  checked={this.state.checkRegionSelectAll}
                                >
                                  {" "}
                                  Select All{" "}
                                </Checkbox>

                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  America
                                </b>
                                <Checkbox
                                  style={{ display: "block" }}
                                  onChange={this.onCheckAllChangeAmericaRegion}
                                  checked={this.state.checkAmericaSelectAll}
                                >
                                  {" "}
                                  Select All{" "}
                                </Checkbox>
                                <Checkbox
                                  indeterminate={this.state.indeterminateAmr1}
                                  onChange={this.onCheckAllChangeSouthAmerica}
                                  checked={this.state.checkAll15}
                                >
                                  South America
                                </Checkbox>

                                <Checkbox
                                  onChange={this.onCheckAllChangeNorthAmerica}
                                  checked={this.state.checkAll17}
                                  indeterminate={this.state.indeterminateAmr2}
                                >
                                  North America
                                </Checkbox>

                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Europe
                                </b>
                                <Checkbox
                                  style={{ display: "block" }}
                                  onChange={this.onCheckAllChangeEurope}
                                  checked={this.state.checkEuropeSelectAll}
                                >
                                  {" "}
                                  Select All
                                </Checkbox>
                                <Checkbox
                                  onChange={this.onCheckAllChangeEasternEurope}
                                  checked={this.state.checkAll6}
                                  indeterminate={this.state.indeterminateEur1}
                                >
                                  Eastern Europe
                                </Checkbox>

                                <Checkbox
                                  onChange={this.onCheckAllChangeNorthernEurope}
                                  checked={this.state.checkAll7}
                                  indeterminate={this.state.indeterminateEur2}
                                >
                                  Northern Europe
                                </Checkbox>

                                <Checkbox
                                  onChange={this.onCheckAllChangeSouthernEurope}
                                  checked={this.state.checkAll8}
                                  indeterminate={this.state.indeterminateEur3}
                                >
                                  Southern Europe
                                </Checkbox>

                                <Checkbox
                                  onChange={this.onCheckAllChangeWesternEurope}
                                  checked={this.state.checkAll9}
                                  indeterminate={this.state.indeterminateEur4}
                                >
                                  Western Europe
                                </Checkbox>

                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Asia
                                </b>
                                <Checkbox
                                  style={{ display: "block" }}
                                  onChange={this.onCheckAllChangeAsia}
                                  checked={this.state.checkAsiaSelectAll}
                                >
                                  {" "}
                                  Select All{" "}
                                </Checkbox>
                                <Checkbox
                                  onChange={this.onCheckAllChangeCentralAsia}
                                  checked={this.state.checkAll10}
                                  indeterminate={this.state.indeterminateAsia1}
                                >
                                  Central Asia
                                </Checkbox>

                                <Checkbox
                                  onChange={this.onCheckAllChangeEastAsia}
                                  checked={this.state.checkAll11}
                                  indeterminate={this.state.indeterminateAsia2}
                                >
                                  East Asia
                                </Checkbox>

                                <Checkbox
                                  onChange={this.onCheckAllChangeSouthAsia}
                                  checked={this.state.checkAll12}
                                  indeterminate={this.state.indeterminateAsia3}
                                >
                                  South Asia
                                </Checkbox>

                                <Checkbox
                                  onChange={this.onCheckAllChangeSoutheastAsia}
                                  checked={this.state.checkAll13}
                                  indeterminate={this.state.indeterminateAsia4}
                                >
                                  Southeast Asia
                                </Checkbox>

                                <Checkbox
                                  onChange={this.onCheckAllChangeWesternAsia}
                                  checked={this.state.checkAll14}
                                  indeterminate={this.state.indeterminateAsia5}
                                >
                                  Western Asia
                                </Checkbox>

                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Africa
                                </b>
                                <Checkbox
                                  style={{ display: "block" }}
                                  onChange={this.onCheckAllChangeAfricaRegion}
                                  checked={this.state.checkAfricaSelectAll}
                                >
                                  {" "}
                                  Select All{" "}
                                </Checkbox>
                                <Checkbox
                                  onChange={
                                    this.onCheckAllChangeNorthernAfricaCountries
                                  }
                                  checked={this.state.checkAll1}
                                  indeterminate={this.state.indeterminateAfr1}
                                >
                                  Northern Africa Countries
                                </Checkbox>

                                <Checkbox
                                  onChange={
                                    this.onCheckAllChangeCentralOrMiddleAfrican
                                  }
                                  checked={this.state.checkAll2}
                                  indeterminate={this.state.indeterminateAfr2}
                                >
                                  Central or Middle African
                                </Checkbox>

                                <Checkbox
                                  onChange={
                                    this.onCheckAllChangeSouthernAfricaCountries
                                  }
                                  checked={this.state.checkAll3}
                                  indeterminate={this.state.indeterminateAfr3}
                                >
                                  Southern Africa Countries
                                </Checkbox>

                                <Checkbox
                                  onChange={
                                    this.onCheckAllChangeEastAfricanCountries
                                  }
                                  checked={this.state.checkAll4}
                                  indeterminate={this.state.indeterminateAfr4}
                                >
                                  East African Countries
                                </Checkbox>

                                <Checkbox
                                  onChange={this.onCheckAllChangeWesternAfrica}
                                  checked={this.state.checkAll5}
                                  indeterminate={this.state.indeterminateAfr5}
                                >
                                  Western Africa
                                </Checkbox>

                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {" "}
                                  Middle East
                                </b>
                                <Checkbox
                                  onChange={this.onCheckAllChangeMiddleEast}
                                  checked={this.state.checkAll27}
                                  indeterminate={this.state.indeterminateMeast}
                                >
                                  Middle East
                                </Checkbox>

                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Antarctic
                                </b>

                                <Checkbox
                                  onChange={this.onCheckAllChangeAntarctic}
                                  checked={this.state.checkAll16}
                                  indeterminate={this.state.indeterminateAnt1}
                                >
                                  Antarctic
                                </Checkbox>

                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Melanesia
                                </b>
                                <Checkbox
                                  onChange={this.onCheckAllChangeMelanesia}
                                  checked={this.state.checkAll19}
                                  indeterminate={this.state.indeterminateMel}
                                >
                                  Melanesia
                                </Checkbox>

                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Polynesia
                                </b>
                                <Checkbox
                                  onChange={this.onCheckAllChangePolynesia}
                                  checked={this.state.checkAll20}
                                  indeterminate={this.state.indeterminatePoly1}
                                >
                                  Polynesia
                                </Checkbox>

                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Micronesia
                                </b>
                                <Checkbox
                                  onChange={this.onCheckAllChangeMicronesia}
                                  checked={this.state.checkAll21}
                                  indeterminate={this.state.indeterminateMic1}
                                >
                                  Micronesia
                                </Checkbox>

                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {" "}
                                  Southern Atlantic Ocean
                                </b>
                                <Checkbox
                                  onChange={
                                    this.onCheckAllChangeSouthernAtlanticOcean
                                  }
                                  checked={this.state.checkAll22}
                                  indeterminate={this.state.indeterminateSAtlan}
                                >
                                  Southern Atlantic Ocean
                                </Checkbox>
                              </div>
                            </div>
                            <div
                              class="col-lg-8 col-md-12 col-sm-12"
                              style={{ height: "530px", overflowX: "scroll" }}
                            >
                              <div
                                style={{
                                  backgroundColor: " #f2f9f2",
                                  padding: "5px",
                                  zIndex: "1000",
                                  position: "sticky",
                                  top: 0,
                                }}
                              >
                                <h4 style={{ color: "orange" }}>
                                  <u>Co</u>untries
                                </h4>

                                <Input
                                  onChange={(e) => {
                                    this.handleChangeCountryRegionSearch(e);
                                  }}
                                  className="countrySearchInput"
                                  placeholder="Search Countries"
                                  prefix={
                                    <FaSearch
                                      size={13}
                                      style={{ float: "left" }}
                                    />
                                  }
                                />
                                <div id="colorCount" className="searchCount">
                                  0/252
                                </div>
                                {/* //mufiz-Task-Prod Issue-Agency-Custom Region and Countries---Country Count issue and Non-Selected Region showing in Region column. */}
                              </div>
                              <div id="myCountryDiv">
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  South America
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeSouthAmerica}
                                  checked={this.state.checkAll15}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeSouthAmerica}checked={this.state.checkAll15}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={SouthAmericaOptions}
                                  value={this.state.SouthAmericacheckedList}
                                  onChange={this.onChangeSouthAmerica}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  North America
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeNorthAmerica}
                                  checked={this.state.checkAll17}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeNorthAmerica } checked={this.state.checkAll17}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={NorthAmericaOptions}
                                  value={this.state.NorthAmericacheckedList}
                                  onChange={this.onChangeNorthAmerica}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Eastern Europe
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeEasternEurope}
                                  checked={this.state.checkAll6}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeEasternEurope}checked={this.state.checkAll6}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={EasternEuropeOptions}
                                  value={this.state.EasternEuropecheckedList}
                                  onChange={this.onChangeEasternEurope}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Northern Europe
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeNorthernEurope}
                                  checked={this.state.checkAll7}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeNorthernEurope}checked={this.state.checkAll7}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={NorthernEuropeOptions}
                                  value={this.state.NorthernEuropecheckedList}
                                  onChange={this.onChangeNorthernEurope}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Southern Europe
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeSouthernEurope}
                                  checked={this.state.checkAll8}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeSouthernEurope} checked={this.state.checkAll8}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={SouthernEuropeOptions}
                                  value={this.state.SouthernEuropecheckedList}
                                  onChange={this.onChangeSouthernEurope}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Western Europe
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeWesternEurope}
                                  checked={this.state.checkAll9}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeWesternEurope}  checked={this.state.checkAll9}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={WesternEuropeOptions}
                                  value={this.state.WesternEuropecheckedList}
                                  onChange={this.onChangeWesternEurope}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Central Asia
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeCentralAsia}
                                  checked={this.state.checkAll10}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox  onChange={this.onCheckAllChangeCentralAsia} checked={this.state.checkAll10}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={CentralAsiaOptions}
                                  value={this.state.CentralAsiacheckedList}
                                  onChange={this.onChangeCentralAsia}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  East Asia
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeEastAsia}
                                  checked={this.state.checkAll11}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeEastAsia} checked={this.state.checkAll11}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={EastAsiaOptions}
                                  value={this.state.EastAsiacheckedList}
                                  onChange={this.onChangeEastAsia}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  South Asia
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeSouthAsia}
                                  checked={this.state.checkAll12}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeSouthAsia} checked={this.state.checkAll12} /> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={SouthAsiaOptions}
                                  value={this.state.SouthAsiacheckedList}
                                  onChange={this.onChangeSouthAsia}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Southeast Asia
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeSoutheastAsia}
                                  checked={this.state.checkAll13}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeSoutheastAsia}checked={this.state.checkAll13}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={SoutheastAsiaOptions}
                                  value={this.state.SoutheastAsiacheckedList}
                                  onChange={this.onChangeSoutheastAsia}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Western Asia
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeWesternAsia}
                                  checked={this.state.checkAll14}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeWesternAsia}checked={this.state.checkAll14}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={WesternAsiaOptions}
                                  value={this.state.WesternAsiacheckedList}
                                  onChange={this.onChangeWesternAsia}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Northern Africa Countries
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={
                                    this.onCheckAllChangeNorthernAfricaCountries
                                  }
                                  checked={this.state.checkAll1}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeNorthernAfricaCountries}checked={this.state.checkAll1}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={NorthernAfricaCountriesOptions}
                                  value={
                                    this.state
                                      .NorthernAfricaCountriescheckedList
                                  }
                                  onChange={
                                    this.onChangeNorthernAfricaCountries
                                  }
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Central or Middle African
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={
                                    this.onCheckAllChangeCentralOrMiddleAfrican
                                  }
                                  checked={this.state.checkAll2}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeCentralOrMiddleAfrican } checked={this.state.checkAll2}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={CentralOrMiddleAfricanOptions}
                                  value={
                                    this.state.CentralOrMiddleAfricancheckedList
                                  }
                                  onChange={this.onChangeCentralOrMiddleAfrican}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Southern Africa Countries
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={
                                    this.onCheckAllChangeSouthernAfricaCountries
                                  }
                                  checked={this.state.checkAll3}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeSouthernAfricaCountries} checked={this.state.checkAll3}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={SouthernAfricaCountriesOptions}
                                  value={
                                    this.state
                                      .SouthernAfricaCountriescheckedList
                                  }
                                  onChange={
                                    this.onChangeSouthernAfricaCountries
                                  }
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  East African Countries
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={
                                    this.onCheckAllChangeEastAfricanCountries
                                  }
                                  checked={this.state.checkAll4}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeEastAfricanCountries} checked={this.state.checkAll4}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={EastAfricanCountriesOptions}
                                  value={
                                    this.state.EastAfricanCountriescheckedList
                                  }
                                  onChange={this.onChangeEastAfricanCountries}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Western Africa
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeWesternAfrica}
                                  checked={this.state.checkAll5}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeWesternAfrica} checked={this.state.checkAll5}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={WesternAfricaOptions}
                                  value={this.state.WesternAfricacheckedList}
                                  onChange={this.onChangeWesternAfrica}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Middle East
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeMiddleEast}
                                  checked={this.state.checkAll27}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeWesternAfrica} checked={this.state.checkAll5}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={MiddleEastOptions}
                                  value={this.state.middleEastCheckedList}
                                  onChange={this.onChangeMiddleEast}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Antarctic
                                </b>
                                &nbsp;&nbsp;
                                {/* <Checkbox
	 onChange={this.onCheckAllChangeAntarctic}
	 checked={this.state.checkAll16}
   >
	 Select All
   </Checkbox> */}
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeAntarctic} checked={this.state.checkAll16}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={AntarcticOptions}
                                  value={this.state.AntarcticcheckedList}
                                  onChange={this.onChangeAntarctic}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Melanesia
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeMelanesia}
                                  checked={this.state.checkAll19}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeMelanesia}checked={this.state.checkAll19}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={MelanesiaOptions}
                                  value={this.state.MelanesiacheckedList}
                                  onChange={this.onChangeMelanesia}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Polynesia
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangePolynesia}
                                  checked={this.state.checkAll20}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangePolynesia}checked={this.state.checkAll20}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={PolynesiaOptions}
                                  value={this.state.PolynesiacheckedList}
                                  onChange={this.onChangePolynesia}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Micronesia
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={this.onCheckAllChangeMicronesia}
                                  checked={this.state.checkAll21}
                                >
                                  select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox  onChange={this.onCheckAllChangeMicronesia}checked={this.state.checkAll21}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={MicronesiaOptions}
                                  value={this.state.MicronesiacheckedList}
                                  onChange={this.onChangeMicronesia}
                                />
                                <br />
                                <br />
                                <b
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Southern Atlantic Ocean
                                </b>
                                &nbsp;&nbsp;
                                <Checkbox
                                  onChange={
                                    this.onCheckAllChangeSouthernAtlanticOcean
                                  }
                                  checked={this.state.checkAll22}
                                >
                                  Select All
                                </Checkbox>
                                <br />
                                {/* <Checkbox onChange={this.onCheckAllChangeSouthernAtlanticOcean}checked={this.state.checkAll22}/> */}
                                <CheckboxGroup
                                  style={{
                                    gridAutoColumns: "auto",
                                    columnCount: "1",
                                  }}
                                  options={SouthernAtlanticOceanOptions}
                                  value={
                                    this.state.SouthernAtlanticOceancheckedList
                                  }
                                  onChange={this.onChangeSouthernAtlanticOcean}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Icon
                    type="plus-circle"
                    style={{ height: "15px", width: "15px", marginLeft: "5px" }}
                    data-toggle="modal"
                    data-target="#regionCountries"
                  />
                  <p>
                    {" "}
                    <span
                      style={{ display: this.state.regionCountriesDisplay }}
                      className="errorMessage"
                    >
                      {" "}
                      Please select region/country{" "}
                    </span>
                  </p>
                </div>
                {/* End of Region/Country column */}

                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                  {/* //shivani-task 3197-changed label format */}
                  <label className="labelStyle">
                    Countryviz lead allocation:
                    <Tooltip title=" Please select region and countries.">
                      <Icon
                        type="question-circle-o"
                        style={{
                          marginLeft: "-4px",
                          marginBottom: "-2px",
                          padding: "1px",
                          marginTop: "4px",
                        }}
                      />
                    </Tooltip>
                  </label>
                  &nbsp;&nbsp;
                  {this.state.region.length > 0 ? (
                    <input
                      type="checkbox"
                      checked={this.state.geoLeadChk == "Yes" ? true : false}
                      value={this.state.geoLeadChk}
                      onChange={this.geoleadChange}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      checked={this.state.geoLeadChk == "Yes" ? true : false}
                      value={this.state.geoLeadChk}
                      disabled
                      onChange={this.geoleadChange}
                    />
                  )}
                  {this.state.region.length > 0 &&
                  this.state.geoLeadChk == "Yes" ? ( //Snehal-Task-3027-Country wise lead allocation display plus circle only when user check checkbox
                    <span style={{ display: this.state.geoLeadDisplay }}>
                      Please click to enter CountryViz lead allocation&nbsp;
                      <Icon
                        type="plus-circle"
                        onClick={this.openCountryWiseLeadAllocation.bind(this)}
                        style={{
                          height: "15px",
                          width: "15px",
                          marginLeft: "5px",
                        }}
                        data-toggle="modal"
                        data-target="#geoWiseLead"
                      />
                      <p>
                        <span
                          style={{ display: this.state.countrywiseLeadDisplay }}
                          className="errorMessage"
                        >
                          {" "}
                          Please enter countryviz lead allocation{" "}
                          {/* //Sandeep-task-3397-error msg issue */}
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            display: this.state.countrywiseLeadDisplay2,
                          }}
                          className="errorMessage"
                        >
                          {" "}
                          Please enter countryviz lead allocation equal to total
                          lead allocation{" "}
                          {/* //Sandeep-task-3397-error msg issue */}
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            display: this.state.countrywiseLeadDisplay3,
                          }}
                          className="errorMessage"
                        >
                          Please enter positive numeric value for lead
                          allocation{" "}
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            display: this.state.countrywiseLeadDisplay4,
                          }}
                          className="errorMessage"
                        >
                          Please enter positive numeric and non-zero value for
                          lead allocation
                        </span>
                      </p>
                    </span>
                  ) : (
                    ""
                  )}
                  <div class="modal fade" id="geoWiseLead" role="dialog">
                    <div class="modal-dialog modal-md CountryVizLead">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                          <h4
                            class="modal-title"
                            style={{
                              fontFamily: "roboto",
                              fontSize: "18px",
                              color: "rgba(25,61,143,1)",
                            }}
                          >
                            CountryViz Lead Allocation
                          </h4>
                          &nbsp;( {this.state.countryWiseAllocationArray.length}
                          &nbsp;Countries selected)
                        </div>
                        <div class="modal-body">
                          <p>
                            <span
                              style={{
                                fontFamily: "roboto",
                                fontSize: "12px",
                                color: "rgba(25,61,143,1)",
                              }}
                            >
                              Total Lead Allocation:
                            </span>
                            {this.state.BasicDetailArrayEdit[0].leadAllocation}
                            <br />
                            <span
                              class="ErrorMessage"
                              style={{
                                color: "red",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                            >
                              {this.state.error.geoWiseLeadAllocationError}
                              {this.state.error.leadAllocationNumeric}
                              {this.state.error.requireLead}
                            </span>
                            <span
                              class="SuccessMessage"
                              style={{
                                color: "green",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                            >
                              {this.state.error.geoWiseLeadAllocationSuccess}
                            </span>
                            <br /> <br />
                            <div
                              style={{ overflowY: "scroll" }}
                              className="countryWiseAllocation_popup"
                            >
                              {this.state.countryWiseAllocationArray.map(
                                (countryWiseAllocationArray, i) => {
                                  return (
                                    <span key={i}>
                                      <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                                          &nbsp; &nbsp; &nbsp;
                                          {countryWiseAllocationArray.country}
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-4 ">
                                          <Input
                                            id="lead"
                                            name="lead"
                                            defaultValue={
                                              countryWiseAllocationArray.lead
                                            }
                                            value={
                                              countryWiseAllocationArray.lead
                                            }
                                            onChange={this.leadAllocationChange.bind(
                                              this,
                                              i
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </span>
                                  );
                                }
                              )}
                            </div>
                            {/* end of scroll */}
                          </p>
                        </div>
                        <div class="modal-footer">
                          <div class="float-left">
                            <button
                              type="button"
                              className="btn mr-auto"
                              onClick={this.submitLead.bind(this)}
                              style={{
                                borderRadius: "15px",
                                width: "80px",
                                backgroundImage:
                                  "linear-gradient(to left, #007CC3,#193D8F)",
                              }}
                            >
                              {" "}
                              {/*Chaitanya-Task-4333-Prod Issue-Agency-CountryViz Lead allocation-Done button Alignment issue on Popup*/}
                              Done
                            </button>
                            <button
                              type="button"
                              class="btn btn-default"
                              data-dismiss="modal"
                              style={{ borderRadius: "15px", width: "80px" }}
                            >
                              Close{" "}
                              {/*Chaitanya-4334-Prod Issue-Agency-CountryViz Lead allocation- Rename of 'Cancel' button as 'Close'*/}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end of geoWise Modal */}
                </div>
                {/* End of Geo wise lead allocation */}
              </div>
              {/* //snehal-task-3608-Akshay sir--Country/region visibility--Campaign all flow (added table to display selected countries and regions ) */}
              {/* snehal-task-3675-changes for responssiveness */}
              {this.state.region.length === 0 ? (
                ""
              ) : this.state.region.length > 0 ||
                this.state.region !== "" ||
                this.state.region !== undefined ||
                this.state.region !== "undefined" ||
                (this.state.region !== this.state.region) !== "null" ? (
                <div
                  className="row region_display_table"
                  style={{ marginBottom: "20px" }}
                >
                  <label style={{ marginLeft: "24px" }}>
                    Total selected region{" "}
                    {this.state.region.length < 10 ? (
                      <span style={{ color: "#1890FF" }}>
                        0{this.state.region.length}
                      </span>
                    ) : (
                      <span style={{ color: "#1890FF" }}>
                        {this.state.region.length}
                      </span>
                    )}{" "}
                    and countries{" "}
                    <span style={{ color: "#1890FF" }}>
                      {this.state.getTotalCountriesCount.length}
                    </span>
                  </label>
                  <div
                    class="table-responsive-lg"
                    style={{
                      marginLeft: "24px",
                      marginRight: "-30px",
                      overflowY: "scroll",
                      height: "150px",
                      border: "1px solid #CCCCCC",
                    }}
                  >
                    {" "}
                    {/* //snehal-task-3608-changes for table */}
                    <table
                      class=" table table-striped "
                      style={{
                        border: "1px solid #CCCCCC",
                        position: "relative",
                      }}
                    >
                      <thead>
                        <tr class="info" style={{ backgroundColor: "#CCCCCC" }}>
                          <th
                            style={{
                              width: "300px",
                              backgroundColor: "#CCCCCC",
                              borderRight: "1px solid #CCCCCC",
                              position: "sticky",
                            }}
                          >
                            Selected Region
                          </th>
                          <th
                            style={{
                              width: "700px",
                              backgroundColor: "#CCCCCC",
                              position: "sticky",
                            }}
                          >
                            Selected Countries
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ backgroundColor: "white" }}>
                        {this.state.region.includes("benelux") ||
                        this.state.region.includes("BENELUX") ||
                        this.state.region.includes("Benelux") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              BENELUX
                            </td>
                            <td>Belgium,Netherlands,Luxembourg</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("dach") ||
                        this.state.region.includes("DACH") ||
                        this.state.region.includes("Dach") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              DACH
                            </td>
                            <td>Austria,Germany,Switzerland</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("nordic") ||
                        this.state.region.includes("NORDIC") ||
                        this.state.region.includes("Nordic") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              NORDIC
                            </td>
                            <td>Denmark,Finland,Iceland,Norway,Sweden</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("anz") ||
                        this.state.region.includes("ANZ") ||
                        this.state.region.includes("Anz") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              ANZ
                            </td>
                            <td>Australia,New Zealand</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("apac") ||
                        this.state.region.includes("APAC") ||
                        this.state.region.includes("Apac") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              APAC
                            </td>
                            <td>
                              Australia,Afghanistan,Bhutan,Kazakhstan,China,
                              Kyrgyzstan,Hong
                              Kong,Tajikistan,India,Turkmenistan,
                              Indonesia,Uzbekistan,Japan,Pakistan,Malaysia,Bangladesh,
                              Nepal,Sri Lanka,New Zealand,Singapore,South
                              Korea,Taiwan,Thailand,Tibet,
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("South America") ||
                        this.state.region.includes("south america") ||
                        this.state.region.includes("South america") ||
                        this.state.region.includes("south America") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              South America
                            </td>
                            <td>
                              {this.state.SouthAmericacheckedList.join(",")}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("North America") ||
                        this.state.region.includes("North america") ||
                        this.state.region.includes("north America") ||
                        this.state.region.includes("north america") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              North America
                            </td>
                            <td>
                              {this.state.NorthAmericacheckedList.join(",")}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("Eastern Europe") ||
                        this.state.region.includes("eastern Europe") ||
                        this.state.region.includes("Eastern europe") ||
                        this.state.region.includes("eastern europe") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Eastern Europe
                            </td>
                            <td>
                              {this.state.EasternEuropecheckedList.join(",")}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("Northern Europe") ||
                        this.state.region.includes("northern Europe") ||
                        this.state.region.includes("Northern europe") ||
                        this.state.region.includes("northern europe") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Northern Europe
                            </td>
                            <td>
                              {this.state.NorthernEuropecheckedList.join(",")}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("Southern Europe") ||
                        this.state.region.includes("southern Europe") ||
                        this.state.region.includes("Southern europe") ||
                        this.state.region.includes("southern europe") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Southern Europe
                            </td>
                            <td>
                              {this.state.SouthernEuropecheckedList.join(",")}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("Western Europe") ||
                        this.state.region.includes("western Europe") ||
                        this.state.region.includes("Western europe") ||
                        this.state.region.includes("western europe") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Western Europe
                            </td>
                            <td>
                              {this.state.WesternEuropecheckedList.join(",")}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("Central Asia") ||
                        this.state.region.includes("central Asia") ||
                        this.state.region.includes("Central asia") ||
                        this.state.region.includes("central asia") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Central Asia
                            </td>
                            <td>
                              {this.state.CentralAsiacheckedList.join(",")}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("East Asia") ||
                        this.state.region.includes("East asia") ||
                        this.state.region.includes("east Asia") ||
                        this.state.region.includes("east asia") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              East Asia
                            </td>
                            <td>{this.state.EastAsiacheckedList.join(",")}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {/* //snehal-task-3608-changes for table(region spelling changes) */}
                        {this.state.region.includes("South Asia") ||
                        this.state.region.includes("south Asia") ||
                        this.state.region.includes("South asia") ||
                        this.state.region.includes("south asia") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              South Asia
                            </td>
                            <td>{this.state.SouthAsiacheckedList.join(",")}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("Southeast Asia") ||
                        this.state.region.includes("Southeast asia") ||
                        this.state.region.includes("southeast Asia") ||
                        this.state.region.includes("southeast asia") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Southeast Asia
                            </td>
                            <td>
                              {this.state.SoutheastAsiacheckedList.join(",")}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("Western Asia") ||
                        this.state.region.includes("western Asia") ||
                        this.state.region.includes("Western asia") ||
                        this.state.region.includes("western asia") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Western Asia
                            </td>
                            <td>
                              {this.state.WesternAsiacheckedList.join(",")}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes(
                          "Northern Africa Countries"
                        ) ||
                        this.state.region.includes(
                          "Northern africa countries"
                        ) ||
                        this.state.region.includes(
                          "northern Africa countries"
                        ) ||
                        this.state.region.includes(
                          "northern africa Countries"
                        ) ||
                        this.state.region.includes(
                          "northern africa countries"
                        ) ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Northern Africa Countries
                            </td>
                            <td>
                              {this.state.NorthernAfricaCountriescheckedList.join(
                                ","
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes(
                          "Central or Middle African"
                        ) ||
                        this.state.region.includes(
                          "Central or Middle african"
                        ) ||
                        this.state.region.includes(
                          "central or middle african"
                        ) ||
                        this.state.region.includes(
                          "central or middle African"
                        ) ||
                        this.state.region.includes(
                          "central or Middle african"
                        ) ||
                        this.state.region.includes(
                          "central or middle African"
                        ) ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Central or Middle African
                            </td>
                            <td>
                              {this.state.CentralOrMiddleAfricancheckedList.join(
                                ","
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes(
                          "Southern Africa Countries"
                        ) ||
                        this.state.region.includes(
                          "southern africa countries"
                        ) ||
                        this.state.region.includes(
                          "Southern Africa countries"
                        ) ||
                        this.state.region.includes(
                          "southern Africa Countries"
                        ) ||
                        this.state.region.includes(
                          "southern africa Countries"
                        ) ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Southern Africa Countries
                            </td>
                            <td>
                              {this.state.SouthernAfricaCountriescheckedList.join(
                                ","
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {/* //snehal-task-3608-changes for table(region spelling changes) */}
                        {this.state.region.includes("East African Countries") ||
                        this.state.region.includes("East African Countries") ||
                        this.state.region.includes("East african countries") ||
                        this.state.region.includes("east african Countries") ||
                        this.state.region.includes("east african countries") ||
                        this.state.region.includes("East African countries") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              East African Countries
                            </td>
                            <td>
                              {this.state.EastAfricanCountriescheckedList.join(
                                ","
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("Western Africa") ||
                        this.state.region.includes("western Africa") ||
                        this.state.region.includes("Western africa") ||
                        this.state.region.includes("western africa") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Western Africa
                            </td>
                            <td>
                              {this.state.WesternAfricacheckedList.join(",")}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("Middle East") ||
                        this.state.region.includes("middle East") ||
                        this.state.region.includes("Middle aast") ||
                        this.state.region.includes("middle east") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Middle East
                            </td>
                            <td>
                              {this.state.middleEastCheckedList.join(",")}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("Antarctic") ||
                        this.state.region.includes("antarctic") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Antarctic
                            </td>
                            <td>{this.state.AntarcticcheckedList.join(",")}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("Melanesia") ||
                        this.state.region.includes("melanesia") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Melanesia
                            </td>
                            <td>{this.state.MelanesiacheckedList.join(",")}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("Polynesia") ||
                        this.state.region.includes("polynesia") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Polynesia
                            </td>
                            <td>{this.state.PolynesiacheckedList.join(",")}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes("Micronesia") ||
                        this.state.region.includes("micronesia") ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Micronesia
                            </td>
                            <td>
                              {this.state.MicronesiacheckedList.join(",")}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {this.state.region.includes(
                          "Southern Atlantic Ocean"
                        ) ||
                        this.state.region.includes("southern atlantic ocean") ||
                        this.state.region.includes("Southern atlantic ocean") ||
                        this.state.region.includes("Southern Atlantic ocean") ||
                        this.state.region.includes(
                          "southern Atlantic ocean"
                        ) ? (
                          <tr style={{ backgroundColor: "white" }}>
                            <td style={{ borderRight: "1px solid #CCCCCC" }}>
                              Southern Atlantic Ocean
                            </td>
                            <td>
                              {this.state.SouthernAtlanticOceancheckedList.join(
                                ","
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* End of 1st Row */}
              {/* snehal-task-3606-step 1.3--state, city, zip code file--Need help option/some note. */}
              <div
                class="row"
                style={{
                  border: "1px solid #CCCCCC",
                  width: "95%",
                  marginLeft: "8px",
                }}
              >
                <div class="col-lg-4 col-md-4 col-sm-12 ">
                  <label className="labelStyle">State :</label> &nbsp;&nbsp;
                  {/* snehal-task-3606-step 1.3--state, city, zip code file--Need help option/some note. */}
                  <HtmlTooltip
                    placement="right"
                    title={
                      <React.Fragment>
                        <ul>
                          <li>
                            {/* {
                              "If leads required from specific city, state and zip code then select and upload the respective files."
                            } */}
                            {/* Nilesh-5040- Update toot tip */}
                            {`1. If leads are required from specific state then upload the respective file.`}
                            <br />
                            {`2. The State file must have one column with header name as 'State'.`}
                          </li>
                        </ul>
                      </React.Fragment>
                    }
                  >
                    <Icon type="question-circle" />
                  </HtmlTooltip>
                  &nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="State"
                    name="State"
                    value=""
                    onChange={this.fileHandleChange}
                  />
                  <span
                    style={{ display: this.state.stateDisplay }}
                    className="errorMessage"
                  >
                    {" "}
                    Please select state file{" "}
                  </span>
                  {/* Sandeep-task-3397-error msg issue */}
                  <br />
                  <div id="StateFile">
                    <input
                      type="file"
                      id="stateFile"
                      name="stateFile"
                      defaultValue={this.state.stateFileBack}
                      onChange={
                        ((e) => this.fileHandleChange,
                        this.stateFilehandleChangeBack)
                      }
                      style={{
                        color: "transparent",
                        borderColor: " #193D8F",
                        display: this.state.displayStateFile,
                      }}
                    />

                    {/* { this.state.stateFileBack !=null && this.state.stateFileBack.length>0?
					   <div id="statefilesName"><a href="#" >
			   <i  class="fa fa-times removeFileBack" onClick={this.dynamicStateFile}  id="statefile"  name="statefile" />
			   </a>{this.state.stateFileBack}
								   <br/><br/>
				   </div>:""} */}
                    <div id={"stateList"} className="word-wrap"></div>
                    <div style={{ color: "red" }}>
                      {this.state.statefileerrors.fileextensions}
                    </div>
                    <div style={{ color: "red" }}>
                      {this.state.statefileHeadererrors.headerError}
                    </div>
                    <div style={{ color: "green" }}>
                      {this.state.statefileArray.headerError}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 ">
                  <label className="labelStyle">City :</label> &nbsp;&nbsp;
                  {/* snehal-task-3606-step 1.3--state, city, zip code file--Need help option/some note. */}
                  <HtmlTooltip
                    placement="right"
                    title={
                      <React.Fragment>
                        <ul>
                          <li>
                            {/* {
                              "If leads required from specific city, state and zip code then select and upload the respective files."
                            } */}
                            {/* Nilesh-5040- Update toot tip */}
                            {`1.If leads required from specific City then upload the respective file.`}
                            <br />
                            {`2.The City file must have one column with header name as 'City'.`}
                          </li>
                        </ul>
                      </React.Fragment>
                    }
                  >
                    <Icon type="question-circle" />
                  </HtmlTooltip>
                  &nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="City"
                    name="City"
                    value=""
                    onChange={this.fileHandleChange}
                  />
                  <span
                    style={{ display: this.state.cityDisplay }}
                    className="errorMessage"
                  >
                    {" "}
                    Please select city file{" "}
                  </span>
                  {/* Sandeep-task-3397-error msg issue */}
                  <br />
                  <div id="CityFile">
                    <input
                      type="file"
                      id="cityFile"
                      name="cityFile"
                      defaultValue={this.state.cityFileBack}
                      onChange={
                        ((e) => this.fileHandleChange,
                        this.cityFilehandleChangeBack)
                      }
                      style={{
                        color: "transparent",
                        borderColor: " #193D8F",
                        display: this.state.displayCityFile,
                      }}
                    />

                    {/* { this.state.cityFileBack !=null && this.state.cityFileBack.length>0?
					   <div id="cityfilesName"><a href="#" >
			   <i  class="fa fa-times removeFileBack" onClick={this.dynamicCityFile}  id="cityfile"  name="cityfile" />
			   </a>{this.state.cityFileBack}
								   <br/><br/>
				   </div>:""} */}
                    <div id={"cityList"} className="word-wrap"></div>
                    <div style={{ color: "red" }}>
                      {this.state.cityfileerrors.fileextensions}
                    </div>
                    <div style={{ color: "red" }}>
                      {this.state.cityfileHeadererrors.headerError}
                    </div>
                    <div style={{ color: "green" }}>
                      {this.state.cityfileArray.headerError}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 ">
                  {/* //shivani-task 3197-changed label format */}
                  <label className="labelStyle">Zipcode :</label> &nbsp;&nbsp;
                  {/* snehal-task-3606-step 1.3--state, city, zip code file--Need help option/some note. */}
                  <HtmlTooltip
                    placement="right"
                    title={
                      <React.Fragment>
                        <ul>
                          <li>
                            {/* {
                              "If leads required from specific city, state and zip code then select and upload the respective files."
                            } */}
                            {/* Nilesh-5040- Update toot tip */}
                            {`1.If leads required from specific Zip code then upload the respective file.`}
                            <br />
                            {`2.The Zip code file must have one column with header name as 'Zip Code'.`}
                          </li>
                        </ul>
                      </React.Fragment>
                    }
                  >
                    <Icon type="question-circle" />
                  </HtmlTooltip>
                  &nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="ZipCode"
                    name="ZipCode"
                    value=""
                    onChange={this.fileHandleChange}
                  />
                  <span
                    style={{ display: this.state.zipCodeDisplay }}
                    className="errorMessage"
                  >
                    {" "}
                    Please select zipcode file{" "}
                  </span>
                  {/* Sandeep-task-3397-error msg issue */}
                  <br />
                  <div id="ZipFile">
                    <input
                      type="file"
                      name="zipcodeFile"
                      id="zipcodeFile"
                      defaultValue={this.state.zipcodeFileBack}
                      style={{
                        color: "transparent",
                        borderColor: " #193D8F",
                        display: this.state.displayZipCodeFile,
                      }}
                      onChange={
                        ((e) => this.fileHandleChange,
                        this.zipcodeFilehandleChangeBack)
                      }
                    />

                    {/* { this.state.zipCodeFileBack !=null && this.state.zipCodeFileBack.length>0?
					   <div id="zipcodefile"><a href="#" >
			   <i  class="fa fa-times removeFileBack" onClick={this.dynamicZipCodeFile}  id="zipCodefile"  name="zipCodefile" />
			   </a>{this.state.zipCodeFileBack}
								   <br/><br/>
				   </div>:""} */}
                    <div id={"zipcodeList"} className="word-wrap"></div>
                    <div style={{ color: "red" }}>
                      {this.state.zipfileerrors.fileextensions}
                    </div>
                    <div style={{ color: "red" }}>
                      {this.state.zipfileHeadererrors.headerError}
                    </div>
                    <div style={{ color: "green" }}>
                      {this.state.zipfileArray.headerError}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              {/* End of 2nd Row */}
              {/* //snehal-task-3608-Akshay sir--Country/region visibility--Campaign all flow (added table to display selected countries and regions ) */}
              <div
                class="row" //   style={{ width: "95%", marginLeft: "6px" }}
              >
                {/* Chaitanya-4907-Added Non-English Campaign checkbox as per requirement */}
                <legend
                  style={{ height: "35px", width: "92%", marginLeft: "2%" }}
                >
                  <div class="col-xl-8 col-lg-8 col-md-7 col-sm-7">
                    <h4 style={{ color: "#F28C0F", marginLeft: "-2%" }}>
                      Section 2
                    </h4>
                  </div>
                  {this.state.nonEnglishChecked == "Yes" ? (
                    <input
                      defaultChecked
                      style={{ marginLeft: "7%" }}
                      type="checkbox"
                      checked={
                        this.state.nonEnglishChecked == "Yes" ? true : false
                      }
                      value={this.state.nonEnglishChecked}
                      onChange={this.nonEnglishChange}
                    />
                  ) : (
                    <input
                      style={{ marginLeft: "7%" }}
                      type="checkbox"
                      checked={
                        this.state.nonEnglishChecked == "Yes" ? true : false
                      }
                      value={this.state.nonEnglishChecked}
                      onChange={this.nonEnglishChange}
                    />
                  )}
                  <label
                    className="labelStyle"
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      marginLeft: "1%",
                    }}
                  >
                    Multilingual Campaign
                  </label>
                  {/* Chaitanya-5010-Changed non english label to Multilingual Campaign and added below toolip */}
                  &nbsp;&nbsp;
                  <HtmlTooltip
                    placement="right"
                    title={
                      <React.Fragment>
                        <ol>
                          {
                            " Enable this to enter Multi language Campaign Specification E.g.(English + Japanese) "
                          }
                        </ol>
                      </React.Fragment>
                    }
                  >
                    <Icon type="question-circle" />
                  </HtmlTooltip>
                </legend>
                <hr
                  style={{
                    // height: 2,
                    backgroundColor: "#f5f5f5",
                    borderWidth: 0,
                  }}
                />
              </div>
              <div class="row">
                {/* //snehal-task-2798-UI/UX-Changes-3.1(start) */}
                {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(UI changes) */}
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="card-body">
                      <label className="labelStyle">Employee size</label>
                      <span className="asterisk">*</span>&nbsp;&nbsp;
                      <Tooltip title="Not Applicable">
                        <input
                          type="checkbox"
                          name="employeeSizeNA"
                          style={{ float: "right" }}
                          onChange={this.commonhandleChange}
                          id="empSizeNA"
                        />
                      </Tooltip>
                      <p style={{ float: "right", fontSize: "12px" }}>
                        ( If N/A please ✓ )
                      </p>
                      &nbsp;&nbsp;
                      <span
                        style={{ display: this.state.employeeSizeDisplay }}
                        className="errorMessage"
                      >
                        Please select valid employee size
                      </span>
                      {/* Sandeep-task-3397-error msg issue */}
                      <Picky
                        value={this.state.employeeSize}
                        options={employeeSizeOption}
                        onChange={this.employeeHandleChange}
                        className={
                          this.state.inputClassDropDownEmployeeSize +
                          " " +
                          this.state.drpdisabled
                        }
                        open={false}
                        valueKey="id"
                        labelKey="name"
                        multiple={true}
                        includeSelectAll={true}
                        includeFilter={true}
                        dropdownHeight={200}
                        numberDisplayed={0}
                        id="employeeSize"
                        name="employeeSize"
                      />
                      <label
                        className="labelStyle"
                        style={{ fontSize: "14px", color: "#F28C0F" }}
                      >
                        Custom values :
                      </label>{" "}
                      &nbsp;&nbsp;
                      {/* <Tooltip  placement="right" html={true} title={"<ul><li>Add employee size if not available in list separated bt '|'.</li><li>Comma separated company revenue will not be allowed.</li><li>Employee size range must be include adjacent 'from-to' values(e.g 1-20).</li><li>Other allowed range: 1000|>1000|1000<</li></ul>"}><Icon type="question-circle" /></Tooltip> */}
                      {/* Sandeep-task-3779-Emp size--Help option modification- Employee Size in all flow */}
                      <HtmlTooltip
                        placement="right"
                        title={
                          <React.Fragment>
                            <ol>
                              {/* rutuja 4436 changed the toolip according to validation */}
                              <li>
                                {
                                  "Employee Size values will be separated by Enter only"
                                }
                              </li>
                              <li>
                                {"allowed ranges: () , <> a-z A-Z + - 0-9"}
                              </li>
                              {/* <li>
																{
																	"Employee size range must be include adjacent 'from-to' values(e.g 1-20)."
																}
															</li>
															<li>
																{"Other allowed ranges: 1000|>1000|1000<"}
															</li> */}
                            </ol>
                          </React.Fragment>
                        }
                      >
                        <Icon type="question-circle" />
                      </HtmlTooltip>
                      {/* Sandeep-task-3779-Emp size--Help option modification- Employee Size in all flow */}
                      <br />{" "}
                      <p>
                        <br />
                        {/*&nbsp;(
												 <span>
													e.g.1000&lt;|1000+|&gt;2500|10,000+|1,000-5,000
												</span>
												) */}
                      </p>{" "}
                      {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(UI changes) */}
                      {/* snehal-local and PAT---create and edit camp---company revenue issue(On priority) */}
                      <span
                        style={{
                          display: this.state.customEmployeeSizeTextDisplay,
                        }}
                        className="errorMessage"
                      >
                        Please enter valid values for employee size
                      </span>
                      <textarea
                        className={
                          "form-control" + " " + this.state.drpdisabled
                        }
                        style={{ height: "140px", resize: "none" }}
                        id="customEmployeeSizeText"
                        name="customEmployeeSizeText"
                        onChange={this.customEmplyeeSizeTextChange}
                        value={this.state.customEmployeeSizeText}
                      ></textarea>
                    </div>
                  </div>
                  {/* end of 2nd card */}
                </div>
                {/* end of Employee Size col */}

                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
                  {/* <div class="card" style={{width: "35rem"}}> */}
                  <div class="card">
                    <div class="card-body">
                      <label className="labelStyle">Company revenue</label>
                      {/* Chaitanya-4907-Disabled dropdown if non-english check box is checked */}
                      {/* //Rutuja -4930 as requirment changed for company reveneue removing 4906  */}
                      {/* {this.state.nonEnglishChecked === "No" ? ( */}
                      <Picky
                        value={this.state.companyRevenue}
                        options={companyRevenueOption}
                        onChange={this.revenuehandleChange}
                        className={this.state.inputClassDropDowncompanyRevenue}
                        open={false}
                        valueKey="id"
                        labelKey="name"
                        multiple={true}
                        includeSelectAll={true}
                        includeFilter={true}
                        dropdownHeight={200}
                        numberDisplayed={0}
                        id="companyRevenue"
                        name="companyRevenue"
                      />
                      {/* //Rutuja -4930 as requirment changed for company reveneue removing 4907 */}
                      {/* // ) 
                      // : (
                      //   <Picky
                      //     value={this.state.companyRevenue}
                      //     options={companyRevenueOption}
                      //     onChange={this.revenuehandleChange}
                      //     className={
                      //       this.state.inputClassDropDowncompanyRevenue
                      //     }
                      //     open={false}
                      //     valueKey="id"
                      //     labelKey="name"
                      //     multiple={true}
                      //     includeSelectAll={true}
                      //     includeFilter={true}
                      //     dropdownHeight={200}
                      //     numberDisplayed={0}
                      //     id="companyRevenue"
                      //     name="companyRevenue"
                      //     disabled
                      //   />
                      // )} */}
                      <label
                        className="labelStyle"
                        style={{ fontSize: "14px", color: "#F28C0F" }}
                      >
                        Custom values :
                      </label>{" "}
                      &nbsp;&nbsp;
                      <HtmlTooltip
                        placement="right"
                        title={
                          <React.Fragment>
                            {/* Aman-5273-added tooltip list and commented other as discussed*/}
                            <ol>
                              {/* <li>
                                {
                                  "Add company revenue if not available in list separated by '|'."
                                }
                              </li> */}
                              {/* <li>
                                {
                                  "Comma separated company revenue will not be allowed."
                                }
                              </li> */}
                              <li>
                                {
                                  "Company revenue values will be separated by 'Enter' only."
                                }
                              </li>
                              <li>
                                {
                                  "Company revenue range must be include adjacent 'from-to' values (e.g$1M-$10M)."
                                }
                              </li>
                              <li>
                                {"Other allowed ranges: $100M|>$100M|$100M<"}{" "}
                                {/*Mufiz-Task-Prod issue-Agency-Create Campaign-Company Revenue Custom values issue  */}
                              </li>
                            </ol>
                          </React.Fragment>
                        }
                      >
                        <Icon type="question-circle" />
                      </HtmlTooltip>
                      <br />
                      <p>
                        &nbsp;(<span>e.g.$1000M</span>)
                      </p>{" "}
                      {/* snehal-local and PAT---create and edit camp---company revenue issue(On priority) */}
                      <span
                        style={{
                          display: this.state.customCompanyRevenueTextDisplay,
                        }}
                        className="errorMessage"
                      >
                        Please enter valid value for company revenue
                      </span>
                      <textarea
                        className="form-control textarea_company_revenue"
                        style={{ height: "140px", resize: "none" }}
                        id="companyRevenueText"
                        name="companyRevenueText"
                        onChange={this.customCompanyRevenueTextChange}
                        value={this.state.customCompanyRevenueText}
                      ></textarea>
                    </div>
                  </div>
                  {/* end of card */}
                </div>
                {/* end of revenue col */}
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
                  {/* <div class="card" style={{width: "32rem"}}> */}
                  <div className="card_industry">
                    <div class="card">
                      <div class="card-body">
                        <label className="labelStyle">Industry</label>
                        <span className="asterisk">*</span>
                        <Tooltip title="Not Applicable">
                          <input
                            type="checkbox"
                            name="industryNA"
                            style={{ float: "right" }}
                            onChange={this.commonhandleChange}
                            id="indSizeNA"
                          />
                        </Tooltip>
                        <p style={{ float: "right", fontSize: "12px" }}>
                          ( If N/A please ✓ )
                        </p>
                        &nbsp;&nbsp;
                        <span
                          style={{ display: this.state.industryDisplay }}
                          className="errorMessage"
                        >
                          Please select valid industry
                        </span>
                        {/* Sandeep-task-3397-error msg issue */}
                        {/* Chaitanya-4907-Disabled dropdown if non-english check box is checked */}
                        {this.state.nonEnglishChecked === "No" ? (
                          <Picky
                            value={this.state.industry}
                            options={industryOptions}
                            onChange={this.industryHandleChange}
                            className={
                              this.state.inputClassIndustry +
                              " " +
                              this.state.drpdisabled1
                            }
                            open={false}
                            valueKey="id"
                            labelKey="name"
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={true}
                            dropdownHeight={200}
                            numberDisplayed={0}
                            id="industry"
                            name="industry"
                          />
                        ) : (
                          <Picky
                            value={this.state.industry}
                            options={industryOptions}
                            onChange={this.industryHandleChange}
                            className={
                              this.state.inputClassIndustry +
                              " " +
                              this.state.drpdisabled1
                            }
                            open={false}
                            valueKey="id"
                            labelKey="name"
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={true}
                            dropdownHeight={200}
                            numberDisplayed={0}
                            id="industry"
                            name="industry"
                            disabled
                          />
                        )}
                        {/*//snehal-task-3647-Industry Exclusion on DI - FE*/}
                        <label
                          className="labelStyle"
                          style={{ fontSize: "14px", color: "#F28C0F" }}
                        >
                          Upload Industry Exclusion :
                        </label>{" "}
                        &nbsp;&nbsp;
                        <HtmlTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <ul>
                                <li>
                                  {
                                    "Upload only .csv or .xlsx file for industry exclusion having cloumn header as Industry/industry"
                                  }
                                </li>
                              </ul>
                            </React.Fragment>
                          }
                        >
                          <Icon type="question-circle" />
                        </HtmlTooltip>
                        &nbsp;&nbsp;
                        <input
                          type="checkbox"
                          id="excludedIndustry"
                          name="excludedIndustry"
                          value=""
                          className={this.state.drpdisabled1}
                          onChange={this.fileHandleChange}
                        />
                        <span
                          style={{
                            display: this.state.excludedIndustryDisplay,
                          }}
                          className="errorMessage"
                        >
                          Please upload file for industry exclusion
                        </span>
                        <br />
                        <div id="IndustryFileBlock">
                          <input
                            type="file"
                            name="excludedIndustryFile"
                            id="excludedIndustryFile"
                            defaultValue={this.state.excludedIndustryFileBack}
                            style={{
                              color: "transparent",
                              borderColor: " #193D8F",
                              display: this.state.displayExcludedIndustryFile,
                            }}
                            onChange={
                              ((e) => this.fileHandleChange,
                              this.excludedIndustryFilehandleChangeBack)
                            }
                          />
                          <div
                            id={"excludedIndustryList"}
                            className="word-wrap"
                          ></div>
                          <div style={{ color: "red" }}>
                            {
                              this.state.excludedIndustryFileerrors
                                .fileextensions
                            }
                          </div>
                          <div
                            style={{ color: "red" }}
                            id="headerErrorexcludedIndustry"
                          >
                            {
                              this.state.excludedIndustryFileHeadererrors
                                .headerError
                            }
                          </div>
                          {/*bysonali */}
                          <div
                            style={{ color: "green" }}
                            id="headerSuccessexcludedIndustry"
                          >
                            {this.state.excludedIndustryFileArray.headerError}
                          </div>
                        </div>
                        <label
                          className="labelStyle"
                          style={{ fontSize: "14px", color: "#F28C0F" }}
                        >
                          Custom values :
                        </label>{" "}
                        &nbsp;&nbsp;
                        <HtmlTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <ol>
                                <li>
                                  {/* 4433-validation changes */}
                                  {/* Rutuja 4931 added + in tooltip  */}
                                  {/* Chaitanya 5252 added ; & ' in tooltip  */}
                                  {
                                    " Allowed values: - , ; ' / | & ( ) + . _ : 0-9 "
                                  }
                                </li>
                                <li>
                                  {
                                    " Industry values will be separated by 'Enter' only "
                                  }
                                </li>
                              </ol>
                            </React.Fragment>
                          }
                        >
                          <Icon type="question-circle" />
                        </HtmlTooltip>
                        <span
                          style={{
                            display: this.state.customIndustryTextDisplay,
                          }}
                          className="errorMessage"
                        >
                          Please enter valid values for industry
                        </span>
                        {/* //snehal-task-3344-edit camp---error msg issue */}
                        <textarea
                          className={
                            "form-control textarea_industry" +
                            " " +
                            this.state.drpdisabled1
                          }
                          style={{ height: "148px", resize: "none" }}
                          id="customIndustryText"
                          name="customIndustryText"
                          onChange={this.customIndustryTextChange}
                          value={this.state.customIndustryText}
                        ></textarea>
                      </div>
                    </div>
                    {/* end of 1st card */}
                  </div>
                </div>
                {/* end of industry col */}
                {/* </div> */}
                {/* End of 3rd Row */}
                <br />
                {/* <div class="row" style={{marginLeft:"-42px"}}> */}
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
                  {/* <div class="card" style={{width: "27rem"}}> */}
                  <div class="card">
                    <div class="card-body">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">Job level</label>
                      {/* Chaitanya-4907-Disabled dropdown if non-english check box is checked */}
                      {this.state.nonEnglishChecked === "No" ? (
                        <Picky
                          value={this.state.jobLevel}
                          options={jobLevelOptions}
                          onChange={this.jobLevelHandleChange}
                          //className={this.state.inputClassDropDowncompanyRevenue}
                          className="jobLevel_picky"
                          open={false}
                          valueKey="id"
                          labelKey="name"
                          multiple={true}
                          includeSelectAll={true}
                          includeFilter={true}
                          dropdownHeight={200}
                          numberDisplayed={0}
                          id="jobLevel"
                          name="jobLevel"
                        />
                      ) : (
                        <Picky
                          value={this.state.jobLevel}
                          options={jobLevelOptions}
                          onChange={this.jobLevelHandleChange}
                          //className={this.state.inputClassDropDowncompanyRevenue}
                          className="jobLevel_picky"
                          open={false}
                          valueKey="id"
                          labelKey="name"
                          multiple={true}
                          includeSelectAll={true}
                          includeFilter={true}
                          dropdownHeight={200}
                          numberDisplayed={0}
                          id="jobLevel"
                          name="jobLevel"
                          disabled
                        />
                      )}
                      <br />
                      <label
                        className="labelStyle"
                        style={{ fontSize: "14px", color: "#F28C0F" }}
                      >
                        Custom values :
                      </label>{" "}
                      &nbsp;&nbsp;
                      <HtmlTooltip
                        placement="right"
                        title={
                          <React.Fragment>
                            <ol>
                              <li>
                                {/* 4433-validation changes */}
                                {" Allowed values : - , / | & ( ) . _ : 0-9 "}
                              </li>
                              <li>
                                {
                                  " Job level values will be separated by 'Enter' only "
                                }
                              </li>
                            </ol>
                          </React.Fragment>
                        }
                      >
                        {/* Sandeep-task-3397-error msg issue */}
                        <Icon type="question-circle" />
                      </HtmlTooltip>
                      <br />
                      <span
                        style={{ display: this.state.customJobLevelDisplay }}
                        className="errorMessage"
                      >
                        Please enter valid values for job level
                      </span>
                      <textarea
                        className="form-control textarea_jobLevel"
                        style={{ height: "140px", resize: "none" }}
                        id="jobLevelText"
                        name="jobLevelText"
                        onChange={this.customJobLevelTextChange}
                        value={this.state.customJobLevelText}
                      ></textarea>
                    </div>
                  </div>
                  {/* end of Job Level card */}
                </div>
                {/* end of job level col */}

                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
                  {/* <div class="card" style={{width: "27rem"}}> */}
                  <div class="card">
                    <div class="card-body">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">Job function</label>
                      &nbsp;&nbsp;
                      {/* Chaitanya-4907-Disabled dropdown if non-english check box is checked */}
                      {this.state.nonEnglishChecked === "No" ? (
                        <Picky
                          value={this.state.jobFunction}
                          options={jobFunctionOptions}
                          onChange={this.jobFunctionHandleChange}
                          //className={this.state.inputClassDropDowncompanyRevenue}
                          //className="jobFunction_picky"
                          open={false}
                          valueKey="id"
                          labelKey="name"
                          multiple={true}
                          includeSelectAll={true}
                          includeFilter={true}
                          dropdownHeight={200}
                          numberDisplayed={0}
                          id="jobFunction"
                          name="jobFunction"
                        />
                      ) : (
                        <Picky
                          value={this.state.jobFunction}
                          options={jobFunctionOptions}
                          onChange={this.jobFunctionHandleChange}
                          //className={this.state.inputClassDropDowncompanyRevenue}
                          //className="jobFunction_picky"
                          open={false}
                          valueKey="id"
                          labelKey="name"
                          multiple={true}
                          includeSelectAll={true}
                          includeFilter={true}
                          dropdownHeight={200}
                          numberDisplayed={0}
                          id="jobFunction"
                          name="jobFunction"
                          disabled
                        />
                      )}
                      <br />
                      <label
                        className="labelStyle"
                        style={{ fontSize: "14px", color: "#F28C0F" }}
                      >
                        Custom values :
                      </label>{" "}
                      &nbsp;&nbsp;
                      <HtmlTooltip
                        placement="right"
                        title={
                          <React.Fragment>
                            <ul>
                              <li>
                                <b>{"Allowed values: & , ( ) , - , : , . "}</b>
                              </li>
                            </ul>
                          </React.Fragment>
                        }
                      >
                        {/* Sandeep-task-3397-error msg issue */}
                        <Icon type="question-circle" />
                      </HtmlTooltip>
                      <br />
                      <span
                        style={{ display: this.state.customJobFunctionDisplay }}
                        className="errorMessage"
                      >
                        Please enter valid values for job function
                      </span>
                      <textarea
                        className="form-control textarea_jobFunction"
                        style={{ height: "140px", resize: "none" }}
                        id="jobFunctionText"
                        name="jobFunctionText"
                        onChange={this.customJobFunctionTextChange}
                        value={this.state.customJobFunctionText}
                      ></textarea>
                    </div>
                  </div>
                  {/* end of Job Function Card */}
                </div>
                {/* end of Job function col */}

                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
                  <div class="card" style={{ marginRight: "20px" }}>
                    <div class="card-body">
                      {/* //shivani-task 3197-changed label format */}
                      <label className="labelStyle">Job title</label>
                      <span className="asterisk">*</span>&nbsp;&nbsp;
                      <HtmlTooltip
                        placement="right"
                        title={
                          <React.Fragment>
                            <ol>
                              <li>
                                {/* 4433-validation changes */}
                                {/* 4955-validation updated for + */}
                                {/* Chaitanya 5252 added ; & ' in tooltip  */}
                                {
                                  " Allowed values: - + , ; ' / | & ( ) . _ : 0-9 "
                                }
                              </li>
                              <li>
                                {
                                  " Job title values will be separated by 'Enter' only "
                                }
                              </li>
                            </ol>
                          </React.Fragment>
                        }
                      >
                        {/* Sandeep-task-3397-error msg issue */}
                        <Icon type="question-circle" />
                      </HtmlTooltip>
                      &nbsp;&nbsp;
                      <Tooltip placement="right" title="Job Title Validation">
                        {/* //snehal-task-2989-Job title ---check box issue */}
                        {this.state.jobTitlechk == "Yes" ? (
                          <input
                            type="checkbox"
                            id="jobChk"
                            name="jobChk"
                            defaultChecked
                            value={this.state.jobTitlechk}
                            onClick={this.commonhandleChange}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            id="jobChk"
                            name="jobChk"
                            value={this.state.jobTitlechk}
                            onClick={this.commonhandleChange}
                          />
                        )}
                      </Tooltip>
                      <span
                        style={{ display: this.state.jobTitleDisplay }}
                        className="errorMessage"
                      >
                        Please enter job title
                      </span>
                      {/* Sandeep-task-3397-error msg issue */}
                      <span
                        style={{ display: this.state.jobTitleDisplay2 }}
                        className="errorMessage"
                      >
                        Please enter valid values for job title
                      </span>
                      <textarea
                        id="jobTitle"
                        name="jobTitle"
                        className="form-control inputStyle"
                        style={{
                          resize: "none",
                          height: "224px",
                          width: "97%",
                        }}
                        onChange={this.commonhandleChange}
                        value={this.state.jobTitle}
                        required
                      ></textarea>
                    </div>
                  </div>
                  {/* //snehal-3398-STC---agency side---create and edit camp---custom header and error msg issue(UI changes) */}
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
                  {/* //shivani-task 3197-changed label format */}
                  <label className="labelStyle">Other specifications</label>

                  <textarea
                    id="otherSpecifications"
                    name="otherSpecifications"
                    className="form-control inputStyle"
                    style={{
                      resize: "none",
                      height: "100px",
                      width: "97%",
                    }}
                    onChange={this.commonhandleChange}
                    value={this.state.otherSpecifications}
                    required
                  ></textarea>
                </div>
              </div>
              {/* //snehal-task-2798-UI/UX-Changes-3.1(end) */}
              {/* end of 4 th row */}
              <div class="row"></div>
            </form>
            <button
              className="btn add-button"
              onClick={this.submitForm}
              style={{ float: "right", margin: "10px" }}
            >
              Save And Next
            </button>
            <button
              className="btn add-button"
              onClick={this.backFunction}
              style={{ margin: "10px" }}
            >
              Back
            </button>
            <br />
            <br />
            <br />
          </div>
        </div>

        {/* end of 3rd container */}
        {/* start of 4th container */}
        <div class="container">
          <div
            class="card"
            className="cardStep1"
            style={{ display: this.state.display4 }}
          >
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                {/* snehal-task-2940-Campaign Step1-back button alignment */}
                <a
                  href="#"
                  onClick={this.backFunction}
                  style={{ color: "#056eb8" }}
                >
                  <FaArrowAltCircleLeft
                    size={25}
                    style={{ float: "left", marginRight: "10px" }}
                  />
                </a>
                {/* snehal-task-2940-Campaign Step1-back button alignment */}
                <h3
                  style={{
                    color: "#193D8F",
                    fontWeight: "600",
                    fontSize: "24px",
                    marginBottom: "0px",
                  }}
                >
                  4 Custom Questions
                </h3>
                {/* snehal-task-2940-Campaign Step1-back button alignment */}
                {/* //snehal-task-3112-Custom Question Alias */}
                <div style={{ float: "right" }}>
                  <input
                    type="checkbox"
                    id=""
                    name="customQuestionAliasName"
                    onClick={this.handleQuestionAlias}
                    checked={
                      this.state.customQuestionAliasName == "Yes" &&
                      this.state.checkedQue === true
                        ? true
                        : false
                    }
                  />
                  {/* //snehal-task-3167-Edit campaign--Alias issue */}
                  <label className="labelStyle" style={{ marginRight: "5px" }}>
                    Add Question Alias
                  </label>
                </div>
              </div>
            </div>
            <div className="instructions" style={{ marginTop: "-20px" }}>
              <p style={{ marginTop: "0px !important" }}>
                <b>Instructions:</b>
              </p>
              <p style={{ margin: "0px 50px" }}>
                1. Every answer should be on new Line in text box
              </p>
              <p style={{ margin: "0px 50px" }}>
                2. Please fill the answer in the respective block
              </p>
              {this.state.customQuestionAliasName == "Yes" ? (
                <p style={{ margin: "0px 50px" }}>
                  3. If you add question alias then this information come as
                  your custom question header into lead delivery templete
                </p>
              ) : (
                ""
              )}
              <br />
            </div>
            <div>
              <span
                style={{ display: this.state.customQuestionDisplay1 }}
                className="errorMessage"
              >
                Please Enter Custom Question{" "}
              </span>
              <span
                style={{ display: this.state.customQuestionDisplay2 }}
                className="errorMessage"
              >
                Please Enter Alias Name
              </span>
              <span
                style={{ display: this.state.customQuestionDisplay3 }}
                className="errorMessage"
              >
                Please Enter Alias Name In Proper Format
              </span>
              {this.state.customQuestionAliasName === "Yes" ? ( //Aman-5042-added condtion to show error as per the AliasName or customQuestions
                <span
                  style={{ display: this.state.customQuestionDisplay4 }}
                  className="errorMessage"
                >
                  Alias Name Should Be Unique
                </span>
              ) : (
                <span
                  style={{
                    display: this.state.customQuestionDisplay4,
                    marginLeft: "90px",
                  }}
                  className="errorMessage"
                >
                  Custom Questions Should Be Unique
                </span>
              )}{" "}
              {/* Aman-5042-added condtion to show error for duplicate customQuestions  */}
              <span
                style={{ display: this.state.customQuestionDisplay5 }} //kiran-4873-showing error msg in span
                className="errorMessage"
              >
                Question Alias Name Should Not Be Same As System Fields
              </span>
            </div>
            <div
              class="row"
              style={{ marginBottom: "3px", marginRight: "-1px" }}
            >
              {this.state.customQuestionAliasName == "Yes" ? (
                <div class="col-lg-2 col-md-10 col-sm-10">
                  <div
                    align="center"
                    className="AliasBox"
                    style={{ backgroundColor: "#FBFCD4" }}
                  >
                    <label>Question Alias</label>
                  </div>
                </div>
              ) : (
                <div class="col-lg-1 col-md-9 col-sm-9"></div>
              )}
              <div class="col-lg-3 col-md-10 col-sm-10">
                <div
                  align="center"
                  className="QueBox"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <label>Questions</label>
                </div>
              </div>
              <div class="col-lg-3 col-md-10 col-sm-10">
                <div
                  align="center"
                  className="QueBox"
                  style={{ backgroundColor: "#eafeeb" }}
                >
                  <label>Answers</label>
                </div>
              </div>
              <div class="col-lg-3 col-md-10 col-sm-10">
                <div
                  align="center"
                  className="QueBox"
                  style={{ backgroundColor: "#ffe6e6" }}
                >
                  <label>Not Allowed Answers</label>
                </div>
              </div>
            </div>
            <div className="forScroll">
              {this.state.CustomQuestionsEdit.map((CustomQuestionsEdit, i) => {
                return (
                  <div
                    class="row"
                    align="center"
                    key={CustomQuestionsEdit.customQuestionID}
                  >
                    {this.state.customQuestionAliasName == "Yes" ? (
                      <div class="col-lg-2 col-md-9 col-sm-9">
                        <textarea
                          className="customAlias"
                          name="aliasName"
                          value={CustomQuestionsEdit.aliasName}
                          onChange={this.customQuestionHandleChange.bind(
                            this,
                            i
                          )}
                        />
                      </div>
                    ) : (
                      <div class="col-lg-1 col-md-9 col-sm-9"></div>
                    )}
                    <div class="col-lg-3 col-md-9 col-sm-9">
                      <textarea
                        className="customQue"
                        name="question"
                        value={CustomQuestionsEdit.customQuestion}
                        onChange={this.customQuestionHandleChange.bind(this, i)}
                      />
                    </div>

                    <div class="col-lg-3 col-md-9 col-sm-9">
                      <textarea
                        className="customQue"
                        name="answer"
                        value={CustomQuestionsEdit.answer}
                        onChange={this.customQuestionHandleChange.bind(this, i)}
                      />
                    </div>

                    <div class="col-lg-3 col-md-9 col-sm-9">
                      <textarea
                        className="customQue"
                        name="disAllowAnswer"
                        value={CustomQuestionsEdit.disAllowAnswer}
                        onChange={this.customQuestionHandleChange.bind(this, i)}
                      />
                    </div>

                    <div class="col-lg-1 col-md-2 col-sm-2">
                      <a>
                        <span
                          class="glyphicon glyphicon-minus-sign"
                          id={CustomQuestionsEdit.customQuestionID}
                          onClick={this.removecustomQuestion.bind(this, i)}
                          style={{
                            color: "Red",
                            fontWeight: "bold",
                            fontSize: 22,
                          }}
                        ></span>
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
            <div class="row">
              {this.state.customQuestionAliasName == "Yes" ? (
                <div class="col-lg-11 col-md-10 col-sm-10">
                  <div
                    align="center"
                    style={{ backgroundColor: "#e0e0e0", padding: "10px" }}
                    className="add_more"
                  >
                    <label onClick={this.addCustomQuestion.bind(this)}>
                      + Add More
                    </label>
                  </div>
                </div>
              ) : (
                <div class="offset-lg-1 col-lg-9 col-md-10 col-sm-10">
                  <div
                    align="center"
                    style={{ backgroundColor: "#e0e0e0", padding: "10px" }}
                    className="add_more"
                  >
                    <label onClick={this.addCustomQuestion.bind(this)}>
                      + Add More
                    </label>
                  </div>
                </div>
              )}
            </div>
            {this.state.customeFlag === true ? (
              <button
                className="btn add-button"
                onClick={this.submitForm}
                style={{ float: "right", margin: "10px", marginRight: "30px" }}
              >
                Save And Next
              </button>
            ) : (
              <button
                className="btn add-button"
                disabled
                onClick={this.submitForm}
                style={{ float: "right", margin: "10px", marginRight: "30px" }}
              >
                Save And Next
              </button>
            )}
            <button
              type="button"
              class="btn-outline-primary"
              onClick={this.skipAndNext}
              style={{
                float: "right",
                margin: "10px",
                height: "35px",
                borderRadius: "6px",
              }}
            >
              Skip And Next
            </button>
            <button
              className="btn add-button"
              onClick={this.backFunction}
              style={{ margin: "10px" }}
            >
              Back
            </button>
            <br />
            <br />
            <br />
          </div>
        </div>
        {/* end of 4th container */}

        <Footer />
      </div>
    );
  }
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
EditExistingCampaign.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(EditExistingCampaign)
);
