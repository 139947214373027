/* Copyright(c) 2023 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/**
 * @file LeadFileTrackingReport 
 * @author  Aman Shaikh - 4660
 * @version 1.0
 * @section DESCRIPTION Lead File Tracking Report Page 
 */

import React from "react";
import Navigation from "../layouts/MBAdminNavPage";
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import "./LeadFileTrackingReport.css"
import { Icon, Table, Tooltip} from 'antd';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
import { saveAs } from "file-saver";
import { css } from "emotion";
import moment from 'moment'; //Rutuja 5422 import the module for date
const xlsx = require("xlsx");
const Swal = require('sweetalert2');

const tableCSS = css({
  backgroundColor: 'white',
  fontFamily: "sans-serif",
  borderStyle: 'bold',
  '& thead  > tr': {
    backgroundImage: 'linear-gradient(to right,#28166f,#007cc3) !important',
    color: 'white',
    fontSize: '12px !important',
    fontWeight: '500',
  },
  '& thead > tr >th': {
    textAlign: "center !important",
    color: 'white',
  }
});

class LeadFileTrackingReport extends React.Component {
  constructor() {
    super();
    this.state = {
      LeadFileTrackingDetails: [],
      allUserNames: [],
      UserName: [],
      selectedUser: '',
      selectedDate: '',
      sevenDayDate: '',
      orgID: '',
      loader_progress: "",
      tableState: { size: "small" },
      getClientList:[],//Rutuja 5422 intialized client array
    }
    this.NameHandleChange = this.NameHandleChange.bind(this);
    //this.dateHandleChange = this.dateHandleChange.bind(this); 
    this.searchHandleChange = this.searchHandleChange.bind(this);
    this.handledownloadLeadTrackingReport = this.handledownloadLeadTrackingReport.bind(this);
    this.onClientHandleChange = this.onClientHandleChange.bind(this); //Rutuja 5422 function for client handle chnage
    this.handleChange = this.handleChange.bind(this); //Rutuja 5422 function for date handle change
    this.handleChangePageSize = this.handleChangePageSize.bind(this);
  }
//Rutuja 5422 written function for date handle change
  handleChange(e) {

    if (e.target.name.toString() === "startDate") {
      let date = e.target.value;
      date = moment().format('mm/dd/yyyy');
      let dateArray = date.split('/');
      if (dateArray.length === 0) {
          dateArray = date.split('-');
      }
      this.setState({
          startDate: e.target.value, LeadFileTrackingDetails: ""
      })
  }
  
  else if (e.target.name.toString() === "endDate") {
      this.setState({
          endDate: e.target.value , LeadFileTrackingDetails: ""
      })

  }
  }

 //Aman-4660 -MBAdmin-(to get UserName and set)eport - Lead File Tracking Report- DMA's Upload lead file count and file details
  NameHandleChange(e) {
    e.preventDefault();

    if(e.target.value!=="Select Publisher"){
      this.setState({ selectedUser : this.state.UserName[e.target.value]});
      let selectedUser = this.state.UserName[e.target.value];
      let data = {
        client:"yes",
        pID: selectedUser.orgID 
    }
    this.fetchClinetDetails(data); //Rutuja 5422 calling fetch call when publisher is selected so clinet list will be fetch depend upon publisher
    this.setState({ LeadFileTrackingDetails: "" })
    }
    else{
      
      this.setState({ selectedUser : "" , selectedClient :""});
      document.getElementById("client").value ="Select Client";
      this.setState({ LeadFileTrackingDetails: "" })
      let data = {
        client:"no",
    }
      this.fetchClinetDetails(data);
    }
    }
//Rutuja 5422 fetching client list when publisher selected
  fetchClinetDetails(data){
  fetch("/admin/getClientList", {

      method: 'POST',
      headers: {
          'Content-Type': 'application/json'

      },
      body: JSON.stringify(data)
  }).then(res => res.json())
      .then(getClientList => {
        this.setState({ getClientList: getClientList, selectedClient :""})
        document.getElementById("client").value ="Select Client";
      })
  }

  //Rutuja 5422 function for handle change client
  onClientHandleChange(e) {
    e.preventDefault();
    if(e.target.value!=="Select Client"){
      this.setState({ selectedClient : e.target.value});
      this.setState({ LeadFileTrackingDetails: "" })
    }
    else{
      this.setState({ selectedClient : ""});
      this.setState({ LeadFileTrackingDetails: "" })
    }
   
  }

//Rutuja task 5422 commented below code as written new code    
   //Aman-4660 -MBAdmin-(to get Date and set)eport - Lead File Tracking Report- DMA's Upload lead file count and file details
  // dateHandleChange(e){
  //   e.preventDefault();
  //      // Get the selected date from a form input or other source
  //      var selectedDate = e.target.value;
  //      var sevenDayPreviousDate = sevenDayPreviousDate(selectedDate)

  //     function sevenDayPreviousDate(selectedDate){
  //       let d = new Date(selectedDate);
  //       d.setDate(d.getDate() - 7);
  //       return d.toISOString().split('T')[0];
  //     };
  //     this.setState({ sevenDayDate : sevenDayPreviousDate,selectedDate:selectedDate}); 
  // }
   //Aman-4660 -MBAdmin-(this will search the data on the bases of name and date)eport - Lead File Tracking Report- DMA's Upload lead file count and file details
  // searchHandleChange(e) {
  //     let selectedUser = this.state.selectedUser;
  //     let selectedDate = this.state.selectedDate;     
      
  //     if((selectedUser && selectedUser !== "Select User") && (selectedUser !== "undefined" || undefined || null || "null" || "")
  //      && (selectedDate && selectedDate !== "")){ 
  //       let finalData = this.state.allUserNames.filter((element) => {
  //         return element.firstName == selectedUser.firstName && element.lastName == selectedUser.lastName; //Nilesh-4980- fetching the lastname data from element
  //       });
  //       this.setState({ loader_progress: "loader_campaign_list" });
  //       let data = {
  //         finalData: finalData,
  //         selectedDate: selectedDate,
  //       }
  //       fetch("/admin/LeadFileTrackingReportData", {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //       body: JSON.stringify(data)
  //       }).then(res => res.json())
  //         .then(LeadFileTrackingData => {
  //           this.setState({ loader_progress: "" });
  //         if(LeadFileTrackingData.length>0){   
  //           this.setState({ LeadFileTrackingDetails: LeadFileTrackingData, })
  //         }else{
  //             Swal.fire({
  //                 type: "warning",
  //                 title: "No records Found",
  //                 confirmButtonText: "Ok",
  //                 allowOutsideClick: false,
  //                 preConfirm: () => {
  //                   window.location.reload();
  //                 },
  //           });
  //         }
  //       }).catch(function (err) { console.log(err) });
  //     }else{
  //       Swal.fire({
  //         type: "warning",
  //         title: "Please Select All Mandatory Fields",
  //         confirmButtonText: "Ok",
  //         allowOutsideClick: false
  //       });
  //       this.setState({ loader_progress: "" });
  //     }
  // }
//Rutuja 5422 MBAdmin-(this will search the data on the bases of publisheror client and date)
  searchHandleChange(e) {
    let selectedUser = this.state.selectedUser;
    let startDate = this.state.startDate;     
    let endDate = this.state.endDate;
    let selectedClient = this.state.selectedClient; 
      let data = {};
      let finalData = [];
    const isUserSelected = selectedUser && selectedUser !== "Select User" && selectedUser !== "undefined" && selectedUser !== null && selectedUser !== "null" && selectedUser !== "";
    const isClientSelected = selectedClient && selectedClient !== "Select Client" && selectedClient !== "undefined" && selectedClient !== null && selectedClient !== "null" && selectedClient !== "";
    if ((startDate && startDate !== "") && (endDate && endDate !== "") && (isUserSelected || isClientSelected)) {
      //Rutuja 5422 when both user and client is selected 
    if (isUserSelected && isClientSelected) {
      // alert("both")
      finalData = this.state.allUserNames.filter((element) => {
          return element.firstName === selectedUser.firstName && element.lastName === selectedUser.lastName;
      });

      data = {
          finalData: finalData,
          startDate: startDate,
          endDate: endDate,
          selectedClient: selectedClient,
          key: "userclient"
      };
  } 
  //Rutuja 5422 when  user  is selected 
  else if (isUserSelected) {
    // alert("user")
      finalData = this.state.allUserNames.filter((element) => {
          return element.firstName === selectedUser.firstName && element.lastName === selectedUser.lastName;
      });

      data = {
          finalData: finalData,
          startDate: startDate,
          endDate: endDate,
          key: "user"
      };
  } 
   //Rutuja 5422 when  client  is selected 
  else if (isClientSelected) {
      // alert("client");

      data = {
          startDate: startDate,
          endDate: endDate,
          selectedClient: selectedClient,
          key: "client"
      };
  }

      this.setState({ loader_progress: "loader_campaign_list" });
      fetch("/admin/LeadFileTrackingReportData", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      body: JSON.stringify(data)
      }).then(res => res.json())
        .then(LeadFileTrackingData => {
          this.setState({ loader_progress: "" });
        if(LeadFileTrackingData.length>0){   
          this.setState({ LeadFileTrackingDetails: LeadFileTrackingData, })
        }else{
            Swal.fire({
                type: "warning",
                title: "No records Found",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                preConfirm: () => {
                  window.location.reload();
                },
          });
        }
      }).catch(function (err) { console.log(err) });
    }else{
      Swal.fire({
        type: "warning",
        title: "Please Select All Mandatory Fields",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        preConfirm: () => {
          window.location.reload();
        },
      });
      this.setState({ loader_progress: "" });
    }
}
 //Aman-4660 -MBAdmin-(This will generate the EXCEL file)eport - Lead File Tracking Report- DMA's Upload lead file count and file details
  handledownloadLeadTrackingReport(){
    let LeadFileTrackingDetails = this.state.LeadFileTrackingDetails;
    let selectedUser = this.state.selectedUser;
    let selectedClient = this.state.selectedClient; 
    const isUserSelected = selectedUser && selectedUser !== "Select User" && selectedUser !== "undefined" && selectedUser !== null && selectedUser !== "null" && selectedUser !== "";
    const isClientSelected = selectedClient && selectedClient !== "Select Client" && selectedClient !== "undefined" && selectedClient !== null && selectedClient !== "null" && selectedClient !== "";
        if (LeadFileTrackingDetails.length > 0) {
        var ws = xlsx.utils.json_to_sheet(LeadFileTrackingDetails);
        var wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Lead");
        var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); 

        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (let i = 0; i !== s.length; ++i)
                view[i] = s.charCodeAt(i) & 0xff;
            return buf;
        }
        var fileName
        if(isUserSelected && isClientSelected)
        {
           fileName = "Lead File Tracking Report-"+selectedUser.firstName+""+selectedUser.lastName+"-"+selectedClient +".xlsx";

        }
        else if(isUserSelected){
          fileName = "Lead File Tracking Report-"+selectedUser.firstName+""+selectedUser.lastName+".xlsx";

        }
        else if(isClientSelected){
          fileName = "Lead File Tracking Report -"+selectedClient +".xlsx";

        }
        saveAs(
            new Blob([s2ab(buf)], { type: "application/octet-stream" }),
            fileName
        );
    }
  }
  
    /**
* @author : Sandeep Dhawale-task-3479
* @description : handlechange for pageSize .
*/
handleChangePageSize(e) {
  var pageSize = e.target.value;
  this.setState({ pageSize: pageSize });
}

  //Aman-4660 -MBAdmin-(This will get the User Name from Route)eport - Lead File Tracking Report- DMA's Upload lead file count and file details
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }
    else {
      const { isAuthenticated } = this.props.auth;
      var reportType = this.state.reportType;
      this.setState({ loader: "loader_report" })
      let data = {
        type: reportType
      };
      fetch("/admin/allPcPncUserDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
      }).then((res) => res.json())
        .then((UserNameDetails) => {
          // alert("UserNameDetails -->" + JSON.stringify(UserNameDetails))
          this.setState({ allUserNames: UserNameDetails});
          let userNameArray = [];
          if(UserNameDetails.length>0)
          {
            for(let i=0;i<UserNameDetails.length;i++){
              userNameArray.push({firstName:UserNameDetails[i].firstName,lastName:UserNameDetails[i].lastName, orgID:UserNameDetails[i].orgID});
            }
            userNameArray = Array.from(
              new Set(
                userNameArray.map((el) =>
                  JSON.stringify(el)
                )
              )
            ).map((el) => JSON.parse(el));
          }
          this.setState({ UserName: userNameArray})
        }).catch(function (err) { console.log(err) ;});

        //Rutuja  5422 when client is not selected then fetching all client list 
        let data1 = {
          client:"no",
      }
      fetch("/admin/getClientList", {
    
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
    
          },
          body: JSON.stringify(data1)
      }).then(res => res.json())
          .then(getClientList => {
            this.setState({ getClientList: getClientList})
          })
      
          
    }
  }
  
  
  render() {
    const { isAuthenticated, user } = this.props.auth;
    const asterisk = { color: 'red' }
    const columnsReport = [
      {
          title: <span>Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
          dataIndex: "Date",
          key: "Date",
          width: "13%",
          align: "left",
          sorter: (a, b) => {
            const asc = "asc";
            if (a.created < b.created) {
                return asc ? -1 : 1;
            } else if (a.created > b.created) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
          },
          render: (text, record) => (
              <div>
                  <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                      {record.created}
                  </span>
                  <br />
              </div>
          ),
      },
      {
          title: <span>Client Camp ID&nbsp;&nbsp;&nbsp;</span>,
          dataIndex: "clientCampID",
          key: "clientCampID",
          width: "8%",
          align: "left",
          render: (text, record) => (
              <div>
                  <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                      {record.clientCampID}
                  </span>
                  <br />
              </div>
          ),
      },
      {
          title: <span>Camp ID&nbsp;&nbsp;&nbsp;</span>,
          dataIndex: "campID",
          key: "campID",
          width: "11%",
          align: "left",
          render: (text, record) => (
              <div>
                  <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                      {record.campID}
                  </span>
                  <br />
              </div>
          ),
      },
     
      {
        title: <span>File Name&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "fileName",
        key: "fileName",
        width: "30%",
        align: "left",
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.fileName}
                </span>
                <br />
            </div>
        ),
      },
      // Rutuja 5422 added column upload lead count - leads upload by PC/PNC except system rejected
      {
        title: <span>Upload Lead Count&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "uploadleadcount",
        key: "uploadleadcount",
        width: "10%",
        align: "left",
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.uploadLeadCount}
                </span>
                <br />
            </div>

        ),
      },
            // Rutuja 5422 added column Rejected lead count - leads Rejected by PC
      {
        title: <span>Rejected Lead Count&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "Rejected Lead Count",
        key: "Rejected Lead Count",
        width: "10%",
        align: "left",
        render: (text, record) => (
          <div>
          <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
              {record.rejectedLeadCount}
          </span>
          <br />
      </div>

        ),
      },
      {
        // rutuja 5422 added quality lead count - lead count whose status is 'DI QA ACCEPTED', 'PV Review'
        title: <span>Accepted by CQ &nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "Quality Lead Count",
        key: "Quality Lead Count",
        width: "10%",
        align: "left",
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.qualityLeadCount}
                </span>
                <br />
            </div>

        ),
      },
      {
        //  rutuja 5422 added Accepted lead count - whose lead status is ['QA Review', 'Advertiser Accepted', 'AgencyInternalReview', 'Accepted', 'ACCEPTED'];
        title: <span>At Agency&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "Accepted Lead Count",
        key: "Accepted Lead Count",
        width: "10%",
        align: "left",
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.agencyLeadCount}
                </span>
                <br />
            </div>

        ),
      },
      {
        title: <span>User Name&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "pubName",
        key: "pubName",
        width: "20%",
        align: "left",
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.userName}
                </span>
                <br />
            </div>
        ),
    },
    {
      title: <span>Client Name&nbsp;&nbsp;&nbsp;</span>,
      dataIndex: "cName",
      key: "cName",
      width: "20%",
      align: "left",
      render: (text, record) => (
          <div>
              <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                  {record.clientName}
              </span>
              <br />
          </div>
      ),
  },
    ]
    
		function onChange(pagination, filters, sorter) {
      console.log("params", pagination, filters, sorter);
    }

    return (
    <div>
      <Navigation />
        <div class="container-fluid" style={{ paddingTop: '100px' }}>

        {/* heading section */}
          <div class="row">
                  {/* <div class="col-lg- col-md-3 col-sm-3">
                  <a
                        onClick={() =>
                          this.props.history.push("/MBAdminDashboard", {
                            agencyID: this.state.agencyID,
                          })
                        } //karan-task-3684-replace query params
                        style={{ color: "#056eb8" }}>
                        <FaArrowAltCircleLeft
                          size={32}
                          style={{ float: "left" }}
                          title="Back to Dashboard"
                        />
                      </a>
                  </div> */}
                  
            <div class="offset-lg-2 offset-sm-2 col-lg-8 col-md-8 col-sm-8" align="center">
              <center><label id="labelDI" style={{ fontSize: '28px', fontStyle: 'roboto', fontWeight: '500', color: '#193D8F' }} align="center">Lead File Tracking Report</label></center>
            </div>
          </div>
          
        <div className="Main-body">  
          <div class="container-fluid" style={{ paddingTop: '10px', marginBottom: '20px' }}>
            <div className="card card-signin my-1">
              <div className="card-body">

                {/* Filter section */}          
                <div className="row align-items-center d-flex justify-content-between">
              <div className="col-sm-12 col-md-6 col-lg-2 mb-3" id="AD_ALTD">
                  <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>
                      Select User <span style={asterisk}>*</span>
                  </label><br />
                  <select  
                      style={{
                          width: '160px',
                          height: "34px",
                          border: '1px solid #cccccc',
                          backgroundColor: "#FFFFFF",
                          borderRadius: '3px',
                          fontFamily: 'sans-serif',
                          color: '#555555'
                      }} 
                      onChange={this.NameHandleChange}
                      >
                      <option value={"Select Publisher"}>Select User</option>
                      {this.state.UserName.map((user, i) => (
                          <option value={i} key={i} style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#555555" }}>
                              {user.firstName} {user.lastName}
                          </option>
                      ))}
                  </select>
              </div>
{/* Rutuja task 5422 added select client drop down */}
            <div className="col-sm-12 col-md-6 col-lg-2 mb-3" id="AD_ALTD">
                <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>
                    Select Client <span style={asterisk}>*</span>
                </label><br />
                <select id="client"
                    
                    style={{
                        width: "160px",
                        height: "34px",
                        border: "1px solid #CCCCCC",
                        borderRadius: "3px",
                        backgroundColor: "#FFFFFF",
                        fontFamily: "sans-serif",
                        color: "#555555",
                    }}
                     onChange={this.onClientHandleChange}
                     
                >
                    <option value={"Select Client"} style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#555555" }}>
                        Select Client 
                    </option>
                    {this.state.getClientList && this.state.getClientList.map((client, i) => (
                        <option style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#555555" }} value={client.clientName} key={i}>
                            {client}
                        </option>
                    ))}

                    {/* {this.state.getClientList.map((client, i) => (
                          <option value={i} key={i} style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#555555" }}>
                              {client.clientName}
                          </option>
                      ))} */}
                </select>
            </div>
          {/* Rutuja task 5422 added start date */}
            <div className=" col-sm-12 col-md-6 col-lg-2 mb-3" id="AD_ALTD">
                <label id="label" style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>
                    <b>Start date </b><span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy)</span>
                </label>
                <input 
                    type="date" 
                    id="startDate" 
                    name="startDate" 
                    ref={(input) => { this.nameInput = input; }} 
                    value={this.state.startDate}
                    className={this.state.inputClassSdate}
                    onChange={this.handleChange}
                    className="form-control" 
                    required 
                />
            </div>
              {/* Rutuja task 5422 added End date */}
            <div className="col-sm-12 col-md-6 col-lg-2 mb-3" id="AD_ALTD">
                <label id="label" style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>
                    <b>End date </b><span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy)</span>
                </label>
                <input 
                    type="date" 
                    id="endDate" 
                    name="endDate" 
                    ref={(input) => { this.nameInput = input; }} 
                    value={this.state.endDate}
                    onChange={this.handleChange}
                    className="form-control"
                    required 
                />
            </div>

    <div className="col-lg-2 mb-3" id="AD_ALTD"> 
    <label id="label" style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>
            <b> </b><span style={asterisk}></span><span style={{ color: "#337ab7", fontSize: "12px" }}></span>
        </label>  
        <button
             style={{
              marginLeft : '40px',
              marginTop : '40px'
          }}
            type="button"
            className="btn add-button"
            onClick={this.searchHandleChange}>
            Search
        </button>
    </div>      
                </div>


                <div align="center"  id={this.state.loader_progress}></div>  {/* Aman-4660 -MBAdmin-(Loader)eport - Lead File Tracking Report- DMA's Upload lead file count and file details */}

                  {this.state.LeadFileTrackingDetails.length > 0 ? 
                      <div> 
                       <hr /> 
                        <p style={{ marginBottom: "10px"}}>
                          <br />
                              <Table
                                {...this.state.tableState}
                                bordered
                                dataSource={this.state.LeadFileTrackingDetails}
                                onChange={onChange}
                                columns={columnsReport}
                                className={`${tableCSS} "ant-table-thead table-responsive"`}
                                scroll={{ y: 250, x: 1500 }}
                                pagination={{
                                  pageSize: this.state.pageSize,
                                  position: "bottom",
                                }}
                                >
                              </Table>
                              <div class="row">
            <div
              class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style={{ marginTop: "-45px", marginLeft: "5px" }}
            >
              &nbsp;Records per page : &nbsp;
              <select
                defaultValue={this.state.pageSize}
                onChange={this.handleChangePageSize}
                id="pacing1"
                class="input-small"
                className="form-control"
                name="pacing"
                style={{ width: "71px", height: "30px", display: "initial" }}
              >
                <option value="25" selected>
                  25
                </option>
                <option value="50">50</option>
                <option value="75">75</option>
              </select>
            </div>
          </div>
                        </p>
                            <button class="btn add-button" 
                            style={{float: "right" }} 
                            title="Export the data in excel file"
                            value={this.state.clientLeadDetails}
                            onClick={this.handledownloadLeadTrackingReport}
                            >Export</button>
                      </div> 
                : " "}
              </div>
            </div>
          </div>
        </div>
                        
      </div>
      <Footer />
    </div>
    );
  }
}

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
LeadFileTrackingReport.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(LeadFileTrackingReport));

