/* Copyright(c) 2024 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @author : Nilesh Zinzurao
 * @description : It is page use for column sequence mapping
 */
import React from "react";
import * as $ from "jquery";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import { Tooltip, Card } from "antd";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import "./supportingDocumentNew.css";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //snehal-task-3766-specification VAPT api call
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc"; //Nilesh-5122- Use for up and down navigation arroe
import styled from "styled-components";
import * as XLSX from 'xlsx'; // Priyanka- 5397 -Import XLSX library for Excel generation
//Priyanka-5397-Below code use for tooltip
const HtmlTooltip = styled(Tooltip)`
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: '12px',
		border: '1px solid #dadde9',
		`;

// to display alerts
const Swal = require("sweetalert2");

class columnSequencing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // sequencingArray: [], //Nilesh-5122- This array use for store all available fields
      selectedValue: [], //Nilesh-5122- This array use for store all selected fields
      clickedItems: [], // Initialize clickedItems as an empty array
      selectedKey: null,
      selectedArray: [],
      newArray: {},
      campID: this.props.location.state.campID,
      campaignStatus: "Active", //Aman-5275-REPORT-Campaign Tracing-status showing Incorrect value 
      customQuestionDetails: [], //Nilesh-5122- storing custom questions
      customizedColumns: [], //Nilesh-5122- This array use for store all available fields
      existingValue: [],
      existingColumnsSequence: [], //5264-storing existing Columns Sequence
      newAvailableFields: [], //5264-storing New Available Fields
      loader_progress: "", //5264-for loader
      draggingIndex: null, //Aman-5271-added Index of the item being dragged
      dragOverIndex: null, //Aman-5271-added Index of the item being dragged over
      draggingList: null, //Aman-5271-added to track draggingList
      availableFields:[],
      parentCampID :this.props.location.state.parentCampID,
      reallocationID:this.props.location.state.reallocationID,
      applyFlag: false,  //Priyanka-5457-Added flag to disable Apply button
      updateFlag: true, //Priyanka-5457-Added flag to disable Update button
    };
    this.loadingRef = React.createRef();
    this.closeLoadingRef = React.createRef();
    this.clearData = this.clearData.bind(this);
    this.clearExistingSequence = this.clearExistingSequence.bind(this);
    this.fetchColumns = this.fetchColumns.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.displayCampaignSpecification = this.displayCampaignSpecification.bind(this)
  }

  componentWillMount() {
    var campID = this.state.campID;
    var parsed = this.props.location.state;
    var pID = parsed.pID;
   
    let data = {
      campID: campID,
      pID: pID,
    };

    // let blankArray = [];

    // this.setState({ 
    //   availableFields: blankArray, 
    //   selectedArray: blankArray, 
    //   existingValue: blankArray, 
    //   newAvailableFields: blankArray,
    //   customizedColumns: blankArray
    // });

    this.fetchColumns(data);
  }

  // Chaitanya-5264-funtion for fetch columns data
  fetchColumns = (data) => {
    //this.loadingRef.current.click();
    //Priyanka-5457-Setting blank all arrays

    let blankArray = [];

    this.setState({ 
      loader_progress: "loader_campaign_list", 
      availableFields: blankArray, 
      selectedValue: blankArray, 
      existingValue: blankArray, 
      newAvailableFields: blankArray,
      customizedColumns: blankArray
    });
    this.fetchQuestionDetails();
    //Nilesh-5122- It is route use fetching all selected fildes data
    fetch("/columnMapping/columnSequenceMapping", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((customizedColumns) => {
        //alert("customizedColumns--->"+JSON.stringify(customizedColumns))
        if (customizedColumns.length > 0) {
          //this.closeLoadingRef.current.click();
          // Assuming customQuestionDetails is an array of custom question objects
          const customQuestions = this.state.customQuestionDetails.map(
            (item) => item.customQuestion
          );
          // Create an array of custom question objects
          //5210-Added space before - for below CQ as faced issue for download specs at PC side
          const customQuestionObjects = customQuestions.map(
            (question, index) => ({
              [`customQuestion${index + 1}`]: `CQ -${question}`,
            })
          );
          // Create an array of alias question objects
          var customQuestionAliasObjects;
          let updatedSequenceArray = [];
          //alert(JSON.stringify(this.state.customQuestionDetails))
          if (this.state.customQuestionDetails.length > 0) {
            if (this.state.customQuestionDetails[0].aliasName !== "") {
              const customQuestionsAlias = this.state.customQuestionDetails.map(
                (item) => item.aliasName
              );
              customQuestionAliasObjects = customQuestionsAlias.map(
                (questionAlias, index) => ({
                  [`aliasName${index + 1}`]: questionAlias,
                })
              );
            } else {
              customQuestionAliasObjects = [];
            }

            if (customQuestionAliasObjects.length > 0) {
              // Add customQuestions to each object in customizedColumns array
              const updatedSequencingArray = customizedColumns.map(
                (item, index) => {
                  const customQuestionsForItem =
                    customQuestionAliasObjects.reduce((acc, question) => {
                      return { ...acc, ...question };
                    }, {});
                  return { ...item, ...customQuestionsForItem };
                }
              );

              updatedSequenceArray = updatedSequencingArray;
            } else {
              // Add customQuestions to each object in customizedColumns array
              const updatedSequencingArray = customizedColumns.map(
                (item, index) => {
                  const customQuestionsForItem = customQuestionObjects.reduce(
                    (acc, question) => {
                      return { ...acc, ...question };
                    },
                    {}
                  );
                  return { ...item, ...customQuestionsForItem };
                }
              );

              updatedSequenceArray = updatedSequencingArray;
            }
          } else {
            updatedSequenceArray = customizedColumns;
          }

          //5397-Priyanka-taking available fields to add them in excel file
          this.setState({
            availableFields: updatedSequenceArray
          });

          //Priyanka-5429
          this.state.newAvailableFields.length = 0;

          // Chaitanya-5264-fetch call to read blob data
          fetch("/columnMapping/getBlob", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((existingColumnsSequence) => {

              let data1={
                campID : this.state.campID,
                existingColumnsSequence : existingColumnsSequence
              }

              this.setState({ loader_progress: "" });
              if (existingColumnsSequence.length > 0) {
                // Extract keys from existingValue
                const existingKeys = existingColumnsSequence.map(
                  (item) => item.key
                );

                //Chaitanya-5336-Added condition for updatedSequenceArray 
                const updatedKeys = updatedSequenceArray.length > 0 ? Object.keys(updatedSequenceArray[0]) : [];
                // Filter out values in customizedColumns that are not in existingKeys
                const newAvailableFields = updatedKeys
                  .filter((key) => !existingKeys.includes(key))
                  .reduce((arr, key) => {
                    arr.push({ [key]: updatedSequenceArray[0][key] });
                    return arr;
                  }, []);

                if (newAvailableFields.length > 0) {
                  //updatedSequenceArray.length = 0;    //Chaitanya-5336-commented updatedSequenceArray
                  //alert(updatedSequenceArray.length)

                    //Priyanka- 5429- Extract fields from array2
                    const fieldsToExtract = newAvailableFields.map(item => Object.keys(item)[0]);
       
                    //Priyanka-5429- Create array3 by copying only the specified fields from array1
                    const filteredArray = updatedSequenceArray.map(item => {
                      let newItem = {};
                      for (let key in item) {
                        if (!fieldsToExtract.includes(key)) {
                          newItem[key] = item[key];
                        }
                      }
                      return newItem;
                    });
         
                  this.setState({
                    newAvailableFields: newAvailableFields,
                    customizedColumns: filteredArray,
                    existingValue: existingColumnsSequence,
                  });
                } else {
                  //newAvailableFields.length = 0;      //Chaitanya-5336-commented newAvailableFields
                  //updatedSequenceArray.length = 0;    //Chaitanya-5336-commented updatedSequenceArray
                  this.setState({
                    newAvailableFields: [], //Chaitanya-5336-newAvailableFields set blank
                   // customizedColumns: [],  //Chaitanya-5336-customizedColumns set blank
                    customizedColumns: updatedSequenceArray,  //Nilesh-5429-customizedColumns set blank
                    existingValue: existingColumnsSequence,
                  });
                }
              } else {
                let emptyArray = [] 
                // let existingValue = this.state.existingValue;
                // existingValue.length = 0;
                this.setState({
                  customizedColumns: updatedSequenceArray,
                  existingValue: emptyArray,      //Chaitanya-5337-Passing emptyArray to existingValue 
                  selectedValue: emptyArray,      //Chaitanya-5337-Passing emptyArray to selectedValue
                  newAvailableFields: emptyArray, //Chaitanya-5337-Passing emptyArray to newAvailableFields
                });
                //alert(JSON.stringify(this.state.customizedColumns))
              }
            });
        } else {
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  //Nilesh-5122- Used for fetching questions details
  fetchQuestionDetails = () => {
    let data = {
      campID: this.state.campID,
    };
    // alert("data -->" + JSON.stringify(data))
    //Nilesh-5122- Add routes for fetching custom question details
    fetch("campaignNew/questionDetailsForEdit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((customQuestionDetails) => {
        // Handle the response data
        this.setState({
          customQuestionDetails: customQuestionDetails,
        });
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Error:", error);
      });
  };

  //Nilesh-5122- Click on arrow and move all fields
  // handleImageClick = (key, value) => {
  //   // Chaitanya-5264-added condition to add New Available Fields
  //   if (this.state.newAvailableFields.length > 0) {
  //     const NewExistingValues = [];

  //     this.state.newAvailableFields.forEach((item) => {
  //       Object.entries(item).forEach(([key, value]) => {
  //         if (value) {
  //           NewExistingValues.push({ key, value });
  //         }
  //       });
  //     });
  //     let existingValue = this.state.existingValue;

  //     const mergedValues = [...existingValue, ...NewExistingValues];

  //     this.setState(
  //       {
  //         existingValue: mergedValues,
  //         clickedItems: NewExistingValues.map((item) => item.value), // Update the clickedItems state to include all values
  //       },
  //       () => {}
  //     );
  //   } else {
  //     const selectedValues = [];
  //     this.state.customizedColumns.forEach((item) => {
  //       Object.entries(item).forEach(([key, value]) => {
  //         if (value) {
  //           selectedValues.push({ key, value });
  //         }
  //       });
  //     });
  //     //  ("selectedValues--->"+JSON.stringify(selectedValues))
  //     this.setState(
  //       {
  //         selectedValue: selectedValues,
  //         clickedItems: selectedValues.map((item) => item.value), // Update the clickedItems state to include all values
  //       },
  //       () => {}
  //     );
  //   }
  // };

  //Nilesh-5122- Clear all data from selected array
  clearData = (value) => {
    // alert("this.state.clickedItems.includes(value)--->"+ JSON.stringify(this.state.clickedItems.includes(value)))
    Swal.fire({
      // title: "Supporting Document Details Saved",
      text: "Do you want to clear selection?", //Nilesh-5338- Change text message
      icon: "Warning",
      type: "warning",
      //showCancelButton: true,
      confirmButtonColor: "#193D8F",
      cancelButtonColor: "#193D8F",
      confirmButtonText: "Ok",
      //cancelButtonText: "Cancle",
      showCloseButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      // alert("result -->" + JSON.stringify(result))
      if (result.value) {
        // alert("hiiiii clearData")
        // User clicked "Ok"
        this.setState({
          selectedValue: [],
          clickedItems: [], // Clear the clickedItems array
        });
        // alert("this.state.clickedItems.includes(value) >>>"+ JSON.stringify(this.state.clickedItems.includes(value)))
      } else if (result.dismiss === "cancel") {
        // User clicked "Cancel" or dismissed the modal
        console.log("Reset selection cancelled");
      }
    });
  };

  //Chaitanya-5264-fuction for delete existing column sequence
  clearExistingSequence = (value) => {
    // alert("this.state.clickedItems.includes(value)--->"+ JSON.stringify(this.state.clickedItems.includes(value)))
    //this.loadingRef.current.click();
    let data = {
      campID: this.state.campID,
      campaignStatus: this.state.campaignStatus,
    };
    Swal.fire({
      // title: "Supporting Document Details Saved",
      text: "Do you want to Delete Existing Column Sequence?", //Nilesh-5338- Change text message
      icon: "Warning",
      type: "warning",
      //showCancelButton: true,
      confirmButtonColor: "#193D8F",
      cancelButtonColor: "#193D8F",
      confirmButtonText: "Ok",
      //cancelButtonText: "Cancle",
      showCloseButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      //alert("result -->" + JSON.stringify(result))
      if (result.value) {
        this.setState({ loader_progress: "loader_campaign_list" });
        // User clicked "Ok"
        // Make the fetch call to delete the blob
        fetch("columnMapping/deleteBlob", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => {
            if (!res.ok) {
              throw new Error(`HTTP error! status: ${res.status}`);
            }
            return res.json();
          })
          .then((data) => {
            console.log("Delete Blob Response:", data);
            if (data.message) {
              //this.closeLoadingRef.current.click();
              //Priyanka-5457-Set Apply button to enable
              this.setState({ loader_progress: "", applyFlag: false});
              //alert('Blob deleted successfully!');
              let campID = this.state.campID;
              const { user } = this.props.auth;
              let data = {
                campID: campID,
                pID: user.id,
              };
              this.setState({
                existingValue: [],
                clickedItems: [], // Clear the clickedItems array
              });
              this.fetchColumns(data);
            }
          })
          .catch((err) => {
            console.error("Error deleting blob:", err);
            //alert('Error deleting blob: ' + err.message);
          });
        // alert("this.state.clickedItems.includes(value) >>>"+ JSON.stringify(this.state.clickedItems.includes(value)))
      } else if (result.dismiss === "cancel") {
        this.setState({ loader_progress: "" });
        // User clicked "Cancel" or dismissed the modal
        console.log("Reset selection cancelled");
      }
    });
  };
  //Nilesh- 5122- Use for selected value move Up
  // moveItemUp = (index) => {
  //   if (index === 0) return; // Cannot move the first item up
  //   const newSelectedValue = [...this.state.selectedValue];
  //   const temp = newSelectedValue[index];
  //   newSelectedValue[index] = newSelectedValue[index - 1];
  //   newSelectedValue[index - 1] = temp;
  //   this.setState({ selectedValue: newSelectedValue });
  // };

  //Nilesh- 5122- Use for selected value move down
  // moveItemDown = (index) => {
  //   if (index === this.state.selectedValue.length - 1) return; // Cannot move the last item down
  //   const newSelectedValue = [...this.state.selectedValue];
  //   const temp = newSelectedValue[index];
  //   newSelectedValue[index] = newSelectedValue[index + 1];
  //   newSelectedValue[index + 1] = temp;
  //   this.setState({ selectedValue: newSelectedValue });
  // };

  //Chaitanya-5264- Use for selected value move Up
  // moveExistingItemUp = (index) => {
  //   if (index === 0) return; // Cannot move the first item up
  //   const newSelectedValue = [...this.state.existingValue];
  //   const temp = newSelectedValue[index];
  //   newSelectedValue[index] = newSelectedValue[index - 1];
  //   newSelectedValue[index - 1] = temp;
  //   this.setState({ existingValue: newSelectedValue });
  // };

  //Chaitanya-5264- Use for selected value move down
  // moveExistingItemDown = (index) => {
  //   if (index === this.state.existingValue.length - 1) return; // Cannot move the last item down
  //   const newSelectedValue = [...this.state.existingValue];
  //   const temp = newSelectedValue[index];
  //   newSelectedValue[index] = newSelectedValue[index + 1];
  //   newSelectedValue[index + 1] = temp;
  //   this.setState({ existingValue: newSelectedValue });
  // };

  //Aman-5271-added Method to handle drag start
  // handleDragStart = (e, index, listType) => {
  //     //Aman-5271- Reset the color of the previously dropped item when starting a new drag
  //     if (this.droppedItem) {
  //         this.droppedItem.style.backgroundColor = '#0777cb'; // Reset to original color
  //         this.droppedItem = null;
  //     }

  //     this.setState({ draggingIndex: index, draggingList: listType });
  //     const draggedItem = listType === 'existing' ? this.state.existingValue[index] : this.state.selectedValue[index];
  //     e.dataTransfer.setData("text/plain", index);
  //     e.dataTransfer.effectAllowed = "move";

  //     const transparentImage = document.createElement('img');
  //     transparentImage.src = 'data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACwAAAAAAQABAEACAkQBADs=';
  //     e.dataTransfer.setDragImage(transparentImage, 0, 0);

  //     const dragPreview = document.createElement('div');
  //     dragPreview.textContent = draggedItem.value;

  //     //Aman-5271- Apply styles to limit the size and add ellipsis
  //     dragPreview.style.maxWidth = '200px';
  //     dragPreview.style.whiteSpace = 'nowrap';
  //     dragPreview.style.overflow = 'hidden';
  //     dragPreview.style.textOverflow = 'ellipsis';
  //     dragPreview.style.backgroundColor = '#0777cb';
  //     dragPreview.style.color = '#fff';
  //     dragPreview.style.padding = '10px';
  //     dragPreview.style.borderRadius = '5px';
  //     dragPreview.style.position = 'fixed';
  //     dragPreview.style.pointerEvents = 'none';
  //     dragPreview.style.zIndex = '1000';
  //     dragPreview.style.left = `${e.clientX}px`;
  //     dragPreview.style.top = `${e.clientY}px`;

  //     document.body.appendChild(dragPreview);
  //     this.dragPreview = dragPreview;
  // };

  //Aman-5271-added Method to handle drag over
  // handleDragOver = (e, targetIndex) => {
  //   e.preventDefault();
  //   const draggingOverItem = e.currentTarget;
  //   const boundingRect = draggingOverItem.getBoundingClientRect();
  //   const offset = e.clientY - boundingRect.top;
  
  //   //Aman-5271- Determine if the mouse is in the top or bottom half of the target item
  //   const shouldInsertAfter = offset > boundingRect.height / 2;
  //   const newTargetIndex = shouldInsertAfter ? targetIndex + 1 : targetIndex;

  //   this.setState({ dragOverIndex: newTargetIndex });
    
  //   this.dragPreview.style.left = `${e.clientX}px`;
  //   this.dragPreview.style.top = `${e.clientY}px`;
  //   this.dragPreview.style.transform = 'translate(-50%, -50%)';
  // };
  
  //Aman-5271-added Method to handle drag end
  // handleDragEnd = () => {
  //   if (this.dragPreview) {
  //       document.body.removeChild(this.dragPreview);
  //       this.dragPreview = null;
  //   }
  //   this.setState({ draggingIndex: null, dragOverIndex: null, draggingList: null });
  // };

  //Aman-5271-added Method to handle drop
  // handleDrop = (e, targetIndex, listType) => {
  //   e.preventDefault();
  //   const dragIndex = parseInt(e.dataTransfer.getData("text/plain"), 10);
    
  //   if (dragIndex !== targetIndex) {
  //       // If dragging within the same list
  //       if (listType === 'existing' && this.state.draggingList === 'existing') {
  //           this.moveItem(dragIndex, targetIndex, 'existing');
  //       } else if (listType === 'selected' && this.state.draggingList === 'selected') {
  //           this.moveItem(dragIndex, targetIndex, 'selected');
  //       }
  //   }

  //   //Aman-5271- Change the background color of the dropped item to grey temporarily
  //   const droppedItem = e.currentTarget;
  //   droppedItem.style.backgroundColor = '#B0B0B0';
  //   this.droppedItem = droppedItem;

  //   this.setState({ draggingIndex: null, dragOverIndex: null, draggingList: null });
  // };

  //Aman-5271-added Method to handle drag enter
  // handleDragEnter = (e) => {
  //   e.preventDefault();
  //   e.dataTransfer.dropEffect = 'move';
  //   e.target.classList.add('drag-over'); //Aman-5271-Add visual feedback
  // };
  
  //Aman-5271-added Method to handle drag leave
  // handleDragLeave = (e) => {
  //   e.preventDefault();
  //   e.target.classList.remove('drag-over'); //Aman-5271-added to Remove drag-over class
  // };

  //Aman-5271-added Method to swap items
  // moveItem = (fromIndex, toIndex, listType) => {
  //   const newItems = listType === 'existing' ? [...this.state.existingValue] : [...this.state.selectedValue];
  //   const [movedItem] = newItems.splice(fromIndex, 1); //Aman-5271-added to Remove the item from the original position
  //   newItems.splice(toIndex, 0, movedItem); //Aman-5271-added to Insert the item at the new position
    
  //   if (listType === 'existing') {
  //     this.setState({ existingValue: newItems });
  //   } else {
  //     this.setState({ selectedValue: newItems });
  //   }
  // };

  //Nilesh-5122- Used for updating(i.e. inserting) blob in the delivery_format_mapping table
  handleApplySequencing = () => {
    //Aman-5271- Reset the color of the previously dropped item when starting a new drag
    if (this.droppedItem) {
      this.droppedItem.style.backgroundColor = '#0777cb';
      this.droppedItem = null;
    }

    //this.loadingRef.current.click();
    this.setState({ loader_progress: "loader_campaign_list" });
    //alert("this.state.existingValue ==> " +JSON.stringify(this.state.existingValue))
    let data2 = {
      campID: this.state.campID,
      campaignStatus: this.state.campaignStatus,
      columnSequenceCheck: "Yes",
    };
    // alert("data2 --------------->" + JSON.stringify(data2));

    const saveAsBlob = async (data) => {
      try {
        const jsonString = JSON.stringify(data);
        // alert("jsonString -->" + JSON.stringify(jsonString))
        //const dataArray = JSON.parse(jsonString);	//5264
        // alert("dataArray -->" + JSON.stringify(jsonString))
        const blob = new Blob([jsonString], { type: "application/json" });
        const formData = new FormData();
        formData.append("file", blob);
        formData.append("campID", data2.campID);
        formData.append("campaignStatus", data2.campaignStatus);
        formData.append("columnSequenceCheck", data2.columnSequenceCheck);

        const response = await fetch("/columnMapping/saveBlob", {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        });

        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new TypeError("Received non-JSON response");
        }
        //this.closeLoadingRef.current.click();
        //Priyanka-5457-Set Apply and Update button to disable
        this.setState({ loader_progress: "", applyFlag: true, updateFlag : true });  //Priyanka-5457
        //const responseData = await response.json();
        Swal.fire({
          text: "Column Sequence Changes Saved Successfully!", //Nilesh-5338- Change text message
          type: "success",
          icon: "success",
          confirmButtonText: "Ok",
          heightAuto: false,
          allowOutsideClick: false,
        }).then(() => {
          // Refresh the columnSequencing page

          let campID = this.state.campID;
          const { user } = this.props.auth;
          let data = {
            campID: campID,
            pID: user.id,
          };
          this.fetchColumns(data);
        });
      } catch (error) {
        Swal.fire({
          text: "Error saving sequencing. Please try again.",
          type: "error",
          icon: "error",
          confirmButtonText: "Ok",
          heightAuto: false,
          allowOutsideClick: false,
        });
      }
    };

    if (this.state.existingValue.length === 0) {
      if (this.state.selectedValue.length === 0) {
        this.setState({ loader_progress: "" });
        //Priyanka-5457-Changed pop up Message
        Swal.fire({
          text: "Please upload Selected Fields file.", //Nilesh-5270- change text
          type: "error",
          icon: "error",
          confirmButtonText: "Ok",
          heightAuto: false,
          allowOutsideClick: false,
        });
      } else {
        saveAsBlob(this.state.selectedValue);
      }
    } else {
      saveAsBlob(this.state.existingValue);
    }
  };

  //Priyanka-5397- below function used for download excel file
  downloadFile = () => {
    let availableFields = this.state.availableFields;

    if (availableFields.length === 0) {
      //alert("No available fields to download.");
      return;
    }
    if(this.state.newAvailableFields.length === 0)
    {
      availableFields = [...availableFields,...this.state.newAvailableFields]
    }

    //Priyanka-5397- Prepare data for the Excel file with both "Available Fields" and "Selected Fields"
    const excelData = [];

    //Priyanka-5397- Iterate through all customized columns
    availableFields.forEach((fieldObj) => {
      //Priyanka-5397- For each field object, iterate over its key-value pairs
      Object.entries(fieldObj).forEach(([fieldName, fieldValue]) => {
        excelData.push({
          "Available Fields": fieldValue, //Priyanka-5397- First column value in "Available Fields"
          "Selected Fields": "", //Priyanka-5397- First column value in "Available Fields"
        });
      });
    });

    //Priyanka-5397-Adding Existing sequence in template file if present.
    if (this.state.existingValue.length > 0) {
      this.state.existingValue.forEach((fieldObj, index) => {
        // Iterate over key-value pairs
        Object.entries(fieldObj).forEach(([fieldName, fieldValue]) => {
          if (fieldName === "value") {
            // Update the corresponding row in excelData
            if (index < excelData.length) {
                excelData[index]["Selected Fields"] = fieldValue; // Add value to the second column
            }
          }
        });
      });
    } 
    else{
      excelData.forEach(row => {
        row["Selected Fields"] = ""; // Ensure all are empty
    });
    }

    //Priyanka-5397- Create a new workbook and add the data
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Fields");

    //Priyanka-5397- Create a downloadable Excel file
    XLSX.writeFile(workbook, this.state.campID + "-Column Sequence Mapping.xlsx");
  };

  //Priyanka-5397- Upload excel file function
  uploadFile = (e) => {
    let self = this;
    e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
		e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
		e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
		e.click(); //to open file dialougeadded by Raunak
  
    e.addEventListener("change", function () {
        const { files } = e;
        if (files.length === 0) {
            return;
        }
  
        const file = files[0];
        const fileType = file.name.split(".").pop().toLowerCase();
  
        //Nilesh-5429- Show loader before starting the file processing
        self.setState({ loader_progress: "loader_campaign_list" });

        //Nilesh-5429- Check file format
        if (fileType !== "xls" && fileType !== "xlsx") {
            Swal.fire({
                icon: 'error',
                type: 'error',
                text: 'Please upload the correct format file xls/xlsx.',
            });
            self.setState({ loader_progress: "" });
            return;
        }
  
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: "array" });
  
            //Priyanka-5397- Assuming the first sheet
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
  
            //Nilesh-5429-Convert worksheet to JSON (header: 1 to get raw data)
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
            //Nilesh-5429-Check if the file is empty
            const isEmpty = jsonData.every(row => row.every(cell => cell === "" || cell === null || cell === undefined));
            if (jsonData.length === 0 || isEmpty) {
                Swal.fire({
                    icon: 'error',
                    type: 'error',
                    text: 'Please upload a file with data.',
                });
                self.setState({ loader_progress: "" });
                return;
            }
  
            //Nilesh-5429-Extract headers
            const headers = jsonData[0]; 
            const expectedHeader = ["Available Fields","Selected Fields"]; 
        
            //Nilesh-5429-Check if headers are valid
            if (headers.length !== expectedHeader.length || !headers.every((header, index) => header === expectedHeader[index])|| !headers.includes("Available Fields") || !headers.includes("Selected Fields")) {
                Swal.fire({
                    icon: 'error',
                    type: 'error',
                    text: "Invalid Header. Please Correct",
                });
                self.setState({ loader_progress: "" });
                return;
            }
  
            //Nilesh-5429-Extract 'Available Fields' and 'Selected Fields' columns
            let availableFieldsData = jsonData.slice(1).map((row) => row[0] || "").filter((value) => value !== "");
            let selectedFieldsData = jsonData.slice(1).map((row) => row[1] || "").filter((value) => value !== "");
  
            ///Nilesh-5429- Check if both 'Available Fields' and 'Selected Fields' are empty
            if (availableFieldsData.length === 0 && selectedFieldsData.length === 0) {
                Swal.fire({
                    icon: 'error',
                    type: 'error',
                    text: 'Please enter values in both Available Fields and Selected Fields.',
                });
                self.setState({ loader_progress: "" });
                return;
            }
  
            //Nilesh-5429- Validate 'Selected Fields'
            if (selectedFieldsData.length === 0) {
                Swal.fire({
                    icon: 'error',
                    type: 'error',
                    text: 'Please enter values in the Selected Fields.',
                });
                self.setState({ loader_progress: "" });
                return;
            }

            ///Nilesh-5429- Validate 'Available Fields'
            if (availableFieldsData.length === 0) {
                Swal.fire({
                    icon: 'error',
                    type: 'error',
                    text: 'Please enter values in the Available Fields.',
                });
                self.setState({ loader_progress: "" });
                return;
            }

            ///Nilesh-5429- Check for duplicates in 'Available Fields'
            const availableDuplicates = availableFieldsData.filter((item, index) => availableFieldsData.indexOf(item) !== index);
            if (availableDuplicates.length > 0) {
                Swal.fire({
                    icon: 'error',
                    type: 'error',
                    text: `Duplicate Fields found in Available Fields: ${availableDuplicates.join(", ")}`,
                });
                self.setState({ loader_progress: "" });
                return;
            }

            //Nilesh-5429-Check for duplicates in 'Selected Fields'
            const selectedDuplicates = selectedFieldsData.filter((item, index) => selectedFieldsData.indexOf(item) !== index);
            if (selectedDuplicates.length > 0) {
                Swal.fire({
                    icon: 'error',
                    type: 'error',
                    text: `Duplicate Fields found in Selected Fields: ${selectedDuplicates.join(", ")}`,
                });
                self.setState({ loader_progress: "" });
                return;
            }
  
            ///Nilesh-5429- Validate against availableFields in the state
           let availableFields = Object.values(self.state.availableFields[0]);

            //Nilesh-5429-Check for "Not Found Fields" (in 'Available Fields' but missing in 'Selected Fields')
            const notFoundFieldsAvl = availableFieldsData.filter(field => !availableFields.includes(field));
            if (notFoundFieldsAvl.length > 0) {
                Swal.fire({
                    icon: 'error',
                    type: 'error',
                    text: `Mismatch Field Found: ${notFoundFieldsAvl.join(", ")}`,
                });
                self.setState({ loader_progress: "" });
                return;
            }

            //Nilesh-5429-Check for "Missing Fields" (in 'Available Fields' but missing in 'Selected Fields')
            const missingFieldsAvl = availableFields.filter(field => !availableFieldsData.includes(field));
            if (missingFieldsAvl.length > 0) {
                Swal.fire({
                    icon: 'error',
                    type: 'error',
                    text: `Expected Field: ${missingFieldsAvl.join(", ")}`,
                });
                self.setState({ loader_progress: "" });
                return;
            }

            //Nilesh-5429-Check for "Not Found Fields" in 'Selected Fields' not in 'Available Fields'
            const notFoundFields = selectedFieldsData.filter(field => !availableFields.includes(field));
            if (notFoundFields.length > 0) {
                Swal.fire({
                    icon: 'error',
                    type: 'error',
                    text: `Mismatch Field Found: ${notFoundFields.join(", ")}`,
                });
                self.setState({ loader_progress: "" });
                return;
            }

            //Nilesh-5429-Check for "Missing Fields" in 'Available Fields' but missing in 'Selected Fields'
            const missingFields = availableFields.filter(field => !selectedFieldsData.includes(field));
            if (missingFields.length > 0) {
                Swal.fire({
                    icon: 'error',
                     type: 'error',
                    text: `Expected Field: ${missingFields.join(", ")}`,
                });
                self.setState({ loader_progress: "" });
                return;
            }
            
            // console.log("availableFields--->"+JSON.stringify(self.state.availableFields));
            // console.log("selectedFieldsData--->"+JSON.stringify(selectedFieldsData))
 
            //Nilesh-5429- Flatten availableFields to get key-value pairs
            const flattenedFields = Object.entries(self.state.availableFields[0]);
 
            //Nilesh-5429- Create output by matching selectedFieldsData sequence
            const orderedFields = selectedFieldsData.map(field => {
            // Find the corresponding key-value pair in availableFields
            const [key, value] = flattenedFields.find(([, v]) => v === field);
            return { key, value };
            });

            //Priyanka-5458-Added condition to display updated sequence
            if (self.state.existingValue.length > 0) { 
                self.setState({ existingValue : orderedFields, updateFlag : false, applyFlag: false });
            } else {
                self.setState({ selectedValue : orderedFields, updateFlag : false, applyFlag: false });
            }
                self.setState({ loader_progress: "" });
        };

        //Nilesh-5429- Start reading the file
        reader.readAsArrayBuffer(file);
    });
};



  //Priyanka-5397-Added campaign specification button to open in new tab
  displayCampaignSpecification(e) {
    let self = this;
    e.preventDefault(); //Somnath Task-3955 Add method
    var campID = self.state.campID;
    var parentCampID = self.state.parentCampID;
    var reallocationID = self.state.reallocationID;
    var tabkey = "1"; //Priyanka-5457-Added key to 1 to disable Edit Campaign button of Campaign Specification
    sessionStorage.setItem("campID", campID);
    sessionStorage.setItem("parentCampID", parentCampID);
    sessionStorage.setItem("reallocationID", reallocationID);
    sessionStorage.setItem("tabkey", tabkey); //Priyanka-5457-Paasing key
    window.open("/campaignSpecificationDashboard");
  }

  render() {
    this.fileInputRef = React.createRef();
    return (
      <div>
        {/*Nilesh-5122- Start - Navigation */}
        <Navigation />
        <div className="card-navigation" style={{ paddingTop: "100px"}}>{/* //Aman-5271-added  paddingTop*/}
          <div class="col-lg-4 col-md-6 text-center">
            <a
              href="/campaignList"
              onClick={() =>
                this.props.history.push("/campaignList", {
                  agencyID: this.state.agencyID,
                })
              }
            >
              <FaArrowAltCircleLeft
                size={25}
                style={{
                  float: "left",
                  marginRight: "20px",
                  marginBottom: "5px",
                  color: "#056eb8",
                }}
                title="Back to Campaign Progress"
              />
            </a>
            </div>
            <div class="col-lg-4 col-md-6 text-center">
              <label
                id="campallocation"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#056eb8",
                }}
              >
                Column Sequence Mapping <br />
                Campaign ID: {this.state.campID}
              </label>
            </div>
            {/* Campaign Specification button */}
            <div className="col-lg-4 col-md-6 col-sm-6">
              {/* Chaitanya-5076-Added Campaign Specification button*/}
              <button
                className="btn add-button"
                onClick={this.displayCampaignSpecification}
                style={{ float: "right" }} 
              >
                {" "}
                Campaign Specification
              </button>
            </div>
        </div>

        <Card
          className="card-addon"
          bordered={false}
          style={{ marginTop: "10px", borderRadius: "10px"}}
        >
          {/* End - Navigation */}
              {/*Nilesh-5122- Start - Available fields and Selected fields Box */}
          <div
            className="row my-2 mx-0"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div className="col-lg-6 col-md-12 padding-left-col md-screen-padding"
              style={{
                paddingLeft: "200px",
                paddingRight: "25px",
              }}
            >
              <h4 style={{ textAlign: "center" }}>Available Fields
              {/* Priyanka-5397-Added image to download file */}
              <img
                        className="float-right mx-1"
                        style={{ height: 19, width: 19, cursor: "pointer" }}
                        src="download1.png"
                        onClick={this.downloadFile}
                        title="Download template to map column sequence"
                      />
              </h4>
              {/* {this.state.newAvailableFields.length > 0 ? (
                <div
                  className="card"
                  style={{
                    border: "0.7px solid #CCCCCC",
                    paddingLeft: 15,
                    paddingRight: 10,
                    height: "40rem",
                    overflowY: "auto", // Add overflowY: "auto" to enable vertical scrolling
                  }}
                >
                  <ul>
                    {this.state.newAvailableFields.map((item, index) => (
                      <div key={index}>
                        {Object.entries(item).map(([key, value]) => (
                          <p
                            key={key}
                            value={value}
                            style={{
                              margin: "5px",
                              padding: "5px",
                              borderRadius: "5px",
                              fontWeight: "bold",
                              color: "#fff",
                              backgroundColor: //"#8d93a0",
                              this.state.clickedItems.includes(
                                value
                              )
                                ? "#8d93a0"
                                : "#0777cb",
                              //Priyanka-5457-Set cursor to default
                              cursor: "default"
                              // this.state.clickedItems.includes(value)
                              //   ? "default"
                              //   : "pointer",
                            }}
                          >
                            {value}
                          </p>
                        ))}
                      </div>
                    ))}
                  </ul>
                </div>
              ) : ( */}
                <div
                  className="card"
                  style={{
                    border: "0.7px solid #CCCCCC",
                    paddingLeft: 15,
                    paddingRight: 10,
                    height: "40rem",
                    overflowY: "auto", // Add overflowY: "auto" to enable vertical scrolling
                  }}
                >
                 <ul>
                    {this.state.customizedColumns.map((item, index) => (
                      <div key={index}>
                        {/* {alert("======>" + JSON.stringify(this.state.availableFields))} */}
                        {Object.entries(item).map(([key, value]) => (
                          <p
                            key={key}
                            value={value}
                            style={{
                              margin: "5px",
                              padding: "5px",
                              borderRadius: "5px",
                              fontWeight: "bold",
                              color: "#fff",
                              backgroundColor: this.state.existingValue.length === 0  ? "#0777CB" : "#8d93a0", //Nilesh-5429- When user Apply sequence then change the Available Field color
                              cursor: "default",
                            }}
                          >
                            {value}
                          </p>
                        ))}
                      </div>
                    ))}
                  
                    {this.state.newAvailableFields.length > 0 &&
                      this.state.newAvailableFields.map((item, index) => (
                        <div key={index}>
                          {/* {alert("+++>" + JSON.stringify(this.state.availableFields))} */}
                          {Object.entries(item).map(([key, value]) => (
                            <p
                              key={key}
                              value={value}
                              style={{
                                margin: "5px",
                                padding: "5px",
                                borderRadius: "5px",
                                fontWeight: "bold",
                                color: "#fff",
                                backgroundColor:"rgb(7, 119, 203)",
                                cursor: "default",
                              }}
                            >
                              {value}
                            </p>
                          ))}
                        </div>
                      ))}
                 </ul>
 
                </div>
            </div>
            <div
              align="center"
              style={{ marginBottom: "10%" }}
              id={this.state.loader_progress}
            ></div>
            {/* Priyanka-5397-Comment below code to remove arrow from UI */}
            {/* <div className="col-lg-2 justify-content-center">
              <Tooltip
                title= { this.state.clickedItems.length === 0  ? "Move All Fields" : "User Already Moved All Fields"}
              >
                <img
                  id="arrow-image" //Aman-5271-added id to access in Css
                  src="right-arrow.png"
                  alt="Arrow"
                  // style={{ cursor: "pointer", width: "100px" }}
                  style={{ cursor: this.state.clickedItems.length === 0  ? "pointer" : "not-allowed", width: "100px" }}
                  // onClick={this.handleImageClick}
                  //Nilesh-5338- Add handleImageClick disabled condition
                  onClick={this.state.clickedItems.length === 0 ? this.handleImageClick : null}
                   />
              </Tooltip>
            </div> */}
 
            {/* Chaitanya-5264-Shown existing column blob value */}
            {this.state.existingValue.length > 0 ? (
              <div
                className="col-lg-6 col-md-12 padding-left-col md-screen-padding"
                style={{
                  paddingLeft: "25px", //50px
                  paddingRight: "200px",  //Priyanka-5457-Adjusted column width
                }}
              >
                <h4 style={{ textAlign: "center" }}>Selected Fields
                {/* Priyanka-5397-Added image to upload file */}
                <img
                        className="float-right mx-1"
                        style={{ height: 19, width: 19, cursor: "pointer" }}
                        src="upload_file.png"
                        onClick={this.uploadFile}
                        title="Upload file to map column sequence"
                      />
                </h4>
                <div
                  className="card"
                  style={{
                    border: "0.7px solid #CCCCCC",
                    paddingLeft: 10,
                    paddingRight: 15,
                    height: "40rem",
                    overflowY: "auto", // Add overflowY: "auto" to enable vertical scrolling
                    position:"relative"
                  }}
                >
                <ul>
                    {this.state.existingValue.map((item, index) => (
                      <b
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor: "#0777cb",
                          margin: "5px",
                          padding: "5px",
                          borderRadius: " 5px",
                          fontSize: "bold",
                          color: "#fff",
                          hover: "",
                          float: "center"
                        }}
                      >
                        {/* //Priyanka-5457-Commented below arrow code
                        <div>
                          <VscTriangleUp
                            style={{
                              float: "right",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => this.moveExistingItemUp(index)}
                          />
                        </div> */}
                        <div>{item.value}</div>
                        {/* //Priyanka-5457-Commented below arrow code
                        <div>
                          <VscTriangleDown
                            style={{
                              float: "right",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => this.moveExistingItemDown(index)}
                          />
                        </div> */}
                      </b>
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              <div
                className="col-lg-6 col-md-12 padding-left-col md-screen-padding"
                id="selectedFieldSection"
                style={{
                  paddingLeft: "25px", //50px
                  paddingRight: "200px",  //Priyanka-5457-Adjusted column width
                }}
              >
                <h4 style={{ textAlign: "center" }}>Selected Fields
                  {/* Priyanka-5397-Added image to upload file */}
                <img
                        className="float-right mx-1"
                        style={{ height: 19, width: 19, cursor: "pointer" }}
                        src="upload_file.png"
                        onClick={this.uploadFile}
                        title="Upload file to map column sequence"
 
                      />
                </h4>
                <div
                  className="card"
                  style={{
                    border: "0.7px solid #CCCCCC",
                    paddingLeft: 10,
                    paddingRight: 15,
                    height: "40rem",
                    overflowY: "auto",
                    position:"relative"
                  }}
                >
                  <ul>
                    {this.state.selectedValue.map((item, index) => (
                      <b
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor: "#0777cb",
                          margin: "5px",
                          padding: "5px",
                          borderRadius: " 5px",
                          fontSize: "bold",
                          color: "#fff",
                          hover: "",
                        }}
                      >
                        {/* //Priyanka-5457-Commented below arrow code
                        <div>
                          <VscTriangleUp
                            style={{
                              float: "right",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => this.moveItemUp(index)}
                          />
                        </div> */}
                        <div>{item.value}</div>
                        {/* //Priyanka-5457-Commented below arrow code
                        <div>
                          <VscTriangleDown
                            style={{
                              float: "right",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => this.moveItemDown(index)}
                          />
                        </div> */}
                      </b>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          <br></br>
          {/* //Aman-5271-added padding below  also move button position*/}

          <div>
            <div style={{ float: "left" }}>
          {/* Nilesh-5310- Add instruction below  */}
          {/* {this.state.clickedItems.length > 0 || this.state.existingValue.length > 0 ?  */}
          {this.state.selectedValue.length > 0 ? 
          (<b style={{
            padding: "5px 15px",
            marginTop: "20px",
            color: "red",
            textAlign: "center"
          }}
          >Please click on "Apply/Update" button to save the Selected Fields changes.</b>)
          : ""}
           </div>

            <div style={{ float: "right" }}>
              <button
                className="btn-save-and-next"
                disabled={this.state.existingValue.length > 0 ? false : true}
                style={{
                  padding: "5px 15px",
                  cursor:
                    this.state.existingValue.length > 0
                      ? "pointer"
                      : "not-allowed",
                  opacity: this.state.existingValue.length > 0 ? 1 : 0.5,
                  marginRight: "25px",
                }}
                title="Delete Existing Column Sequence"
                onClick={this.clearExistingSequence}
              >
                Delete
              </button>
            </div>

            <div style={{ float: "right" }}>
              <button
               title="Clear Selected Fields"
                className="btn-save-and-next"
                //Nilesh-5338- Add clear button disabled condition
                disabled={this.state.existingValue.length === 0 ? (this.state.selectedValue.length > 0 ? false : true ) : true}
                style={{
                  padding: "5px 15px", //Aman-5271-added padding
                  cursor:
                  this.state.existingValue.length === 0 ? (this.state.selectedValue.length > 0 ?  "pointer" :  "not-allowed" ) :  "not-allowed",
                  opacity: this.state.existingValue.length === 0 ? (this.state.selectedValue.length > 0 ? 1 : 0.5 ) : 0.5,
                  marginRight: "25px", //Aman-5271-added margin
                }}
                onClick={this.clearData}
              >
                Clear
              </button>
            </div>

            {/* Nilesh-5122- Start - Apply Button */}
            {/* Chaitanya-5264-Change Label of 'Apply' button to 'Update' when there is no field is listed in 'Available Fields' */}
            <div style={{ float: "right" }}>
              {this.state.existingValue.length === 0 ? (   // Nilesh-5429- Add update button condition
                //Priyanka-5457-Added disable button and cursor condition
                <button
                  type="button"
                  class="btn-save-and-next"
                  disabled={this.state.applyFlag}
                  style={{ 
                    marginRight: "25px", 
                    padding: "5px 15px",
                    curser: this.state.applyFlag === false ?  "pointer" :  "not-allowed",
                    opacity: this.state.applyFlag === false ? 1 : 0.5,
                  }}
                  onClick={this.handleApplySequencing}
                >
                  Apply
                </button>
              ) : (
                //Priyanka-5457-Added disable button and cursor condition
                <button
                  type="button"
                  class="btn-save-and-next"
                  disabled={this.state.updateFlag}
                  style={{ 
                    marginRight: "25px", 
                    padding: "5px 15px",
                    curser: this.state.updateFlag === false ?  "pointer" :  "not-allowed",
                    opacity: this.state.updateFlag === false ? 1 : 0.5,
                  }}
                  onClick={this.handleApplySequencing}
                >
                  Update
                </button>
              )}
            </div>
           
          </div>
          {/* End - Button Save and Next */}
          <Footer />
        </Card>
          <br />
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(columnSequencing)
);
