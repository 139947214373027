
/* Copyright(c) 2024 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Nilesh Zinzurao - 5024
@Description:UI for Change Password at DIQA side
Date:15-03-2024
*/
import React, { Component } from "react";
import { Form, Input, button, Card, Icon, Tooltip } from "antd";
import { connect } from "react-redux";
import "./userLogin.css";
import "./changPassword.css"; //mufiz-bug-4114-Title-RESPONSIVE -Prod issue-Ref-(3910)-Agency-setting option-Responsiveness issue(On priority) */
import Navigation from "../layouts/navPage";
// import PropTypes from "prop-types";  //Nilesh-2833-Removing console warnings
import Footer from "../layouts/footer";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3723-vapt header and query params
import { FaArrowAltCircleLeft } from "react-icons/fa"; // Nilesh-5024- Add back button
// import { encryption } from "../../configration/fetch_default_headers"; //Nilesh-5024-Add encryption module for encrypt password
// const Cryptr = require("cryptr");
// const cryptr = new Cryptr(encryption.secretKey);
const Swal = require("sweetalert2");

export class ChangePasswordAgency extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      oldPassword: "",
      confirmPassword: "",
      confirmDirty: false,
      fields: {},
      errors: {},
      orgID: "",
      userDetails: [],
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      // alert(this.state.oldPassword+"  Old Password Not matching  "+values.oldPassword);

      if (!err) {
        console.log("Received values of form: ", values);
        //  if(this.state.oldPassword === values.oldPassword){
        //alert("oldPassword: "+values.oldPassword+", New password: "+values.password+", confirmPassword: "+values.confirmPassword);
        // const { isAuthenticated, user } = this.props.auth;  //Nilesh-2833-Removing console warnings
        
        let data = {
          password: values.password,
          // password: cryptr.encrypt(values.password),
        }; // karan-task-3723-vapt header and query params

        fetch("/users/setNewPassword", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.success === true) {
              //window.location.reload();
              Swal.fire({
                text: "Password Changed Successfully..",
                type: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                preConfirm: () => {
                  // window.location.reload();
                  //Priyanka-5465-Role wise redirecting to dashboard and lead review page
                  const { user } = this.props.auth;
                  if (user.role === "DC") {
                    this.props.history.push("/qualityDashboard"); 
                  } else {
                    this.props.history.push("/qualityLeadsReview");
                  }
                },
              });
              // this.props.history.push('/userLogin');
            }
            //Nilesh-5448- Not accept same password for same email
						if (response.error) {
							Swal.fire({
								icon: 'error',
								type: 'error',
								text: response.error,
								allowOutsideClick: false,
								preConfirm: () => {
								  window.location.reload();
								},
							});
						}
          });
        // }
        // else{
        //     alert("Old Password Not matching");
        // }
      }
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  validateToOldPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    if (value) {
      if (this.state.oldPassword !== form.getFieldValue("oldPassword")) {
        callback("Old password not matching!");
      }
    }
    callback();
  };

  componentDidMount() {
    // if(!this.props.auth.isAuthenticated) {
    //     this.props.history.push('/userLogin');
    // }
    // else{

    // const { isAuthenticated, user } = this.props.a //Nilesh-2833-Removing console warningsuth;
    const { user } = this.props.auth;
    var orgID = user.id;
    this.setState({ orgID: orgID });

    fetch("/users/getUserInfo", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }) // karan-task-3723-vapt header and query params
      .then((res) => res.json())
      .then((userDetails) => {
        this.setState({
          userDetails: userDetails,
          oldPassword: userDetails[0].password,
        });

        // alert("Return with response:"+JSON.stringify(userDetails[0].password));
      });
    //   }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    return (
      <div
      id="containerForPsFirst" // Bug:-4114 mufiz khan -Adedd Id for Responsive website title:-RESPONSIVE -Agency-setting option-Change password
        style={{
          backgroundColor: "rgb(216, 221, 230)",
          textAlign: "center",
          paddingTop: "111px",
        }}
      >
        <Navigation />
       
        <div class="card" id="cardMainDiv">
         {/* Nilesh-5024- Add back button */}
         {/* Priyanka-5465- Role wise navigate to Dashboard and Lead Review */}
          <div class="col-xs-8">
          {this.props.auth.user.role === "DC" ?
            (
            <a
              class="TabOne"
              href={"/qualityDashboard"}
              
              style={{ color: "#056eb8" }}
            >
              <FaArrowAltCircleLeft
                size={50}
                style={{ float: "left", paddingTop: "20px" }}
                title="Back to Dashboard"
                
              />
            </a>
            ):
            (
              <a
              class="TabOne"
              href={"/qualityLeadsReview"}
              
              style={{ color: "#056eb8" }}
            >
              <FaArrowAltCircleLeft
                size={50}
                style={{ float: "left", paddingTop: "20px" }}
                title="Back to Lead Review"
                
              />
            </a>
            )
          }
          </div>
          <div class="card-body" id="cardBodyForPass">
            <Card
              id="CardIdPs"
              style={{
                margin: "auto",
                display: "block",
                border: "2px solid #0000001c",
                borderRadius: "8px",
                backgroundColor: "rgba(20,72,152,0.0)",
              }}
            >
              <h3 style={{ textAlign: "center", color: "rgb(16, 31, 101)" }}>
                Change Password
              </h3>
              <Form  onSubmit={this.handleSubmit}>
                <br />
                <br />
                <Form.Item
                  label={<span>Old Password&nbsp;&nbsp;&nbsp;</span>}
                  hasFeedback
                  {...formItemLayout}
                >
                  {getFieldDecorator("oldPassword", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter old password!",
                      },
                      {
                        validator: this.validateToOldPassword,
                      },
                    ],
                  })(
                    <Input.Password
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)",}}
                        />
                      }
                      type="password"
                      placeholder="Old Password"
                      id="oldPassword"
                      name="oldPassword"
                    />
                  )}
                </Form.Item>

                <Form.Item
                  label={
                    <span>
                      New Password&nbsp;
                      <Tooltip title="Password with 6 characters containing a-z,A-Z,0-9,@#$%&...">
                        <Icon
                          type="question-circle-o"
                          className="iconbackground1"
                        />
                        {/*sunita-task-3818-added classname as per requirement for removing white patches */}
                      </Tooltip>
                      &nbsp;&nbsp;
                    </span>
                  }
                  hasFeedback
                  {...formItemLayout}
                >
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter New password!",
                      },
                      {
                        min: 6,
                        message: "Password can only be of 6 characters",
                      },
                      {
                        pattern:
                          "(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]){6}",
                        message: "Please enter valid password!",
                      },
                      {
                        validator: this.validateToNextPassword,
                      },
                    ],
                  })(
                    <Input.Password
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      type="password"
                      placeholder="Password"
                      id="password"
                      name="password"
                      title="Password with 6 characters containing a-z,A-Z,0-9,@#$%&..."
                    />
                  )}
                  {/* <Tooltip title="Password with 6 characters containing a-z,A-Z,0-9,@#$%&...">
                <Icon type="question-circle-o" />
              </Tooltip> */}
                </Form.Item>

                <Form.Item
                  label={<span>Confirm Password&nbsp;&nbsp;&nbsp;</span>}
                  hasFeedback
                  {...formItemLayout}
                >
                  {getFieldDecorator("confirmPassword", {
                    rules: [
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                  })(
                    <Input.Password
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      type="password"
                      placeholder="Confirm Password"
                      id="confirmPassword"
                      name="confirmPassword"
                    />
                  )}
                </Form.Item>

                <br />
                <Form.Item {...tailFormItemLayout}>
                  <button
                    type="primary"
                    htmltype="submit"
                    style={{
                      borderRadius: "40px",
                      maxWidth: "240px",
                      width: "100%",
                      fontSize: "18px",
                      backgroundImage:
                        "linear-gradient(#1f143a, #101f65, #093394)",
                      outline: "none",
                      color: "white",
                    }}
                  >
                    Submit
                  </button>
                </Form.Item>
              </Form>
              <Footer />
            </Card>
          </div>
        </div>
       
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

const WrappedChangedPassword = Form.create({ name: "change-password" })(
  ChangePasswordAgency
);
export default connect(
  mapStateToProps,
  { ChangePasswordAgency }
)(WrappedChangedPassword);
// export default ChangePassword
