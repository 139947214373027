/* Copyright(c) 2024 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Chaitanya
@Description: 5426-DC Dashboard Page created
date: 15-10-2024
*/

import React from "react";
import { connect } from "react-redux";
import Navigation from "../layouts/navPage";
import PropTypes from "prop-types";
import Footer from "../layouts/footer";
import "./publisherEmailConfig.css";
import "antd/dist/antd.css";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { css } from "emotion";
import { Table, Button } from "antd";
import "./dashboardDC.css";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import Swal from "sweetalert2";

const tableCSS = css({
  backgroundColor: "white",
  borderStyle: "bold",
  "& thead  > tr": {
    backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
    color: "white",
    fontSize: "12px",
    textAlign: "center",
    fontWeight: "500",
  },
  "& thead > tr >th": {
    textAlign: "center",
    color: "white",
  },
});

class dashboardDC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignDetails: [],
      tableState: { size: "small" }, //Array for table properties
      tableSearchText: "",
      campID: "",
      advCampID: "",
      loading1: true,
      parentCampID: "",
      reallocationID: "",
      loader_progress: "",
      campList: [],
      campDetails: [],
      leadDetails: [],
      getLeadCounts: "",
      leadDetailsCount: "",
      diqaList: [],
      selectedDiqaList: [],
      selectedDiqa: [],
      diqaAssignedList: [],
      diqa: [],
    };
    this.displayCampaignSpecification =
      this.displayCampaignSpecification.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleChangePageSize = this.handleChangePageSize.bind(this);
    this.handleAssignClick = this.handleAssignClick.bind(this);
  }

  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      const { user } = this.props.auth;
      this.setState({ role: user.role });
    }

    let self = this;
    this.setState({ loader_progress: "loader_campaign_list" });

    //5421-Chaitanya-Call to fetch camp details
    fetch("/diQARole/leadReviewDI", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((campList) => {
        if (campList.length > 0) {
          //5421-Chaitanya-Call to fetch pv review lead from lead_info table details
          fetch("/diQARole/leadReviewPV", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
            .then((campListPV) => {
              campList.push(...campListPV);

              let data = { campList };
              //5421-Chaitanya-call to get publisher details who reviewed and uploaded leads
              fetch("/diQARole/getPubDetails", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
              })
                .then((res) => res.json())
                .then((response) => {
                  this.setState({ campList: response, loader_progress: "" });
                })
                .catch(function (err) {
                  self.setState({ loading1: false, loader2: "" });
                });

              //5430-Chaitanya-call to get DIQA details
              fetch("/diQARole/getDIQADetails", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
              })
                .then((res) => res.json())
                .then((response) => {
                  this.setState({ diqaList: response, loader_progress: "" });
                  // alert("diqa role : " + JSON.stringify(this.state.diqaList));
                  //5430-Chaitanya-call to get DIQA details
                  fetch("/diQARole/getAssignedDiqa", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                  })
                    .then((res) => res.json())
                    .then((data) => {
                      // Create an object to map campID to DIQA full name
                      const assignedDiqaList = {};
                      data.forEach((assignment) => {
                        //Chaitanya-5425-Display all names of already assigned user in Assigned To
                        assignedDiqaList[assignment.campID] =
                          assignment.assignedTo;
                      });
                      //alert("assignedDiqaList ==>"+JSON.stringify(assignedDiqaList))
                      // Set the fetched assignments into state
                      this.setState({ selectedDiqaList: assignedDiqaList });
                    })
                    .catch((error) =>
                      console.error("Error loading assignments:", error)
                    );
                })
                .catch(function (err) {
                  self.setState({ loading1: false, loader2: "" });
                });
            });
        } else {
          this.setState({
            loader_progress: "",
            campList: [],
            campID: "",
            campDetails: [],
            leadDetails: [],
            getLeadCounts: "",
            leadDetailsCount: "",
          });
        }
      })
      .catch(function (err) {
        self.setState({ loading1: false, loader2: "" });
      });
  }

  displayCampaignSpecification(e) {
    e.preventDefault();
    var campID = e.target.getAttribute("campID");
    sessionStorage.setItem("campID", campID);
    window.open("/campaignSpecificationQADashboard");
  }

  handleChangePageSize(e) {
    var pageSize = e.target.value;
    this.setState({ pageSize: pageSize });
  }

  handleChangeSearch(e) {
    e.preventDefault();
    let currentList = [];
    // Variable to hold the filtered list before putting into state
    let newList = [];
    let filterData = e.target.value;
    // If the search bar isn't empty
    if (e.target.value === "") {
      //alert("if")
      newList = this.state.campList;
    } else {
      //alert("else"+JSON.stringify(this.state.campList))
      // Assign the original list to currentList
      currentList = this.state.campList;
      //alert("currentList-->"+JSON.stringify(currentList))

      newList = currentList.filter((item) => {
        // change current item to lowercase
        const lc = item;
        // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        //alert("filter-->"+filter)
        // check to see if the current list item includes the search term
        // If it does, it will be added to newList. Using lowercase eliminates
        // issues with capitalization in search terms and search content
        var data = Object.values(lc);
        var dataFinal = data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower = dataFinal.toLowerCase();
        //alert("dataToLower-->"+dataToLower)
        return dataToLower.includes(filter);
      });
      //alert("newList-->"+JSON.stringify(newList))
    }
    // Set the filtered state based on what our rules added to newList
    this.setState({
      campListSearch: [...newList],
      tableSearchText: filterData,
    });
  }

  //Chaitanya-5430-handleAssignClick function
  handleAssignClick = (record) => {
    const selectedValue = document.getElementById(
      `select-${record.campID}`
    ).value;

    // Retrieve selected DIQA user details based on the selected value
    const selectedDiqa = this.state.diqaList.find(
      (diqa) => `${diqa.firstName} ${diqa.lastName}` === selectedValue
    );

    //Chaitanya-5425-Updated swal fire message "Please Select DIQA"
    // Ensure a DIQA is selected before proceeding
    if (!selectedDiqa) {
      Swal.fire("Please select DIQA.", "", "warning");
      return;
    }

    // Data to send to backend
    const data = {
      userName: selectedDiqa.userName,
      userID: selectedDiqa.userID, // Assuming userID is part of diqaList object
      campID: record.campID, // Replace with the appropriate field for camp ID
      allocatedLead: record.totalLeadCount || 0, // Or another appropriate field if needed
      assignedTo: selectedValue, // Include the assigned DIQA name
      startDate: record.endDate,
      endDate: record.endDate,
    };

    //alert("data ==>" + JSON.stringify(data));

    fetch("/diQARole/diqa_allocation", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to allocate DIQA");
        }
      })
      .then((result) => {
        // Update the state with the assigned DIQA for the specific row
        this.setState((prevState) => ({
          selectedDiqaList: {
            ...prevState.selectedDiqaList,
            [record.campID]: selectedValue,
          },
        }));

        // Show a success alert with the assigned DIQA's name
        Swal.fire({
          icon: "success",
          type: "success",
          title: "Campaign Assigned Successfully!",
          text: `Assigned to User: ${selectedValue}`,
          confirmButtonText: "OK",
          //Chaitanya-5425-After assigning campaign, refresh page
          preConfirm: () => {
            window.location.reload();
          },
        });
      })
      .catch((error) => {
        console.error("Error assigning DIQA:", error);
        Swal.fire(
          "Error",
          "There was an error assigning the DIQA. Please try again.",
          "error"
        );
      });
  };

  render() {
    const asterisk = {
      color: "red",
    };
    const { user } = this.props.auth;

    //5421-Chaitanya-to show columns data
    const columns = [
      {
        title: "Campaign ID",
        dataIndex: "campID",
        key: "campID",
        width: "6%",
        align: "center",
        sorter: (a, b) => a.campID - b.campID,
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "12px" }}>
              {record.campID ? record.campID : text}
            </span>
          </div>
        ),
      },
      {
        title: "Agency Camp ID",
        dataIndex: "agencycampID",
        key: "agencycampID",
        width: "6%",
        align: "center",
        sorter: (a, b) => a.agencycampID - b.agencycampID,
        render: (text, record) => (
          <div style={{ width: "75px" }}>
            <span style={{ fontSize: "12px" }}>
              {record.clientCampID ? record.clientCampID : text}
            </span>
            <br />
          </div>
        ),
      },
      //Chaitanya-5430-Added Client Name Column
      {
        title: "Client Name",
        dataIndex: "clientName",
        key: "clientName",
        width: "11%",
        align: "center",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.clientName.toUpperCase() < b.clientName.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (a.clientName.toUpperCase() > b.clientName.toUpperCase()) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div class="dot1" style={{ width: "100%" }} title={record.clientName}>
            {/* Chaitanya-5425-Displayed full name in tooltip for Client Name. */}
            <span style={{ fontSize: "12px" }}>
              {record.clientName ? record.clientName : text}
            </span>
          </div>
        ),
      },
      {
        title: "Campaign Name",
        dataIndex: "campaignName",
        key: "campaignName",
        width: "17%",
        align: "center",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div
            class="dot1"
            style={{ width: "100%" }}
            title={record.campaignName}
          >
            {/* Chaitanya-5425-Displayed full name in tooltip for Campaign Name. */}
            <span>
              {record.campaignName ? (
                <a
                  href="#/" //karan-task-3895-link issue //Rutuja 2978 chnaged # to #/
                  onClick={this.displayCampaignSpecification}
                  campID={record.campID}
                  campaignName={record.campaignName}
                  parentCampID={record.parentCampID}
                  reallocationID={record.reallocationID}
                  style={{ fontSize: "12px", width: "100px" }}
                >
                  {record.campaignName}
                </a>
              ) : (
                <span style={{ fontSize: "12px", width: "100px" }}>
                  <a
                    href="#/"
                    onClick={this.displayCampaignSetup}
                    advCampID={record.advCampID}
                    agencyAllocationID={record.agencyAllocationID}
                    advCampaignName={record.advCampaignName}
                  >
                    {record.advCampaignName}
                  </a>
                </span>
              )}
            </span>
            <br />
          </div>
        ),
      },
      {
        title: "Uploaded By",
        dataIndex: "startDate",
        key: "startDate",
        width: "11%",
        fontSize: "12px",
        align: "center",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.uploadedBy.toUpperCase() < b.uploadedBy.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (a.uploadedBy.toUpperCase() > b.uploadedBy.toUpperCase()) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "12px" }}>
              {record.uploadedBy ? record.uploadedBy : text}
            </span>
          </div>
        ),
      },
      {
        title: "Reviewed By",
        dataIndex: "endDate",
        key: "endDate",
        width: "11%",
        fontSize: "12px",
        align: "center",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.reviewedBy.toUpperCase() < b.reviewedBy.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (a.reviewedBy.toUpperCase() > b.reviewedBy.toUpperCase()) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "12px" }}>
              {record.reviewedBy ? record.reviewedBy : text}
            </span>
          </div>
        ),
      },
      {
        title: "Lead Count",
        align: "center",
        width: "5%",

        align: "center",
        sorter: (a, b) => a.totalLeadCount - b.totalLeadCount,
        render: (text, record) => (
          <div style={{ width: "55px" }}>
            <span style={{ fontSize: "12px" }}>
              {record.totalLeadCount ? record.totalLeadCount : text}
            </span>
            <br />
          </div>
        ),
      },
      {
        title: "Assigned To",
        width: "11%",
        align: "center",
        sorter: (a, b) => a.campID - b.campID,
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "12px" }}>
              {this.state.selectedDiqaList[record.campID] || "Not Assigned"}
            </span>
            <br />
          </div>
        ),
      },
      {
        title: "Assign",
        dataIndex: "",
        key: "",
        width: "20%",
        sorter: (a, b) => a.campID - b.campID,
        render: (text, record) => (
          <div
            style={{
              display: "flex",
              alignItems: "center", // Aligns items vertically centered
              justifyContent: "space-between", // Space between select and button
              gap: "10px", // Add some space between elements
              width: "100%",
            }}
          >
            <select
              className="form-control"
              id={`select-${record.campID}`} // unique ID for each row
              style={{
                width: "180px",
                height: "34px",
                border: "1px solid #cccccc",
                backgroundColor: "#FFFFFF",
                borderRadius: "3px",
                fontFamily: "sans-serif",
                color: "#555555",
              }}
            >
              <option value="Select DIQA">-- Select DIQA --</option>
              {this.state.diqaList.map((diqa, i) => (
                <option key={i} value={`${diqa.firstName} ${diqa.lastName}`}>
                  {diqa.firstName} {diqa.lastName}
                </option>
              ))}
            </select>

            <Button
              onClick={() => this.handleAssignClick(record)}
              style={{
                height: "34px",
                whiteSpace: "nowrap", // Prevent button text from wrapping
              }}
            >
              Assign
            </Button>
          </div>
        ),
      },
    ];

    function onChange(pagination, filters, sorter) {
      console.log("params", pagination, filters, sorter);
    }

    return (
      <div>
        <Navigation />
        <div
          className="container-fluid"
          id="Container-PUB"
          style={{ paddingTop: "85px", paddingBottom: "60px" }}
        >
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 col-xl-9">
              <span>
                <p
                  className="campaignOverview"
                  style={{
                    fontFamily: "roboto",
                    color: "black",
                    fontSize: "26px",
                    fontWeight: "500",
                    marginBottom: "20px",
                    marginTop: "25px",
                  }}
                >
                  DIQA Allocation &nbsp;&nbsp;
                  {/*Chaitanya-5430-Changed title from "Campaigns for Lead Review" to "DIQA Allocation" */}
                </p>
              </span>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <input
                type="text"
                className="inputstyle4"
                name="search"
                placeholder="Search Campaign"
                style={{
                  backgroundImage: "url(searchIcon.png)",
                  backgroundPosition: "15px 6px",
                  backgroundSize: "15px 15px",
                  backgroundRepeat: "no-repeat",
                  width: "280px",
                  height: "28px",
                  fontSize: "12px",
                  border: "1px solid #CCCCCC",
                  padding: "12px 20px 15px 36px",
                  backgroundColor: "#FFFFFF",
                  fontFamily: "sans-serif",
                  borderRadius: "2px",
                  marginTop: "35px",
                }}
                onChange={this.handleChangeSearch}
                onPressEnter={this.handleChangeSearch}
              ></input>
            </div>
          </div>

          <div align="center" id={this.state.loader_progress}></div>

          <div class="row">
            <div
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              style={{ paddingTop: "5px" }}
            >
              {this.state.tableSearchText === "" ? (
                <Table
                  {...this.state.tableState}
                  id="myTable"
                  bordered
                  dataSource={this.state.campList}
                  onChange={onChange}
                  columns={columns}
                  loading={this.state.loading}
                  className={`${tableCSS} "ant-table-thead"`}
                  scroll={{ x: 1280, y: 4000 }}
                  expandedRowRender={(record) => (
                    <div>
                      <div class="container-fluid">
                        <table
                          border="1"
                          style={{
                            tableLayout: "auto",
                            borderCollapse: "collapse",
                          }}
                        >
                          <tr>
                            <th style={{ fontWeight: "400", width: "7.5%" }}>
                              &nbsp;Campaign Type :
                            </th>
                            <td style={{ width: "9.5%" }}>
                              <b>
                                &nbsp;
                                {record.ABM ? record.ABM : record.ABM}
                              </b>
                            </td>
                            <th style={{ fontWeight: "400", width: "7.5%" }}>
                              &nbsp;Start Date :
                            </th>
                            <td style={{ width: "9.5%" }}>
                              <b>
                                &nbsp;
                                {record.startDate
                                  ? record.startDate
                                  : record.advstartDate}
                              </b>
                            </td>
                            <th style={{ fontWeight: "400", width: "7.5%" }}>
                              &nbsp;End Date :
                            </th>
                            <td style={{ width: "9.5%" }}>
                              <b>
                                &nbsp;
                                {record.endDate
                                  ? record.endDate
                                  : record.advendDate}
                              </b>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  )}
                  pagination={{
                    pageSize: this.state.pageSize,
                    position: "bottom",
                  }}
                ></Table>
              ) : (
                <Table
                  {...this.state.tableState}
                  id="myTable"
                  bordered
                  dataSource={this.state.campListSearch}
                  onChange={onChange}
                  columns={columns}
                  loading={this.state.loading}
                  className={`${tableCSS} "ant-table-thead"`}
                  scroll={{ x: 1280, y: 4000 }}
                  expandedRowRender={(record) => (
                    <div>
                      <div class="container-fluid">
                        <table
                          border="1"
                          style={{
                            tableLayout: "auto",
                            borderCollapse: "collapse",
                          }}
                        >
                          <tr>
                            <th style={{ fontWeight: "400", width: "7.5%" }}>
                              &nbsp;Campaign Type :
                            </th>
                            <td style={{ width: "9.5%" }}>
                              <b>
                                &nbsp;
                                {record.ABM ? record.ABM : record.ABM}
                              </b>
                            </td>
                            <th style={{ fontWeight: "400", width: "7.5%" }}>
                              &nbsp;Start Date :
                            </th>
                            <td style={{ width: "9.5%" }}>
                              <b>
                                &nbsp;
                                {record.startDate
                                  ? record.startDate
                                  : record.advstartDate}
                              </b>
                            </td>
                            <th style={{ fontWeight: "400", width: "7.5%" }}>
                              &nbsp;End Date :
                            </th>
                            <td style={{ width: "9.5%" }}>
                              <b>
                                &nbsp;
                                {record.endDate
                                  ? record.endDate
                                  : record.advendDate}
                              </b>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  )}
                  pagination={{
                    pageSize: this.state.pageSize,
                    position: "bottom",
                  }}
                ></Table>
              )}
            </div>
          </div>

          <div class="row">
            <div
              class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style={{ marginTop: "-45px", marginLeft: "5px" }}
            >
              &nbsp;Records per page : &nbsp;
              <select
                defaultValue={this.state.pageSize}
                onChange={this.handleChangePageSize}
                id="pacing1"
                class="input-small"
                className="form-control"
                name="pacing"
                style={{ width: "71px", height: "30px", display: "initial" }}
              >
                <option value="25" selected>
                  25
                </option>
                <option value="50">50</option>
                <option value="75">75</option>
              </select>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
dashboardDC.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(dashboardDC)
);
