/* Copyright(c) 2021 APSS Media Pvt. Ltd.
 * All Rights Reserved
 */
/*
@author Chaitanya Shinde
@fileName : diqaReviewedLeadsFileReport.js
@Creation Date: 04-04-2024
*Desc: UI for DIQA -5074- Reviewed Leads File Report
*/

import React from "react";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
import { Table, Tooltip,Icon } from "antd";
import { css } from "emotion";
import dateTime from "node-datetime";
import styled from "styled-components";

const tableCSS = css({
  backgroundColor: "white",
  fontFamily: "sans-serif",
  borderStyle: "bold",
  "& thead  > tr": {
    backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
    color: "white",
    fontSize: "13px",
    textAlign: "center !important",
    fontWeight: "500",
  },
  "& thead > tr >th": {
    textAlign: "center !important",
    color: "white",
    fontSize: "12px",
  },
});

const xlsx = require("xlsx");
const Swal = require("sweetalert2");
// const CheckboxGroup = Checkbox.Group;
var chunk1 = []; //4639-Nilesh- stored data in batches

const HtmlTooltip = styled(Tooltip)`
         backgroundColor: '#f5f5f9',
         color: 'rgba(0, 0, 0, 0.87)',
         maxWidth: 220,
         fontSize: '12px',
         border: '1px solid #dadde9',
         `;

//sunita-task-3579- added code to change the width
let z = 8000; //not resolve giving issue
let a = 60;
if (a === 60) {
  z = 120 * a;
}

class diqaReviewedLeadsFileReport extends React.Component {
  constructor() {
    super();
    this.state = {
      tableState: { size: "small" }, //sunita-task-3413-used for table design
      campaignStatus: [], //sunita-task-3413-used to display bydefault non-selected .
      label: "", //sunita-task-3413-used to filter deadline as per label .
      type: "Advertiser Lead Tracking", //sunita-task-3413-given by default type
      pageSize: 100, //sunita-task-3413-added for page size.
      campID: "Select Campaign", //sunita-task-3413-declare campID for campaign dropdown .
      getCampaignListByADV: [], //sunita-task-3413-declare array  for getting the campaign list.
      advLeadDetails: [], //sunita-task-3413-declare array to iterate table data
      token: "", //sunita-task-3413-used token for authentication
      startDate: "",
      endDate: "",

      diqaLeadsDetails: [],
      diqaLeadsDetailsSearch: [],
      leadStartSelectedDate: "",
      leadEndSelectedDate: "",
      reviewedBy: "",
      handleValueChange: [],
      selectedUser: "Select User",
      usersList: [],
      leadDetails: [],
      tableSearchText: 0,
      downloadFilesData: [],
      role: "",
    }; //end of state

    //binded all below mentioned handle changes as per requirement
    this.loadingRef = React.createRef();
    this.closeLoadingRef = React.createRef();
    this.backButtonHandleChange = this.backButtonHandleChange.bind(this);
    this.handledownload = this.handledownload.bind(this);
    this.handleChangePageSize = this.handleChangePageSize.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.startDatedateHandleChange = this.startDatedateHandleChange.bind(this);
    this.endDatedateHandleChange = this.endDatedateHandleChange.bind(this);
    this.onUserHandleChange = this.onUserHandleChange.bind(this);
  } // end of constrcutor

  backButtonHandleChange(e) {
    e.preventDefault();
    this.props.history.push("/qualityLeadsReview"); //Priyanka-5465-Changed file name
  } //end of backButtonHandleChange

  //Chaitanya-5074-added startDatedateHandleChange to handle the report startSelectedDate date
  startDatedateHandleChange(e) {
    e.preventDefault();
    var startSelectedDate = e.target.value;
    this.setState({ leadStartSelectedDate: startSelectedDate }, () => {
      this.filterleadByStartAndEndDate();
    });

    const reversedDate = this.reverseDateFormat(startSelectedDate); //Output: "17-01-2024"
    this.setState({
      stectedStartDateforDisplay: reversedDate,
      selectedDateStart: startSelectedDate, //Update state variable for input value
    });
  }
  //Chaitanya-5074-added endDatedateHandleChange to handle the report endSelectedDate date
  endDatedateHandleChange(e) {
    e.preventDefault();
    var endSelectedDate = e.target.value;
    this.setState({ leadEndSelectedDate: endSelectedDate }, () => {
      this.filterleadByStartAndEndDate();
    });

    const reversedDate = this.reverseDateFormat(endSelectedDate); //Output: "04-04-2024"
    this.setState({
      stectedEndDateforDisplay: reversedDate,
      selectedDateEnd: endSelectedDate, //Update state variable for input value
    });
  }

  filterleadByStartAndEndDate(e) {
    if (
      this.state.leadStartSelectedDate !== "" &&
      this.state.leadEndSelectedDate !== ""
    ) {
      this.setState({ loading1: true, loader2: "loader_review" });
      let data = {
        campID: this.state.campID,
        startDate: this.state.leadStartSelectedDate,
        endDate: this.state.leadEndSelectedDate,
        userID: this.state.userID,
        role: this.state.role,
      };
      //alert("data ==> " + JSON.stringify(data));
      fetch("/diQARole/diqaLeadsReport", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())

        .then((diqaLeadsDetails) => {
          let count = diqaLeadsDetails.length;
          if (count < 0) {
            count = 0;
          }
          let userNameArray = [];
          for (let i = 0; i < diqaLeadsDetails.length; i++) {
            userNameArray.push({
              userID: diqaLeadsDetails[i].userID,
              reviewedBy: diqaLeadsDetails[i].reviewedBy,
            });
          }

          userNameArray = Array.from(
            new Set(userNameArray.map((el) => JSON.stringify(el)))
          ).map((el) => JSON.parse(el));

          this.setState({
            loading1: false,
            loader2: "",
            filterleadDetailsSatus: true,
            leadDetails: diqaLeadsDetails,
            diqaLeadsDetails: diqaLeadsDetails,
            diqaLeadsDetailsSearch: diqaLeadsDetails,
            usersList: userNameArray,
          });

          //alert("this.state.diqaLeadsDetails.reviewedBy ==>"+JSON.stringify(diqaLeadsDetails[0].reviewedBy))
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
    }
  }

  reverseDateFormat(originalDate) {
    //Added to Split the original date string
    const dateParts = originalDate.split("-");
    //Added to Rearrange the parts in the desired order
    const reversedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    return reversedDate;
  }

  /**
   * @author Chaitanya
   * @param  Description  Searching table information on campaign id
   */
  handleChangeSearch(e) {
    e.preventDefault();
    let currentList = [];
    // Variable to hold the filtered list before putting into state
    let newList = [];
    let filterData = e.target.value;
    //alert(filterData);
    let selectedUser = this.state.selectedUser;
    // If the search bar isn't empty

    if (e.target.value === "") {
      if (
        this.state.selectedDateEnd !== "" &&
        this.state.selectedDateStart !== ""
      ) {
        newList = this.state.leadDetails;
      } else {
        newList = this.state.diqaLeadsDetailsSearch;
      }
      filterData = 0;
    } else {
      // Assign the original list to currentList
      //Chaitanya-5061-Changed this.state.diqaLeadsDetailsSearch to this.state.leadDetails
      currentList = this.state.leadDetails;

      newList = currentList.filter((item) => {
        // change current item to lowercase
        const lc = item;
        //const lc = item.toLowerCase();
        // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        //alert("filter-->"+filter)
        // check to see if the current list item includes the search term
        // If it does, it will be added to newList. Using lowercase eliminates
        // issues with capitalization in search terms and search content
        var data = Object.values(lc);
        var dataFinal = data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower = dataFinal.toLowerCase();
        // alert("dataToLower-->"+dataToLower)
        return dataToLower.includes(filter);
      });
    }
    // alert(JSON.stringify(newList))

    if (selectedUser.toString() !== "Select User") {
      //alert("i")
      newList = newList.filter((element) => {
        return Number(element.userID) === Number(selectedUser);
      });
    }
    //alert(JSON.stringify(newList))

    // Set the filtered state based on what our rules added to newList
    this.setState({
      diqaLeadsDetails: [...newList],
      tableSearchText: filterData,
    });
  }

  onUserHandleChange(e) {
    e.preventDefault();
    let selectedUser = e.target.value;
    let campID = this.state.tableSearchText;
    //let diqaLeadsDetails = this.state.diqaLeadsDetails;
    //alert("selectedUser ==>" + JSON.stringify(selectedUser));
    let finalData;
    if (selectedUser === "Select User") {
      finalData = this.state.leadDetails;
    } else {
      finalData = this.state.leadDetails.filter((element) => {
        return Number(element.userID) === Number(selectedUser);
      });
    }

    if (campID !== 0) {
      finalData = finalData.filter((element) => {
        let c = element.campID;
        return c.toString().includes(campID);
      });
    }

    this.setState({
      selectedUser: selectedUser,
      diqaLeadsDetails: finalData,
      diqaLeadsDetailsSearch: finalData,
    });
    //alert("diqaLeadsDetails ==>" + JSON.stringify(this.state.diqaLeadsDetails));
  }

  handledownload(e) {
    let data = {
      diqaLeadsDetails: this.state.diqaLeadsDetails,
    };

    fetch("/diQARole/diqaLeadsReportZipFile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.blob())
      .then((downloadFilesData) => {
        //alert(JSON.stringify(downloadFilesData))
        this.setState({ downloadFilesData: downloadFilesData });
        this.handleFileShowDownload(downloadFilesData);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  handleFileShowDownload(blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    // alert(this.state.campID);
    var dt = dateTime.create();
    var date = dt.format("d-m-Y");

    let zipFileDownloadName =
      "DIQA Reviewed Lead Details Report_" + date + ".zip";

    var newBlob = new Blob([this.state.downloadFilesData], {
      type: "application/zip",
    });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = zipFileDownloadName;
    link.click();

    Swal.fire({
      text: "File Downloaded Successfully",
      type: "success",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    });
  }

  /**
   * @description : handlechange for pageSize .
   */
  handleChangePageSize(e) {
    var pageSize = e.target.value;
    this.setState({ pageSize: pageSize });
  }

  /**
   * @author : Sunita Landge - task 3413
   * @description : for disable screen if report key is present in url
   */
  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      let type;

      if (this.props.location.state !== undefined) {
        type = this.props.location.state.type;
      }

      const { user } = this.props.auth;
      const userID = user.userID;
      const role = user.role;

      this.setState({
        userID: userID,
        type: type,
        role: role,
      });
    }
  } //end of componentWillMount

  /**
   * @author : Chaitanya Shinde-task 4470
   * @description :Render part to display complete screen with functionality as per given screen .
   */

  render() {
    const asterisk = {
      color: "red",
    };

    const { user } = this.props.auth;
    let username = user.firstName + " " + user.lastName;

    const columns1 = [
      {
        title: (
          <span>
            Date&nbsp;&nbsp;&nbsp;
            <span>
              <i class="fas fa-sort"></i>
            </span>
          </span>
        ),
        dataIndex: "generateDate",
        key: "generateDate",
        width: 15,
        align: "Center",
        sorter: (a, b) => {
          const asc = "asc";

          if (a.generateDate < b.generateDate) {
            return asc ? -1 : 1;
          } else if (a.generateDate > b.generateDate) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record, i) => (
          <div style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.generateDate}
          </div>
        ),
      },
      {
        title: (
          <span>
            Campaign ID&nbsp;&nbsp;&nbsp;
            <span>
              <i class="fas fa-sort"></i>
            </span>
          </span>
        ),
        dataIndex: "campID",
        key: "campID",
        width: 10,
        align: "Center",
        sorter: (a, b) => {
          const asc = "asc";

          if (a.campID < b.campID) {
            return asc ? -1 : 1;
          } else if (a.campID > b.campID) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
              {record.campID}
            </span>
          </div>
        ),
      },
      {
        title: <span>File Name&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "fileName",
        key: "fileName",
        width: 50,
        align: "Center",

        render: (text, record, i) => (
          <div style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.fileName}
          </div>
        ),
      },
      {
        title: (
          <span>
            Lead Count&nbsp;&nbsp;&nbsp;
            <span>
              <i class="fas fa-sort"></i>
            </span>
          </span>
        ),
        dataIndex: "leadCount",
        key: "leadCount",
        width: 10,
        align: "Center",
        sorter: (a, b) => {
          const asc = "asc";

          if (a.leadCount < b.leadCount) {
            return asc ? -1 : 1;
          } else if (a.leadCount > b.leadCount) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record, i) => (
          <div id="to_truncate" style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.leadCount}
          </div>
        ),
      },
      {
        title: <span>Reviewed By&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "reviewedBy",
        key: "reviewedBy",
        width: 20,
        align: "Center",

        render: (text, record, i) => (
          <div style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.reviewedBy}
          </div>
        ),
      },
    ];

    function onChange(pagination, filters, sorter) {
      console.log("params", pagination, filters, sorter);
    }

    return (
      <div>
        <Navigation />
        <div
          style={{ paddingBottom: "60px", paddingTop: "100px" }}
          class="container-fluid "
        >
          <div class="row">
            <div
              class="col-xs-2 col-sm-2 col-md-2 col-lg-2"
              style={{ paddingLeft: "4%" }}
            >
              {/* Priyanka-5465- Changed tooltip message */}
              <a
                style={{ color: "#056eb8" }}
                href="#/"
                onClick={this.backButtonHandleChange}
              >
                <FaArrowAltCircleLeft size={32} title="Back to Lead Review" />
              </a>
            </div>

            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" align="center">
              <label id="labelDI">Reviewed Leads File Report</label>
            </div>
          </div>

          <div class="row" style={{ marginTop: "2%", marginBottom: "1%" }}>
            <div class="col-sm-6 col-md-6 col-lg-1 col-xl-1"></div>

            <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
              <label
                id="label"
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                <b>
                  Start date : <span style={asterisk}>*</span>
                </b>
                <span style={{ color: "#337ab7", fontSize: "12px" }}>
                  (dd-mm-yyyy){" "}
                </span>
              </label>
              <input
                style={{ width: "100%" }}
                type="date"
                id="startDate"
                name="startDate"
                ref={(input) => {
                  this.nameInput = input;
                }}
                min=""
                pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                value={this.state.selectedDateStart}
                //className={this.state.inputClassSdate}
                onChange={this.startDatedateHandleChange}
                class="form-control"
                required
              />
            </div>

            <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
              <label
                id="label"
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                <b>
                  End date : <span style={asterisk}>*</span>
                </b>
                <span style={{ color: "#337ab7", fontSize: "12px" }}>
                  (dd-mm-yyyy){" "}
                </span>
              </label>
              <input
                style={{ width: "100%" }}
                type="date"
                id="endDate"
                name="endDate"
                ref={(input) => {
                  this.nameInput = input;
                }}
                min=""
                pattern="[0-9]{2}-[0-9]{}-[0-9]{4}"
                value={this.state.selectedDateEnd}
                onChange={this.endDatedateHandleChange}
                className="form-control"
                required
              />
            </div>

            <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
              <label
                id="label"
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                <b>User : </b>
              </label>

              <div
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                <input
                  placeholder={username}
                  class="form-control"
                  name="AdvertiserName"
                  id="for_res"
                  style={{
                    width: "220px",
                    height: "34px",
                    border: "1px solid #cccccc",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "3px",
                    fontFamily: "sans-serif",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                  disabled
                ></input>
              </div>
            </div>
            {/* Chaitanya-5061-Removed Campaign ID name for search box  */}
            <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
              <label
                id="label"
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                  paddingTop: "19%",
                }}
              >
                {/* <b>Search : </b> */}
              </label>
              <input
                id="search-bar1"
                type="text"
                onkeyup="myFunction()"
                style={{
                  backgroundImage: "url(searchIcon.png)",
                  backgroundPosition: "9px 9px",
                  backgroundSize: "15px 14px",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                  height: "40%",
                  fontSize: "15px",
                  border: "1px solid #ddd",
                  padding: "12px 20px 12px 28px",
                  borderRadius: "3px",
                  //backgroundColor: "rgb(221, 221, 221)",
                }}
                onChange={this.handleChangeSearch}
                onPressEnter={this.handleChangeSearch}
                placeholder="Search..."
                title="Type in a name"
              />
            </div>

            <div
              class="col-sm-6 col-md-6 col-lg-2 col-xl-2"
              style={{ paddingTop: "24px" }}
            >
              <div style={{ display: "flex"}}>
              <button
                class="btn add-button"
                type="button"
                onClick={this.handledownload}
                style={{
                  backgroundColor: "#056eb8",
                  color: "white",
                  height: "52%",
                  width: "80%",
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                  fontWeight: "400",
                  borderRadius: "4px",
                }}
                disabled={this.state.diqaLeadsDetails.length > 0 ? false : true}
              >
                &nbsp; Download &nbsp;
                <i class="fa fa-cloud-download" aria-hidden="true"></i>
              </button>
              {/* Chaitanya-5442-Tooltip aligned beside the button */}
              <HtmlTooltip
                  placement="right"
                  title={
                    <React.Fragment>
                      The reviewed lead file will be accessible for 180 days
                      from the date of its creation.
                    </React.Fragment>
                  }
                >
                  <Icon
                    type="question-circle"
                    style={{ marginLeft: "8px", cursor: "pointer" }}
                  />
              </HtmlTooltip>
              </div>
            </div>

          <div class="col-sm-6 col-md-6 col-lg-1 col-xl-1"></div>
          </div>

          <div>
            <div
              class="table-responsive"
              style={{ marginBottom: "5%", paddingLeft: "5%", width: "95%" }}
            >
              {/* Chaitanya-5074-Display tabel data */}
              <Table
                {...this.state.tableState}
                id="myTable"
                bordered
                dataSource={
                  this.state.tableSearchText === ""
                    ? this.state.diqaLeadsDetails
                    : this.state.diqaLeadsDetails
                }
                columns={columns1}
                onChange={onChange}
                className={`${tableCSS} "ant-table-thead"`}
                scroll={{ x: 1200, y: 1333 }}
                pagination={{
                  pageSize: this.state.pageSize,
                  position: "bottom",
                }}
              ></Table>

              <div
                class="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6"
                style={{
                  marginTop: "-53px",
                  marginLeft: "5px",
                  fontSize: "13px",
                  color: "#4F4F4F",
                  fontFamily: "sans-serif",
                }}
              >
                Records per page:&nbsp;
                <select
                  defaultValue={this.state.pageSize}
                  onChange={this.handleChangePageSize}
                  id="pacing11"
                  class="input-small"
                  className="form-control pacingAgency"
                  name="pacing"
                  style={{
                    width: "71px",
                    height: "30px",
                    display: "initial",
                    fontSize: "13px",
                    fontFamily: "sans-serif",
                    color: "#4F4F4F",
                  }}
                >
                  <option value="100" selected>
                    100
                  </option>
                  <option option value="200">
                    200
                  </option>
                  <option value="300">300</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

diqaReviewedLeadsFileReport.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(diqaReviewedLeadsFileReport)
);
