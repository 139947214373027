
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Achal somani
@Description:UI for OTP
date: 09-12-2019 
*/

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {userLogin} from "./authentication";
import { Form, Icon, Input, Button, Checkbox,Card, Tooltip} from 'antd';
import  './userLogin.css';
import './forgotPasswordEmail.css'

const queryString = require('query-string');
const Swal =require('sweetalert2');

class ResetPwd extends React.Component  {
  
 
  constructor() {
    super();
    this.state = {
      fields: {},
      newPassword:'',
      confirmPassword:'',
      errors: {},
      email:'',
      userData:[],
      userID:'',
    }

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeConfirmPassword=this.handleChangeConfirmPassword.bind(this);
  };


  


  onSubmitHandler = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        //Aman-4876-added window method to get user id and comented queryString to hide userid from url
        // var parsed = queryString.parse(this.props.location.search);    
        // var userID=parsed.userID;
        var userID = window.localStorage.getItem("url");
        var email = window.localStorage.getItem("email");//Rutuja 5451 getting email from url
    if(this.state.newPassword=='' ||this.state.confirmPassword=='')
    {
        let errors={};
        errors['wrongPassword']=""
        errors['blankPassword']="Please Fill the Password"
        this.setState({errors:errors})
return
    }
    if(this.state.newPassword===this.state.confirmPassword)
    {
      
      var password=this.state.newPassword;
    
    var userData=this.state.userData;
    if(userData[0].forgotOTP==null||userData[0].forgotOTP==undefined||userData[0].forgotOTP=="")
    {

    }else{
      let errors={};
      errors['wrongPassword']=""
errors['blankPassword']=""
this.setState({errors:errors})
      let data={
        userID:userID,
        password:password,
        email:email //Rutuja 5451 passing email to Backend
    }
      fetch("/users/setForgotPassword", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then(response=> { 
        if(response.success==true)
        {
          // Swal.fire({text: "Password has been updated Successfully",
          //   type: 'success',
          //   confirmButtonText: 'Ok',
          //   allowOutsideClick: false,
          //   preConfirm: () => {
          //     window.location.href="/userlogin";
          //   }
          //   })

        let errors={};
        errors['success']="Password has been updated Successfully"
        this.setState({errors:errors})
        setTimeout(function(){
          //Aman-4876-added window method to set id
          window.localStorage.removeItem("url"); 
          window.location.href="/userlogin";
        },800)  

        }// end of if
        //Rutuja 5451 if password is already exists in database then showing error
        else{
          let errors={};
          errors['samePassword']="The password you entered is identical to the current one. Please choose a different password."
          this.setState({errors:errors})
        
        }
      })
    }
  } 
  else
  {
    if(this.state.newPassword!==this.state.confirmPassword)
    {
let errors={}
errors['wrongPassword']="Passwords Do Not Match"
this.setState({errors:errors})
    }
    
  }
      }
      else{
      }
    });
    
  } 
   
  handleChange(e) {
    let errors={};
    errors['blankPassword']=""
    var newPassword=e.target.value;
    this.setState({newPassword:newPassword,errors:errors})
  }

  handleChangeConfirmPassword(e)
  {
    let errors={};
    errors['blankPassword']=""
    var newPassword=e.target.value;
    var confirmPassword=e.target.value;
    this.setState({confirmPassword:confirmPassword,errors:errors})
  }

  componentDidMount()
  {
    //Aman-4876-added window method to get user id and comented queryString to hide userid from url
    //var parsed = queryString.parse(this.props.location.search);
    // var userID=parsed.userID;
    var userID = window.localStorage.getItem("url");

    this.setState({userID:userID});
    let data={
      userID:userID
  }
  fetch("/users/getForgotPasswordOTP", {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    }).then(res => res.json())
    .then(userData=> { 
      this.setState({userData:userData})
    })
  }
  
  

render() {
  const { getFieldDecorator } = this.props.form;
  const {errors} = this.state;
  return (
    <div style={{backgroundColor:'rgb(216, 221, 230)'}}>
   

    <div class="container-fluid" style={{position:"absolute",width:"100%",height:"100%"}}>
    <div class="row" style={{position:"absolute",width:"100%",height:"100%"}}>
    <div class="col-xs-12  col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center " id="forgot_password_reset_background" style={{backgroundSize:'cover', backgroundPosition: 'center center',   backgroundRepeat: 'no-repeat'}}> 
    <div id="forgot_password_reset_form" style={{marginTop:'120px',zIndex:'5'}}>
    <Card id="forgot_password_reset_form_card" style={{ width: 300, height:400, margin:"auto",marginTop:"20px",display:"block",border:"2px solid #ff000005",borderRadius:"10px",backgroundColor:'rgba(20,72,152,0.0)',paddingTop:'50px'}}> 
    <Form style={{marginTop:'-76px'}} onSubmit={this.handleSubmit}>
      <br/>
     <span style={{color:'red'}}> {errors.agency}</span>
     <br/>
     <br/>
     <span class="successMessage" style={{color:'Yellow',fontSize:'16px'}}>{this.state.errors.success}</span>
     {/* rutuja 5451 showing error message */}
     <span class="errorMessage" style={{color:'red',fontSize:'16px'}}>{this.state.errors.samePassword}</span> 
     <Form.Item style={{marginBottom:'20px'}} className='error_reset_password'>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please enter your password!'  },
            {
              pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$",
              message: 'Please Enter valid password',
          }
          ],
          })(
            <span style={{marginTop:'-30px'}}>
            {/* //Aman-4876-added some chnages in this div like (margin and padding)to reslove password error message shwoing issue */}
            <span>
              <label style={{color:'#ffffff',}}>New Password &nbsp;
              <Tooltip title="Password with 6 characters containing At least one upper case English Letter,
              At least one lower case English letter,At least one digit,At least one special character">
                {/* kiran-4280-checke responsiveness*/}
                <Icon type="question-circle-o" style={{verticalAlign: 'middle'}}/>
              </Tooltip>
              </label>
            <Input.Password style={{width: '263px', marginBottom: "20px"}}
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="New Password" onChange={this.handleChange} id="pwd" name="pwd"
            type="password"
              />
              {/* , */} {/* Aman-4876 - commented this showing in UI*/}
            </span>
             <span class="errorMessage" style={{color:"red"}}>{this.state.errors.blankPassword }</span>
            </span>
          )}
        </Form.Item>   
        <Form.Item style={{marginTop:'-30px'}} className='error_reset_password'>
          {getFieldDecorator('Re_enterpwd', {
            rules: [{ required: true,
              message: 'Please Re-enter your password!' }],
          
          
          })(
            <span style={{marginTop:'-30px'}}>
            {/* //Aman-4876-added some chnages in this div like (margin and padding)to reslove password error message shwoing issue */}
              <span>
              <label style={{color:'#ffffff'}}>Confirm Password</label>
            <Input.Password style={{width: '263px', marginBottom: "20px"}}
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Confirm Password" onChange={this.handleChangeConfirmPassword} id="Rpwd" name="Rpwd"
              type="password"
              />{/* , */} {/* Aman-4876 - commented this shwoing in UI*/}
              </span>
              <span class="errorMessage">{this.state.errors.wrongPassword}</span>
              <span class="errorMessage" style={{color:"red"}}>{this.state.errors.blankPassword }</span>
              </span>
          )}
        </Form.Item>
        <Form.Item>
          <button type="primary" htmlType="submit" className="login-form-button" onClick={this.onSubmitHandler} style={{borderRadius:'40px',fontSize:'18px',backgroundImage: 'linear-gradient(#1f143a, #101f65, #093394)',outline:'none',color:'white',width: '263px'}}>
            Submit
          </button>
      
        </Form.Item>
      </Form>
      </Card> 
      </div>
      </div>     
    </div>
    </div>

</div>);
}


      propTypes = {
        loginUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired
      }
      
      }
      const mapStateToProps = (state) => ({
        auth: state.auth,
        errors: state.errors
      })
const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(ResetPwd);
export default connect(mapStateToProps, { userLogin })(WrappedNormalLoginForm); 